import { Codelist } from './codelists.constants';

export const MdnEditorCodelist: Codelist = {
  name: 'CND Codelist (MDN Codes)',
  values: [
    {
      value: 'A',
      label: 'CODELISTCONSTANTS.MDNCode.A.label',
      description: 'CODELISTCONSTANTS.MDNCode.A.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'A01',
      label: 'CODELISTCONSTANTS.MDNCode.A01.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0101',
      label: 'CODELISTCONSTANTS.MDNCode.A0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A010101',
      label: 'CODELISTCONSTANTS.MDNCode.A010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01010101',
      label: 'CODELISTCONSTANTS.MDNCode.A01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0101010101',
      label: 'CODELISTCONSTANTS.MDNCode.A0101010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0101010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0101010102',
      label: 'CODELISTCONSTANTS.MDNCode.A0101010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0101010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A01010102',
      label: 'CODELISTCONSTANTS.MDNCode.A01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0101010201',
      label: 'CODELISTCONSTANTS.MDNCode.A0101010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0101010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0101010202',
      label: 'CODELISTCONSTANTS.MDNCode.A0101010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0101010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A01010199',
      label: 'CODELISTCONSTANTS.MDNCode.A01010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010102',
      label: 'CODELISTCONSTANTS.MDNCode.A010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01010201',
      label: 'CODELISTCONSTANTS.MDNCode.A01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01010202',
      label: 'CODELISTCONSTANTS.MDNCode.A01010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010103',
      label: 'CODELISTCONSTANTS.MDNCode.A010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01010301',
      label: 'CODELISTCONSTANTS.MDNCode.A01010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01010302',
      label: 'CODELISTCONSTANTS.MDNCode.A01010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010104',
      label: 'CODELISTCONSTANTS.MDNCode.A010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010105',
      label: 'CODELISTCONSTANTS.MDNCode.A010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010105.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01010501',
      label: 'CODELISTCONSTANTS.MDNCode.A01010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01010502',
      label: 'CODELISTCONSTANTS.MDNCode.A01010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01010502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010106',
      label: 'CODELISTCONSTANTS.MDNCode.A010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010180',
      label: 'CODELISTCONSTANTS.MDNCode.A010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010180.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01018001',
      label: 'CODELISTCONSTANTS.MDNCode.A01018001.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01018001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01018099',
      label: 'CODELISTCONSTANTS.MDNCode.A01018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01018099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010199',
      label: 'CODELISTCONSTANTS.MDNCode.A010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0102',
      label: 'CODELISTCONSTANTS.MDNCode.A0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A010201',
      label: 'CODELISTCONSTANTS.MDNCode.A010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01020101',
      label: 'CODELISTCONSTANTS.MDNCode.A01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0102010101',
      label: 'CODELISTCONSTANTS.MDNCode.A0102010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0102010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0102010102',
      label: 'CODELISTCONSTANTS.MDNCode.A0102010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0102010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A01020102',
      label: 'CODELISTCONSTANTS.MDNCode.A01020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020199',
      label: 'CODELISTCONSTANTS.MDNCode.A01020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010202',
      label: 'CODELISTCONSTANTS.MDNCode.A010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01020201',
      label: 'CODELISTCONSTANTS.MDNCode.A01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020202',
      label: 'CODELISTCONSTANTS.MDNCode.A01020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020203',
      label: 'CODELISTCONSTANTS.MDNCode.A01020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020204',
      label: 'CODELISTCONSTANTS.MDNCode.A01020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020299',
      label: 'CODELISTCONSTANTS.MDNCode.A01020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010203',
      label: 'CODELISTCONSTANTS.MDNCode.A010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01020301',
      label: 'CODELISTCONSTANTS.MDNCode.A01020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020302',
      label: 'CODELISTCONSTANTS.MDNCode.A01020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020303',
      label: 'CODELISTCONSTANTS.MDNCode.A01020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020399',
      label: 'CODELISTCONSTANTS.MDNCode.A01020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010204',
      label: 'CODELISTCONSTANTS.MDNCode.A010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01020401',
      label: 'CODELISTCONSTANTS.MDNCode.A01020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020402',
      label: 'CODELISTCONSTANTS.MDNCode.A01020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010205',
      label: 'CODELISTCONSTANTS.MDNCode.A010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010205.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01020501',
      label: 'CODELISTCONSTANTS.MDNCode.A01020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020502',
      label: 'CODELISTCONSTANTS.MDNCode.A01020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01020599',
      label: 'CODELISTCONSTANTS.MDNCode.A01020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01020599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010206',
      label: 'CODELISTCONSTANTS.MDNCode.A010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010207',
      label: 'CODELISTCONSTANTS.MDNCode.A010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010207.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010299',
      label: 'CODELISTCONSTANTS.MDNCode.A010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0103',
      label: 'CODELISTCONSTANTS.MDNCode.A0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A010301',
      label: 'CODELISTCONSTANTS.MDNCode.A010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A01030101',
      label: 'CODELISTCONSTANTS.MDNCode.A01030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01030102',
      label: 'CODELISTCONSTANTS.MDNCode.A01030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A01030103',
      label: 'CODELISTCONSTANTS.MDNCode.A01030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A01030103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A010302',
      label: 'CODELISTCONSTANTS.MDNCode.A010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010399',
      label: 'CODELISTCONSTANTS.MDNCode.A010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0104',
      label: 'CODELISTCONSTANTS.MDNCode.A0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A010401',
      label: 'CODELISTCONSTANTS.MDNCode.A010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010480',
      label: 'CODELISTCONSTANTS.MDNCode.A010480.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010499',
      label: 'CODELISTCONSTANTS.MDNCode.A010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0105',
      label: 'CODELISTCONSTANTS.MDNCode.A0105.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0105.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A010501',
      label: 'CODELISTCONSTANTS.MDNCode.A010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010502',
      label: 'CODELISTCONSTANTS.MDNCode.A010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010503',
      label: 'CODELISTCONSTANTS.MDNCode.A010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010503.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010504',
      label: 'CODELISTCONSTANTS.MDNCode.A010504.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010504.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010505',
      label: 'CODELISTCONSTANTS.MDNCode.A010505.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010505.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010506',
      label: 'CODELISTCONSTANTS.MDNCode.A010506.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010506.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010599',
      label: 'CODELISTCONSTANTS.MDNCode.A010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0106',
      label: 'CODELISTCONSTANTS.MDNCode.A0106.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0106.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A010601',
      label: 'CODELISTCONSTANTS.MDNCode.A010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010602',
      label: 'CODELISTCONSTANTS.MDNCode.A010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A010699',
      label: 'CODELISTCONSTANTS.MDNCode.A010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.A010699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0180',
      label: 'CODELISTCONSTANTS.MDNCode.A0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0180.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A018001',
      label: 'CODELISTCONSTANTS.MDNCode.A018001.label',
      description: 'CODELISTCONSTANTS.MDNCode.A018001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A018002',
      label: 'CODELISTCONSTANTS.MDNCode.A018002.label',
      description: 'CODELISTCONSTANTS.MDNCode.A018002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A018003',
      label: 'CODELISTCONSTANTS.MDNCode.A018003.label',
      description: 'CODELISTCONSTANTS.MDNCode.A018003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A018004',
      label: 'CODELISTCONSTANTS.MDNCode.A018004.label',
      description: 'CODELISTCONSTANTS.MDNCode.A018004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A018005',
      label: 'CODELISTCONSTANTS.MDNCode.A018005.label',
      description: 'CODELISTCONSTANTS.MDNCode.A018005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A018006',
      label: 'CODELISTCONSTANTS.MDNCode.A018006.label',
      description: 'CODELISTCONSTANTS.MDNCode.A018006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A018099',
      label: 'CODELISTCONSTANTS.MDNCode.A018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.A018099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0190',
      label: 'CODELISTCONSTANTS.MDNCode.A0190.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0190.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A019001',
      label: 'CODELISTCONSTANTS.MDNCode.A019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019002',
      label: 'CODELISTCONSTANTS.MDNCode.A019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019003',
      label: 'CODELISTCONSTANTS.MDNCode.A019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019004',
      label: 'CODELISTCONSTANTS.MDNCode.A019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019005',
      label: 'CODELISTCONSTANTS.MDNCode.A019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019006',
      label: 'CODELISTCONSTANTS.MDNCode.A019006.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019007',
      label: 'CODELISTCONSTANTS.MDNCode.A019007.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019008',
      label: 'CODELISTCONSTANTS.MDNCode.A019008.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019008.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019009',
      label: 'CODELISTCONSTANTS.MDNCode.A019009.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019009.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019010',
      label: 'CODELISTCONSTANTS.MDNCode.A019010.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019010.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019011',
      label: 'CODELISTCONSTANTS.MDNCode.A019011.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019011.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019012',
      label: 'CODELISTCONSTANTS.MDNCode.A019012.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019012.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019013',
      label: 'CODELISTCONSTANTS.MDNCode.A019013.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019013.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019014',
      label: 'CODELISTCONSTANTS.MDNCode.A019014.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019014.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019015',
      label: 'CODELISTCONSTANTS.MDNCode.A019015.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019015.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019016',
      label: 'CODELISTCONSTANTS.MDNCode.A019016.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019016.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A019099',
      label: 'CODELISTCONSTANTS.MDNCode.A019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.A019099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0199',
      label: 'CODELISTCONSTANTS.MDNCode.A0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A02',
      label: 'CODELISTCONSTANTS.MDNCode.A02.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0201',
      label: 'CODELISTCONSTANTS.MDNCode.A0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A020101',
      label: 'CODELISTCONSTANTS.MDNCode.A020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A020102',
      label: 'CODELISTCONSTANTS.MDNCode.A020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A02010201',
      label: 'CODELISTCONSTANTS.MDNCode.A02010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0201020101',
      label: 'CODELISTCONSTANTS.MDNCode.A0201020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0201020101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'A020102010101',
      label: 'CODELISTCONSTANTS.MDNCode.A020102010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020102010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'A020102010102',
      label: 'CODELISTCONSTANTS.MDNCode.A020102010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020102010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'A0201020102',
      label: 'CODELISTCONSTANTS.MDNCode.A0201020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0201020102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'A020102010201',
      label: 'CODELISTCONSTANTS.MDNCode.A020102010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020102010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'A020102010202',
      label: 'CODELISTCONSTANTS.MDNCode.A020102010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020102010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'A02010202',
      label: 'CODELISTCONSTANTS.MDNCode.A02010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0201020201',
      label: 'CODELISTCONSTANTS.MDNCode.A0201020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0201020201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'A020102020101',
      label: 'CODELISTCONSTANTS.MDNCode.A020102020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020102020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'A020102020102',
      label: 'CODELISTCONSTANTS.MDNCode.A020102020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020102020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'A02010203',
      label: 'CODELISTCONSTANTS.MDNCode.A02010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010204',
      label: 'CODELISTCONSTANTS.MDNCode.A02010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010205',
      label: 'CODELISTCONSTANTS.MDNCode.A02010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010299',
      label: 'CODELISTCONSTANTS.MDNCode.A02010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A020104',
      label: 'CODELISTCONSTANTS.MDNCode.A020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A02010401',
      label: 'CODELISTCONSTANTS.MDNCode.A02010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010499',
      label: 'CODELISTCONSTANTS.MDNCode.A02010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A020105',
      label: 'CODELISTCONSTANTS.MDNCode.A020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020105.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A02010501',
      label: 'CODELISTCONSTANTS.MDNCode.A02010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010502',
      label: 'CODELISTCONSTANTS.MDNCode.A02010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A020106',
      label: 'CODELISTCONSTANTS.MDNCode.A020106.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020106.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A02010601',
      label: 'CODELISTCONSTANTS.MDNCode.A02010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010602',
      label: 'CODELISTCONSTANTS.MDNCode.A02010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010603',
      label: 'CODELISTCONSTANTS.MDNCode.A02010603.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010603.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0201060301',
      label: 'CODELISTCONSTANTS.MDNCode.A0201060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0201060301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0201060302',
      label: 'CODELISTCONSTANTS.MDNCode.A0201060302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0201060302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A02010699',
      label: 'CODELISTCONSTANTS.MDNCode.A02010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A020107',
      label: 'CODELISTCONSTANTS.MDNCode.A020107.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020107.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A02010701',
      label: 'CODELISTCONSTANTS.MDNCode.A02010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010702',
      label: 'CODELISTCONSTANTS.MDNCode.A02010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A02010799',
      label: 'CODELISTCONSTANTS.MDNCode.A02010799.label',
      description: 'CODELISTCONSTANTS.MDNCode.A02010799.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A020108',
      label: 'CODELISTCONSTANTS.MDNCode.A020108.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020108.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A020109',
      label: 'CODELISTCONSTANTS.MDNCode.A020109.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020109.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A020199',
      label: 'CODELISTCONSTANTS.MDNCode.A020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0202',
      label: 'CODELISTCONSTANTS.MDNCode.A0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A020201',
      label: 'CODELISTCONSTANTS.MDNCode.A020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A020202',
      label: 'CODELISTCONSTANTS.MDNCode.A020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A020203',
      label: 'CODELISTCONSTANTS.MDNCode.A020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A020299',
      label: 'CODELISTCONSTANTS.MDNCode.A020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0280',
      label: 'CODELISTCONSTANTS.MDNCode.A0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0280.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0299',
      label: 'CODELISTCONSTANTS.MDNCode.A0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A03',
      label: 'CODELISTCONSTANTS.MDNCode.A03.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0301',
      label: 'CODELISTCONSTANTS.MDNCode.A0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A030101',
      label: 'CODELISTCONSTANTS.MDNCode.A030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A03010101',
      label: 'CODELISTCONSTANTS.MDNCode.A03010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010102',
      label: 'CODELISTCONSTANTS.MDNCode.A03010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010103',
      label: 'CODELISTCONSTANTS.MDNCode.A03010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010104',
      label: 'CODELISTCONSTANTS.MDNCode.A03010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010105',
      label: 'CODELISTCONSTANTS.MDNCode.A03010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010199',
      label: 'CODELISTCONSTANTS.MDNCode.A03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A030102',
      label: 'CODELISTCONSTANTS.MDNCode.A030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A03010201',
      label: 'CODELISTCONSTANTS.MDNCode.A03010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010202',
      label: 'CODELISTCONSTANTS.MDNCode.A03010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010299',
      label: 'CODELISTCONSTANTS.MDNCode.A03010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A030103',
      label: 'CODELISTCONSTANTS.MDNCode.A030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A03010301',
      label: 'CODELISTCONSTANTS.MDNCode.A03010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010302',
      label: 'CODELISTCONSTANTS.MDNCode.A03010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03010399',
      label: 'CODELISTCONSTANTS.MDNCode.A03010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A030104',
      label: 'CODELISTCONSTANTS.MDNCode.A030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A030105',
      label: 'CODELISTCONSTANTS.MDNCode.A030105.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A030199',
      label: 'CODELISTCONSTANTS.MDNCode.A030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0302',
      label: 'CODELISTCONSTANTS.MDNCode.A0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A030201',
      label: 'CODELISTCONSTANTS.MDNCode.A030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A03020101',
      label: 'CODELISTCONSTANTS.MDNCode.A03020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03020102',
      label: 'CODELISTCONSTANTS.MDNCode.A03020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03020199',
      label: 'CODELISTCONSTANTS.MDNCode.A03020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A0303',
      label: 'CODELISTCONSTANTS.MDNCode.A0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0303.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0304',
      label: 'CODELISTCONSTANTS.MDNCode.A0304.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0304.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A030401',
      label: 'CODELISTCONSTANTS.MDNCode.A030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A03040101',
      label: 'CODELISTCONSTANTS.MDNCode.A03040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03040101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03040102',
      label: 'CODELISTCONSTANTS.MDNCode.A03040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03040102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03040199',
      label: 'CODELISTCONSTANTS.MDNCode.A03040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A030402',
      label: 'CODELISTCONSTANTS.MDNCode.A030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A03040201',
      label: 'CODELISTCONSTANTS.MDNCode.A03040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03040202',
      label: 'CODELISTCONSTANTS.MDNCode.A03040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03040202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03040203',
      label: 'CODELISTCONSTANTS.MDNCode.A03040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03040203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03040204',
      label: 'CODELISTCONSTANTS.MDNCode.A03040204.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03040204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A03040299',
      label: 'CODELISTCONSTANTS.MDNCode.A03040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A03040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A030403',
      label: 'CODELISTCONSTANTS.MDNCode.A030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A030499',
      label: 'CODELISTCONSTANTS.MDNCode.A030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.A030499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0380',
      label: 'CODELISTCONSTANTS.MDNCode.A0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0399',
      label: 'CODELISTCONSTANTS.MDNCode.A0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A04',
      label: 'CODELISTCONSTANTS.MDNCode.A04.label',
      description: 'CODELISTCONSTANTS.MDNCode.A04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0401',
      label: 'CODELISTCONSTANTS.MDNCode.A0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0401.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A040101',
      label: 'CODELISTCONSTANTS.MDNCode.A040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A040101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A04010101',
      label: 'CODELISTCONSTANTS.MDNCode.A04010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A04010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A04010102',
      label: 'CODELISTCONSTANTS.MDNCode.A04010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A04010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A04010199',
      label: 'CODELISTCONSTANTS.MDNCode.A04010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A04010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A040180',
      label: 'CODELISTCONSTANTS.MDNCode.A040180.label',
      description: 'CODELISTCONSTANTS.MDNCode.A040180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A040199',
      label: 'CODELISTCONSTANTS.MDNCode.A040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A040199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0499',
      label: 'CODELISTCONSTANTS.MDNCode.A0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A05',
      label: 'CODELISTCONSTANTS.MDNCode.A05.label',
      description: 'CODELISTCONSTANTS.MDNCode.A05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0501',
      label: 'CODELISTCONSTANTS.MDNCode.A0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0501.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A050101',
      label: 'CODELISTCONSTANTS.MDNCode.A050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A050101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A05010101',
      label: 'CODELISTCONSTANTS.MDNCode.A05010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A05010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A05010102',
      label: 'CODELISTCONSTANTS.MDNCode.A05010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A05010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A050102',
      label: 'CODELISTCONSTANTS.MDNCode.A050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A050102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A05010201',
      label: 'CODELISTCONSTANTS.MDNCode.A05010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A05010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A05010202',
      label: 'CODELISTCONSTANTS.MDNCode.A05010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A05010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A0502',
      label: 'CODELISTCONSTANTS.MDNCode.A0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0502.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0580',
      label: 'CODELISTCONSTANTS.MDNCode.A0580.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0580.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0599',
      label: 'CODELISTCONSTANTS.MDNCode.A0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A06',
      label: 'CODELISTCONSTANTS.MDNCode.A06.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0601',
      label: 'CODELISTCONSTANTS.MDNCode.A0601.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A060101',
      label: 'CODELISTCONSTANTS.MDNCode.A060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A06010101',
      label: 'CODELISTCONSTANTS.MDNCode.A06010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0601010101',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0601010102',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0601010103',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0601010199',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A06010102',
      label: 'CODELISTCONSTANTS.MDNCode.A06010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0601010201',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0601010299',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A06010103',
      label: 'CODELISTCONSTANTS.MDNCode.A06010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A06010104',
      label: 'CODELISTCONSTANTS.MDNCode.A06010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06010104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A0601010401',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0601010402',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0601010403',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A0601010499',
      label: 'CODELISTCONSTANTS.MDNCode.A0601010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0601010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A06010199',
      label: 'CODELISTCONSTANTS.MDNCode.A06010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A060102',
      label: 'CODELISTCONSTANTS.MDNCode.A060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060199',
      label: 'CODELISTCONSTANTS.MDNCode.A060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0602',
      label: 'CODELISTCONSTANTS.MDNCode.A0602.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0602.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A060201',
      label: 'CODELISTCONSTANTS.MDNCode.A060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060202',
      label: 'CODELISTCONSTANTS.MDNCode.A060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060203',
      label: 'CODELISTCONSTANTS.MDNCode.A060203.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060204',
      label: 'CODELISTCONSTANTS.MDNCode.A060204.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060205',
      label: 'CODELISTCONSTANTS.MDNCode.A060205.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060206',
      label: 'CODELISTCONSTANTS.MDNCode.A060206.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060299',
      label: 'CODELISTCONSTANTS.MDNCode.A060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0603',
      label: 'CODELISTCONSTANTS.MDNCode.A0603.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0603.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A060301',
      label: 'CODELISTCONSTANTS.MDNCode.A060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060302',
      label: 'CODELISTCONSTANTS.MDNCode.A060302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A060303',
      label: 'CODELISTCONSTANTS.MDNCode.A060303.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A06030301',
      label: 'CODELISTCONSTANTS.MDNCode.A06030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06030301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A06030302',
      label: 'CODELISTCONSTANTS.MDNCode.A06030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06030302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A06030399',
      label: 'CODELISTCONSTANTS.MDNCode.A06030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06030399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A060304',
      label: 'CODELISTCONSTANTS.MDNCode.A060304.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060304.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A06030401',
      label: 'CODELISTCONSTANTS.MDNCode.A06030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06030401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A06030499',
      label: 'CODELISTCONSTANTS.MDNCode.A06030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.A06030499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A060399',
      label: 'CODELISTCONSTANTS.MDNCode.A060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.A060399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0680',
      label: 'CODELISTCONSTANTS.MDNCode.A0680.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0680.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0699',
      label: 'CODELISTCONSTANTS.MDNCode.A0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A07',
      label: 'CODELISTCONSTANTS.MDNCode.A07.label',
      description: 'CODELISTCONSTANTS.MDNCode.A07.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0701',
      label: 'CODELISTCONSTANTS.MDNCode.A0701.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0701.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A070101',
      label: 'CODELISTCONSTANTS.MDNCode.A070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A070101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A070102',
      label: 'CODELISTCONSTANTS.MDNCode.A070102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A070102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A070103',
      label: 'CODELISTCONSTANTS.MDNCode.A070103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A070103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A070199',
      label: 'CODELISTCONSTANTS.MDNCode.A070199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A070199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0702',
      label: 'CODELISTCONSTANTS.MDNCode.A0702.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0702.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0703',
      label: 'CODELISTCONSTANTS.MDNCode.A0703.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0703.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0704',
      label: 'CODELISTCONSTANTS.MDNCode.A0704.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0704.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0705',
      label: 'CODELISTCONSTANTS.MDNCode.A0705.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0705.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A070501',
      label: 'CODELISTCONSTANTS.MDNCode.A070501.label',
      description: 'CODELISTCONSTANTS.MDNCode.A070501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A070502',
      label: 'CODELISTCONSTANTS.MDNCode.A070502.label',
      description: 'CODELISTCONSTANTS.MDNCode.A070502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A07050201',
      label: 'CODELISTCONSTANTS.MDNCode.A07050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A07050201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A07050202',
      label: 'CODELISTCONSTANTS.MDNCode.A07050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A07050202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A070599',
      label: 'CODELISTCONSTANTS.MDNCode.A070599.label',
      description: 'CODELISTCONSTANTS.MDNCode.A070599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0706',
      label: 'CODELISTCONSTANTS.MDNCode.A0706.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0706.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0799',
      label: 'CODELISTCONSTANTS.MDNCode.A0799.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0799.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A08',
      label: 'CODELISTCONSTANTS.MDNCode.A08.label',
      description: 'CODELISTCONSTANTS.MDNCode.A08.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0801',
      label: 'CODELISTCONSTANTS.MDNCode.A0801.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0801.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A080101',
      label: 'CODELISTCONSTANTS.MDNCode.A080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A080101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A080102',
      label: 'CODELISTCONSTANTS.MDNCode.A080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A080102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A080199',
      label: 'CODELISTCONSTANTS.MDNCode.A080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A080199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0802',
      label: 'CODELISTCONSTANTS.MDNCode.A0802.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0802.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A080201',
      label: 'CODELISTCONSTANTS.MDNCode.A080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A080201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A080299',
      label: 'CODELISTCONSTANTS.MDNCode.A080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A080299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0880',
      label: 'CODELISTCONSTANTS.MDNCode.A0880.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0880.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A088001',
      label: 'CODELISTCONSTANTS.MDNCode.A088001.label',
      description: 'CODELISTCONSTANTS.MDNCode.A088001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A088099',
      label: 'CODELISTCONSTANTS.MDNCode.A088099.label',
      description: 'CODELISTCONSTANTS.MDNCode.A088099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0899',
      label: 'CODELISTCONSTANTS.MDNCode.A0899.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0899.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A09',
      label: 'CODELISTCONSTANTS.MDNCode.A09.label',
      description: 'CODELISTCONSTANTS.MDNCode.A09.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A0901',
      label: 'CODELISTCONSTANTS.MDNCode.A0901.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0901.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A090101',
      label: 'CODELISTCONSTANTS.MDNCode.A090101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A090101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A090199',
      label: 'CODELISTCONSTANTS.MDNCode.A090199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A090199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A0980',
      label: 'CODELISTCONSTANTS.MDNCode.A0980.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0980.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A0999',
      label: 'CODELISTCONSTANTS.MDNCode.A0999.label',
      description: 'CODELISTCONSTANTS.MDNCode.A0999.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A10',
      label: 'CODELISTCONSTANTS.MDNCode.A10.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A1001',
      label: 'CODELISTCONSTANTS.MDNCode.A1001.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A100101',
      label: 'CODELISTCONSTANTS.MDNCode.A100101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A10010101',
      label: 'CODELISTCONSTANTS.MDNCode.A10010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1001010101',
      label: 'CODELISTCONSTANTS.MDNCode.A1001010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1001010102',
      label: 'CODELISTCONSTANTS.MDNCode.A1001010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A10010102',
      label: 'CODELISTCONSTANTS.MDNCode.A10010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1001010201',
      label: 'CODELISTCONSTANTS.MDNCode.A1001010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1001010202',
      label: 'CODELISTCONSTANTS.MDNCode.A1001010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A100102',
      label: 'CODELISTCONSTANTS.MDNCode.A100102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A10010201',
      label: 'CODELISTCONSTANTS.MDNCode.A10010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1001020101',
      label: 'CODELISTCONSTANTS.MDNCode.A1001020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1001020102',
      label: 'CODELISTCONSTANTS.MDNCode.A1001020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A10010202',
      label: 'CODELISTCONSTANTS.MDNCode.A10010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10010202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1001020201',
      label: 'CODELISTCONSTANTS.MDNCode.A1001020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1001020202',
      label: 'CODELISTCONSTANTS.MDNCode.A1001020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A100103',
      label: 'CODELISTCONSTANTS.MDNCode.A100103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A10010301',
      label: 'CODELISTCONSTANTS.MDNCode.A10010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10010301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1001030101',
      label: 'CODELISTCONSTANTS.MDNCode.A1001030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1001030102',
      label: 'CODELISTCONSTANTS.MDNCode.A1001030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A10010302',
      label: 'CODELISTCONSTANTS.MDNCode.A10010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10010302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1001030201',
      label: 'CODELISTCONSTANTS.MDNCode.A1001030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1001030202',
      label: 'CODELISTCONSTANTS.MDNCode.A1001030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1001030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A100104',
      label: 'CODELISTCONSTANTS.MDNCode.A100104.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A100199',
      label: 'CODELISTCONSTANTS.MDNCode.A100199.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A1002',
      label: 'CODELISTCONSTANTS.MDNCode.A1002.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A100201',
      label: 'CODELISTCONSTANTS.MDNCode.A100201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A10020101',
      label: 'CODELISTCONSTANTS.MDNCode.A10020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1002010101',
      label: 'CODELISTCONSTANTS.MDNCode.A1002010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1002010102',
      label: 'CODELISTCONSTANTS.MDNCode.A1002010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A10020102',
      label: 'CODELISTCONSTANTS.MDNCode.A10020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1002010201',
      label: 'CODELISTCONSTANTS.MDNCode.A1002010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1002010202',
      label: 'CODELISTCONSTANTS.MDNCode.A1002010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A10020103',
      label: 'CODELISTCONSTANTS.MDNCode.A10020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1002010301',
      label: 'CODELISTCONSTANTS.MDNCode.A1002010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1002010302',
      label: 'CODELISTCONSTANTS.MDNCode.A1002010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A10020104',
      label: 'CODELISTCONSTANTS.MDNCode.A10020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'A1002010401',
      label: 'CODELISTCONSTANTS.MDNCode.A1002010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A1002010402',
      label: 'CODELISTCONSTANTS.MDNCode.A1002010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1002010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'A100202',
      label: 'CODELISTCONSTANTS.MDNCode.A100202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A10020201',
      label: 'CODELISTCONSTANTS.MDNCode.A10020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A10020202',
      label: 'CODELISTCONSTANTS.MDNCode.A10020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A100203',
      label: 'CODELISTCONSTANTS.MDNCode.A100203.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A10020301',
      label: 'CODELISTCONSTANTS.MDNCode.A10020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A10020302',
      label: 'CODELISTCONSTANTS.MDNCode.A10020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A100204',
      label: 'CODELISTCONSTANTS.MDNCode.A100204.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'A10020401',
      label: 'CODELISTCONSTANTS.MDNCode.A10020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A10020402',
      label: 'CODELISTCONSTANTS.MDNCode.A10020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.A10020402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'A100205',
      label: 'CODELISTCONSTANTS.MDNCode.A100205.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A100299',
      label: 'CODELISTCONSTANTS.MDNCode.A100299.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A1003',
      label: 'CODELISTCONSTANTS.MDNCode.A1003.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1003.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A100301',
      label: 'CODELISTCONSTANTS.MDNCode.A100301.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A100302',
      label: 'CODELISTCONSTANTS.MDNCode.A100302.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A100399',
      label: 'CODELISTCONSTANTS.MDNCode.A100399.label',
      description: 'CODELISTCONSTANTS.MDNCode.A100399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A1080',
      label: 'CODELISTCONSTANTS.MDNCode.A1080.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1080.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'A108001',
      label: 'CODELISTCONSTANTS.MDNCode.A108001.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A108002',
      label: 'CODELISTCONSTANTS.MDNCode.A108002.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A108003',
      label: 'CODELISTCONSTANTS.MDNCode.A108003.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A108004',
      label: 'CODELISTCONSTANTS.MDNCode.A108004.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A108005',
      label: 'CODELISTCONSTANTS.MDNCode.A108005.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A108006',
      label: 'CODELISTCONSTANTS.MDNCode.A108006.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A108007',
      label: 'CODELISTCONSTANTS.MDNCode.A108007.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A108008',
      label: 'CODELISTCONSTANTS.MDNCode.A108008.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108008.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A108099',
      label: 'CODELISTCONSTANTS.MDNCode.A108099.label',
      description: 'CODELISTCONSTANTS.MDNCode.A108099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'A1099',
      label: 'CODELISTCONSTANTS.MDNCode.A1099.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A11',
      label: 'CODELISTCONSTANTS.MDNCode.A11.label',
      description: 'CODELISTCONSTANTS.MDNCode.A11.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'A1101',
      label: 'CODELISTCONSTANTS.MDNCode.A1101.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1101.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A1102',
      label: 'CODELISTCONSTANTS.MDNCode.A1102.label',
      description: 'CODELISTCONSTANTS.MDNCode.A1102.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'A12',
      label: 'CODELISTCONSTANTS.MDNCode.A12.label',
      description: 'CODELISTCONSTANTS.MDNCode.A12.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'A99',
      label: 'CODELISTCONSTANTS.MDNCode.A99.label',
      description: 'CODELISTCONSTANTS.MDNCode.A99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'B',
      label: 'CODELISTCONSTANTS.MDNCode.B.label',
      description: 'CODELISTCONSTANTS.MDNCode.B.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'B01',
      label: 'CODELISTCONSTANTS.MDNCode.B01.label',
      description: 'CODELISTCONSTANTS.MDNCode.B01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'B0101',
      label: 'CODELISTCONSTANTS.MDNCode.B0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'B010101',
      label: 'CODELISTCONSTANTS.MDNCode.B010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'B01010101',
      label: 'CODELISTCONSTANTS.MDNCode.B01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.B01010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'B0101010101',
      label: 'CODELISTCONSTANTS.MDNCode.B0101010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B0101010199',
      label: 'CODELISTCONSTANTS.MDNCode.B0101010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B01010102',
      label: 'CODELISTCONSTANTS.MDNCode.B01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.B01010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'B0101010201',
      label: 'CODELISTCONSTANTS.MDNCode.B0101010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B0101010202',
      label: 'CODELISTCONSTANTS.MDNCode.B0101010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B0101010203',
      label: 'CODELISTCONSTANTS.MDNCode.B0101010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B0101010299',
      label: 'CODELISTCONSTANTS.MDNCode.B0101010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B010102',
      label: 'CODELISTCONSTANTS.MDNCode.B010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'B01010201',
      label: 'CODELISTCONSTANTS.MDNCode.B01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.B01010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'B0101020101',
      label: 'CODELISTCONSTANTS.MDNCode.B0101020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B0101020102',
      label: 'CODELISTCONSTANTS.MDNCode.B0101020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B0101020199',
      label: 'CODELISTCONSTANTS.MDNCode.B0101020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B01010202',
      label: 'CODELISTCONSTANTS.MDNCode.B01010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.B01010202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'B0101020201',
      label: 'CODELISTCONSTANTS.MDNCode.B0101020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B0101020202',
      label: 'CODELISTCONSTANTS.MDNCode.B0101020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B0101020299',
      label: 'CODELISTCONSTANTS.MDNCode.B0101020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0101020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'B010199',
      label: 'CODELISTCONSTANTS.MDNCode.B010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B0102',
      label: 'CODELISTCONSTANTS.MDNCode.B0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'B010201',
      label: 'CODELISTCONSTANTS.MDNCode.B010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B010202',
      label: 'CODELISTCONSTANTS.MDNCode.B010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B010299',
      label: 'CODELISTCONSTANTS.MDNCode.B010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B0103',
      label: 'CODELISTCONSTANTS.MDNCode.B0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0103.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0104',
      label: 'CODELISTCONSTANTS.MDNCode.B0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'B010401',
      label: 'CODELISTCONSTANTS.MDNCode.B010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B010402',
      label: 'CODELISTCONSTANTS.MDNCode.B010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B010403',
      label: 'CODELISTCONSTANTS.MDNCode.B010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B010499',
      label: 'CODELISTCONSTANTS.MDNCode.B010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.B010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B0105',
      label: 'CODELISTCONSTANTS.MDNCode.B0105.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0105.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0180',
      label: 'CODELISTCONSTANTS.MDNCode.B0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0180.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0190',
      label: 'CODELISTCONSTANTS.MDNCode.B0190.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0190.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'B019001',
      label: 'CODELISTCONSTANTS.MDNCode.B019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.B019001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B019002',
      label: 'CODELISTCONSTANTS.MDNCode.B019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.B019002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B019003',
      label: 'CODELISTCONSTANTS.MDNCode.B019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.B019003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B019004',
      label: 'CODELISTCONSTANTS.MDNCode.B019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.B019004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B0199',
      label: 'CODELISTCONSTANTS.MDNCode.B0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B02',
      label: 'CODELISTCONSTANTS.MDNCode.B02.label',
      description: 'CODELISTCONSTANTS.MDNCode.B02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'B0201',
      label: 'CODELISTCONSTANTS.MDNCode.B0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'B020101',
      label: 'CODELISTCONSTANTS.MDNCode.B020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.B020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'B02010101',
      label: 'CODELISTCONSTANTS.MDNCode.B02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.B02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'B02010102',
      label: 'CODELISTCONSTANTS.MDNCode.B02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.B02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'B02010199',
      label: 'CODELISTCONSTANTS.MDNCode.B02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.B02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'B020102',
      label: 'CODELISTCONSTANTS.MDNCode.B020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.B020102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'B02010201',
      label: 'CODELISTCONSTANTS.MDNCode.B02010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.B02010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'B02010202',
      label: 'CODELISTCONSTANTS.MDNCode.B02010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.B02010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'B02010299',
      label: 'CODELISTCONSTANTS.MDNCode.B02010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.B02010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'B0202',
      label: 'CODELISTCONSTANTS.MDNCode.B0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'B020201',
      label: 'CODELISTCONSTANTS.MDNCode.B020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.B020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B020202',
      label: 'CODELISTCONSTANTS.MDNCode.B020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.B020202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B0280',
      label: 'CODELISTCONSTANTS.MDNCode.B0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0280.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0299',
      label: 'CODELISTCONSTANTS.MDNCode.B0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B03',
      label: 'CODELISTCONSTANTS.MDNCode.B03.label',
      description: 'CODELISTCONSTANTS.MDNCode.B03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'B0301',
      label: 'CODELISTCONSTANTS.MDNCode.B0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'B030101',
      label: 'CODELISTCONSTANTS.MDNCode.B030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B030102',
      label: 'CODELISTCONSTANTS.MDNCode.B030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B030103',
      label: 'CODELISTCONSTANTS.MDNCode.B030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B030104',
      label: 'CODELISTCONSTANTS.MDNCode.B030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B030199',
      label: 'CODELISTCONSTANTS.MDNCode.B030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B0302',
      label: 'CODELISTCONSTANTS.MDNCode.B0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'B030201',
      label: 'CODELISTCONSTANTS.MDNCode.B030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B030202',
      label: 'CODELISTCONSTANTS.MDNCode.B030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B030203',
      label: 'CODELISTCONSTANTS.MDNCode.B030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B030204',
      label: 'CODELISTCONSTANTS.MDNCode.B030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B030299',
      label: 'CODELISTCONSTANTS.MDNCode.B030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.B030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'B0380',
      label: 'CODELISTCONSTANTS.MDNCode.B0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0399',
      label: 'CODELISTCONSTANTS.MDNCode.B0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B04',
      label: 'CODELISTCONSTANTS.MDNCode.B04.label',
      description: 'CODELISTCONSTANTS.MDNCode.B04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'B0401',
      label: 'CODELISTCONSTANTS.MDNCode.B0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0401.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0402',
      label: 'CODELISTCONSTANTS.MDNCode.B0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0402.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0480',
      label: 'CODELISTCONSTANTS.MDNCode.B0480.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0480.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0499',
      label: 'CODELISTCONSTANTS.MDNCode.B0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B05',
      label: 'CODELISTCONSTANTS.MDNCode.B05.label',
      description: 'CODELISTCONSTANTS.MDNCode.B05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'B0501',
      label: 'CODELISTCONSTANTS.MDNCode.B0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0501.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0502',
      label: 'CODELISTCONSTANTS.MDNCode.B0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0502.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B0599',
      label: 'CODELISTCONSTANTS.MDNCode.B0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.B0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'B06',
      label: 'CODELISTCONSTANTS.MDNCode.B06.label',
      description: 'CODELISTCONSTANTS.MDNCode.B06.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'B07',
      label: 'CODELISTCONSTANTS.MDNCode.B07.label',
      description: 'CODELISTCONSTANTS.MDNCode.B07.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'B99',
      label: 'CODELISTCONSTANTS.MDNCode.B99.label',
      description: 'CODELISTCONSTANTS.MDNCode.B99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'C',
      label: 'CODELISTCONSTANTS.MDNCode.C.label',
      description: 'CODELISTCONSTANTS.MDNCode.C.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'C01',
      label: 'CODELISTCONSTANTS.MDNCode.C01.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'C0101',
      label: 'CODELISTCONSTANTS.MDNCode.C0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C010101',
      label: 'CODELISTCONSTANTS.MDNCode.C010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01010101',
      label: 'CODELISTCONSTANTS.MDNCode.C01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0101010101',
      label: 'CODELISTCONSTANTS.MDNCode.C0101010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0101010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0101010102',
      label: 'CODELISTCONSTANTS.MDNCode.C0101010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0101010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C01010102',
      label: 'CODELISTCONSTANTS.MDNCode.C01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0101010201',
      label: 'CODELISTCONSTANTS.MDNCode.C0101010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0101010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0101010202',
      label: 'CODELISTCONSTANTS.MDNCode.C0101010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0101010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C01010103',
      label: 'CODELISTCONSTANTS.MDNCode.C01010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01010180',
      label: 'CODELISTCONSTANTS.MDNCode.C01010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01010180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01010199',
      label: 'CODELISTCONSTANTS.MDNCode.C01010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010102',
      label: 'CODELISTCONSTANTS.MDNCode.C010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010103',
      label: 'CODELISTCONSTANTS.MDNCode.C010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010104',
      label: 'CODELISTCONSTANTS.MDNCode.C010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010180',
      label: 'CODELISTCONSTANTS.MDNCode.C010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010199',
      label: 'CODELISTCONSTANTS.MDNCode.C010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0102',
      label: 'CODELISTCONSTANTS.MDNCode.C0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C010201',
      label: 'CODELISTCONSTANTS.MDNCode.C010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01020101',
      label: 'CODELISTCONSTANTS.MDNCode.C01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01020102',
      label: 'CODELISTCONSTANTS.MDNCode.C01020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010202',
      label: 'CODELISTCONSTANTS.MDNCode.C010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01020201',
      label: 'CODELISTCONSTANTS.MDNCode.C01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01020202',
      label: 'CODELISTCONSTANTS.MDNCode.C01020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01020203',
      label: 'CODELISTCONSTANTS.MDNCode.C01020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010203',
      label: 'CODELISTCONSTANTS.MDNCode.C010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01020301',
      label: 'CODELISTCONSTANTS.MDNCode.C01020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01020302',
      label: 'CODELISTCONSTANTS.MDNCode.C01020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01020303',
      label: 'CODELISTCONSTANTS.MDNCode.C01020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010204',
      label: 'CODELISTCONSTANTS.MDNCode.C010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01020401',
      label: 'CODELISTCONSTANTS.MDNCode.C01020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01020402',
      label: 'CODELISTCONSTANTS.MDNCode.C01020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01020499',
      label: 'CODELISTCONSTANTS.MDNCode.C01020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01020499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010280',
      label: 'CODELISTCONSTANTS.MDNCode.C010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010299',
      label: 'CODELISTCONSTANTS.MDNCode.C010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0103',
      label: 'CODELISTCONSTANTS.MDNCode.C0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C010301',
      label: 'CODELISTCONSTANTS.MDNCode.C010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010302',
      label: 'CODELISTCONSTANTS.MDNCode.C010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010303',
      label: 'CODELISTCONSTANTS.MDNCode.C010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010380',
      label: 'CODELISTCONSTANTS.MDNCode.C010380.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010399',
      label: 'CODELISTCONSTANTS.MDNCode.C010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0104',
      label: 'CODELISTCONSTANTS.MDNCode.C0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C010401',
      label: 'CODELISTCONSTANTS.MDNCode.C010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01040101',
      label: 'CODELISTCONSTANTS.MDNCode.C01040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01040101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0104010101',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104010102',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104010199',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C01040102',
      label: 'CODELISTCONSTANTS.MDNCode.C01040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01040102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0104010201',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'C010401020101',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010401020102',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010401020180',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020180.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010401020199',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C0104010202',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104010203',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104010204',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010204.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'C010401020401',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020401.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010401020402',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020402.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010401020480',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020480.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020480.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010401020499',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020499.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C0104010205',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010205.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'C010401020501',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020501.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010401020502',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020502.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010401020580',
      label: 'CODELISTCONSTANTS.MDNCode.C010401020580.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010401020580.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C0104010280',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104010299',
      label: 'CODELISTCONSTANTS.MDNCode.C0104010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C01040180',
      label: 'CODELISTCONSTANTS.MDNCode.C01040180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01040180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01040199',
      label: 'CODELISTCONSTANTS.MDNCode.C01040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010402',
      label: 'CODELISTCONSTANTS.MDNCode.C010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01040201',
      label: 'CODELISTCONSTANTS.MDNCode.C01040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01040201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0104020101',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020102',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020103',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020180',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020199',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C01040202',
      label: 'CODELISTCONSTANTS.MDNCode.C01040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01040202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0104020201',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'C010402020101',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020102',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020180',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020180.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020199',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C0104020202',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020203',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020203.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'C010402020301',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020302',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020303',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020303.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020380',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020380.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020399',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020399.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C0104020204',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020205',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020205.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020206',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020206.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020206.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'C010402020601',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020601.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020602',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020602.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020680',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020680.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020680.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020699',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020699.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020699.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C0104020207',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020207.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020207.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'C010402020701',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020701.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020702',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020702.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020702.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C010402020780',
      label: 'CODELISTCONSTANTS.MDNCode.C010402020780.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010402020780.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'C0104020208',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020208.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020209',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020209.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020280',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0104020299',
      label: 'CODELISTCONSTANTS.MDNCode.C0104020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0104020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C01040280',
      label: 'CODELISTCONSTANTS.MDNCode.C01040280.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01040280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01040299',
      label: 'CODELISTCONSTANTS.MDNCode.C01040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010403',
      label: 'CODELISTCONSTANTS.MDNCode.C010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010480',
      label: 'CODELISTCONSTANTS.MDNCode.C010480.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010499',
      label: 'CODELISTCONSTANTS.MDNCode.C010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0105',
      label: 'CODELISTCONSTANTS.MDNCode.C0105.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0105.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C010501',
      label: 'CODELISTCONSTANTS.MDNCode.C010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01050101',
      label: 'CODELISTCONSTANTS.MDNCode.C01050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01050102',
      label: 'CODELISTCONSTANTS.MDNCode.C01050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01050102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010502',
      label: 'CODELISTCONSTANTS.MDNCode.C010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01050201',
      label: 'CODELISTCONSTANTS.MDNCode.C01050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01050201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01050202',
      label: 'CODELISTCONSTANTS.MDNCode.C01050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01050202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01050203',
      label: 'CODELISTCONSTANTS.MDNCode.C01050203.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01050203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01050299',
      label: 'CODELISTCONSTANTS.MDNCode.C01050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01050299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C010580',
      label: 'CODELISTCONSTANTS.MDNCode.C010580.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010580.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C010599',
      label: 'CODELISTCONSTANTS.MDNCode.C010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.C010599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0180',
      label: 'CODELISTCONSTANTS.MDNCode.C0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0180.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0190',
      label: 'CODELISTCONSTANTS.MDNCode.C0190.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0190.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C019001',
      label: 'CODELISTCONSTANTS.MDNCode.C019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019002',
      label: 'CODELISTCONSTANTS.MDNCode.C019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019003',
      label: 'CODELISTCONSTANTS.MDNCode.C019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019004',
      label: 'CODELISTCONSTANTS.MDNCode.C019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019005',
      label: 'CODELISTCONSTANTS.MDNCode.C019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019006',
      label: 'CODELISTCONSTANTS.MDNCode.C019006.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019007',
      label: 'CODELISTCONSTANTS.MDNCode.C019007.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019008',
      label: 'CODELISTCONSTANTS.MDNCode.C019008.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019008.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019009',
      label: 'CODELISTCONSTANTS.MDNCode.C019009.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019009.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019010',
      label: 'CODELISTCONSTANTS.MDNCode.C019010.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019010.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019011',
      label: 'CODELISTCONSTANTS.MDNCode.C019011.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019011.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01901101',
      label: 'CODELISTCONSTANTS.MDNCode.C01901101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01901101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01901102',
      label: 'CODELISTCONSTANTS.MDNCode.C01901102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01901102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C019012',
      label: 'CODELISTCONSTANTS.MDNCode.C019012.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019012.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01901201',
      label: 'CODELISTCONSTANTS.MDNCode.C01901201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01901201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01901202',
      label: 'CODELISTCONSTANTS.MDNCode.C01901202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01901202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C019013',
      label: 'CODELISTCONSTANTS.MDNCode.C019013.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019013.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019014',
      label: 'CODELISTCONSTANTS.MDNCode.C019014.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019014.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019015',
      label: 'CODELISTCONSTANTS.MDNCode.C019015.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019015.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019016',
      label: 'CODELISTCONSTANTS.MDNCode.C019016.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019016.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019017',
      label: 'CODELISTCONSTANTS.MDNCode.C019017.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019017.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019018',
      label: 'CODELISTCONSTANTS.MDNCode.C019018.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019018.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C019019',
      label: 'CODELISTCONSTANTS.MDNCode.C019019.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019019.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C01901901',
      label: 'CODELISTCONSTANTS.MDNCode.C01901901.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01901901.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01901902',
      label: 'CODELISTCONSTANTS.MDNCode.C01901902.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01901902.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01901903',
      label: 'CODELISTCONSTANTS.MDNCode.C01901903.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01901903.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C01901999',
      label: 'CODELISTCONSTANTS.MDNCode.C01901999.label',
      description: 'CODELISTCONSTANTS.MDNCode.C01901999.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C019020',
      label: 'CODELISTCONSTANTS.MDNCode.C019020.label',
      description: 'CODELISTCONSTANTS.MDNCode.C019020.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0199',
      label: 'CODELISTCONSTANTS.MDNCode.C0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C02',
      label: 'CODELISTCONSTANTS.MDNCode.C02.label',
      description: 'CODELISTCONSTANTS.MDNCode.C02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'C0201',
      label: 'CODELISTCONSTANTS.MDNCode.C0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C020101',
      label: 'CODELISTCONSTANTS.MDNCode.C020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020102',
      label: 'CODELISTCONSTANTS.MDNCode.C020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020103',
      label: 'CODELISTCONSTANTS.MDNCode.C020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020104',
      label: 'CODELISTCONSTANTS.MDNCode.C020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C02010401',
      label: 'CODELISTCONSTANTS.MDNCode.C02010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.C02010401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C02010402',
      label: 'CODELISTCONSTANTS.MDNCode.C02010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.C02010402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C02010403',
      label: 'CODELISTCONSTANTS.MDNCode.C02010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.C02010403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C02010499',
      label: 'CODELISTCONSTANTS.MDNCode.C02010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.C02010499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C020105',
      label: 'CODELISTCONSTANTS.MDNCode.C020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020180',
      label: 'CODELISTCONSTANTS.MDNCode.C020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020199',
      label: 'CODELISTCONSTANTS.MDNCode.C020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0202',
      label: 'CODELISTCONSTANTS.MDNCode.C0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C020201',
      label: 'CODELISTCONSTANTS.MDNCode.C020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020202',
      label: 'CODELISTCONSTANTS.MDNCode.C020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020203',
      label: 'CODELISTCONSTANTS.MDNCode.C020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020280',
      label: 'CODELISTCONSTANTS.MDNCode.C020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020299',
      label: 'CODELISTCONSTANTS.MDNCode.C020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0203',
      label: 'CODELISTCONSTANTS.MDNCode.C0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C020301',
      label: 'CODELISTCONSTANTS.MDNCode.C020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020302',
      label: 'CODELISTCONSTANTS.MDNCode.C020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020303',
      label: 'CODELISTCONSTANTS.MDNCode.C020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020380',
      label: 'CODELISTCONSTANTS.MDNCode.C020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020399',
      label: 'CODELISTCONSTANTS.MDNCode.C020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0204',
      label: 'CODELISTCONSTANTS.MDNCode.C0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0204.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C020401',
      label: 'CODELISTCONSTANTS.MDNCode.C020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020402',
      label: 'CODELISTCONSTANTS.MDNCode.C020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C02040201',
      label: 'CODELISTCONSTANTS.MDNCode.C02040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C02040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C02040202',
      label: 'CODELISTCONSTANTS.MDNCode.C02040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C02040202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C02040299',
      label: 'CODELISTCONSTANTS.MDNCode.C02040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C02040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C020480',
      label: 'CODELISTCONSTANTS.MDNCode.C020480.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020499',
      label: 'CODELISTCONSTANTS.MDNCode.C020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0205',
      label: 'CODELISTCONSTANTS.MDNCode.C0205.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0205.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C020501',
      label: 'CODELISTCONSTANTS.MDNCode.C020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C020599',
      label: 'CODELISTCONSTANTS.MDNCode.C020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.C020599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0280',
      label: 'CODELISTCONSTANTS.MDNCode.C0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0280.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0299',
      label: 'CODELISTCONSTANTS.MDNCode.C0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C03',
      label: 'CODELISTCONSTANTS.MDNCode.C03.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'C0301',
      label: 'CODELISTCONSTANTS.MDNCode.C0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C030101',
      label: 'CODELISTCONSTANTS.MDNCode.C030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C03010101',
      label: 'CODELISTCONSTANTS.MDNCode.C03010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03010102',
      label: 'CODELISTCONSTANTS.MDNCode.C03010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03010104',
      label: 'CODELISTCONSTANTS.MDNCode.C03010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03010199',
      label: 'CODELISTCONSTANTS.MDNCode.C03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C030102',
      label: 'CODELISTCONSTANTS.MDNCode.C030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C030102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C030103',
      label: 'CODELISTCONSTANTS.MDNCode.C030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.C030103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C03010301',
      label: 'CODELISTCONSTANTS.MDNCode.C03010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03010399',
      label: 'CODELISTCONSTANTS.MDNCode.C03010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C030104',
      label: 'CODELISTCONSTANTS.MDNCode.C030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.C030104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C03010401',
      label: 'CODELISTCONSTANTS.MDNCode.C03010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0301040101',
      label: 'CODELISTCONSTANTS.MDNCode.C0301040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0301040101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0301040102',
      label: 'CODELISTCONSTANTS.MDNCode.C0301040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0301040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C03010402',
      label: 'CODELISTCONSTANTS.MDNCode.C03010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010402.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0301040201',
      label: 'CODELISTCONSTANTS.MDNCode.C0301040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0301040201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0301040202',
      label: 'CODELISTCONSTANTS.MDNCode.C0301040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0301040202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C03010403',
      label: 'CODELISTCONSTANTS.MDNCode.C03010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03010404',
      label: 'CODELISTCONSTANTS.MDNCode.C03010404.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010404.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'C0301040401',
      label: 'CODELISTCONSTANTS.MDNCode.C0301040401.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0301040401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0301040402',
      label: 'CODELISTCONSTANTS.MDNCode.C0301040402.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0301040402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C0301040499',
      label: 'CODELISTCONSTANTS.MDNCode.C0301040499.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0301040499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'C03010405',
      label: 'CODELISTCONSTANTS.MDNCode.C03010405.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010405.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03010406',
      label: 'CODELISTCONSTANTS.MDNCode.C03010406.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010406.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03010499',
      label: 'CODELISTCONSTANTS.MDNCode.C03010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03010499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C030180',
      label: 'CODELISTCONSTANTS.MDNCode.C030180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C030180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C030199',
      label: 'CODELISTCONSTANTS.MDNCode.C030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0380',
      label: 'CODELISTCONSTANTS.MDNCode.C0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0390',
      label: 'CODELISTCONSTANTS.MDNCode.C0390.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0390.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C039001',
      label: 'CODELISTCONSTANTS.MDNCode.C039001.label',
      description: 'CODELISTCONSTANTS.MDNCode.C039001.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C03900101',
      label: 'CODELISTCONSTANTS.MDNCode.C03900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03900101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03900102',
      label: 'CODELISTCONSTANTS.MDNCode.C03900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03900102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C039002',
      label: 'CODELISTCONSTANTS.MDNCode.C039002.label',
      description: 'CODELISTCONSTANTS.MDNCode.C039002.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C03900201',
      label: 'CODELISTCONSTANTS.MDNCode.C03900201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03900201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03900202',
      label: 'CODELISTCONSTANTS.MDNCode.C03900202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03900202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03900203',
      label: 'CODELISTCONSTANTS.MDNCode.C03900203.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03900203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C03900299',
      label: 'CODELISTCONSTANTS.MDNCode.C03900299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C03900299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C0399',
      label: 'CODELISTCONSTANTS.MDNCode.C0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C04',
      label: 'CODELISTCONSTANTS.MDNCode.C04.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'C0401',
      label: 'CODELISTCONSTANTS.MDNCode.C0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0401.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C040101',
      label: 'CODELISTCONSTANTS.MDNCode.C040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C040101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C04010101',
      label: 'CODELISTCONSTANTS.MDNCode.C04010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04010102',
      label: 'CODELISTCONSTANTS.MDNCode.C04010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04010199',
      label: 'CODELISTCONSTANTS.MDNCode.C04010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C040102',
      label: 'CODELISTCONSTANTS.MDNCode.C040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C040102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C04010201',
      label: 'CODELISTCONSTANTS.MDNCode.C04010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04010202',
      label: 'CODELISTCONSTANTS.MDNCode.C04010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04010299',
      label: 'CODELISTCONSTANTS.MDNCode.C04010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C0402',
      label: 'CODELISTCONSTANTS.MDNCode.C0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0402.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C040201',
      label: 'CODELISTCONSTANTS.MDNCode.C040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C040201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C04020101',
      label: 'CODELISTCONSTANTS.MDNCode.C04020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04020102',
      label: 'CODELISTCONSTANTS.MDNCode.C04020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04020199',
      label: 'CODELISTCONSTANTS.MDNCode.C04020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C040202',
      label: 'CODELISTCONSTANTS.MDNCode.C040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C040202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C04020201',
      label: 'CODELISTCONSTANTS.MDNCode.C04020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04020202',
      label: 'CODELISTCONSTANTS.MDNCode.C04020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04020299',
      label: 'CODELISTCONSTANTS.MDNCode.C04020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C0403',
      label: 'CODELISTCONSTANTS.MDNCode.C0403.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0403.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C040301',
      label: 'CODELISTCONSTANTS.MDNCode.C040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C040301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C04030101',
      label: 'CODELISTCONSTANTS.MDNCode.C04030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04030102',
      label: 'CODELISTCONSTANTS.MDNCode.C04030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04030199',
      label: 'CODELISTCONSTANTS.MDNCode.C04030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C040302',
      label: 'CODELISTCONSTANTS.MDNCode.C040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.C040302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C04030201',
      label: 'CODELISTCONSTANTS.MDNCode.C04030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04030202',
      label: 'CODELISTCONSTANTS.MDNCode.C04030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04030202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C04030299',
      label: 'CODELISTCONSTANTS.MDNCode.C04030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.C04030299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C0480',
      label: 'CODELISTCONSTANTS.MDNCode.C0480.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0480.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0499',
      label: 'CODELISTCONSTANTS.MDNCode.C0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C05',
      label: 'CODELISTCONSTANTS.MDNCode.C05.label',
      description: 'CODELISTCONSTANTS.MDNCode.C05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'C0501',
      label: 'CODELISTCONSTANTS.MDNCode.C0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0501.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0502',
      label: 'CODELISTCONSTANTS.MDNCode.C0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0502.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0503',
      label: 'CODELISTCONSTANTS.MDNCode.C0503.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0503.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0504',
      label: 'CODELISTCONSTANTS.MDNCode.C0504.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0504.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0580',
      label: 'CODELISTCONSTANTS.MDNCode.C0580.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0580.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C058001',
      label: 'CODELISTCONSTANTS.MDNCode.C058001.label',
      description: 'CODELISTCONSTANTS.MDNCode.C058001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C058099',
      label: 'CODELISTCONSTANTS.MDNCode.C058099.label',
      description: 'CODELISTCONSTANTS.MDNCode.C058099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C0599',
      label: 'CODELISTCONSTANTS.MDNCode.C0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C06',
      label: 'CODELISTCONSTANTS.MDNCode.C06.label',
      description: 'CODELISTCONSTANTS.MDNCode.C06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'C0601',
      label: 'CODELISTCONSTANTS.MDNCode.C0601.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0601.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0602',
      label: 'CODELISTCONSTANTS.MDNCode.C0602.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0602.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0603',
      label: 'CODELISTCONSTANTS.MDNCode.C0603.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0603.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C0699',
      label: 'CODELISTCONSTANTS.MDNCode.C0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.C0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C90',
      label: 'CODELISTCONSTANTS.MDNCode.C90.label',
      description: 'CODELISTCONSTANTS.MDNCode.C90.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'C9001',
      label: 'CODELISTCONSTANTS.MDNCode.C9001.label',
      description: 'CODELISTCONSTANTS.MDNCode.C9001.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C900101',
      label: 'CODELISTCONSTANTS.MDNCode.C900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.C900101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C900102',
      label: 'CODELISTCONSTANTS.MDNCode.C900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.C900102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C900103',
      label: 'CODELISTCONSTANTS.MDNCode.C900103.label',
      description: 'CODELISTCONSTANTS.MDNCode.C900103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'C90010301',
      label: 'CODELISTCONSTANTS.MDNCode.C90010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C90010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C90010302',
      label: 'CODELISTCONSTANTS.MDNCode.C90010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.C90010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C90010303',
      label: 'CODELISTCONSTANTS.MDNCode.C90010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.C90010303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C90010399',
      label: 'CODELISTCONSTANTS.MDNCode.C90010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.C90010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'C900180',
      label: 'CODELISTCONSTANTS.MDNCode.C900180.label',
      description: 'CODELISTCONSTANTS.MDNCode.C900180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C900199',
      label: 'CODELISTCONSTANTS.MDNCode.C900199.label',
      description: 'CODELISTCONSTANTS.MDNCode.C900199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C9002',
      label: 'CODELISTCONSTANTS.MDNCode.C9002.label',
      description: 'CODELISTCONSTANTS.MDNCode.C9002.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C9003',
      label: 'CODELISTCONSTANTS.MDNCode.C9003.label',
      description: 'CODELISTCONSTANTS.MDNCode.C9003.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'C900301',
      label: 'CODELISTCONSTANTS.MDNCode.C900301.label',
      description: 'CODELISTCONSTANTS.MDNCode.C900301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C900399',
      label: 'CODELISTCONSTANTS.MDNCode.C900399.label',
      description: 'CODELISTCONSTANTS.MDNCode.C900399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'C9005',
      label: 'CODELISTCONSTANTS.MDNCode.C9005.label',
      description: 'CODELISTCONSTANTS.MDNCode.C9005.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C9006',
      label: 'CODELISTCONSTANTS.MDNCode.C9006.label',
      description: 'CODELISTCONSTANTS.MDNCode.C9006.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C9080',
      label: 'CODELISTCONSTANTS.MDNCode.C9080.label',
      description: 'CODELISTCONSTANTS.MDNCode.C9080.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'C99',
      label: 'CODELISTCONSTANTS.MDNCode.C99.label',
      description: 'CODELISTCONSTANTS.MDNCode.C99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'D',
      label: 'CODELISTCONSTANTS.MDNCode.D.label',
      description: 'CODELISTCONSTANTS.MDNCode.D.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'D01',
      label: 'CODELISTCONSTANTS.MDNCode.D01.label',
      description: 'CODELISTCONSTANTS.MDNCode.D01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'D0101',
      label: 'CODELISTCONSTANTS.MDNCode.D0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'D010101',
      label: 'CODELISTCONSTANTS.MDNCode.D010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'D01010101',
      label: 'CODELISTCONSTANTS.MDNCode.D01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D01010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D01010102',
      label: 'CODELISTCONSTANTS.MDNCode.D01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.D01010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D01010103',
      label: 'CODELISTCONSTANTS.MDNCode.D01010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.D01010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D010102',
      label: 'CODELISTCONSTANTS.MDNCode.D010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.D010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'D01010201',
      label: 'CODELISTCONSTANTS.MDNCode.D01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.D01010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D010103',
      label: 'CODELISTCONSTANTS.MDNCode.D010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.D010103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D010199',
      label: 'CODELISTCONSTANTS.MDNCode.D010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D0199',
      label: 'CODELISTCONSTANTS.MDNCode.D0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D02',
      label: 'CODELISTCONSTANTS.MDNCode.D02.label',
      description: 'CODELISTCONSTANTS.MDNCode.D02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'D0201',
      label: 'CODELISTCONSTANTS.MDNCode.D0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'D020101',
      label: 'CODELISTCONSTANTS.MDNCode.D020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'D02010101',
      label: 'CODELISTCONSTANTS.MDNCode.D02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D02010102',
      label: 'CODELISTCONSTANTS.MDNCode.D02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.D02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D02010199',
      label: 'CODELISTCONSTANTS.MDNCode.D02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D020102',
      label: 'CODELISTCONSTANTS.MDNCode.D020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.D020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D020199',
      label: 'CODELISTCONSTANTS.MDNCode.D020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D0299',
      label: 'CODELISTCONSTANTS.MDNCode.D0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D03',
      label: 'CODELISTCONSTANTS.MDNCode.D03.label',
      description: 'CODELISTCONSTANTS.MDNCode.D03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'D0301',
      label: 'CODELISTCONSTANTS.MDNCode.D0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'D030101',
      label: 'CODELISTCONSTANTS.MDNCode.D030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'D03010101',
      label: 'CODELISTCONSTANTS.MDNCode.D03010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D03010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D03010102',
      label: 'CODELISTCONSTANTS.MDNCode.D03010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.D03010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D03010199',
      label: 'CODELISTCONSTANTS.MDNCode.D03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D030102',
      label: 'CODELISTCONSTANTS.MDNCode.D030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.D030102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D030103',
      label: 'CODELISTCONSTANTS.MDNCode.D030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.D030103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D030199',
      label: 'CODELISTCONSTANTS.MDNCode.D030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D0302',
      label: 'CODELISTCONSTANTS.MDNCode.D0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0302.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D0303',
      label: 'CODELISTCONSTANTS.MDNCode.D0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0303.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'D030301',
      label: 'CODELISTCONSTANTS.MDNCode.D030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.D030301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D030302',
      label: 'CODELISTCONSTANTS.MDNCode.D030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.D030302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D030399',
      label: 'CODELISTCONSTANTS.MDNCode.D030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.D030399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D0399',
      label: 'CODELISTCONSTANTS.MDNCode.D0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D05',
      label: 'CODELISTCONSTANTS.MDNCode.D05.label',
      description: 'CODELISTCONSTANTS.MDNCode.D05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'D0501',
      label: 'CODELISTCONSTANTS.MDNCode.D0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0501.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'D050101',
      label: 'CODELISTCONSTANTS.MDNCode.D050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D050101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D050102',
      label: 'CODELISTCONSTANTS.MDNCode.D050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.D050102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D050103',
      label: 'CODELISTCONSTANTS.MDNCode.D050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.D050103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D050199',
      label: 'CODELISTCONSTANTS.MDNCode.D050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D050199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D0502',
      label: 'CODELISTCONSTANTS.MDNCode.D0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0502.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D0599',
      label: 'CODELISTCONSTANTS.MDNCode.D0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D06',
      label: 'CODELISTCONSTANTS.MDNCode.D06.label',
      description: 'CODELISTCONSTANTS.MDNCode.D06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'D0601',
      label: 'CODELISTCONSTANTS.MDNCode.D0601.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0601.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'D060101',
      label: 'CODELISTCONSTANTS.MDNCode.D060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D060101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'D06010101',
      label: 'CODELISTCONSTANTS.MDNCode.D06010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.D06010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D06010199',
      label: 'CODELISTCONSTANTS.MDNCode.D06010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D06010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'D060102',
      label: 'CODELISTCONSTANTS.MDNCode.D060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.D060102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D060199',
      label: 'CODELISTCONSTANTS.MDNCode.D060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.D060199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'D0699',
      label: 'CODELISTCONSTANTS.MDNCode.D0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D07',
      label: 'CODELISTCONSTANTS.MDNCode.D07.label',
      description: 'CODELISTCONSTANTS.MDNCode.D07.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'D0701',
      label: 'CODELISTCONSTANTS.MDNCode.D0701.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0701.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D0702',
      label: 'CODELISTCONSTANTS.MDNCode.D0702.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0702.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D0799',
      label: 'CODELISTCONSTANTS.MDNCode.D0799.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0799.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D08',
      label: 'CODELISTCONSTANTS.MDNCode.D08.label',
      description: 'CODELISTCONSTANTS.MDNCode.D08.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'D0801',
      label: 'CODELISTCONSTANTS.MDNCode.D0801.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0801.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D0899',
      label: 'CODELISTCONSTANTS.MDNCode.D0899.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0899.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D09',
      label: 'CODELISTCONSTANTS.MDNCode.D09.label',
      description: 'CODELISTCONSTANTS.MDNCode.D09.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'D0901',
      label: 'CODELISTCONSTANTS.MDNCode.D0901.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0901.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D0902',
      label: 'CODELISTCONSTANTS.MDNCode.D0902.label',
      description: 'CODELISTCONSTANTS.MDNCode.D0902.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'D99',
      label: 'CODELISTCONSTANTS.MDNCode.D99.label',
      description: 'CODELISTCONSTANTS.MDNCode.D99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'F',
      label: 'CODELISTCONSTANTS.MDNCode.F.label',
      description: 'CODELISTCONSTANTS.MDNCode.F.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'F01',
      label: 'CODELISTCONSTANTS.MDNCode.F01.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'F0106',
      label: 'CODELISTCONSTANTS.MDNCode.F0106.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0106.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F010601',
      label: 'CODELISTCONSTANTS.MDNCode.F010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010601.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'F01060101',
      label: 'CODELISTCONSTANTS.MDNCode.F01060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060102',
      label: 'CODELISTCONSTANTS.MDNCode.F01060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060103',
      label: 'CODELISTCONSTANTS.MDNCode.F01060103.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060199',
      label: 'CODELISTCONSTANTS.MDNCode.F01060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F010602',
      label: 'CODELISTCONSTANTS.MDNCode.F010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010602.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'F01060201',
      label: 'CODELISTCONSTANTS.MDNCode.F01060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060202',
      label: 'CODELISTCONSTANTS.MDNCode.F01060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060203',
      label: 'CODELISTCONSTANTS.MDNCode.F01060203.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060299',
      label: 'CODELISTCONSTANTS.MDNCode.F01060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F010603',
      label: 'CODELISTCONSTANTS.MDNCode.F010603.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010603.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'F01060301',
      label: 'CODELISTCONSTANTS.MDNCode.F01060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060302',
      label: 'CODELISTCONSTANTS.MDNCode.F01060302.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060303',
      label: 'CODELISTCONSTANTS.MDNCode.F01060303.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01060399',
      label: 'CODELISTCONSTANTS.MDNCode.F01060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01060399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F010604',
      label: 'CODELISTCONSTANTS.MDNCode.F010604.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010604.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F0107',
      label: 'CODELISTCONSTANTS.MDNCode.F0107.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0107.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F010701',
      label: 'CODELISTCONSTANTS.MDNCode.F010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F010702',
      label: 'CODELISTCONSTANTS.MDNCode.F010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F010799',
      label: 'CODELISTCONSTANTS.MDNCode.F010799.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F0108',
      label: 'CODELISTCONSTANTS.MDNCode.F0108.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0108.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F010801',
      label: 'CODELISTCONSTANTS.MDNCode.F010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010801.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'F01080101',
      label: 'CODELISTCONSTANTS.MDNCode.F01080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01080101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01080102',
      label: 'CODELISTCONSTANTS.MDNCode.F01080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01080102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F010802',
      label: 'CODELISTCONSTANTS.MDNCode.F010802.label',
      description: 'CODELISTCONSTANTS.MDNCode.F010802.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'F01080201',
      label: 'CODELISTCONSTANTS.MDNCode.F01080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01080201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F01080202',
      label: 'CODELISTCONSTANTS.MDNCode.F01080202.label',
      description: 'CODELISTCONSTANTS.MDNCode.F01080202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F0109',
      label: 'CODELISTCONSTANTS.MDNCode.F0109.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0109.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0199',
      label: 'CODELISTCONSTANTS.MDNCode.F0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F02',
      label: 'CODELISTCONSTANTS.MDNCode.F02.label',
      description: 'CODELISTCONSTANTS.MDNCode.F02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'F0201',
      label: 'CODELISTCONSTANTS.MDNCode.F0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F020101',
      label: 'CODELISTCONSTANTS.MDNCode.F020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F020102',
      label: 'CODELISTCONSTANTS.MDNCode.F020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F020104',
      label: 'CODELISTCONSTANTS.MDNCode.F020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F020180',
      label: 'CODELISTCONSTANTS.MDNCode.F020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F020199',
      label: 'CODELISTCONSTANTS.MDNCode.F020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F0202',
      label: 'CODELISTCONSTANTS.MDNCode.F0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F020201',
      label: 'CODELISTCONSTANTS.MDNCode.F020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'F02020101',
      label: 'CODELISTCONSTANTS.MDNCode.F02020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.F02020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F02020102',
      label: 'CODELISTCONSTANTS.MDNCode.F02020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.F02020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F02020199',
      label: 'CODELISTCONSTANTS.MDNCode.F02020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.F02020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F020202',
      label: 'CODELISTCONSTANTS.MDNCode.F020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'F02020201',
      label: 'CODELISTCONSTANTS.MDNCode.F02020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.F02020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F02020202',
      label: 'CODELISTCONSTANTS.MDNCode.F02020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.F02020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F02020299',
      label: 'CODELISTCONSTANTS.MDNCode.F02020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.F02020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F020280',
      label: 'CODELISTCONSTANTS.MDNCode.F020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F020299',
      label: 'CODELISTCONSTANTS.MDNCode.F020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.F020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F0299',
      label: 'CODELISTCONSTANTS.MDNCode.F0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F03',
      label: 'CODELISTCONSTANTS.MDNCode.F03.label',
      description: 'CODELISTCONSTANTS.MDNCode.F03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'F0301',
      label: 'CODELISTCONSTANTS.MDNCode.F0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0301.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0302',
      label: 'CODELISTCONSTANTS.MDNCode.F0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0302.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0303',
      label: 'CODELISTCONSTANTS.MDNCode.F0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0303.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0304',
      label: 'CODELISTCONSTANTS.MDNCode.F0304.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0304.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0305',
      label: 'CODELISTCONSTANTS.MDNCode.F0305.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0305.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0306',
      label: 'CODELISTCONSTANTS.MDNCode.F0306.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0306.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0307',
      label: 'CODELISTCONSTANTS.MDNCode.F0307.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0307.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0399',
      label: 'CODELISTCONSTANTS.MDNCode.F0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F04',
      label: 'CODELISTCONSTANTS.MDNCode.F04.label',
      description: 'CODELISTCONSTANTS.MDNCode.F04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'F0401',
      label: 'CODELISTCONSTANTS.MDNCode.F0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0401.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F040101',
      label: 'CODELISTCONSTANTS.MDNCode.F040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.F040101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F040102',
      label: 'CODELISTCONSTANTS.MDNCode.F040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.F040102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F0402',
      label: 'CODELISTCONSTANTS.MDNCode.F0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0402.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F040201',
      label: 'CODELISTCONSTANTS.MDNCode.F040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.F040201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F040202',
      label: 'CODELISTCONSTANTS.MDNCode.F040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.F040202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F0403',
      label: 'CODELISTCONSTANTS.MDNCode.F0403.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0403.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F040302',
      label: 'CODELISTCONSTANTS.MDNCode.F040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.F040302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F040399',
      label: 'CODELISTCONSTANTS.MDNCode.F040399.label',
      description: 'CODELISTCONSTANTS.MDNCode.F040399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F0404',
      label: 'CODELISTCONSTANTS.MDNCode.F0404.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0404.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F0499',
      label: 'CODELISTCONSTANTS.MDNCode.F0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.F0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F05',
      label: 'CODELISTCONSTANTS.MDNCode.F05.label',
      description: 'CODELISTCONSTANTS.MDNCode.F05.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'F90',
      label: 'CODELISTCONSTANTS.MDNCode.F90.label',
      description: 'CODELISTCONSTANTS.MDNCode.F90.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'F9001',
      label: 'CODELISTCONSTANTS.MDNCode.F9001.label',
      description: 'CODELISTCONSTANTS.MDNCode.F9001.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F900101',
      label: 'CODELISTCONSTANTS.MDNCode.F900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F900199',
      label: 'CODELISTCONSTANTS.MDNCode.F900199.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F9002',
      label: 'CODELISTCONSTANTS.MDNCode.F9002.label',
      description: 'CODELISTCONSTANTS.MDNCode.F9002.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F900201',
      label: 'CODELISTCONSTANTS.MDNCode.F900201.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F900202',
      label: 'CODELISTCONSTANTS.MDNCode.F900202.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F900299',
      label: 'CODELISTCONSTANTS.MDNCode.F900299.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F9003',
      label: 'CODELISTCONSTANTS.MDNCode.F9003.label',
      description: 'CODELISTCONSTANTS.MDNCode.F9003.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F900301',
      label: 'CODELISTCONSTANTS.MDNCode.F900301.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F900302',
      label: 'CODELISTCONSTANTS.MDNCode.F900302.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F9004',
      label: 'CODELISTCONSTANTS.MDNCode.F9004.label',
      description: 'CODELISTCONSTANTS.MDNCode.F9004.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'F900401',
      label: 'CODELISTCONSTANTS.MDNCode.F900401.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'F90040101',
      label: 'CODELISTCONSTANTS.MDNCode.F90040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.F90040101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F90040102',
      label: 'CODELISTCONSTANTS.MDNCode.F90040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.F90040102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'F900402',
      label: 'CODELISTCONSTANTS.MDNCode.F900402.label',
      description: 'CODELISTCONSTANTS.MDNCode.F900402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'F9006',
      label: 'CODELISTCONSTANTS.MDNCode.F9006.label',
      description: 'CODELISTCONSTANTS.MDNCode.F9006.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F9080',
      label: 'CODELISTCONSTANTS.MDNCode.F9080.label',
      description: 'CODELISTCONSTANTS.MDNCode.F9080.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'F9099',
      label: 'CODELISTCONSTANTS.MDNCode.F9099.label',
      description: 'CODELISTCONSTANTS.MDNCode.F9099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G',
      label: 'CODELISTCONSTANTS.MDNCode.G.label',
      description: 'CODELISTCONSTANTS.MDNCode.G.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'G01',
      label: 'CODELISTCONSTANTS.MDNCode.G01.label',
      description: 'CODELISTCONSTANTS.MDNCode.G01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'G0101',
      label: 'CODELISTCONSTANTS.MDNCode.G0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G010101',
      label: 'CODELISTCONSTANTS.MDNCode.G010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010102',
      label: 'CODELISTCONSTANTS.MDNCode.G010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010199',
      label: 'CODELISTCONSTANTS.MDNCode.G010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0102',
      label: 'CODELISTCONSTANTS.MDNCode.G0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G010201',
      label: 'CODELISTCONSTANTS.MDNCode.G010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010202',
      label: 'CODELISTCONSTANTS.MDNCode.G010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010299',
      label: 'CODELISTCONSTANTS.MDNCode.G010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0103',
      label: 'CODELISTCONSTANTS.MDNCode.G0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G010301',
      label: 'CODELISTCONSTANTS.MDNCode.G010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010302',
      label: 'CODELISTCONSTANTS.MDNCode.G010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010303',
      label: 'CODELISTCONSTANTS.MDNCode.G010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010304',
      label: 'CODELISTCONSTANTS.MDNCode.G010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010305',
      label: 'CODELISTCONSTANTS.MDNCode.G010305.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G010399',
      label: 'CODELISTCONSTANTS.MDNCode.G010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.G010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0180',
      label: 'CODELISTCONSTANTS.MDNCode.G0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0180.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G0199',
      label: 'CODELISTCONSTANTS.MDNCode.G0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G02',
      label: 'CODELISTCONSTANTS.MDNCode.G02.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'G0201',
      label: 'CODELISTCONSTANTS.MDNCode.G0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G020101',
      label: 'CODELISTCONSTANTS.MDNCode.G020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G020102',
      label: 'CODELISTCONSTANTS.MDNCode.G020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G020199',
      label: 'CODELISTCONSTANTS.MDNCode.G020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0202',
      label: 'CODELISTCONSTANTS.MDNCode.G0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G020201',
      label: 'CODELISTCONSTANTS.MDNCode.G020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G02020101',
      label: 'CODELISTCONSTANTS.MDNCode.G02020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02020102',
      label: 'CODELISTCONSTANTS.MDNCode.G02020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02020199',
      label: 'CODELISTCONSTANTS.MDNCode.G02020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G020202',
      label: 'CODELISTCONSTANTS.MDNCode.G020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G02020201',
      label: 'CODELISTCONSTANTS.MDNCode.G02020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02020202',
      label: 'CODELISTCONSTANTS.MDNCode.G02020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02020203',
      label: 'CODELISTCONSTANTS.MDNCode.G02020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G020299',
      label: 'CODELISTCONSTANTS.MDNCode.G020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0203',
      label: 'CODELISTCONSTANTS.MDNCode.G0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G020301',
      label: 'CODELISTCONSTANTS.MDNCode.G020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G020399',
      label: 'CODELISTCONSTANTS.MDNCode.G020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0204',
      label: 'CODELISTCONSTANTS.MDNCode.G0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0204.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G020401',
      label: 'CODELISTCONSTANTS.MDNCode.G020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G020402',
      label: 'CODELISTCONSTANTS.MDNCode.G020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G020499',
      label: 'CODELISTCONSTANTS.MDNCode.G020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0205',
      label: 'CODELISTCONSTANTS.MDNCode.G0205.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0205.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G020501',
      label: 'CODELISTCONSTANTS.MDNCode.G020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G020502',
      label: 'CODELISTCONSTANTS.MDNCode.G020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G020503',
      label: 'CODELISTCONSTANTS.MDNCode.G020503.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020503.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G020599',
      label: 'CODELISTCONSTANTS.MDNCode.G020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0206',
      label: 'CODELISTCONSTANTS.MDNCode.G0206.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0206.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G020601',
      label: 'CODELISTCONSTANTS.MDNCode.G020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020601.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G02060101',
      label: 'CODELISTCONSTANTS.MDNCode.G02060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060102',
      label: 'CODELISTCONSTANTS.MDNCode.G02060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060199',
      label: 'CODELISTCONSTANTS.MDNCode.G02060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G020602',
      label: 'CODELISTCONSTANTS.MDNCode.G020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020602.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G02060201',
      label: 'CODELISTCONSTANTS.MDNCode.G02060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060202',
      label: 'CODELISTCONSTANTS.MDNCode.G02060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060299',
      label: 'CODELISTCONSTANTS.MDNCode.G02060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G020603',
      label: 'CODELISTCONSTANTS.MDNCode.G020603.label',
      description: 'CODELISTCONSTANTS.MDNCode.G020603.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G02060301',
      label: 'CODELISTCONSTANTS.MDNCode.G02060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060302',
      label: 'CODELISTCONSTANTS.MDNCode.G02060302.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060303',
      label: 'CODELISTCONSTANTS.MDNCode.G02060303.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060304',
      label: 'CODELISTCONSTANTS.MDNCode.G02060304.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060305',
      label: 'CODELISTCONSTANTS.MDNCode.G02060305.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060305.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G02060399',
      label: 'CODELISTCONSTANTS.MDNCode.G02060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.G02060399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G0280',
      label: 'CODELISTCONSTANTS.MDNCode.G0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0280.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G0299',
      label: 'CODELISTCONSTANTS.MDNCode.G0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G03',
      label: 'CODELISTCONSTANTS.MDNCode.G03.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'G0301',
      label: 'CODELISTCONSTANTS.MDNCode.G0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G030101',
      label: 'CODELISTCONSTANTS.MDNCode.G030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03010101',
      label: 'CODELISTCONSTANTS.MDNCode.G03010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0301010101',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0301010102',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G03010102',
      label: 'CODELISTCONSTANTS.MDNCode.G03010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0301010201',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0301010202',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G03010103',
      label: 'CODELISTCONSTANTS.MDNCode.G03010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03010103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0301010301',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0301010302',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G03010104',
      label: 'CODELISTCONSTANTS.MDNCode.G03010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03010104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0301010401',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0301010402',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G03010105',
      label: 'CODELISTCONSTANTS.MDNCode.G03010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03010105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0301010501',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0301010502',
      label: 'CODELISTCONSTANTS.MDNCode.G0301010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0301010502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G03010199',
      label: 'CODELISTCONSTANTS.MDNCode.G03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030102',
      label: 'CODELISTCONSTANTS.MDNCode.G030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03010201',
      label: 'CODELISTCONSTANTS.MDNCode.G03010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03010202',
      label: 'CODELISTCONSTANTS.MDNCode.G03010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G0302',
      label: 'CODELISTCONSTANTS.MDNCode.G0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G030201',
      label: 'CODELISTCONSTANTS.MDNCode.G030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03020101',
      label: 'CODELISTCONSTANTS.MDNCode.G03020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03020102',
      label: 'CODELISTCONSTANTS.MDNCode.G03020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030202',
      label: 'CODELISTCONSTANTS.MDNCode.G030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030203',
      label: 'CODELISTCONSTANTS.MDNCode.G030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03020301',
      label: 'CODELISTCONSTANTS.MDNCode.G03020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03020302',
      label: 'CODELISTCONSTANTS.MDNCode.G03020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03020302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03020399',
      label: 'CODELISTCONSTANTS.MDNCode.G03020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03020399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030204',
      label: 'CODELISTCONSTANTS.MDNCode.G030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030299',
      label: 'CODELISTCONSTANTS.MDNCode.G030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0303',
      label: 'CODELISTCONSTANTS.MDNCode.G0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0303.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G030301',
      label: 'CODELISTCONSTANTS.MDNCode.G030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03030101',
      label: 'CODELISTCONSTANTS.MDNCode.G03030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03030102',
      label: 'CODELISTCONSTANTS.MDNCode.G03030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03030199',
      label: 'CODELISTCONSTANTS.MDNCode.G03030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030399',
      label: 'CODELISTCONSTANTS.MDNCode.G030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0304',
      label: 'CODELISTCONSTANTS.MDNCode.G0304.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G030401',
      label: 'CODELISTCONSTANTS.MDNCode.G030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03040101',
      label: 'CODELISTCONSTANTS.MDNCode.G03040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03040101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0304010101',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0304010102',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0304010103',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0304010104',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0304010199',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G03040102',
      label: 'CODELISTCONSTANTS.MDNCode.G03040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03040102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0304010201',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0304010202',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0304010203',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0304010204',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0304010299',
      label: 'CODELISTCONSTANTS.MDNCode.G0304010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0304010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G030402',
      label: 'CODELISTCONSTANTS.MDNCode.G030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03040201',
      label: 'CODELISTCONSTANTS.MDNCode.G03040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03040202',
      label: 'CODELISTCONSTANTS.MDNCode.G03040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03040202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03040299',
      label: 'CODELISTCONSTANTS.MDNCode.G03040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030403',
      label: 'CODELISTCONSTANTS.MDNCode.G030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030403.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03040301',
      label: 'CODELISTCONSTANTS.MDNCode.G03040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03040301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03040302',
      label: 'CODELISTCONSTANTS.MDNCode.G03040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03040302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03040303',
      label: 'CODELISTCONSTANTS.MDNCode.G03040303.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03040303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030499',
      label: 'CODELISTCONSTANTS.MDNCode.G030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0305',
      label: 'CODELISTCONSTANTS.MDNCode.G0305.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0305.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G030501',
      label: 'CODELISTCONSTANTS.MDNCode.G030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03050101',
      label: 'CODELISTCONSTANTS.MDNCode.G03050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03050102',
      label: 'CODELISTCONSTANTS.MDNCode.G03050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03050102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0305010201',
      label: 'CODELISTCONSTANTS.MDNCode.G0305010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0305010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0305010202',
      label: 'CODELISTCONSTANTS.MDNCode.G0305010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0305010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G03050103',
      label: 'CODELISTCONSTANTS.MDNCode.G03050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03050103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03050199',
      label: 'CODELISTCONSTANTS.MDNCode.G03050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03050199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030502',
      label: 'CODELISTCONSTANTS.MDNCode.G030502.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0306',
      label: 'CODELISTCONSTANTS.MDNCode.G0306.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0306.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G030601',
      label: 'CODELISTCONSTANTS.MDNCode.G030601.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030602',
      label: 'CODELISTCONSTANTS.MDNCode.G030602.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030603',
      label: 'CODELISTCONSTANTS.MDNCode.G030603.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030604',
      label: 'CODELISTCONSTANTS.MDNCode.G030604.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030604.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030699',
      label: 'CODELISTCONSTANTS.MDNCode.G030699.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0307',
      label: 'CODELISTCONSTANTS.MDNCode.G0307.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0307.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G030701',
      label: 'CODELISTCONSTANTS.MDNCode.G030701.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030702',
      label: 'CODELISTCONSTANTS.MDNCode.G030702.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030703',
      label: 'CODELISTCONSTANTS.MDNCode.G030703.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030704',
      label: 'CODELISTCONSTANTS.MDNCode.G030704.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030704.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030799',
      label: 'CODELISTCONSTANTS.MDNCode.G030799.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0308',
      label: 'CODELISTCONSTANTS.MDNCode.G0308.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0308.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'G030801',
      label: 'CODELISTCONSTANTS.MDNCode.G030801.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030801.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03080101',
      label: 'CODELISTCONSTANTS.MDNCode.G03080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03080101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'G0308010101',
      label: 'CODELISTCONSTANTS.MDNCode.G0308010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0308010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G0308010102',
      label: 'CODELISTCONSTANTS.MDNCode.G0308010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0308010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'G03080102',
      label: 'CODELISTCONSTANTS.MDNCode.G03080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03080102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03080199',
      label: 'CODELISTCONSTANTS.MDNCode.G03080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03080199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030803',
      label: 'CODELISTCONSTANTS.MDNCode.G030803.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030803.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'G03080301',
      label: 'CODELISTCONSTANTS.MDNCode.G03080301.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03080301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G03080399',
      label: 'CODELISTCONSTANTS.MDNCode.G03080399.label',
      description: 'CODELISTCONSTANTS.MDNCode.G03080399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'G030804',
      label: 'CODELISTCONSTANTS.MDNCode.G030804.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030804.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030805',
      label: 'CODELISTCONSTANTS.MDNCode.G030805.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030805.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G030899',
      label: 'CODELISTCONSTANTS.MDNCode.G030899.label',
      description: 'CODELISTCONSTANTS.MDNCode.G030899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'G0380',
      label: 'CODELISTCONSTANTS.MDNCode.G0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G0399',
      label: 'CODELISTCONSTANTS.MDNCode.G0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G04',
      label: 'CODELISTCONSTANTS.MDNCode.G04.label',
      description: 'CODELISTCONSTANTS.MDNCode.G04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'G0401',
      label: 'CODELISTCONSTANTS.MDNCode.G0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0401.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G0402',
      label: 'CODELISTCONSTANTS.MDNCode.G0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0402.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G0499',
      label: 'CODELISTCONSTANTS.MDNCode.G0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.G0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'G05',
      label: 'CODELISTCONSTANTS.MDNCode.G05.label',
      description: 'CODELISTCONSTANTS.MDNCode.G05.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'G99',
      label: 'CODELISTCONSTANTS.MDNCode.G99.label',
      description: 'CODELISTCONSTANTS.MDNCode.G99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'H',
      label: 'CODELISTCONSTANTS.MDNCode.H.label',
      description: 'CODELISTCONSTANTS.MDNCode.H.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'H01',
      label: 'CODELISTCONSTANTS.MDNCode.H01.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'H0101',
      label: 'CODELISTCONSTANTS.MDNCode.H0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H010101',
      label: 'CODELISTCONSTANTS.MDNCode.H010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H01010101',
      label: 'CODELISTCONSTANTS.MDNCode.H01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0101010101',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101010101',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101010102',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101010103',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101010104',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010104.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010102',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101010201',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101010202',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101010203',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010103',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010103.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101010301',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101010302',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101010303',
      label: 'CODELISTCONSTANTS.MDNCode.H010101010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101010303.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010199',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H01010102',
      label: 'CODELISTCONSTANTS.MDNCode.H01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0101010201',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101020101',
      label: 'CODELISTCONSTANTS.MDNCode.H010101020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101020102',
      label: 'CODELISTCONSTANTS.MDNCode.H010101020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101020103',
      label: 'CODELISTCONSTANTS.MDNCode.H010101020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101020103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010202',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101020201',
      label: 'CODELISTCONSTANTS.MDNCode.H010101020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101020202',
      label: 'CODELISTCONSTANTS.MDNCode.H010101020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101020203',
      label: 'CODELISTCONSTANTS.MDNCode.H010101020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101020203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010299',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H01010103',
      label: 'CODELISTCONSTANTS.MDNCode.H01010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01010103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0101010301',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010301.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101030101',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101030102',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101030103',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010302',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010302.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101030201',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101030202',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101030203',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010303',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010303.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101030301',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101030302',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101030303',
      label: 'CODELISTCONSTANTS.MDNCode.H010101030303.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101030303.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010399',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H01010104',
      label: 'CODELISTCONSTANTS.MDNCode.H01010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01010104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0101010401',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010401.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101040101',
      label: 'CODELISTCONSTANTS.MDNCode.H010101040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101040101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101040102',
      label: 'CODELISTCONSTANTS.MDNCode.H010101040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101040102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010402',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010402.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010101040201',
      label: 'CODELISTCONSTANTS.MDNCode.H010101040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101040201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010101040202',
      label: 'CODELISTCONSTANTS.MDNCode.H010101040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010101040202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0101010499',
      label: 'CODELISTCONSTANTS.MDNCode.H0101010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0101010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0102',
      label: 'CODELISTCONSTANTS.MDNCode.H0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H010201',
      label: 'CODELISTCONSTANTS.MDNCode.H010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H01020101',
      label: 'CODELISTCONSTANTS.MDNCode.H01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0102010101',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010201010101',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010102',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010103',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102010102',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010201010201',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010202',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010203',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102010103',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010103.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010201010301',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010302',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102010104',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010104.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010201010401',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010401.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010402',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010402.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010403',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010403.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010404',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010404.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010404.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102010105',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010105.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010201010501',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010501.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010502',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010502.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102010106',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010106.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010201010601',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010601.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201010602',
      label: 'CODELISTCONSTANTS.MDNCode.H010201010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201010602.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102010199',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H01020102',
      label: 'CODELISTCONSTANTS.MDNCode.H01020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01020102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0102010201',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010201020101',
      label: 'CODELISTCONSTANTS.MDNCode.H010201020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201020102',
      label: 'CODELISTCONSTANTS.MDNCode.H010201020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102010202',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010201020201',
      label: 'CODELISTCONSTANTS.MDNCode.H010201020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201020202',
      label: 'CODELISTCONSTANTS.MDNCode.H010201020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010201020203',
      label: 'CODELISTCONSTANTS.MDNCode.H010201020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010201020203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102010299',
      label: 'CODELISTCONSTANTS.MDNCode.H0102010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H010202',
      label: 'CODELISTCONSTANTS.MDNCode.H010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H01020201',
      label: 'CODELISTCONSTANTS.MDNCode.H01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01020201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0102020101',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010202010101',
      label: 'CODELISTCONSTANTS.MDNCode.H010202010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010202010102',
      label: 'CODELISTCONSTANTS.MDNCode.H010202010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102020102',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010202010201',
      label: 'CODELISTCONSTANTS.MDNCode.H010202010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010202010202',
      label: 'CODELISTCONSTANTS.MDNCode.H010202010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102020103',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020103.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010202010301',
      label: 'CODELISTCONSTANTS.MDNCode.H010202010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202010301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010202010302',
      label: 'CODELISTCONSTANTS.MDNCode.H010202010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202010302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102020199',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H01020202',
      label: 'CODELISTCONSTANTS.MDNCode.H01020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H01020202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0102020201',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010202020101',
      label: 'CODELISTCONSTANTS.MDNCode.H010202020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010202020102',
      label: 'CODELISTCONSTANTS.MDNCode.H010202020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102020202',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010202020201',
      label: 'CODELISTCONSTANTS.MDNCode.H010202020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010202020202',
      label: 'CODELISTCONSTANTS.MDNCode.H010202020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102020203',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020203.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010202020301',
      label: 'CODELISTCONSTANTS.MDNCode.H010202020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202020301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010202020302',
      label: 'CODELISTCONSTANTS.MDNCode.H010202020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202020302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102020204',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020204.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H010202020401',
      label: 'CODELISTCONSTANTS.MDNCode.H010202020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202020401.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H010202020402',
      label: 'CODELISTCONSTANTS.MDNCode.H010202020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.H010202020402.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0102020299',
      label: 'CODELISTCONSTANTS.MDNCode.H0102020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0102020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0199',
      label: 'CODELISTCONSTANTS.MDNCode.H0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'H02',
      label: 'CODELISTCONSTANTS.MDNCode.H02.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'H0201',
      label: 'CODELISTCONSTANTS.MDNCode.H0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H020101',
      label: 'CODELISTCONSTANTS.MDNCode.H020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H02010101',
      label: 'CODELISTCONSTANTS.MDNCode.H02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02010102',
      label: 'CODELISTCONSTANTS.MDNCode.H02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02010103',
      label: 'CODELISTCONSTANTS.MDNCode.H02010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02010104',
      label: 'CODELISTCONSTANTS.MDNCode.H02010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02010105',
      label: 'CODELISTCONSTANTS.MDNCode.H02010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02010106',
      label: 'CODELISTCONSTANTS.MDNCode.H02010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02010199',
      label: 'CODELISTCONSTANTS.MDNCode.H02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H020102',
      label: 'CODELISTCONSTANTS.MDNCode.H020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H02010201',
      label: 'CODELISTCONSTANTS.MDNCode.H02010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02010202',
      label: 'CODELISTCONSTANTS.MDNCode.H02010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02010299',
      label: 'CODELISTCONSTANTS.MDNCode.H02010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H020180',
      label: 'CODELISTCONSTANTS.MDNCode.H020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H020199',
      label: 'CODELISTCONSTANTS.MDNCode.H020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H0202',
      label: 'CODELISTCONSTANTS.MDNCode.H0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H020201',
      label: 'CODELISTCONSTANTS.MDNCode.H020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H02020101',
      label: 'CODELISTCONSTANTS.MDNCode.H02020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0202010101',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H020201010101',
      label: 'CODELISTCONSTANTS.MDNCode.H020201010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020201010102',
      label: 'CODELISTCONSTANTS.MDNCode.H020201010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0202010102',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H020201010201',
      label: 'CODELISTCONSTANTS.MDNCode.H020201010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020201010202',
      label: 'CODELISTCONSTANTS.MDNCode.H020201010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0202010199',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H02020102',
      label: 'CODELISTCONSTANTS.MDNCode.H02020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0202010201',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H020201020101',
      label: 'CODELISTCONSTANTS.MDNCode.H020201020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020201020102',
      label: 'CODELISTCONSTANTS.MDNCode.H020201020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0202010202',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H020201020201',
      label: 'CODELISTCONSTANTS.MDNCode.H020201020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020201020202',
      label: 'CODELISTCONSTANTS.MDNCode.H020201020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020201020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0202010299',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H02020103',
      label: 'CODELISTCONSTANTS.MDNCode.H02020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0202010301',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0202010302',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0202010399',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H02020104',
      label: 'CODELISTCONSTANTS.MDNCode.H02020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0202010401',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0202010402',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0202010499',
      label: 'CODELISTCONSTANTS.MDNCode.H0202010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H02020199',
      label: 'CODELISTCONSTANTS.MDNCode.H02020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H020202',
      label: 'CODELISTCONSTANTS.MDNCode.H020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H02020201',
      label: 'CODELISTCONSTANTS.MDNCode.H02020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0202020101',
      label: 'CODELISTCONSTANTS.MDNCode.H0202020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0202020102',
      label: 'CODELISTCONSTANTS.MDNCode.H0202020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H02020202',
      label: 'CODELISTCONSTANTS.MDNCode.H02020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0202020201',
      label: 'CODELISTCONSTANTS.MDNCode.H0202020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0202020202',
      label: 'CODELISTCONSTANTS.MDNCode.H0202020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0202020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H02020203',
      label: 'CODELISTCONSTANTS.MDNCode.H02020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02020204',
      label: 'CODELISTCONSTANTS.MDNCode.H02020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02020299',
      label: 'CODELISTCONSTANTS.MDNCode.H02020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H020203',
      label: 'CODELISTCONSTANTS.MDNCode.H020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H02020301',
      label: 'CODELISTCONSTANTS.MDNCode.H02020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02020302',
      label: 'CODELISTCONSTANTS.MDNCode.H02020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02020399',
      label: 'CODELISTCONSTANTS.MDNCode.H02020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02020399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H020280',
      label: 'CODELISTCONSTANTS.MDNCode.H020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H020299',
      label: 'CODELISTCONSTANTS.MDNCode.H020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H0203',
      label: 'CODELISTCONSTANTS.MDNCode.H0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H020301',
      label: 'CODELISTCONSTANTS.MDNCode.H020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H02030104',
      label: 'CODELISTCONSTANTS.MDNCode.H02030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02030104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0203010401',
      label: 'CODELISTCONSTANTS.MDNCode.H0203010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0203010401.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H020301040101',
      label: 'CODELISTCONSTANTS.MDNCode.H020301040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301040101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020301040102',
      label: 'CODELISTCONSTANTS.MDNCode.H020301040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301040102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020301040199',
      label: 'CODELISTCONSTANTS.MDNCode.H020301040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301040199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0203010402',
      label: 'CODELISTCONSTANTS.MDNCode.H0203010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0203010402.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H020301040201',
      label: 'CODELISTCONSTANTS.MDNCode.H020301040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301040201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020301040202',
      label: 'CODELISTCONSTANTS.MDNCode.H020301040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301040202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020301040299',
      label: 'CODELISTCONSTANTS.MDNCode.H020301040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301040299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0203010499',
      label: 'CODELISTCONSTANTS.MDNCode.H0203010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0203010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H02030105',
      label: 'CODELISTCONSTANTS.MDNCode.H02030105.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02030105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0203010501',
      label: 'CODELISTCONSTANTS.MDNCode.H0203010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0203010501.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H020301050101',
      label: 'CODELISTCONSTANTS.MDNCode.H020301050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301050101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020301050102',
      label: 'CODELISTCONSTANTS.MDNCode.H020301050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301050102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020301050199',
      label: 'CODELISTCONSTANTS.MDNCode.H020301050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301050199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H0203010502',
      label: 'CODELISTCONSTANTS.MDNCode.H0203010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0203010502.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'H020301050201',
      label: 'CODELISTCONSTANTS.MDNCode.H020301050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301050201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020301050202',
      label: 'CODELISTCONSTANTS.MDNCode.H020301050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301050202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H020301050299',
      label: 'CODELISTCONSTANTS.MDNCode.H020301050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020301050299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'H02030106',
      label: 'CODELISTCONSTANTS.MDNCode.H02030106.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02030106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H02030199',
      label: 'CODELISTCONSTANTS.MDNCode.H02030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H020302',
      label: 'CODELISTCONSTANTS.MDNCode.H020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H02030201',
      label: 'CODELISTCONSTANTS.MDNCode.H02030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02030201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'H0203020101',
      label: 'CODELISTCONSTANTS.MDNCode.H0203020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0203020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H0203020199',
      label: 'CODELISTCONSTANTS.MDNCode.H0203020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0203020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'H02030299',
      label: 'CODELISTCONSTANTS.MDNCode.H02030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H02030299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H020303',
      label: 'CODELISTCONSTANTS.MDNCode.H020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H020380',
      label: 'CODELISTCONSTANTS.MDNCode.H020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H020399',
      label: 'CODELISTCONSTANTS.MDNCode.H020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H0204',
      label: 'CODELISTCONSTANTS.MDNCode.H0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0204.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H020401',
      label: 'CODELISTCONSTANTS.MDNCode.H020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H020402',
      label: 'CODELISTCONSTANTS.MDNCode.H020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H020403',
      label: 'CODELISTCONSTANTS.MDNCode.H020403.label',
      description: 'CODELISTCONSTANTS.MDNCode.H020403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H0299',
      label: 'CODELISTCONSTANTS.MDNCode.H0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'H03',
      label: 'CODELISTCONSTANTS.MDNCode.H03.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'H0301',
      label: 'CODELISTCONSTANTS.MDNCode.H0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H030101',
      label: 'CODELISTCONSTANTS.MDNCode.H030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H03010101',
      label: 'CODELISTCONSTANTS.MDNCode.H03010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03010102',
      label: 'CODELISTCONSTANTS.MDNCode.H03010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03010104',
      label: 'CODELISTCONSTANTS.MDNCode.H03010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03010105',
      label: 'CODELISTCONSTANTS.MDNCode.H03010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03010105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03010199',
      label: 'CODELISTCONSTANTS.MDNCode.H03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H030102',
      label: 'CODELISTCONSTANTS.MDNCode.H030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H030102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H03010201',
      label: 'CODELISTCONSTANTS.MDNCode.H03010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03010202',
      label: 'CODELISTCONSTANTS.MDNCode.H03010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H030199',
      label: 'CODELISTCONSTANTS.MDNCode.H030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H0302',
      label: 'CODELISTCONSTANTS.MDNCode.H0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H030201',
      label: 'CODELISTCONSTANTS.MDNCode.H030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H030201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H03020101',
      label: 'CODELISTCONSTANTS.MDNCode.H03020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03020103',
      label: 'CODELISTCONSTANTS.MDNCode.H03020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03020103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03020199',
      label: 'CODELISTCONSTANTS.MDNCode.H03020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H030299',
      label: 'CODELISTCONSTANTS.MDNCode.H030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H0303',
      label: 'CODELISTCONSTANTS.MDNCode.H0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0303.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H030301',
      label: 'CODELISTCONSTANTS.MDNCode.H030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H030301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H03030101',
      label: 'CODELISTCONSTANTS.MDNCode.H03030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03030102',
      label: 'CODELISTCONSTANTS.MDNCode.H03030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H0380',
      label: 'CODELISTCONSTANTS.MDNCode.H0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0380.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H038001',
      label: 'CODELISTCONSTANTS.MDNCode.H038001.label',
      description: 'CODELISTCONSTANTS.MDNCode.H038001.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H03800101',
      label: 'CODELISTCONSTANTS.MDNCode.H03800101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03800101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H03800102',
      label: 'CODELISTCONSTANTS.MDNCode.H03800102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H03800102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H038099',
      label: 'CODELISTCONSTANTS.MDNCode.H038099.label',
      description: 'CODELISTCONSTANTS.MDNCode.H038099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H0399',
      label: 'CODELISTCONSTANTS.MDNCode.H0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.H0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'H90',
      label: 'CODELISTCONSTANTS.MDNCode.H90.label',
      description: 'CODELISTCONSTANTS.MDNCode.H90.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'H9001',
      label: 'CODELISTCONSTANTS.MDNCode.H9001.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9001.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H900101',
      label: 'CODELISTCONSTANTS.MDNCode.H900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H90010101',
      label: 'CODELISTCONSTANTS.MDNCode.H90010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H90010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H90010102',
      label: 'CODELISTCONSTANTS.MDNCode.H90010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H90010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H900102',
      label: 'CODELISTCONSTANTS.MDNCode.H900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900199',
      label: 'CODELISTCONSTANTS.MDNCode.H900199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H9002',
      label: 'CODELISTCONSTANTS.MDNCode.H9002.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9002.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H900201',
      label: 'CODELISTCONSTANTS.MDNCode.H900201.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'H90020101',
      label: 'CODELISTCONSTANTS.MDNCode.H90020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.H90020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H90020102',
      label: 'CODELISTCONSTANTS.MDNCode.H90020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.H90020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H90020103',
      label: 'CODELISTCONSTANTS.MDNCode.H90020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.H90020103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H90020199',
      label: 'CODELISTCONSTANTS.MDNCode.H90020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.H90020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'H900202',
      label: 'CODELISTCONSTANTS.MDNCode.H900202.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900203',
      label: 'CODELISTCONSTANTS.MDNCode.H900203.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900299',
      label: 'CODELISTCONSTANTS.MDNCode.H900299.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H9003',
      label: 'CODELISTCONSTANTS.MDNCode.H9003.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9003.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H900301',
      label: 'CODELISTCONSTANTS.MDNCode.H900301.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900302',
      label: 'CODELISTCONSTANTS.MDNCode.H900302.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900303',
      label: 'CODELISTCONSTANTS.MDNCode.H900303.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900304',
      label: 'CODELISTCONSTANTS.MDNCode.H900304.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900305',
      label: 'CODELISTCONSTANTS.MDNCode.H900305.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900399',
      label: 'CODELISTCONSTANTS.MDNCode.H900399.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H9005',
      label: 'CODELISTCONSTANTS.MDNCode.H9005.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9005.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'H9006',
      label: 'CODELISTCONSTANTS.MDNCode.H9006.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9006.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'H9007',
      label: 'CODELISTCONSTANTS.MDNCode.H9007.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9007.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'H9008',
      label: 'CODELISTCONSTANTS.MDNCode.H9008.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9008.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'H900801',
      label: 'CODELISTCONSTANTS.MDNCode.H900801.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900801.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900802',
      label: 'CODELISTCONSTANTS.MDNCode.H900802.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900802.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H900899',
      label: 'CODELISTCONSTANTS.MDNCode.H900899.label',
      description: 'CODELISTCONSTANTS.MDNCode.H900899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'H9080',
      label: 'CODELISTCONSTANTS.MDNCode.H9080.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9080.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'H9099',
      label: 'CODELISTCONSTANTS.MDNCode.H9099.label',
      description: 'CODELISTCONSTANTS.MDNCode.H9099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J',
      label: 'CODELISTCONSTANTS.MDNCode.J.label',
      description: 'CODELISTCONSTANTS.MDNCode.J.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'J01',
      label: 'CODELISTCONSTANTS.MDNCode.J01.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'J0101',
      label: 'CODELISTCONSTANTS.MDNCode.J0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J010101',
      label: 'CODELISTCONSTANTS.MDNCode.J010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01010101',
      label: 'CODELISTCONSTANTS.MDNCode.J01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01010102',
      label: 'CODELISTCONSTANTS.MDNCode.J01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J010102',
      label: 'CODELISTCONSTANTS.MDNCode.J010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01010201',
      label: 'CODELISTCONSTANTS.MDNCode.J01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01010202',
      label: 'CODELISTCONSTANTS.MDNCode.J01010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J010103',
      label: 'CODELISTCONSTANTS.MDNCode.J010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01010301',
      label: 'CODELISTCONSTANTS.MDNCode.J01010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01010302',
      label: 'CODELISTCONSTANTS.MDNCode.J01010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J010104',
      label: 'CODELISTCONSTANTS.MDNCode.J010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01010401',
      label: 'CODELISTCONSTANTS.MDNCode.J01010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01010401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J010105',
      label: 'CODELISTCONSTANTS.MDNCode.J010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010180',
      label: 'CODELISTCONSTANTS.MDNCode.J010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010199',
      label: 'CODELISTCONSTANTS.MDNCode.J010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0102',
      label: 'CODELISTCONSTANTS.MDNCode.J0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J010201',
      label: 'CODELISTCONSTANTS.MDNCode.J010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010280',
      label: 'CODELISTCONSTANTS.MDNCode.J010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010299',
      label: 'CODELISTCONSTANTS.MDNCode.J010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0103',
      label: 'CODELISTCONSTANTS.MDNCode.J0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J010301',
      label: 'CODELISTCONSTANTS.MDNCode.J010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01030101',
      label: 'CODELISTCONSTANTS.MDNCode.J01030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01030102',
      label: 'CODELISTCONSTANTS.MDNCode.J01030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01030103',
      label: 'CODELISTCONSTANTS.MDNCode.J01030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01030103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J010380',
      label: 'CODELISTCONSTANTS.MDNCode.J010380.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010399',
      label: 'CODELISTCONSTANTS.MDNCode.J010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0104',
      label: 'CODELISTCONSTANTS.MDNCode.J0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J010401',
      label: 'CODELISTCONSTANTS.MDNCode.J010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010480',
      label: 'CODELISTCONSTANTS.MDNCode.J010480.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010499',
      label: 'CODELISTCONSTANTS.MDNCode.J010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0105',
      label: 'CODELISTCONSTANTS.MDNCode.J0105.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0105.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J010501',
      label: 'CODELISTCONSTANTS.MDNCode.J010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01050101',
      label: 'CODELISTCONSTANTS.MDNCode.J01050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J010502',
      label: 'CODELISTCONSTANTS.MDNCode.J010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01050201',
      label: 'CODELISTCONSTANTS.MDNCode.J01050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01050201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J010503',
      label: 'CODELISTCONSTANTS.MDNCode.J010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010503.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01050301',
      label: 'CODELISTCONSTANTS.MDNCode.J01050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01050301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J010504',
      label: 'CODELISTCONSTANTS.MDNCode.J010504.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010504.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010580',
      label: 'CODELISTCONSTANTS.MDNCode.J010580.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010580.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010599',
      label: 'CODELISTCONSTANTS.MDNCode.J010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0106',
      label: 'CODELISTCONSTANTS.MDNCode.J0106.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0106.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0107',
      label: 'CODELISTCONSTANTS.MDNCode.J0107.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0107.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J010701',
      label: 'CODELISTCONSTANTS.MDNCode.J010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010702',
      label: 'CODELISTCONSTANTS.MDNCode.J010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010780',
      label: 'CODELISTCONSTANTS.MDNCode.J010780.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010780.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010782',
      label: 'CODELISTCONSTANTS.MDNCode.J010782.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010782.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J010799',
      label: 'CODELISTCONSTANTS.MDNCode.J010799.label',
      description: 'CODELISTCONSTANTS.MDNCode.J010799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0190',
      label: 'CODELISTCONSTANTS.MDNCode.J0190.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0190.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J019001',
      label: 'CODELISTCONSTANTS.MDNCode.J019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019001.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01900101',
      label: 'CODELISTCONSTANTS.MDNCode.J01900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01900101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'J0190010101',
      label: 'CODELISTCONSTANTS.MDNCode.J0190010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0190010101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'J019001010101',
      label: 'CODELISTCONSTANTS.MDNCode.J019001010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019001010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'J019001010102',
      label: 'CODELISTCONSTANTS.MDNCode.J019001010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019001010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'J0190010102',
      label: 'CODELISTCONSTANTS.MDNCode.J0190010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0190010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'J0190010103',
      label: 'CODELISTCONSTANTS.MDNCode.J0190010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0190010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'J0190010104',
      label: 'CODELISTCONSTANTS.MDNCode.J0190010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0190010104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'J0190010105',
      label: 'CODELISTCONSTANTS.MDNCode.J0190010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0190010105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'J01900102',
      label: 'CODELISTCONSTANTS.MDNCode.J01900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01900102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'J0190010201',
      label: 'CODELISTCONSTANTS.MDNCode.J0190010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0190010201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'J019001020101',
      label: 'CODELISTCONSTANTS.MDNCode.J019001020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019001020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'J019001020102',
      label: 'CODELISTCONSTANTS.MDNCode.J019001020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019001020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'J019001020199',
      label: 'CODELISTCONSTANTS.MDNCode.J019001020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019001020199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'J0190010202',
      label: 'CODELISTCONSTANTS.MDNCode.J0190010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0190010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'J01900180',
      label: 'CODELISTCONSTANTS.MDNCode.J01900180.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01900180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01900199',
      label: 'CODELISTCONSTANTS.MDNCode.J01900199.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01900199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J019002',
      label: 'CODELISTCONSTANTS.MDNCode.J019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019002.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J01900203',
      label: 'CODELISTCONSTANTS.MDNCode.J01900203.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01900203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01900280',
      label: 'CODELISTCONSTANTS.MDNCode.J01900280.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01900280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01900282',
      label: 'CODELISTCONSTANTS.MDNCode.J01900282.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01900282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J01900285',
      label: 'CODELISTCONSTANTS.MDNCode.J01900285.label',
      description: 'CODELISTCONSTANTS.MDNCode.J01900285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J019003',
      label: 'CODELISTCONSTANTS.MDNCode.J019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J019004',
      label: 'CODELISTCONSTANTS.MDNCode.J019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J019005',
      label: 'CODELISTCONSTANTS.MDNCode.J019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J019099',
      label: 'CODELISTCONSTANTS.MDNCode.J019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.J019099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J02',
      label: 'CODELISTCONSTANTS.MDNCode.J02.label',
      description: 'CODELISTCONSTANTS.MDNCode.J02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'J0201',
      label: 'CODELISTCONSTANTS.MDNCode.J0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J020101',
      label: 'CODELISTCONSTANTS.MDNCode.J020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J02010101',
      label: 'CODELISTCONSTANTS.MDNCode.J02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.J02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J02010102',
      label: 'CODELISTCONSTANTS.MDNCode.J02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J020102',
      label: 'CODELISTCONSTANTS.MDNCode.J020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020180',
      label: 'CODELISTCONSTANTS.MDNCode.J020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020199',
      label: 'CODELISTCONSTANTS.MDNCode.J020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0202',
      label: 'CODELISTCONSTANTS.MDNCode.J0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J020201',
      label: 'CODELISTCONSTANTS.MDNCode.J020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020202',
      label: 'CODELISTCONSTANTS.MDNCode.J020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'J02020201',
      label: 'CODELISTCONSTANTS.MDNCode.J02020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.J02020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J02020202',
      label: 'CODELISTCONSTANTS.MDNCode.J02020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.J02020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'J020203',
      label: 'CODELISTCONSTANTS.MDNCode.J020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020280',
      label: 'CODELISTCONSTANTS.MDNCode.J020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020299',
      label: 'CODELISTCONSTANTS.MDNCode.J020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0203',
      label: 'CODELISTCONSTANTS.MDNCode.J0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J020301',
      label: 'CODELISTCONSTANTS.MDNCode.J020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020302',
      label: 'CODELISTCONSTANTS.MDNCode.J020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020380',
      label: 'CODELISTCONSTANTS.MDNCode.J020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020399',
      label: 'CODELISTCONSTANTS.MDNCode.J020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0204',
      label: 'CODELISTCONSTANTS.MDNCode.J0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0204.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J020401',
      label: 'CODELISTCONSTANTS.MDNCode.J020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020402',
      label: 'CODELISTCONSTANTS.MDNCode.J020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020403',
      label: 'CODELISTCONSTANTS.MDNCode.J020403.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020480',
      label: 'CODELISTCONSTANTS.MDNCode.J020480.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020499',
      label: 'CODELISTCONSTANTS.MDNCode.J020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0206',
      label: 'CODELISTCONSTANTS.MDNCode.J0206.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0206.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0207',
      label: 'CODELISTCONSTANTS.MDNCode.J0207.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0207.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J020701',
      label: 'CODELISTCONSTANTS.MDNCode.J020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020780',
      label: 'CODELISTCONSTANTS.MDNCode.J020780.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020780.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020782',
      label: 'CODELISTCONSTANTS.MDNCode.J020782.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020782.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020785',
      label: 'CODELISTCONSTANTS.MDNCode.J020785.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020785.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0208',
      label: 'CODELISTCONSTANTS.MDNCode.J0208.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0208.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J020801',
      label: 'CODELISTCONSTANTS.MDNCode.J020801.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020801.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020802',
      label: 'CODELISTCONSTANTS.MDNCode.J020802.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020802.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020803',
      label: 'CODELISTCONSTANTS.MDNCode.J020803.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020803.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J020804',
      label: 'CODELISTCONSTANTS.MDNCode.J020804.label',
      description: 'CODELISTCONSTANTS.MDNCode.J020804.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0209',
      label: 'CODELISTCONSTANTS.MDNCode.J0209.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0209.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0210',
      label: 'CODELISTCONSTANTS.MDNCode.J0210.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0210.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0211',
      label: 'CODELISTCONSTANTS.MDNCode.J0211.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0211.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0280',
      label: 'CODELISTCONSTANTS.MDNCode.J0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0280.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0299',
      label: 'CODELISTCONSTANTS.MDNCode.J0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J03',
      label: 'CODELISTCONSTANTS.MDNCode.J03.label',
      description: 'CODELISTCONSTANTS.MDNCode.J03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'J0301',
      label: 'CODELISTCONSTANTS.MDNCode.J0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0301.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0302',
      label: 'CODELISTCONSTANTS.MDNCode.J0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0302.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0303',
      label: 'CODELISTCONSTANTS.MDNCode.J0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0303.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0380',
      label: 'CODELISTCONSTANTS.MDNCode.J0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0399',
      label: 'CODELISTCONSTANTS.MDNCode.J0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J04',
      label: 'CODELISTCONSTANTS.MDNCode.J04.label',
      description: 'CODELISTCONSTANTS.MDNCode.J04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'J0401',
      label: 'CODELISTCONSTANTS.MDNCode.J0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0401.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0402',
      label: 'CODELISTCONSTANTS.MDNCode.J0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0402.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0403',
      label: 'CODELISTCONSTANTS.MDNCode.J0403.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0403.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'J040301',
      label: 'CODELISTCONSTANTS.MDNCode.J040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.J040301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J040380',
      label: 'CODELISTCONSTANTS.MDNCode.J040380.label',
      description: 'CODELISTCONSTANTS.MDNCode.J040380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J040382',
      label: 'CODELISTCONSTANTS.MDNCode.J040382.label',
      description: 'CODELISTCONSTANTS.MDNCode.J040382.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J040385',
      label: 'CODELISTCONSTANTS.MDNCode.J040385.label',
      description: 'CODELISTCONSTANTS.MDNCode.J040385.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'J0480',
      label: 'CODELISTCONSTANTS.MDNCode.J0480.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0480.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0499',
      label: 'CODELISTCONSTANTS.MDNCode.J0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J05',
      label: 'CODELISTCONSTANTS.MDNCode.J05.label',
      description: 'CODELISTCONSTANTS.MDNCode.J05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'J0501',
      label: 'CODELISTCONSTANTS.MDNCode.J0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0501.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0502',
      label: 'CODELISTCONSTANTS.MDNCode.J0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0502.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0580',
      label: 'CODELISTCONSTANTS.MDNCode.J0580.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0580.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J06',
      label: 'CODELISTCONSTANTS.MDNCode.J06.label',
      description: 'CODELISTCONSTANTS.MDNCode.J06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'J0601',
      label: 'CODELISTCONSTANTS.MDNCode.J0601.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0601.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0602',
      label: 'CODELISTCONSTANTS.MDNCode.J0602.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0602.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0680',
      label: 'CODELISTCONSTANTS.MDNCode.J0680.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0680.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J07',
      label: 'CODELISTCONSTANTS.MDNCode.J07.label',
      description: 'CODELISTCONSTANTS.MDNCode.J07.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'J0701',
      label: 'CODELISTCONSTANTS.MDNCode.J0701.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0701.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0702',
      label: 'CODELISTCONSTANTS.MDNCode.J0702.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0702.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0780',
      label: 'CODELISTCONSTANTS.MDNCode.J0780.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0780.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J0799',
      label: 'CODELISTCONSTANTS.MDNCode.J0799.label',
      description: 'CODELISTCONSTANTS.MDNCode.J0799.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'J99',
      label: 'CODELISTCONSTANTS.MDNCode.J99.label',
      description: 'CODELISTCONSTANTS.MDNCode.J99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'K',
      label: 'CODELISTCONSTANTS.MDNCode.K.label',
      description: 'CODELISTCONSTANTS.MDNCode.K.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'K01',
      label: 'CODELISTCONSTANTS.MDNCode.K01.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'K0101',
      label: 'CODELISTCONSTANTS.MDNCode.K0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K010101',
      label: 'CODELISTCONSTANTS.MDNCode.K010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K01010101',
      label: 'CODELISTCONSTANTS.MDNCode.K01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01010102',
      label: 'CODELISTCONSTANTS.MDNCode.K01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01010103',
      label: 'CODELISTCONSTANTS.MDNCode.K01010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01010104',
      label: 'CODELISTCONSTANTS.MDNCode.K01010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01010105',
      label: 'CODELISTCONSTANTS.MDNCode.K01010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01010105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01010180',
      label: 'CODELISTCONSTANTS.MDNCode.K01010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01010180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01010199',
      label: 'CODELISTCONSTANTS.MDNCode.K01010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K010102',
      label: 'CODELISTCONSTANTS.MDNCode.K010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K0102',
      label: 'CODELISTCONSTANTS.MDNCode.K0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K010201',
      label: 'CODELISTCONSTANTS.MDNCode.K010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K01020101',
      label: 'CODELISTCONSTANTS.MDNCode.K01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020102',
      label: 'CODELISTCONSTANTS.MDNCode.K01020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020103',
      label: 'CODELISTCONSTANTS.MDNCode.K01020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020104',
      label: 'CODELISTCONSTANTS.MDNCode.K01020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020105',
      label: 'CODELISTCONSTANTS.MDNCode.K01020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020106',
      label: 'CODELISTCONSTANTS.MDNCode.K01020106.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020107',
      label: 'CODELISTCONSTANTS.MDNCode.K01020107.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020109',
      label: 'CODELISTCONSTANTS.MDNCode.K01020109.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020110',
      label: 'CODELISTCONSTANTS.MDNCode.K01020110.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020110.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020111',
      label: 'CODELISTCONSTANTS.MDNCode.K01020111.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020111.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020112',
      label: 'CODELISTCONSTANTS.MDNCode.K01020112.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020112.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020113',
      label: 'CODELISTCONSTANTS.MDNCode.K01020113.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020113.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020114',
      label: 'CODELISTCONSTANTS.MDNCode.K01020114.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020114.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020115',
      label: 'CODELISTCONSTANTS.MDNCode.K01020115.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020115.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020116',
      label: 'CODELISTCONSTANTS.MDNCode.K01020116.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020116.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020117',
      label: 'CODELISTCONSTANTS.MDNCode.K01020117.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020117.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020118',
      label: 'CODELISTCONSTANTS.MDNCode.K01020118.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020118.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020119',
      label: 'CODELISTCONSTANTS.MDNCode.K01020119.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020119.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020120',
      label: 'CODELISTCONSTANTS.MDNCode.K01020120.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020120.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020199',
      label: 'CODELISTCONSTANTS.MDNCode.K01020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K010202',
      label: 'CODELISTCONSTANTS.MDNCode.K010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K01020201',
      label: 'CODELISTCONSTANTS.MDNCode.K01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020202',
      label: 'CODELISTCONSTANTS.MDNCode.K01020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020203',
      label: 'CODELISTCONSTANTS.MDNCode.K01020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020204',
      label: 'CODELISTCONSTANTS.MDNCode.K01020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020205',
      label: 'CODELISTCONSTANTS.MDNCode.K01020205.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K01020299',
      label: 'CODELISTCONSTANTS.MDNCode.K01020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.K01020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K0103',
      label: 'CODELISTCONSTANTS.MDNCode.K0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K010301',
      label: 'CODELISTCONSTANTS.MDNCode.K010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.K010301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K010302',
      label: 'CODELISTCONSTANTS.MDNCode.K010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.K010302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K010399',
      label: 'CODELISTCONSTANTS.MDNCode.K010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.K010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K0104',
      label: 'CODELISTCONSTANTS.MDNCode.K0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0104.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K0105',
      label: 'CODELISTCONSTANTS.MDNCode.K0105.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0105.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K0106',
      label: 'CODELISTCONSTANTS.MDNCode.K0106.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0106.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K0107',
      label: 'CODELISTCONSTANTS.MDNCode.K0107.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0107.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K0108',
      label: 'CODELISTCONSTANTS.MDNCode.K0108.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0108.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K0180',
      label: 'CODELISTCONSTANTS.MDNCode.K0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0180.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K0199',
      label: 'CODELISTCONSTANTS.MDNCode.K0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K02',
      label: 'CODELISTCONSTANTS.MDNCode.K02.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'K0201',
      label: 'CODELISTCONSTANTS.MDNCode.K0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K020101',
      label: 'CODELISTCONSTANTS.MDNCode.K020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K02010101',
      label: 'CODELISTCONSTANTS.MDNCode.K02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201010101',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201010102',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010102',
      label: 'CODELISTCONSTANTS.MDNCode.K02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201010201',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201010202',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010103',
      label: 'CODELISTCONSTANTS.MDNCode.K02010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201010301',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201010302',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010104',
      label: 'CODELISTCONSTANTS.MDNCode.K02010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201010401',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201010402',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010105',
      label: 'CODELISTCONSTANTS.MDNCode.K02010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201010501',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201010502',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010106',
      label: 'CODELISTCONSTANTS.MDNCode.K02010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010106.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201010601',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201010602',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010107',
      label: 'CODELISTCONSTANTS.MDNCode.K02010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02010108',
      label: 'CODELISTCONSTANTS.MDNCode.K02010108.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010108.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201010801',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201010802',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010802.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010109',
      label: 'CODELISTCONSTANTS.MDNCode.K02010109.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010109.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201010901',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010901.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201010902',
      label: 'CODELISTCONSTANTS.MDNCode.K0201010902.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201010902.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010110',
      label: 'CODELISTCONSTANTS.MDNCode.K02010110.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010110.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201011001',
      label: 'CODELISTCONSTANTS.MDNCode.K0201011001.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201011001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201011002',
      label: 'CODELISTCONSTANTS.MDNCode.K0201011002.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201011002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010111',
      label: 'CODELISTCONSTANTS.MDNCode.K02010111.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010111.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201011101',
      label: 'CODELISTCONSTANTS.MDNCode.K0201011101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201011101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201011102',
      label: 'CODELISTCONSTANTS.MDNCode.K0201011102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201011102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010112',
      label: 'CODELISTCONSTANTS.MDNCode.K02010112.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010112.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0201011201',
      label: 'CODELISTCONSTANTS.MDNCode.K0201011201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201011201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0201011202',
      label: 'CODELISTCONSTANTS.MDNCode.K0201011202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0201011202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02010113',
      label: 'CODELISTCONSTANTS.MDNCode.K02010113.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010113.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02010199',
      label: 'CODELISTCONSTANTS.MDNCode.K02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K020102',
      label: 'CODELISTCONSTANTS.MDNCode.K020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K02010201',
      label: 'CODELISTCONSTANTS.MDNCode.K02010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02010202',
      label: 'CODELISTCONSTANTS.MDNCode.K02010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02010203',
      label: 'CODELISTCONSTANTS.MDNCode.K02010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K020103',
      label: 'CODELISTCONSTANTS.MDNCode.K020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K02010301',
      label: 'CODELISTCONSTANTS.MDNCode.K02010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02010302',
      label: 'CODELISTCONSTANTS.MDNCode.K02010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02010303',
      label: 'CODELISTCONSTANTS.MDNCode.K02010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02010303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K020180',
      label: 'CODELISTCONSTANTS.MDNCode.K020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K020199',
      label: 'CODELISTCONSTANTS.MDNCode.K020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K0202',
      label: 'CODELISTCONSTANTS.MDNCode.K0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K020201',
      label: 'CODELISTCONSTANTS.MDNCode.K020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K02020101',
      label: 'CODELISTCONSTANTS.MDNCode.K02020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010101',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010102',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020102',
      label: 'CODELISTCONSTANTS.MDNCode.K02020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010201',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010202',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020103',
      label: 'CODELISTCONSTANTS.MDNCode.K02020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010301',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010302',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020104',
      label: 'CODELISTCONSTANTS.MDNCode.K02020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010401',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010402',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020105',
      label: 'CODELISTCONSTANTS.MDNCode.K02020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010501',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010502',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020106',
      label: 'CODELISTCONSTANTS.MDNCode.K02020106.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020106.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010601',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010602',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020107',
      label: 'CODELISTCONSTANTS.MDNCode.K02020107.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020107.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010701',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010702',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020108',
      label: 'CODELISTCONSTANTS.MDNCode.K02020108.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020108.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010801',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010802',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010802.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020109',
      label: 'CODELISTCONSTANTS.MDNCode.K02020109.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020109.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0202010901',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010901.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0202010902',
      label: 'CODELISTCONSTANTS.MDNCode.K0202010902.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0202010902.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02020199',
      label: 'CODELISTCONSTANTS.MDNCode.K02020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K020280',
      label: 'CODELISTCONSTANTS.MDNCode.K020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K020299',
      label: 'CODELISTCONSTANTS.MDNCode.K020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K0203',
      label: 'CODELISTCONSTANTS.MDNCode.K0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K020301',
      label: 'CODELISTCONSTANTS.MDNCode.K020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K02030101',
      label: 'CODELISTCONSTANTS.MDNCode.K02030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030102',
      label: 'CODELISTCONSTANTS.MDNCode.K02030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030103',
      label: 'CODELISTCONSTANTS.MDNCode.K02030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030104',
      label: 'CODELISTCONSTANTS.MDNCode.K02030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030105',
      label: 'CODELISTCONSTANTS.MDNCode.K02030105.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030106',
      label: 'CODELISTCONSTANTS.MDNCode.K02030106.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030107',
      label: 'CODELISTCONSTANTS.MDNCode.K02030107.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030108',
      label: 'CODELISTCONSTANTS.MDNCode.K02030108.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030108.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030109',
      label: 'CODELISTCONSTANTS.MDNCode.K02030109.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030110',
      label: 'CODELISTCONSTANTS.MDNCode.K02030110.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030110.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02030199',
      label: 'CODELISTCONSTANTS.MDNCode.K02030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K020380',
      label: 'CODELISTCONSTANTS.MDNCode.K020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K020399',
      label: 'CODELISTCONSTANTS.MDNCode.K020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K0204',
      label: 'CODELISTCONSTANTS.MDNCode.K0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K020401',
      label: 'CODELISTCONSTANTS.MDNCode.K020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K02040101',
      label: 'CODELISTCONSTANTS.MDNCode.K02040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0204010101',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0204010102',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02040102',
      label: 'CODELISTCONSTANTS.MDNCode.K02040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0204010201',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0204010202',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02040103',
      label: 'CODELISTCONSTANTS.MDNCode.K02040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0204010301',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0204010302',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02040104',
      label: 'CODELISTCONSTANTS.MDNCode.K02040104.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0204010401',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0204010402',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02040105',
      label: 'CODELISTCONSTANTS.MDNCode.K02040105.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0204010501',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0204010502',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02040106',
      label: 'CODELISTCONSTANTS.MDNCode.K02040106.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040106.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0204010601',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0204010602',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02040107',
      label: 'CODELISTCONSTANTS.MDNCode.K02040107.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040107.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0204010701',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0204010702',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02040108',
      label: 'CODELISTCONSTANTS.MDNCode.K02040108.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040108.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'K0204010801',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K0204010802',
      label: 'CODELISTCONSTANTS.MDNCode.K0204010802.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0204010802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'K02040109',
      label: 'CODELISTCONSTANTS.MDNCode.K02040109.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02040199',
      label: 'CODELISTCONSTANTS.MDNCode.K02040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K020480',
      label: 'CODELISTCONSTANTS.MDNCode.K020480.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K020499',
      label: 'CODELISTCONSTANTS.MDNCode.K020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K0205',
      label: 'CODELISTCONSTANTS.MDNCode.K0205.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0205.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K020501',
      label: 'CODELISTCONSTANTS.MDNCode.K020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.K020501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'K02050101',
      label: 'CODELISTCONSTANTS.MDNCode.K02050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050102',
      label: 'CODELISTCONSTANTS.MDNCode.K02050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050103',
      label: 'CODELISTCONSTANTS.MDNCode.K02050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050104',
      label: 'CODELISTCONSTANTS.MDNCode.K02050104.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050105',
      label: 'CODELISTCONSTANTS.MDNCode.K02050105.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050106',
      label: 'CODELISTCONSTANTS.MDNCode.K02050106.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050107',
      label: 'CODELISTCONSTANTS.MDNCode.K02050107.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050108',
      label: 'CODELISTCONSTANTS.MDNCode.K02050108.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050108.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050109',
      label: 'CODELISTCONSTANTS.MDNCode.K02050109.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K02050110',
      label: 'CODELISTCONSTANTS.MDNCode.K02050110.label',
      description: 'CODELISTCONSTANTS.MDNCode.K02050110.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'K0299',
      label: 'CODELISTCONSTANTS.MDNCode.K0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K03',
      label: 'CODELISTCONSTANTS.MDNCode.K03.label',
      description: 'CODELISTCONSTANTS.MDNCode.K03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'K0301',
      label: 'CODELISTCONSTANTS.MDNCode.K0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0301.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K0302',
      label: 'CODELISTCONSTANTS.MDNCode.K0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'K030201',
      label: 'CODELISTCONSTANTS.MDNCode.K030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030202',
      label: 'CODELISTCONSTANTS.MDNCode.K030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030203',
      label: 'CODELISTCONSTANTS.MDNCode.K030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030204',
      label: 'CODELISTCONSTANTS.MDNCode.K030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030205',
      label: 'CODELISTCONSTANTS.MDNCode.K030205.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030206',
      label: 'CODELISTCONSTANTS.MDNCode.K030206.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030207',
      label: 'CODELISTCONSTANTS.MDNCode.K030207.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030207.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030208',
      label: 'CODELISTCONSTANTS.MDNCode.K030208.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030208.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030209',
      label: 'CODELISTCONSTANTS.MDNCode.K030209.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030209.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030210',
      label: 'CODELISTCONSTANTS.MDNCode.K030210.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030210.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030211',
      label: 'CODELISTCONSTANTS.MDNCode.K030211.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030211.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030212',
      label: 'CODELISTCONSTANTS.MDNCode.K030212.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030212.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K030299',
      label: 'CODELISTCONSTANTS.MDNCode.K030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.K030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'K0380',
      label: 'CODELISTCONSTANTS.MDNCode.K0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'K0399',
      label: 'CODELISTCONSTANTS.MDNCode.K0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.K0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L',
      label: 'CODELISTCONSTANTS.MDNCode.L.label',
      description: 'CODELISTCONSTANTS.MDNCode.L.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'L01',
      label: 'CODELISTCONSTANTS.MDNCode.L01.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0101',
      label: 'CODELISTCONSTANTS.MDNCode.L0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L010101',
      label: 'CODELISTCONSTANTS.MDNCode.L010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L010102',
      label: 'CODELISTCONSTANTS.MDNCode.L010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L010103',
      label: 'CODELISTCONSTANTS.MDNCode.L010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0102',
      label: 'CODELISTCONSTANTS.MDNCode.L0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0102.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0103',
      label: 'CODELISTCONSTANTS.MDNCode.L0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0103.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0104',
      label: 'CODELISTCONSTANTS.MDNCode.L0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L010401',
      label: 'CODELISTCONSTANTS.MDNCode.L010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01040101',
      label: 'CODELISTCONSTANTS.MDNCode.L01040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040102',
      label: 'CODELISTCONSTANTS.MDNCode.L01040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040103',
      label: 'CODELISTCONSTANTS.MDNCode.L01040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040199',
      label: 'CODELISTCONSTANTS.MDNCode.L01040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010402',
      label: 'CODELISTCONSTANTS.MDNCode.L010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01040201',
      label: 'CODELISTCONSTANTS.MDNCode.L01040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040202',
      label: 'CODELISTCONSTANTS.MDNCode.L01040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040299',
      label: 'CODELISTCONSTANTS.MDNCode.L01040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010403',
      label: 'CODELISTCONSTANTS.MDNCode.L010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L010404',
      label: 'CODELISTCONSTANTS.MDNCode.L010404.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010404.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01040401',
      label: 'CODELISTCONSTANTS.MDNCode.L01040401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040402',
      label: 'CODELISTCONSTANTS.MDNCode.L01040402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010405',
      label: 'CODELISTCONSTANTS.MDNCode.L010405.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010405.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01040501',
      label: 'CODELISTCONSTANTS.MDNCode.L01040501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040502',
      label: 'CODELISTCONSTANTS.MDNCode.L01040502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040503',
      label: 'CODELISTCONSTANTS.MDNCode.L01040503.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040504',
      label: 'CODELISTCONSTANTS.MDNCode.L01040504.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040504.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040505',
      label: 'CODELISTCONSTANTS.MDNCode.L01040505.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040505.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040506',
      label: 'CODELISTCONSTANTS.MDNCode.L01040506.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040506.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040507',
      label: 'CODELISTCONSTANTS.MDNCode.L01040507.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040507.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040508',
      label: 'CODELISTCONSTANTS.MDNCode.L01040508.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040508.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040509',
      label: 'CODELISTCONSTANTS.MDNCode.L01040509.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040509.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0104050901',
      label: 'CODELISTCONSTANTS.MDNCode.L0104050901.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104050901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0104050999',
      label: 'CODELISTCONSTANTS.MDNCode.L0104050999.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104050999.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L01040510',
      label: 'CODELISTCONSTANTS.MDNCode.L01040510.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040510.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040599',
      label: 'CODELISTCONSTANTS.MDNCode.L01040599.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010406',
      label: 'CODELISTCONSTANTS.MDNCode.L010406.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010406.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01040601',
      label: 'CODELISTCONSTANTS.MDNCode.L01040601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040602',
      label: 'CODELISTCONSTANTS.MDNCode.L01040602.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040603',
      label: 'CODELISTCONSTANTS.MDNCode.L01040603.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040604',
      label: 'CODELISTCONSTANTS.MDNCode.L01040604.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040604.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040699',
      label: 'CODELISTCONSTANTS.MDNCode.L01040699.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010407',
      label: 'CODELISTCONSTANTS.MDNCode.L010407.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010407.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01040701',
      label: 'CODELISTCONSTANTS.MDNCode.L01040701.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040702',
      label: 'CODELISTCONSTANTS.MDNCode.L01040702.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01040799',
      label: 'CODELISTCONSTANTS.MDNCode.L01040799.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040799.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010408',
      label: 'CODELISTCONSTANTS.MDNCode.L010408.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010408.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01040801',
      label: 'CODELISTCONSTANTS.MDNCode.L01040801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040801.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0104080101',
      label: 'CODELISTCONSTANTS.MDNCode.L0104080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104080101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0104080102',
      label: 'CODELISTCONSTANTS.MDNCode.L0104080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104080102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0104080199',
      label: 'CODELISTCONSTANTS.MDNCode.L0104080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104080199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L01040899',
      label: 'CODELISTCONSTANTS.MDNCode.L01040899.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01040899.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010409',
      label: 'CODELISTCONSTANTS.MDNCode.L010409.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010409.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L010410',
      label: 'CODELISTCONSTANTS.MDNCode.L010410.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010410.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01041001',
      label: 'CODELISTCONSTANTS.MDNCode.L01041001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01041001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01041099',
      label: 'CODELISTCONSTANTS.MDNCode.L01041099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01041099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010411',
      label: 'CODELISTCONSTANTS.MDNCode.L010411.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010411.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01041101',
      label: 'CODELISTCONSTANTS.MDNCode.L01041101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01041101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0104110101',
      label: 'CODELISTCONSTANTS.MDNCode.L0104110101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104110101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0104110102',
      label: 'CODELISTCONSTANTS.MDNCode.L0104110102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104110102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0104110103',
      label: 'CODELISTCONSTANTS.MDNCode.L0104110103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104110103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0104110199',
      label: 'CODELISTCONSTANTS.MDNCode.L0104110199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104110199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L01041102',
      label: 'CODELISTCONSTANTS.MDNCode.L01041102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01041102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0104110201',
      label: 'CODELISTCONSTANTS.MDNCode.L0104110201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104110201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0104110202',
      label: 'CODELISTCONSTANTS.MDNCode.L0104110202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104110202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0104110299',
      label: 'CODELISTCONSTANTS.MDNCode.L0104110299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0104110299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L01041199',
      label: 'CODELISTCONSTANTS.MDNCode.L01041199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01041199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010412',
      label: 'CODELISTCONSTANTS.MDNCode.L010412.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010412.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L01041201',
      label: 'CODELISTCONSTANTS.MDNCode.L01041201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01041201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01041202',
      label: 'CODELISTCONSTANTS.MDNCode.L01041202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01041202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L01041299',
      label: 'CODELISTCONSTANTS.MDNCode.L01041299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L01041299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L010413',
      label: 'CODELISTCONSTANTS.MDNCode.L010413.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010413.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L010499',
      label: 'CODELISTCONSTANTS.MDNCode.L010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0107',
      label: 'CODELISTCONSTANTS.MDNCode.L0107.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0107.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L010701',
      label: 'CODELISTCONSTANTS.MDNCode.L010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L010702',
      label: 'CODELISTCONSTANTS.MDNCode.L010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L010703',
      label: 'CODELISTCONSTANTS.MDNCode.L010703.label',
      description: 'CODELISTCONSTANTS.MDNCode.L010703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0180',
      label: 'CODELISTCONSTANTS.MDNCode.L0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0180.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0199',
      label: 'CODELISTCONSTANTS.MDNCode.L0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L02',
      label: 'CODELISTCONSTANTS.MDNCode.L02.label',
      description: 'CODELISTCONSTANTS.MDNCode.L02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0201',
      label: 'CODELISTCONSTANTS.MDNCode.L0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0201.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0202',
      label: 'CODELISTCONSTANTS.MDNCode.L0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0202.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0203',
      label: 'CODELISTCONSTANTS.MDNCode.L0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0203.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0204',
      label: 'CODELISTCONSTANTS.MDNCode.L0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0204.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0205',
      label: 'CODELISTCONSTANTS.MDNCode.L0205.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0205.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0206',
      label: 'CODELISTCONSTANTS.MDNCode.L0206.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0206.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0207',
      label: 'CODELISTCONSTANTS.MDNCode.L0207.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0207.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0208',
      label: 'CODELISTCONSTANTS.MDNCode.L0208.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0208.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L020801',
      label: 'CODELISTCONSTANTS.MDNCode.L020801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L020801.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L020802',
      label: 'CODELISTCONSTANTS.MDNCode.L020802.label',
      description: 'CODELISTCONSTANTS.MDNCode.L020802.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0299',
      label: 'CODELISTCONSTANTS.MDNCode.L0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L03',
      label: 'CODELISTCONSTANTS.MDNCode.L03.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0301',
      label: 'CODELISTCONSTANTS.MDNCode.L0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L030101',
      label: 'CODELISTCONSTANTS.MDNCode.L030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L030101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L030199',
      label: 'CODELISTCONSTANTS.MDNCode.L030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0311',
      label: 'CODELISTCONSTANTS.MDNCode.L0311.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0311.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L031101',
      label: 'CODELISTCONSTANTS.MDNCode.L031101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031102',
      label: 'CODELISTCONSTANTS.MDNCode.L031102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031103',
      label: 'CODELISTCONSTANTS.MDNCode.L031103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031104',
      label: 'CODELISTCONSTANTS.MDNCode.L031104.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031199',
      label: 'CODELISTCONSTANTS.MDNCode.L031199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0312',
      label: 'CODELISTCONSTANTS.MDNCode.L0312.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0312.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L031201',
      label: 'CODELISTCONSTANTS.MDNCode.L031201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031202',
      label: 'CODELISTCONSTANTS.MDNCode.L031202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031203',
      label: 'CODELISTCONSTANTS.MDNCode.L031203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031204',
      label: 'CODELISTCONSTANTS.MDNCode.L031204.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031205',
      label: 'CODELISTCONSTANTS.MDNCode.L031205.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031280',
      label: 'CODELISTCONSTANTS.MDNCode.L031280.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031299',
      label: 'CODELISTCONSTANTS.MDNCode.L031299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0313',
      label: 'CODELISTCONSTANTS.MDNCode.L0313.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0313.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L031301',
      label: 'CODELISTCONSTANTS.MDNCode.L031301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031302',
      label: 'CODELISTCONSTANTS.MDNCode.L031302.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031303',
      label: 'CODELISTCONSTANTS.MDNCode.L031303.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031304',
      label: 'CODELISTCONSTANTS.MDNCode.L031304.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031304.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L03130401',
      label: 'CODELISTCONSTANTS.MDNCode.L03130401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03130401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L03130402',
      label: 'CODELISTCONSTANTS.MDNCode.L03130402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03130402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L03130403',
      label: 'CODELISTCONSTANTS.MDNCode.L03130403.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03130403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L03130499',
      label: 'CODELISTCONSTANTS.MDNCode.L03130499.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03130499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L031305',
      label: 'CODELISTCONSTANTS.MDNCode.L031305.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031306',
      label: 'CODELISTCONSTANTS.MDNCode.L031306.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031306.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031307',
      label: 'CODELISTCONSTANTS.MDNCode.L031307.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031307.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031308',
      label: 'CODELISTCONSTANTS.MDNCode.L031308.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031308.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031309',
      label: 'CODELISTCONSTANTS.MDNCode.L031309.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031309.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031310',
      label: 'CODELISTCONSTANTS.MDNCode.L031310.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031310.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031312',
      label: 'CODELISTCONSTANTS.MDNCode.L031312.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031312.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L031399',
      label: 'CODELISTCONSTANTS.MDNCode.L031399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0314',
      label: 'CODELISTCONSTANTS.MDNCode.L0314.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0314.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L031401',
      label: 'CODELISTCONSTANTS.MDNCode.L031401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L03140101',
      label: 'CODELISTCONSTANTS.MDNCode.L03140101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03140101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L03140102',
      label: 'CODELISTCONSTANTS.MDNCode.L03140102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03140102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L03140103',
      label: 'CODELISTCONSTANTS.MDNCode.L03140103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03140103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L03140104',
      label: 'CODELISTCONSTANTS.MDNCode.L03140104.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03140104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L03140105',
      label: 'CODELISTCONSTANTS.MDNCode.L03140105.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03140105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L03140199',
      label: 'CODELISTCONSTANTS.MDNCode.L03140199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L03140199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L031402',
      label: 'CODELISTCONSTANTS.MDNCode.L031402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L031402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0315',
      label: 'CODELISTCONSTANTS.MDNCode.L0315.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0315.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0316',
      label: 'CODELISTCONSTANTS.MDNCode.L0316.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0316.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0317',
      label: 'CODELISTCONSTANTS.MDNCode.L0317.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0317.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0318',
      label: 'CODELISTCONSTANTS.MDNCode.L0318.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0318.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0399',
      label: 'CODELISTCONSTANTS.MDNCode.L0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L04',
      label: 'CODELISTCONSTANTS.MDNCode.L04.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0406',
      label: 'CODELISTCONSTANTS.MDNCode.L0406.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0406.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0408',
      label: 'CODELISTCONSTANTS.MDNCode.L0408.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0408.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L040801',
      label: 'CODELISTCONSTANTS.MDNCode.L040801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L040801.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L04080101',
      label: 'CODELISTCONSTANTS.MDNCode.L04080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04080102',
      label: 'CODELISTCONSTANTS.MDNCode.L04080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04080103',
      label: 'CODELISTCONSTANTS.MDNCode.L04080103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04080199',
      label: 'CODELISTCONSTANTS.MDNCode.L04080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L040802',
      label: 'CODELISTCONSTANTS.MDNCode.L040802.label',
      description: 'CODELISTCONSTANTS.MDNCode.L040802.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L04080201',
      label: 'CODELISTCONSTANTS.MDNCode.L04080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04080202',
      label: 'CODELISTCONSTANTS.MDNCode.L04080202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04080203',
      label: 'CODELISTCONSTANTS.MDNCode.L04080203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080203.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0408020301',
      label: 'CODELISTCONSTANTS.MDNCode.L0408020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0408020301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0408020302',
      label: 'CODELISTCONSTANTS.MDNCode.L0408020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0408020302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0408020303',
      label: 'CODELISTCONSTANTS.MDNCode.L0408020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0408020303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0408020304',
      label: 'CODELISTCONSTANTS.MDNCode.L0408020304.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0408020304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0408020305',
      label: 'CODELISTCONSTANTS.MDNCode.L0408020305.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0408020305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0408020306',
      label: 'CODELISTCONSTANTS.MDNCode.L0408020306.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0408020306.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0408020399',
      label: 'CODELISTCONSTANTS.MDNCode.L0408020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0408020399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L04080204',
      label: 'CODELISTCONSTANTS.MDNCode.L04080204.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04080299',
      label: 'CODELISTCONSTANTS.MDNCode.L04080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04080299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L0409',
      label: 'CODELISTCONSTANTS.MDNCode.L0409.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0409.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L040901',
      label: 'CODELISTCONSTANTS.MDNCode.L040901.label',
      description: 'CODELISTCONSTANTS.MDNCode.L040901.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L040902',
      label: 'CODELISTCONSTANTS.MDNCode.L040902.label',
      description: 'CODELISTCONSTANTS.MDNCode.L040902.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L040903',
      label: 'CODELISTCONSTANTS.MDNCode.L040903.label',
      description: 'CODELISTCONSTANTS.MDNCode.L040903.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L040999',
      label: 'CODELISTCONSTANTS.MDNCode.L040999.label',
      description: 'CODELISTCONSTANTS.MDNCode.L040999.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0410',
      label: 'CODELISTCONSTANTS.MDNCode.L0410.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0410.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L041001',
      label: 'CODELISTCONSTANTS.MDNCode.L041001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L041001.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L04100101',
      label: 'CODELISTCONSTANTS.MDNCode.L04100101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04100101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04100102',
      label: 'CODELISTCONSTANTS.MDNCode.L04100102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04100102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L041002',
      label: 'CODELISTCONSTANTS.MDNCode.L041002.label',
      description: 'CODELISTCONSTANTS.MDNCode.L041002.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L04100201',
      label: 'CODELISTCONSTANTS.MDNCode.L04100201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04100201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04100202',
      label: 'CODELISTCONSTANTS.MDNCode.L04100202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04100202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L04100299',
      label: 'CODELISTCONSTANTS.MDNCode.L04100299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L04100299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L0411',
      label: 'CODELISTCONSTANTS.MDNCode.L0411.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0411.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0499',
      label: 'CODELISTCONSTANTS.MDNCode.L0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L05',
      label: 'CODELISTCONSTANTS.MDNCode.L05.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0508',
      label: 'CODELISTCONSTANTS.MDNCode.L0508.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0508.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L050801',
      label: 'CODELISTCONSTANTS.MDNCode.L050801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050801.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L050802',
      label: 'CODELISTCONSTANTS.MDNCode.L050802.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050802.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L050803',
      label: 'CODELISTCONSTANTS.MDNCode.L050803.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050803.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L05080301',
      label: 'CODELISTCONSTANTS.MDNCode.L05080301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05080301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0508030101',
      label: 'CODELISTCONSTANTS.MDNCode.L0508030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0508030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0508030199',
      label: 'CODELISTCONSTANTS.MDNCode.L0508030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0508030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L05080302',
      label: 'CODELISTCONSTANTS.MDNCode.L05080302.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05080302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L05080303',
      label: 'CODELISTCONSTANTS.MDNCode.L05080303.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05080303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L05080399',
      label: 'CODELISTCONSTANTS.MDNCode.L05080399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05080399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L050804',
      label: 'CODELISTCONSTANTS.MDNCode.L050804.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050804.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L050899',
      label: 'CODELISTCONSTANTS.MDNCode.L050899.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0509',
      label: 'CODELISTCONSTANTS.MDNCode.L0509.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L050901',
      label: 'CODELISTCONSTANTS.MDNCode.L050901.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050901.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L050902',
      label: 'CODELISTCONSTANTS.MDNCode.L050902.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050902.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L05090201',
      label: 'CODELISTCONSTANTS.MDNCode.L05090201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05090201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0509020101',
      label: 'CODELISTCONSTANTS.MDNCode.L0509020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0509020102',
      label: 'CODELISTCONSTANTS.MDNCode.L0509020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0509020103',
      label: 'CODELISTCONSTANTS.MDNCode.L0509020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509020103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0509020199',
      label: 'CODELISTCONSTANTS.MDNCode.L0509020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L05090202',
      label: 'CODELISTCONSTANTS.MDNCode.L05090202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05090202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0509020201',
      label: 'CODELISTCONSTANTS.MDNCode.L0509020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0509020299',
      label: 'CODELISTCONSTANTS.MDNCode.L0509020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L050903',
      label: 'CODELISTCONSTANTS.MDNCode.L050903.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050903.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L05090301',
      label: 'CODELISTCONSTANTS.MDNCode.L05090301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05090301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L05090302',
      label: 'CODELISTCONSTANTS.MDNCode.L05090302.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05090302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0509030201',
      label: 'CODELISTCONSTANTS.MDNCode.L0509030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0509030202',
      label: 'CODELISTCONSTANTS.MDNCode.L0509030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0509030203',
      label: 'CODELISTCONSTANTS.MDNCode.L0509030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509030203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0509030299',
      label: 'CODELISTCONSTANTS.MDNCode.L0509030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0509030299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L05090399',
      label: 'CODELISTCONSTANTS.MDNCode.L05090399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05090399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L050905',
      label: 'CODELISTCONSTANTS.MDNCode.L050905.label',
      description: 'CODELISTCONSTANTS.MDNCode.L050905.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L05090501',
      label: 'CODELISTCONSTANTS.MDNCode.L05090501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05090501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L05090599',
      label: 'CODELISTCONSTANTS.MDNCode.L05090599.label',
      description: 'CODELISTCONSTANTS.MDNCode.L05090599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L0590',
      label: 'CODELISTCONSTANTS.MDNCode.L0590.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0590.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L059001',
      label: 'CODELISTCONSTANTS.MDNCode.L059001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L059002',
      label: 'CODELISTCONSTANTS.MDNCode.L059002.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L059003',
      label: 'CODELISTCONSTANTS.MDNCode.L059003.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L059004',
      label: 'CODELISTCONSTANTS.MDNCode.L059004.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L059005',
      label: 'CODELISTCONSTANTS.MDNCode.L059005.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L059006',
      label: 'CODELISTCONSTANTS.MDNCode.L059006.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L059007',
      label: 'CODELISTCONSTANTS.MDNCode.L059007.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L059008',
      label: 'CODELISTCONSTANTS.MDNCode.L059008.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059008.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L059099',
      label: 'CODELISTCONSTANTS.MDNCode.L059099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L059099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L06',
      label: 'CODELISTCONSTANTS.MDNCode.L06.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0604',
      label: 'CODELISTCONSTANTS.MDNCode.L0604.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0604.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L060401',
      label: 'CODELISTCONSTANTS.MDNCode.L060401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060402',
      label: 'CODELISTCONSTANTS.MDNCode.L060402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060403',
      label: 'CODELISTCONSTANTS.MDNCode.L060403.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060404',
      label: 'CODELISTCONSTANTS.MDNCode.L060404.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060405',
      label: 'CODELISTCONSTANTS.MDNCode.L060405.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060405.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L06040501',
      label: 'CODELISTCONSTANTS.MDNCode.L06040501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06040501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L06040599',
      label: 'CODELISTCONSTANTS.MDNCode.L06040599.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06040599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L060406',
      label: 'CODELISTCONSTANTS.MDNCode.L060406.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060406.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060407',
      label: 'CODELISTCONSTANTS.MDNCode.L060407.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060407.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060408',
      label: 'CODELISTCONSTANTS.MDNCode.L060408.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060408.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060409',
      label: 'CODELISTCONSTANTS.MDNCode.L060409.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060409.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060410',
      label: 'CODELISTCONSTANTS.MDNCode.L060410.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060410.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060411',
      label: 'CODELISTCONSTANTS.MDNCode.L060411.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060411.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060412',
      label: 'CODELISTCONSTANTS.MDNCode.L060412.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060412.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060413',
      label: 'CODELISTCONSTANTS.MDNCode.L060413.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060413.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060480',
      label: 'CODELISTCONSTANTS.MDNCode.L060480.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060499',
      label: 'CODELISTCONSTANTS.MDNCode.L060499.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0605',
      label: 'CODELISTCONSTANTS.MDNCode.L0605.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0605.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L060501',
      label: 'CODELISTCONSTANTS.MDNCode.L060501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L06050101',
      label: 'CODELISTCONSTANTS.MDNCode.L06050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L06050199',
      label: 'CODELISTCONSTANTS.MDNCode.L06050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06050199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L060502',
      label: 'CODELISTCONSTANTS.MDNCode.L060502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L06050201',
      label: 'CODELISTCONSTANTS.MDNCode.L06050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06050201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L06050202',
      label: 'CODELISTCONSTANTS.MDNCode.L06050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06050202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L06050299',
      label: 'CODELISTCONSTANTS.MDNCode.L06050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06050299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L0606',
      label: 'CODELISTCONSTANTS.MDNCode.L0606.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0606.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L060601',
      label: 'CODELISTCONSTANTS.MDNCode.L060601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060601.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L06060101',
      label: 'CODELISTCONSTANTS.MDNCode.L06060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06060101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L06060102',
      label: 'CODELISTCONSTANTS.MDNCode.L06060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06060102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L06060103',
      label: 'CODELISTCONSTANTS.MDNCode.L06060103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06060103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L06060199',
      label: 'CODELISTCONSTANTS.MDNCode.L06060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06060199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L060602',
      label: 'CODELISTCONSTANTS.MDNCode.L060602.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060603',
      label: 'CODELISTCONSTANTS.MDNCode.L060603.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060603.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L06060301',
      label: 'CODELISTCONSTANTS.MDNCode.L06060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06060301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0606030101',
      label: 'CODELISTCONSTANTS.MDNCode.L0606030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0606030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0606030199',
      label: 'CODELISTCONSTANTS.MDNCode.L0606030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0606030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L06060399',
      label: 'CODELISTCONSTANTS.MDNCode.L06060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L06060399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L060604',
      label: 'CODELISTCONSTANTS.MDNCode.L060604.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060604.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L060699',
      label: 'CODELISTCONSTANTS.MDNCode.L060699.label',
      description: 'CODELISTCONSTANTS.MDNCode.L060699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0607',
      label: 'CODELISTCONSTANTS.MDNCode.L0607.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0607.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0608',
      label: 'CODELISTCONSTANTS.MDNCode.L0608.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0608.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0609',
      label: 'CODELISTCONSTANTS.MDNCode.L0609.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0609.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0610',
      label: 'CODELISTCONSTANTS.MDNCode.L0610.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0610.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0611',
      label: 'CODELISTCONSTANTS.MDNCode.L0611.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0611.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0699',
      label: 'CODELISTCONSTANTS.MDNCode.L0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L07',
      label: 'CODELISTCONSTANTS.MDNCode.L07.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0707',
      label: 'CODELISTCONSTANTS.MDNCode.L0707.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0707.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L070701',
      label: 'CODELISTCONSTANTS.MDNCode.L070701.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070702',
      label: 'CODELISTCONSTANTS.MDNCode.L070702.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070702.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L07070201',
      label: 'CODELISTCONSTANTS.MDNCode.L07070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07070201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0707020101',
      label: 'CODELISTCONSTANTS.MDNCode.L0707020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0707020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0707020199',
      label: 'CODELISTCONSTANTS.MDNCode.L0707020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0707020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L07070202',
      label: 'CODELISTCONSTANTS.MDNCode.L07070202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07070202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L070703',
      label: 'CODELISTCONSTANTS.MDNCode.L070703.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070704',
      label: 'CODELISTCONSTANTS.MDNCode.L070704.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070704.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070705',
      label: 'CODELISTCONSTANTS.MDNCode.L070705.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070705.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070799',
      label: 'CODELISTCONSTANTS.MDNCode.L070799.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0708',
      label: 'CODELISTCONSTANTS.MDNCode.L0708.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L070801',
      label: 'CODELISTCONSTANTS.MDNCode.L070801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070801.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L07080101',
      label: 'CODELISTCONSTANTS.MDNCode.L07080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0708010101',
      label: 'CODELISTCONSTANTS.MDNCode.L0708010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0708010180',
      label: 'CODELISTCONSTANTS.MDNCode.L0708010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708010180.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'L070801018001',
      label: 'CODELISTCONSTANTS.MDNCode.L070801018001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070801018001.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'L070801018099',
      label: 'CODELISTCONSTANTS.MDNCode.L070801018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070801018099.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'L0708010199',
      label: 'CODELISTCONSTANTS.MDNCode.L0708010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L07080102',
      label: 'CODELISTCONSTANTS.MDNCode.L07080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L07080103',
      label: 'CODELISTCONSTANTS.MDNCode.L07080103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L07080104',
      label: 'CODELISTCONSTANTS.MDNCode.L07080104.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L07080105',
      label: 'CODELISTCONSTANTS.MDNCode.L07080105.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0708010501',
      label: 'CODELISTCONSTANTS.MDNCode.L0708010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0708010502',
      label: 'CODELISTCONSTANTS.MDNCode.L0708010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708010502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0708010503',
      label: 'CODELISTCONSTANTS.MDNCode.L0708010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708010503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0708010599',
      label: 'CODELISTCONSTANTS.MDNCode.L0708010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708010599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L07080199',
      label: 'CODELISTCONSTANTS.MDNCode.L07080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L070802',
      label: 'CODELISTCONSTANTS.MDNCode.L070802.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070802.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070803',
      label: 'CODELISTCONSTANTS.MDNCode.L070803.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070803.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070804',
      label: 'CODELISTCONSTANTS.MDNCode.L070804.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070804.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070805',
      label: 'CODELISTCONSTANTS.MDNCode.L070805.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070805.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070806',
      label: 'CODELISTCONSTANTS.MDNCode.L070806.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070806.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L07080601',
      label: 'CODELISTCONSTANTS.MDNCode.L07080601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080601.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'L0708060101',
      label: 'CODELISTCONSTANTS.MDNCode.L0708060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708060101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L0708060199',
      label: 'CODELISTCONSTANTS.MDNCode.L0708060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0708060199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'L07080699',
      label: 'CODELISTCONSTANTS.MDNCode.L07080699.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L070807',
      label: 'CODELISTCONSTANTS.MDNCode.L070807.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070807.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070808',
      label: 'CODELISTCONSTANTS.MDNCode.L070808.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070808.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L07080801',
      label: 'CODELISTCONSTANTS.MDNCode.L07080801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L07080899',
      label: 'CODELISTCONSTANTS.MDNCode.L07080899.label',
      description: 'CODELISTCONSTANTS.MDNCode.L07080899.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L070809',
      label: 'CODELISTCONSTANTS.MDNCode.L070809.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070809.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070810',
      label: 'CODELISTCONSTANTS.MDNCode.L070810.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070810.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L070899',
      label: 'CODELISTCONSTANTS.MDNCode.L070899.label',
      description: 'CODELISTCONSTANTS.MDNCode.L070899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L08',
      label: 'CODELISTCONSTANTS.MDNCode.L08.label',
      description: 'CODELISTCONSTANTS.MDNCode.L08.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0801',
      label: 'CODELISTCONSTANTS.MDNCode.L0801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0801.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0804',
      label: 'CODELISTCONSTANTS.MDNCode.L0804.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0804.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0805',
      label: 'CODELISTCONSTANTS.MDNCode.L0805.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0805.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L080501',
      label: 'CODELISTCONSTANTS.MDNCode.L080501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L080502',
      label: 'CODELISTCONSTANTS.MDNCode.L080502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L08050201',
      label: 'CODELISTCONSTANTS.MDNCode.L08050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L08050201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L08050299',
      label: 'CODELISTCONSTANTS.MDNCode.L08050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L08050299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L080599',
      label: 'CODELISTCONSTANTS.MDNCode.L080599.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0806',
      label: 'CODELISTCONSTANTS.MDNCode.L0806.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0806.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L080601',
      label: 'CODELISTCONSTANTS.MDNCode.L080601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080601.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L08060101',
      label: 'CODELISTCONSTANTS.MDNCode.L08060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L08060101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L08060199',
      label: 'CODELISTCONSTANTS.MDNCode.L08060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L08060199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L080602',
      label: 'CODELISTCONSTANTS.MDNCode.L080602.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080602.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L08060201',
      label: 'CODELISTCONSTANTS.MDNCode.L08060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L08060201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L08060299',
      label: 'CODELISTCONSTANTS.MDNCode.L08060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L08060299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L0807',
      label: 'CODELISTCONSTANTS.MDNCode.L0807.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0807.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0808',
      label: 'CODELISTCONSTANTS.MDNCode.L0808.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0808.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L080801',
      label: 'CODELISTCONSTANTS.MDNCode.L080801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080801.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L080802',
      label: 'CODELISTCONSTANTS.MDNCode.L080802.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080802.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L080803',
      label: 'CODELISTCONSTANTS.MDNCode.L080803.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080803.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L080804',
      label: 'CODELISTCONSTANTS.MDNCode.L080804.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080804.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L080805',
      label: 'CODELISTCONSTANTS.MDNCode.L080805.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080805.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L080806',
      label: 'CODELISTCONSTANTS.MDNCode.L080806.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080806.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L080899',
      label: 'CODELISTCONSTANTS.MDNCode.L080899.label',
      description: 'CODELISTCONSTANTS.MDNCode.L080899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0809',
      label: 'CODELISTCONSTANTS.MDNCode.L0809.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0809.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0899',
      label: 'CODELISTCONSTANTS.MDNCode.L0899.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0899.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L09',
      label: 'CODELISTCONSTANTS.MDNCode.L09.label',
      description: 'CODELISTCONSTANTS.MDNCode.L09.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L0901',
      label: 'CODELISTCONSTANTS.MDNCode.L0901.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0901.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0902',
      label: 'CODELISTCONSTANTS.MDNCode.L0902.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0902.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0903',
      label: 'CODELISTCONSTANTS.MDNCode.L0903.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0903.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0904',
      label: 'CODELISTCONSTANTS.MDNCode.L0904.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0904.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L090401',
      label: 'CODELISTCONSTANTS.MDNCode.L090401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L090401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L090402',
      label: 'CODELISTCONSTANTS.MDNCode.L090402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L090402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L090403',
      label: 'CODELISTCONSTANTS.MDNCode.L090403.label',
      description: 'CODELISTCONSTANTS.MDNCode.L090403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L090404',
      label: 'CODELISTCONSTANTS.MDNCode.L090404.label',
      description: 'CODELISTCONSTANTS.MDNCode.L090404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0908',
      label: 'CODELISTCONSTANTS.MDNCode.L0908.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0908.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0909',
      label: 'CODELISTCONSTANTS.MDNCode.L0909.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0909.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L090901',
      label: 'CODELISTCONSTANTS.MDNCode.L090901.label',
      description: 'CODELISTCONSTANTS.MDNCode.L090901.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L090902',
      label: 'CODELISTCONSTANTS.MDNCode.L090902.label',
      description: 'CODELISTCONSTANTS.MDNCode.L090902.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L090903',
      label: 'CODELISTCONSTANTS.MDNCode.L090903.label',
      description: 'CODELISTCONSTANTS.MDNCode.L090903.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L090999',
      label: 'CODELISTCONSTANTS.MDNCode.L090999.label',
      description: 'CODELISTCONSTANTS.MDNCode.L090999.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0910',
      label: 'CODELISTCONSTANTS.MDNCode.L0910.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0910.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L091001',
      label: 'CODELISTCONSTANTS.MDNCode.L091001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091002',
      label: 'CODELISTCONSTANTS.MDNCode.L091002.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091099',
      label: 'CODELISTCONSTANTS.MDNCode.L091099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0911',
      label: 'CODELISTCONSTANTS.MDNCode.L0911.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0911.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L091101',
      label: 'CODELISTCONSTANTS.MDNCode.L091101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091102',
      label: 'CODELISTCONSTANTS.MDNCode.L091102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091103',
      label: 'CODELISTCONSTANTS.MDNCode.L091103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091104',
      label: 'CODELISTCONSTANTS.MDNCode.L091104.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091105',
      label: 'CODELISTCONSTANTS.MDNCode.L091105.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091106',
      label: 'CODELISTCONSTANTS.MDNCode.L091106.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091199',
      label: 'CODELISTCONSTANTS.MDNCode.L091199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0912',
      label: 'CODELISTCONSTANTS.MDNCode.L0912.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0912.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L091201',
      label: 'CODELISTCONSTANTS.MDNCode.L091201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091202',
      label: 'CODELISTCONSTANTS.MDNCode.L091202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0913',
      label: 'CODELISTCONSTANTS.MDNCode.L0913.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0913.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L091301',
      label: 'CODELISTCONSTANTS.MDNCode.L091301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091302',
      label: 'CODELISTCONSTANTS.MDNCode.L091302.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091303',
      label: 'CODELISTCONSTANTS.MDNCode.L091303.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091304',
      label: 'CODELISTCONSTANTS.MDNCode.L091304.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091305',
      label: 'CODELISTCONSTANTS.MDNCode.L091305.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091306',
      label: 'CODELISTCONSTANTS.MDNCode.L091306.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091306.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091307',
      label: 'CODELISTCONSTANTS.MDNCode.L091307.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091307.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L091399',
      label: 'CODELISTCONSTANTS.MDNCode.L091399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L091399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0914',
      label: 'CODELISTCONSTANTS.MDNCode.L0914.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0914.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0915',
      label: 'CODELISTCONSTANTS.MDNCode.L0915.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0915.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0916',
      label: 'CODELISTCONSTANTS.MDNCode.L0916.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0916.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0917',
      label: 'CODELISTCONSTANTS.MDNCode.L0917.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0917.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0918',
      label: 'CODELISTCONSTANTS.MDNCode.L0918.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0918.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0919',
      label: 'CODELISTCONSTANTS.MDNCode.L0919.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0919.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0920',
      label: 'CODELISTCONSTANTS.MDNCode.L0920.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0920.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0921',
      label: 'CODELISTCONSTANTS.MDNCode.L0921.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0921.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0922',
      label: 'CODELISTCONSTANTS.MDNCode.L0922.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0922.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0923',
      label: 'CODELISTCONSTANTS.MDNCode.L0923.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0923.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0924',
      label: 'CODELISTCONSTANTS.MDNCode.L0924.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0924.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0925',
      label: 'CODELISTCONSTANTS.MDNCode.L0925.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0925.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0926',
      label: 'CODELISTCONSTANTS.MDNCode.L0926.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0926.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0927',
      label: 'CODELISTCONSTANTS.MDNCode.L0927.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0927.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L0980',
      label: 'CODELISTCONSTANTS.MDNCode.L0980.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0980.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L098001',
      label: 'CODELISTCONSTANTS.MDNCode.L098001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L098001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L098002',
      label: 'CODELISTCONSTANTS.MDNCode.L098002.label',
      description: 'CODELISTCONSTANTS.MDNCode.L098002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L098003',
      label: 'CODELISTCONSTANTS.MDNCode.L098003.label',
      description: 'CODELISTCONSTANTS.MDNCode.L098003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L098099',
      label: 'CODELISTCONSTANTS.MDNCode.L098099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L098099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L0999',
      label: 'CODELISTCONSTANTS.MDNCode.L0999.label',
      description: 'CODELISTCONSTANTS.MDNCode.L0999.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L10',
      label: 'CODELISTCONSTANTS.MDNCode.L10.label',
      description: 'CODELISTCONSTANTS.MDNCode.L10.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1001',
      label: 'CODELISTCONSTANTS.MDNCode.L1001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1001.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1003',
      label: 'CODELISTCONSTANTS.MDNCode.L1003.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1003.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1004',
      label: 'CODELISTCONSTANTS.MDNCode.L1004.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1004.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1005',
      label: 'CODELISTCONSTANTS.MDNCode.L1005.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1005.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1099',
      label: 'CODELISTCONSTANTS.MDNCode.L1099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L11',
      label: 'CODELISTCONSTANTS.MDNCode.L11.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1102',
      label: 'CODELISTCONSTANTS.MDNCode.L1102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1102.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1103',
      label: 'CODELISTCONSTANTS.MDNCode.L1103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1103.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1104',
      label: 'CODELISTCONSTANTS.MDNCode.L1104.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1104.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1105',
      label: 'CODELISTCONSTANTS.MDNCode.L1105.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1105.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L110501',
      label: 'CODELISTCONSTANTS.MDNCode.L110501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L11050101',
      label: 'CODELISTCONSTANTS.MDNCode.L11050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L11050199',
      label: 'CODELISTCONSTANTS.MDNCode.L11050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11050199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L110502',
      label: 'CODELISTCONSTANTS.MDNCode.L110502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L110503',
      label: 'CODELISTCONSTANTS.MDNCode.L110503.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110503.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L11050301',
      label: 'CODELISTCONSTANTS.MDNCode.L11050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11050301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L11050399',
      label: 'CODELISTCONSTANTS.MDNCode.L11050399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11050399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L110599',
      label: 'CODELISTCONSTANTS.MDNCode.L110599.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1106',
      label: 'CODELISTCONSTANTS.MDNCode.L1106.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1106.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L110601',
      label: 'CODELISTCONSTANTS.MDNCode.L110601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L110602',
      label: 'CODELISTCONSTANTS.MDNCode.L110602.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L110603',
      label: 'CODELISTCONSTANTS.MDNCode.L110603.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110603.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L11060301',
      label: 'CODELISTCONSTANTS.MDNCode.L11060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11060301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L11060399',
      label: 'CODELISTCONSTANTS.MDNCode.L11060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11060399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L110604',
      label: 'CODELISTCONSTANTS.MDNCode.L110604.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110604.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L110699',
      label: 'CODELISTCONSTANTS.MDNCode.L110699.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1107',
      label: 'CODELISTCONSTANTS.MDNCode.L1107.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1107.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L110701',
      label: 'CODELISTCONSTANTS.MDNCode.L110701.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110701.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L11070101',
      label: 'CODELISTCONSTANTS.MDNCode.L11070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11070101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L11070199',
      label: 'CODELISTCONSTANTS.MDNCode.L11070199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L11070199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L110702',
      label: 'CODELISTCONSTANTS.MDNCode.L110702.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L110799',
      label: 'CODELISTCONSTANTS.MDNCode.L110799.label',
      description: 'CODELISTCONSTANTS.MDNCode.L110799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1108',
      label: 'CODELISTCONSTANTS.MDNCode.L1108.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1108.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1109',
      label: 'CODELISTCONSTANTS.MDNCode.L1109.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1109.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1110',
      label: 'CODELISTCONSTANTS.MDNCode.L1110.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1110.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1111',
      label: 'CODELISTCONSTANTS.MDNCode.L1111.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1111.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1112',
      label: 'CODELISTCONSTANTS.MDNCode.L1112.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1112.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1113',
      label: 'CODELISTCONSTANTS.MDNCode.L1113.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1113.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1199',
      label: 'CODELISTCONSTANTS.MDNCode.L1199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L12',
      label: 'CODELISTCONSTANTS.MDNCode.L12.label',
      description: 'CODELISTCONSTANTS.MDNCode.L12.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1201',
      label: 'CODELISTCONSTANTS.MDNCode.L1201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1201.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1202',
      label: 'CODELISTCONSTANTS.MDNCode.L1202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1202.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1203',
      label: 'CODELISTCONSTANTS.MDNCode.L1203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1203.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1204',
      label: 'CODELISTCONSTANTS.MDNCode.L1204.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1204.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1205',
      label: 'CODELISTCONSTANTS.MDNCode.L1205.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1205.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1206',
      label: 'CODELISTCONSTANTS.MDNCode.L1206.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1206.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1207',
      label: 'CODELISTCONSTANTS.MDNCode.L1207.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1207.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1208',
      label: 'CODELISTCONSTANTS.MDNCode.L1208.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1208.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1209',
      label: 'CODELISTCONSTANTS.MDNCode.L1209.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1209.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1210',
      label: 'CODELISTCONSTANTS.MDNCode.L1210.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1210.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1211',
      label: 'CODELISTCONSTANTS.MDNCode.L1211.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1211.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1212',
      label: 'CODELISTCONSTANTS.MDNCode.L1212.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1212.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1213',
      label: 'CODELISTCONSTANTS.MDNCode.L1213.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1213.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1214',
      label: 'CODELISTCONSTANTS.MDNCode.L1214.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1214.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1215',
      label: 'CODELISTCONSTANTS.MDNCode.L1215.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1215.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1216',
      label: 'CODELISTCONSTANTS.MDNCode.L1216.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1216.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1217',
      label: 'CODELISTCONSTANTS.MDNCode.L1217.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1217.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1218',
      label: 'CODELISTCONSTANTS.MDNCode.L1218.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1218.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1280',
      label: 'CODELISTCONSTANTS.MDNCode.L1280.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1280.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1299',
      label: 'CODELISTCONSTANTS.MDNCode.L1299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L13',
      label: 'CODELISTCONSTANTS.MDNCode.L13.label',
      description: 'CODELISTCONSTANTS.MDNCode.L13.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1301',
      label: 'CODELISTCONSTANTS.MDNCode.L1301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1301.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1302',
      label: 'CODELISTCONSTANTS.MDNCode.L1302.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1302.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1303',
      label: 'CODELISTCONSTANTS.MDNCode.L1303.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1303.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1304',
      label: 'CODELISTCONSTANTS.MDNCode.L1304.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1304.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1305',
      label: 'CODELISTCONSTANTS.MDNCode.L1305.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1305.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1306',
      label: 'CODELISTCONSTANTS.MDNCode.L1306.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1306.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1307',
      label: 'CODELISTCONSTANTS.MDNCode.L1307.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1307.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1399',
      label: 'CODELISTCONSTANTS.MDNCode.L1399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L14',
      label: 'CODELISTCONSTANTS.MDNCode.L14.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1401',
      label: 'CODELISTCONSTANTS.MDNCode.L1401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1401.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L140101',
      label: 'CODELISTCONSTANTS.MDNCode.L140101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L14010101',
      label: 'CODELISTCONSTANTS.MDNCode.L14010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010102',
      label: 'CODELISTCONSTANTS.MDNCode.L14010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010103',
      label: 'CODELISTCONSTANTS.MDNCode.L14010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010199',
      label: 'CODELISTCONSTANTS.MDNCode.L14010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L140102',
      label: 'CODELISTCONSTANTS.MDNCode.L140102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L14010201',
      label: 'CODELISTCONSTANTS.MDNCode.L14010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010202',
      label: 'CODELISTCONSTANTS.MDNCode.L14010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010203',
      label: 'CODELISTCONSTANTS.MDNCode.L14010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010204',
      label: 'CODELISTCONSTANTS.MDNCode.L14010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010205',
      label: 'CODELISTCONSTANTS.MDNCode.L14010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010206',
      label: 'CODELISTCONSTANTS.MDNCode.L14010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010206.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14010299',
      label: 'CODELISTCONSTANTS.MDNCode.L14010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L1402',
      label: 'CODELISTCONSTANTS.MDNCode.L1402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1402.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L140201',
      label: 'CODELISTCONSTANTS.MDNCode.L140201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140202',
      label: 'CODELISTCONSTANTS.MDNCode.L140202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140203',
      label: 'CODELISTCONSTANTS.MDNCode.L140203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140204',
      label: 'CODELISTCONSTANTS.MDNCode.L140204.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140205',
      label: 'CODELISTCONSTANTS.MDNCode.L140205.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140206',
      label: 'CODELISTCONSTANTS.MDNCode.L140206.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140299',
      label: 'CODELISTCONSTANTS.MDNCode.L140299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1403',
      label: 'CODELISTCONSTANTS.MDNCode.L1403.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1403.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L140301',
      label: 'CODELISTCONSTANTS.MDNCode.L140301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140399',
      label: 'CODELISTCONSTANTS.MDNCode.L140399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1404',
      label: 'CODELISTCONSTANTS.MDNCode.L1404.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1404.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L140401',
      label: 'CODELISTCONSTANTS.MDNCode.L140401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140402',
      label: 'CODELISTCONSTANTS.MDNCode.L140402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L14040201',
      label: 'CODELISTCONSTANTS.MDNCode.L14040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14040202',
      label: 'CODELISTCONSTANTS.MDNCode.L14040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14040202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14040203',
      label: 'CODELISTCONSTANTS.MDNCode.L14040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14040203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14040204',
      label: 'CODELISTCONSTANTS.MDNCode.L14040204.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14040204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14040299',
      label: 'CODELISTCONSTANTS.MDNCode.L14040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L140499',
      label: 'CODELISTCONSTANTS.MDNCode.L140499.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1405',
      label: 'CODELISTCONSTANTS.MDNCode.L1405.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1405.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L140501',
      label: 'CODELISTCONSTANTS.MDNCode.L140501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L14050101',
      label: 'CODELISTCONSTANTS.MDNCode.L14050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14050102',
      label: 'CODELISTCONSTANTS.MDNCode.L14050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14050102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14050103',
      label: 'CODELISTCONSTANTS.MDNCode.L14050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14050103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L14050199',
      label: 'CODELISTCONSTANTS.MDNCode.L14050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L14050199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L140502',
      label: 'CODELISTCONSTANTS.MDNCode.L140502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140503',
      label: 'CODELISTCONSTANTS.MDNCode.L140503.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140503.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140599',
      label: 'CODELISTCONSTANTS.MDNCode.L140599.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1406',
      label: 'CODELISTCONSTANTS.MDNCode.L1406.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1406.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L140601',
      label: 'CODELISTCONSTANTS.MDNCode.L140601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L140602',
      label: 'CODELISTCONSTANTS.MDNCode.L140602.label',
      description: 'CODELISTCONSTANTS.MDNCode.L140602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1490',
      label: 'CODELISTCONSTANTS.MDNCode.L1490.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1490.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L149001',
      label: 'CODELISTCONSTANTS.MDNCode.L149001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149002',
      label: 'CODELISTCONSTANTS.MDNCode.L149002.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149003',
      label: 'CODELISTCONSTANTS.MDNCode.L149003.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149004',
      label: 'CODELISTCONSTANTS.MDNCode.L149004.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149005',
      label: 'CODELISTCONSTANTS.MDNCode.L149005.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149006',
      label: 'CODELISTCONSTANTS.MDNCode.L149006.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149007',
      label: 'CODELISTCONSTANTS.MDNCode.L149007.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149008',
      label: 'CODELISTCONSTANTS.MDNCode.L149008.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149008.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149009',
      label: 'CODELISTCONSTANTS.MDNCode.L149009.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149009.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149010',
      label: 'CODELISTCONSTANTS.MDNCode.L149010.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149010.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149011',
      label: 'CODELISTCONSTANTS.MDNCode.L149011.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149011.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149012',
      label: 'CODELISTCONSTANTS.MDNCode.L149012.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149012.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149013',
      label: 'CODELISTCONSTANTS.MDNCode.L149013.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149013.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L149099',
      label: 'CODELISTCONSTANTS.MDNCode.L149099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L149099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L15',
      label: 'CODELISTCONSTANTS.MDNCode.L15.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1501',
      label: 'CODELISTCONSTANTS.MDNCode.L1501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1501.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L150101',
      label: 'CODELISTCONSTANTS.MDNCode.L150101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150102',
      label: 'CODELISTCONSTANTS.MDNCode.L150102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150103',
      label: 'CODELISTCONSTANTS.MDNCode.L150103.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1502',
      label: 'CODELISTCONSTANTS.MDNCode.L1502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1502.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L150201',
      label: 'CODELISTCONSTANTS.MDNCode.L150201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150202',
      label: 'CODELISTCONSTANTS.MDNCode.L150202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150299',
      label: 'CODELISTCONSTANTS.MDNCode.L150299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1503',
      label: 'CODELISTCONSTANTS.MDNCode.L1503.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1503.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L150301',
      label: 'CODELISTCONSTANTS.MDNCode.L150301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150302',
      label: 'CODELISTCONSTANTS.MDNCode.L150302.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150399',
      label: 'CODELISTCONSTANTS.MDNCode.L150399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1504',
      label: 'CODELISTCONSTANTS.MDNCode.L1504.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1504.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1505',
      label: 'CODELISTCONSTANTS.MDNCode.L1505.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1505.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1506',
      label: 'CODELISTCONSTANTS.MDNCode.L1506.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1506.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1507',
      label: 'CODELISTCONSTANTS.MDNCode.L1507.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1507.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L150701',
      label: 'CODELISTCONSTANTS.MDNCode.L150701.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150702',
      label: 'CODELISTCONSTANTS.MDNCode.L150702.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150703',
      label: 'CODELISTCONSTANTS.MDNCode.L150703.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150704',
      label: 'CODELISTCONSTANTS.MDNCode.L150704.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150704.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150705',
      label: 'CODELISTCONSTANTS.MDNCode.L150705.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150705.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L150799',
      label: 'CODELISTCONSTANTS.MDNCode.L150799.label',
      description: 'CODELISTCONSTANTS.MDNCode.L150799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1508',
      label: 'CODELISTCONSTANTS.MDNCode.L1508.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1508.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1590',
      label: 'CODELISTCONSTANTS.MDNCode.L1590.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1590.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L159001',
      label: 'CODELISTCONSTANTS.MDNCode.L159001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159002',
      label: 'CODELISTCONSTANTS.MDNCode.L159002.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159003',
      label: 'CODELISTCONSTANTS.MDNCode.L159003.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159004',
      label: 'CODELISTCONSTANTS.MDNCode.L159004.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159005',
      label: 'CODELISTCONSTANTS.MDNCode.L159005.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159006',
      label: 'CODELISTCONSTANTS.MDNCode.L159006.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159006.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L15900601',
      label: 'CODELISTCONSTANTS.MDNCode.L15900601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15900601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L15900602',
      label: 'CODELISTCONSTANTS.MDNCode.L15900602.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15900602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L15900699',
      label: 'CODELISTCONSTANTS.MDNCode.L15900699.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15900699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L159007',
      label: 'CODELISTCONSTANTS.MDNCode.L159007.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159008',
      label: 'CODELISTCONSTANTS.MDNCode.L159008.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159008.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L15900801',
      label: 'CODELISTCONSTANTS.MDNCode.L15900801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15900801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L15900899',
      label: 'CODELISTCONSTANTS.MDNCode.L15900899.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15900899.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L159009',
      label: 'CODELISTCONSTANTS.MDNCode.L159009.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159009.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159010',
      label: 'CODELISTCONSTANTS.MDNCode.L159010.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159010.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159011',
      label: 'CODELISTCONSTANTS.MDNCode.L159011.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159011.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L15901101',
      label: 'CODELISTCONSTANTS.MDNCode.L15901101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15901101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L15901102',
      label: 'CODELISTCONSTANTS.MDNCode.L15901102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15901102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L15901199',
      label: 'CODELISTCONSTANTS.MDNCode.L15901199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L15901199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L159012',
      label: 'CODELISTCONSTANTS.MDNCode.L159012.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159012.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159013',
      label: 'CODELISTCONSTANTS.MDNCode.L159013.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159013.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159014',
      label: 'CODELISTCONSTANTS.MDNCode.L159014.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159014.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159015',
      label: 'CODELISTCONSTANTS.MDNCode.L159015.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159015.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159016',
      label: 'CODELISTCONSTANTS.MDNCode.L159016.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159016.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159017',
      label: 'CODELISTCONSTANTS.MDNCode.L159017.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159017.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159018',
      label: 'CODELISTCONSTANTS.MDNCode.L159018.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159018.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159019',
      label: 'CODELISTCONSTANTS.MDNCode.L159019.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159019.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L159099',
      label: 'CODELISTCONSTANTS.MDNCode.L159099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L159099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L16',
      label: 'CODELISTCONSTANTS.MDNCode.L16.label',
      description: 'CODELISTCONSTANTS.MDNCode.L16.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1601',
      label: 'CODELISTCONSTANTS.MDNCode.L1601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1601.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L160101',
      label: 'CODELISTCONSTANTS.MDNCode.L160101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L160101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L160199',
      label: 'CODELISTCONSTANTS.MDNCode.L160199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L160199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1602',
      label: 'CODELISTCONSTANTS.MDNCode.L1602.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1602.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L160201',
      label: 'CODELISTCONSTANTS.MDNCode.L160201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L160201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L160202',
      label: 'CODELISTCONSTANTS.MDNCode.L160202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L160202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L160299',
      label: 'CODELISTCONSTANTS.MDNCode.L160299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L160299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1699',
      label: 'CODELISTCONSTANTS.MDNCode.L1699.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L17',
      label: 'CODELISTCONSTANTS.MDNCode.L17.label',
      description: 'CODELISTCONSTANTS.MDNCode.L17.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1701',
      label: 'CODELISTCONSTANTS.MDNCode.L1701.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1701.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L170101',
      label: 'CODELISTCONSTANTS.MDNCode.L170101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L17010101',
      label: 'CODELISTCONSTANTS.MDNCode.L17010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L17010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L17010199',
      label: 'CODELISTCONSTANTS.MDNCode.L17010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.L17010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L170102',
      label: 'CODELISTCONSTANTS.MDNCode.L170102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1702',
      label: 'CODELISTCONSTANTS.MDNCode.L1702.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1702.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L170201',
      label: 'CODELISTCONSTANTS.MDNCode.L170201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L170202',
      label: 'CODELISTCONSTANTS.MDNCode.L170202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L170203',
      label: 'CODELISTCONSTANTS.MDNCode.L170203.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L170299',
      label: 'CODELISTCONSTANTS.MDNCode.L170299.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1703',
      label: 'CODELISTCONSTANTS.MDNCode.L1703.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1703.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1704',
      label: 'CODELISTCONSTANTS.MDNCode.L1704.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1704.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L170401',
      label: 'CODELISTCONSTANTS.MDNCode.L170401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L170402',
      label: 'CODELISTCONSTANTS.MDNCode.L170402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L170499',
      label: 'CODELISTCONSTANTS.MDNCode.L170499.label',
      description: 'CODELISTCONSTANTS.MDNCode.L170499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1705',
      label: 'CODELISTCONSTANTS.MDNCode.L1705.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1705.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1706',
      label: 'CODELISTCONSTANTS.MDNCode.L1706.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1706.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1707',
      label: 'CODELISTCONSTANTS.MDNCode.L1707.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1707.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1708',
      label: 'CODELISTCONSTANTS.MDNCode.L1708.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1708.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1709',
      label: 'CODELISTCONSTANTS.MDNCode.L1709.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1709.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1710',
      label: 'CODELISTCONSTANTS.MDNCode.L1710.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1710.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1711',
      label: 'CODELISTCONSTANTS.MDNCode.L1711.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1711.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1712',
      label: 'CODELISTCONSTANTS.MDNCode.L1712.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1712.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1713',
      label: 'CODELISTCONSTANTS.MDNCode.L1713.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1713.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1714',
      label: 'CODELISTCONSTANTS.MDNCode.L1714.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1714.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1715',
      label: 'CODELISTCONSTANTS.MDNCode.L1715.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1715.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1790',
      label: 'CODELISTCONSTANTS.MDNCode.L1790.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1790.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L179001',
      label: 'CODELISTCONSTANTS.MDNCode.L179001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L179001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L179002',
      label: 'CODELISTCONSTANTS.MDNCode.L179002.label',
      description: 'CODELISTCONSTANTS.MDNCode.L179002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L179003',
      label: 'CODELISTCONSTANTS.MDNCode.L179003.label',
      description: 'CODELISTCONSTANTS.MDNCode.L179003.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'L17900301',
      label: 'CODELISTCONSTANTS.MDNCode.L17900301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L17900301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L17900399',
      label: 'CODELISTCONSTANTS.MDNCode.L17900399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L17900399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'L179004',
      label: 'CODELISTCONSTANTS.MDNCode.L179004.label',
      description: 'CODELISTCONSTANTS.MDNCode.L179004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L179005',
      label: 'CODELISTCONSTANTS.MDNCode.L179005.label',
      description: 'CODELISTCONSTANTS.MDNCode.L179005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1799',
      label: 'CODELISTCONSTANTS.MDNCode.L1799.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1799.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L18',
      label: 'CODELISTCONSTANTS.MDNCode.L18.label',
      description: 'CODELISTCONSTANTS.MDNCode.L18.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1801',
      label: 'CODELISTCONSTANTS.MDNCode.L1801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1801.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180101',
      label: 'CODELISTCONSTANTS.MDNCode.L180101.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180102',
      label: 'CODELISTCONSTANTS.MDNCode.L180102.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1802',
      label: 'CODELISTCONSTANTS.MDNCode.L1802.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1802.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180201',
      label: 'CODELISTCONSTANTS.MDNCode.L180201.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180202',
      label: 'CODELISTCONSTANTS.MDNCode.L180202.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1803',
      label: 'CODELISTCONSTANTS.MDNCode.L1803.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1803.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180301',
      label: 'CODELISTCONSTANTS.MDNCode.L180301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180302',
      label: 'CODELISTCONSTANTS.MDNCode.L180302.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1804',
      label: 'CODELISTCONSTANTS.MDNCode.L1804.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1804.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180401',
      label: 'CODELISTCONSTANTS.MDNCode.L180401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180402',
      label: 'CODELISTCONSTANTS.MDNCode.L180402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1805',
      label: 'CODELISTCONSTANTS.MDNCode.L1805.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1805.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180501',
      label: 'CODELISTCONSTANTS.MDNCode.L180501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180502',
      label: 'CODELISTCONSTANTS.MDNCode.L180502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1806',
      label: 'CODELISTCONSTANTS.MDNCode.L1806.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1806.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180601',
      label: 'CODELISTCONSTANTS.MDNCode.L180601.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180602',
      label: 'CODELISTCONSTANTS.MDNCode.L180602.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1807',
      label: 'CODELISTCONSTANTS.MDNCode.L1807.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1807.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180701',
      label: 'CODELISTCONSTANTS.MDNCode.L180701.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180702',
      label: 'CODELISTCONSTANTS.MDNCode.L180702.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1808',
      label: 'CODELISTCONSTANTS.MDNCode.L1808.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1808.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180801',
      label: 'CODELISTCONSTANTS.MDNCode.L180801.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180801.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180802',
      label: 'CODELISTCONSTANTS.MDNCode.L180802.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180802.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1809',
      label: 'CODELISTCONSTANTS.MDNCode.L1809.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1809.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L180901',
      label: 'CODELISTCONSTANTS.MDNCode.L180901.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180901.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L180902',
      label: 'CODELISTCONSTANTS.MDNCode.L180902.label',
      description: 'CODELISTCONSTANTS.MDNCode.L180902.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1890',
      label: 'CODELISTCONSTANTS.MDNCode.L1890.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1890.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L189001',
      label: 'CODELISTCONSTANTS.MDNCode.L189001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L189001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L189002',
      label: 'CODELISTCONSTANTS.MDNCode.L189002.label',
      description: 'CODELISTCONSTANTS.MDNCode.L189002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L1899',
      label: 'CODELISTCONSTANTS.MDNCode.L1899.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1899.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L19',
      label: 'CODELISTCONSTANTS.MDNCode.L19.label',
      description: 'CODELISTCONSTANTS.MDNCode.L19.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L1901',
      label: 'CODELISTCONSTANTS.MDNCode.L1901.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1901.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1902',
      label: 'CODELISTCONSTANTS.MDNCode.L1902.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1902.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1903',
      label: 'CODELISTCONSTANTS.MDNCode.L1903.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1903.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1904',
      label: 'CODELISTCONSTANTS.MDNCode.L1904.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1904.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1905',
      label: 'CODELISTCONSTANTS.MDNCode.L1905.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1905.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1906',
      label: 'CODELISTCONSTANTS.MDNCode.L1906.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1906.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1907',
      label: 'CODELISTCONSTANTS.MDNCode.L1907.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1907.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1908',
      label: 'CODELISTCONSTANTS.MDNCode.L1908.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1908.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1909',
      label: 'CODELISTCONSTANTS.MDNCode.L1909.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1909.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1910',
      label: 'CODELISTCONSTANTS.MDNCode.L1910.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1910.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1911',
      label: 'CODELISTCONSTANTS.MDNCode.L1911.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1911.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1912',
      label: 'CODELISTCONSTANTS.MDNCode.L1912.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1912.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1913',
      label: 'CODELISTCONSTANTS.MDNCode.L1913.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1913.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1914',
      label: 'CODELISTCONSTANTS.MDNCode.L1914.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1914.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1915',
      label: 'CODELISTCONSTANTS.MDNCode.L1915.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1915.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1916',
      label: 'CODELISTCONSTANTS.MDNCode.L1916.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1916.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1917',
      label: 'CODELISTCONSTANTS.MDNCode.L1917.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1917.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1980',
      label: 'CODELISTCONSTANTS.MDNCode.L1980.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1980.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L1999',
      label: 'CODELISTCONSTANTS.MDNCode.L1999.label',
      description: 'CODELISTCONSTANTS.MDNCode.L1999.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L20',
      label: 'CODELISTCONSTANTS.MDNCode.L20.label',
      description: 'CODELISTCONSTANTS.MDNCode.L20.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L2001',
      label: 'CODELISTCONSTANTS.MDNCode.L2001.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2001.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L2099',
      label: 'CODELISTCONSTANTS.MDNCode.L2099.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L21',
      label: 'CODELISTCONSTANTS.MDNCode.L21.label',
      description: 'CODELISTCONSTANTS.MDNCode.L21.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'L22',
      label: 'CODELISTCONSTANTS.MDNCode.L22.label',
      description: 'CODELISTCONSTANTS.MDNCode.L22.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'L23',
      label: 'CODELISTCONSTANTS.MDNCode.L23.label',
      description: 'CODELISTCONSTANTS.MDNCode.L23.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'L24',
      label: 'CODELISTCONSTANTS.MDNCode.L24.label',
      description: 'CODELISTCONSTANTS.MDNCode.L24.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L2401',
      label: 'CODELISTCONSTANTS.MDNCode.L2401.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2401.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L2402',
      label: 'CODELISTCONSTANTS.MDNCode.L2402.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2402.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L2403',
      label: 'CODELISTCONSTANTS.MDNCode.L2403.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2403.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L2499',
      label: 'CODELISTCONSTANTS.MDNCode.L2499.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L25',
      label: 'CODELISTCONSTANTS.MDNCode.L25.label',
      description: 'CODELISTCONSTANTS.MDNCode.L25.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'L2501',
      label: 'CODELISTCONSTANTS.MDNCode.L2501.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2501.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L2502',
      label: 'CODELISTCONSTANTS.MDNCode.L2502.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2502.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L2503',
      label: 'CODELISTCONSTANTS.MDNCode.L2503.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2503.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'L250301',
      label: 'CODELISTCONSTANTS.MDNCode.L250301.label',
      description: 'CODELISTCONSTANTS.MDNCode.L250301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L250399',
      label: 'CODELISTCONSTANTS.MDNCode.L250399.label',
      description: 'CODELISTCONSTANTS.MDNCode.L250399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'L2599',
      label: 'CODELISTCONSTANTS.MDNCode.L2599.label',
      description: 'CODELISTCONSTANTS.MDNCode.L2599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'L26',
      label: 'CODELISTCONSTANTS.MDNCode.L26.label',
      description: 'CODELISTCONSTANTS.MDNCode.L26.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'L80',
      label: 'CODELISTCONSTANTS.MDNCode.L80.label',
      description: 'CODELISTCONSTANTS.MDNCode.L80.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'L99',
      label: 'CODELISTCONSTANTS.MDNCode.L99.label',
      description: 'CODELISTCONSTANTS.MDNCode.L99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'M',
      label: 'CODELISTCONSTANTS.MDNCode.M.label',
      description: 'CODELISTCONSTANTS.MDNCode.M.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'M01',
      label: 'CODELISTCONSTANTS.MDNCode.M01.label',
      description: 'CODELISTCONSTANTS.MDNCode.M01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'M0101',
      label: 'CODELISTCONSTANTS.MDNCode.M0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M010101',
      label: 'CODELISTCONSTANTS.MDNCode.M010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M010101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M010102',
      label: 'CODELISTCONSTANTS.MDNCode.M010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M010103',
      label: 'CODELISTCONSTANTS.MDNCode.M010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M010103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M010199',
      label: 'CODELISTCONSTANTS.MDNCode.M010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.M010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0102',
      label: 'CODELISTCONSTANTS.MDNCode.M0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M010201',
      label: 'CODELISTCONSTANTS.MDNCode.M010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M010201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M010202',
      label: 'CODELISTCONSTANTS.MDNCode.M010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M01020201',
      label: 'CODELISTCONSTANTS.MDNCode.M01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M01020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M01020202',
      label: 'CODELISTCONSTANTS.MDNCode.M01020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M01020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M010203',
      label: 'CODELISTCONSTANTS.MDNCode.M010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M010203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M010299',
      label: 'CODELISTCONSTANTS.MDNCode.M010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0199',
      label: 'CODELISTCONSTANTS.MDNCode.M0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M02',
      label: 'CODELISTCONSTANTS.MDNCode.M02.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'M0201',
      label: 'CODELISTCONSTANTS.MDNCode.M0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M020101',
      label: 'CODELISTCONSTANTS.MDNCode.M020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M02010101',
      label: 'CODELISTCONSTANTS.MDNCode.M02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0201010101',
      label: 'CODELISTCONSTANTS.MDNCode.M0201010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0201010102',
      label: 'CODELISTCONSTANTS.MDNCode.M0201010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M02010102',
      label: 'CODELISTCONSTANTS.MDNCode.M02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0201010201',
      label: 'CODELISTCONSTANTS.MDNCode.M0201010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0201010202',
      label: 'CODELISTCONSTANTS.MDNCode.M0201010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M020102',
      label: 'CODELISTCONSTANTS.MDNCode.M020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M02010201',
      label: 'CODELISTCONSTANTS.MDNCode.M02010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0201020101',
      label: 'CODELISTCONSTANTS.MDNCode.M0201020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0201020102',
      label: 'CODELISTCONSTANTS.MDNCode.M0201020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M02010202',
      label: 'CODELISTCONSTANTS.MDNCode.M02010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02010202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0201020201',
      label: 'CODELISTCONSTANTS.MDNCode.M0201020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0201020202',
      label: 'CODELISTCONSTANTS.MDNCode.M0201020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M020103',
      label: 'CODELISTCONSTANTS.MDNCode.M020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M02010301',
      label: 'CODELISTCONSTANTS.MDNCode.M02010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02010301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0201030101',
      label: 'CODELISTCONSTANTS.MDNCode.M0201030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0201030102',
      label: 'CODELISTCONSTANTS.MDNCode.M0201030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M02010302',
      label: 'CODELISTCONSTANTS.MDNCode.M02010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02010302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0201030201',
      label: 'CODELISTCONSTANTS.MDNCode.M0201030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0201030202',
      label: 'CODELISTCONSTANTS.MDNCode.M0201030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M020104',
      label: 'CODELISTCONSTANTS.MDNCode.M020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M020105',
      label: 'CODELISTCONSTANTS.MDNCode.M020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020105.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M02010501',
      label: 'CODELISTCONSTANTS.MDNCode.M02010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02010501.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0201050101',
      label: 'CODELISTCONSTANTS.MDNCode.M0201050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201050101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0201050102',
      label: 'CODELISTCONSTANTS.MDNCode.M0201050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201050102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M02010502',
      label: 'CODELISTCONSTANTS.MDNCode.M02010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02010502.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0201050201',
      label: 'CODELISTCONSTANTS.MDNCode.M0201050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201050201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0201050202',
      label: 'CODELISTCONSTANTS.MDNCode.M0201050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0201050202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M020106',
      label: 'CODELISTCONSTANTS.MDNCode.M020106.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M020107',
      label: 'CODELISTCONSTANTS.MDNCode.M020107.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020107.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M020199',
      label: 'CODELISTCONSTANTS.MDNCode.M020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0202',
      label: 'CODELISTCONSTANTS.MDNCode.M0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M020201',
      label: 'CODELISTCONSTANTS.MDNCode.M020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M02020101',
      label: 'CODELISTCONSTANTS.MDNCode.M02020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0202010101',
      label: 'CODELISTCONSTANTS.MDNCode.M0202010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0202010102',
      label: 'CODELISTCONSTANTS.MDNCode.M0202010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M02020102',
      label: 'CODELISTCONSTANTS.MDNCode.M02020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02020102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0202010201',
      label: 'CODELISTCONSTANTS.MDNCode.M0202010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0202010202',
      label: 'CODELISTCONSTANTS.MDNCode.M0202010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M020202',
      label: 'CODELISTCONSTANTS.MDNCode.M020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M02020201',
      label: 'CODELISTCONSTANTS.MDNCode.M02020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02020201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0202020101',
      label: 'CODELISTCONSTANTS.MDNCode.M0202020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0202020102',
      label: 'CODELISTCONSTANTS.MDNCode.M0202020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M02020202',
      label: 'CODELISTCONSTANTS.MDNCode.M02020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02020202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0202020201',
      label: 'CODELISTCONSTANTS.MDNCode.M0202020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0202020202',
      label: 'CODELISTCONSTANTS.MDNCode.M0202020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M020203',
      label: 'CODELISTCONSTANTS.MDNCode.M020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M02020301',
      label: 'CODELISTCONSTANTS.MDNCode.M02020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02020301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0202030101',
      label: 'CODELISTCONSTANTS.MDNCode.M0202030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0202030102',
      label: 'CODELISTCONSTANTS.MDNCode.M0202030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M02020302',
      label: 'CODELISTCONSTANTS.MDNCode.M02020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02020302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0202030201',
      label: 'CODELISTCONSTANTS.MDNCode.M0202030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0202030202',
      label: 'CODELISTCONSTANTS.MDNCode.M0202030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0202030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M020204',
      label: 'CODELISTCONSTANTS.MDNCode.M020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M020205',
      label: 'CODELISTCONSTANTS.MDNCode.M020205.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M020299',
      label: 'CODELISTCONSTANTS.MDNCode.M020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0203',
      label: 'CODELISTCONSTANTS.MDNCode.M0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M020301',
      label: 'CODELISTCONSTANTS.MDNCode.M020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M020302',
      label: 'CODELISTCONSTANTS.MDNCode.M020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M02030201',
      label: 'CODELISTCONSTANTS.MDNCode.M02030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M02030202',
      label: 'CODELISTCONSTANTS.MDNCode.M02030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M02030202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M020399',
      label: 'CODELISTCONSTANTS.MDNCode.M020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.M020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0299',
      label: 'CODELISTCONSTANTS.MDNCode.M0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M03',
      label: 'CODELISTCONSTANTS.MDNCode.M03.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'M0301',
      label: 'CODELISTCONSTANTS.MDNCode.M0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M030101',
      label: 'CODELISTCONSTANTS.MDNCode.M030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030102',
      label: 'CODELISTCONSTANTS.MDNCode.M030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0302',
      label: 'CODELISTCONSTANTS.MDNCode.M0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M030201',
      label: 'CODELISTCONSTANTS.MDNCode.M030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030202',
      label: 'CODELISTCONSTANTS.MDNCode.M030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M03020201',
      label: 'CODELISTCONSTANTS.MDNCode.M03020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M03020202',
      label: 'CODELISTCONSTANTS.MDNCode.M03020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M03020299',
      label: 'CODELISTCONSTANTS.MDNCode.M03020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M030299',
      label: 'CODELISTCONSTANTS.MDNCode.M030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0303',
      label: 'CODELISTCONSTANTS.MDNCode.M0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0303.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M030301',
      label: 'CODELISTCONSTANTS.MDNCode.M030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M03030101',
      label: 'CODELISTCONSTANTS.MDNCode.M03030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03030101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0303010101',
      label: 'CODELISTCONSTANTS.MDNCode.M0303010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0303010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0303010103',
      label: 'CODELISTCONSTANTS.MDNCode.M0303010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0303010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M03030102',
      label: 'CODELISTCONSTANTS.MDNCode.M03030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03030102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0303010201',
      label: 'CODELISTCONSTANTS.MDNCode.M0303010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0303010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0303010203',
      label: 'CODELISTCONSTANTS.MDNCode.M0303010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0303010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M03030103',
      label: 'CODELISTCONSTANTS.MDNCode.M03030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03030103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M03030199',
      label: 'CODELISTCONSTANTS.MDNCode.M03030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M030302',
      label: 'CODELISTCONSTANTS.MDNCode.M030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M03030201',
      label: 'CODELISTCONSTANTS.MDNCode.M03030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M03030202',
      label: 'CODELISTCONSTANTS.MDNCode.M03030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03030202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M03030203',
      label: 'CODELISTCONSTANTS.MDNCode.M03030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03030203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M03030299',
      label: 'CODELISTCONSTANTS.MDNCode.M03030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03030299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M0304',
      label: 'CODELISTCONSTANTS.MDNCode.M0304.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M030401',
      label: 'CODELISTCONSTANTS.MDNCode.M030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M03040101',
      label: 'CODELISTCONSTANTS.MDNCode.M03040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03040101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0304010101',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0304010102',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0304010103',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M03040102',
      label: 'CODELISTCONSTANTS.MDNCode.M03040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03040102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0304010201',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0304010202',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0304010203',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M03040103',
      label: 'CODELISTCONSTANTS.MDNCode.M03040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03040103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0304010301',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0304010302',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M0304010303',
      label: 'CODELISTCONSTANTS.MDNCode.M0304010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304010303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'M03040199',
      label: 'CODELISTCONSTANTS.MDNCode.M03040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M030402',
      label: 'CODELISTCONSTANTS.MDNCode.M030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M03040201',
      label: 'CODELISTCONSTANTS.MDNCode.M03040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03040201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0304020101',
      label: 'CODELISTCONSTANTS.MDNCode.M0304020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304020101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'M030402010101',
      label: 'CODELISTCONSTANTS.MDNCode.M030402010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030402010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'M030402010103',
      label: 'CODELISTCONSTANTS.MDNCode.M030402010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030402010103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'M03040202',
      label: 'CODELISTCONSTANTS.MDNCode.M03040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03040202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0304020201',
      label: 'CODELISTCONSTANTS.MDNCode.M0304020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304020201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'M030402020101',
      label: 'CODELISTCONSTANTS.MDNCode.M030402020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030402020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'M030402020103',
      label: 'CODELISTCONSTANTS.MDNCode.M030402020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030402020103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'M03040203',
      label: 'CODELISTCONSTANTS.MDNCode.M03040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03040203.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'M0304020301',
      label: 'CODELISTCONSTANTS.MDNCode.M0304020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0304020301.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'M030402030102',
      label: 'CODELISTCONSTANTS.MDNCode.M030402030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030402030102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'M030402030103',
      label: 'CODELISTCONSTANTS.MDNCode.M030402030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030402030103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'M03040299',
      label: 'CODELISTCONSTANTS.MDNCode.M03040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M030403',
      label: 'CODELISTCONSTANTS.MDNCode.M030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030404',
      label: 'CODELISTCONSTANTS.MDNCode.M030404.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030405',
      label: 'CODELISTCONSTANTS.MDNCode.M030405.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030405.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030499',
      label: 'CODELISTCONSTANTS.MDNCode.M030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0305',
      label: 'CODELISTCONSTANTS.MDNCode.M0305.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0305.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M030501',
      label: 'CODELISTCONSTANTS.MDNCode.M030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030502',
      label: 'CODELISTCONSTANTS.MDNCode.M030502.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M03050201',
      label: 'CODELISTCONSTANTS.MDNCode.M03050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03050201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M03050202',
      label: 'CODELISTCONSTANTS.MDNCode.M03050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03050202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M03050299',
      label: 'CODELISTCONSTANTS.MDNCode.M03050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M03050299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M030503',
      label: 'CODELISTCONSTANTS.MDNCode.M030503.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030503.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030504',
      label: 'CODELISTCONSTANTS.MDNCode.M030504.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030504.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030505',
      label: 'CODELISTCONSTANTS.MDNCode.M030505.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030505.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030506',
      label: 'CODELISTCONSTANTS.MDNCode.M030506.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030506.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M030599',
      label: 'CODELISTCONSTANTS.MDNCode.M030599.label',
      description: 'CODELISTCONSTANTS.MDNCode.M030599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0399',
      label: 'CODELISTCONSTANTS.MDNCode.M0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M04',
      label: 'CODELISTCONSTANTS.MDNCode.M04.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'M0401',
      label: 'CODELISTCONSTANTS.MDNCode.M0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0401.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M040101',
      label: 'CODELISTCONSTANTS.MDNCode.M040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04010101',
      label: 'CODELISTCONSTANTS.MDNCode.M04010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04010102',
      label: 'CODELISTCONSTANTS.MDNCode.M04010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04010199',
      label: 'CODELISTCONSTANTS.MDNCode.M04010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040102',
      label: 'CODELISTCONSTANTS.MDNCode.M040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04010201',
      label: 'CODELISTCONSTANTS.MDNCode.M04010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04010202',
      label: 'CODELISTCONSTANTS.MDNCode.M04010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04010203',
      label: 'CODELISTCONSTANTS.MDNCode.M04010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04010299',
      label: 'CODELISTCONSTANTS.MDNCode.M04010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040199',
      label: 'CODELISTCONSTANTS.MDNCode.M040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0402',
      label: 'CODELISTCONSTANTS.MDNCode.M0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0402.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M040201',
      label: 'CODELISTCONSTANTS.MDNCode.M040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040202',
      label: 'CODELISTCONSTANTS.MDNCode.M040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040203',
      label: 'CODELISTCONSTANTS.MDNCode.M040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040204',
      label: 'CODELISTCONSTANTS.MDNCode.M040204.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040299',
      label: 'CODELISTCONSTANTS.MDNCode.M040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0403',
      label: 'CODELISTCONSTANTS.MDNCode.M0403.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0403.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M040301',
      label: 'CODELISTCONSTANTS.MDNCode.M040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040302',
      label: 'CODELISTCONSTANTS.MDNCode.M040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040303',
      label: 'CODELISTCONSTANTS.MDNCode.M040303.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040304',
      label: 'CODELISTCONSTANTS.MDNCode.M040304.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040305',
      label: 'CODELISTCONSTANTS.MDNCode.M040305.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040306',
      label: 'CODELISTCONSTANTS.MDNCode.M040306.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040306.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040399',
      label: 'CODELISTCONSTANTS.MDNCode.M040399.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0404',
      label: 'CODELISTCONSTANTS.MDNCode.M0404.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0404.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M040401',
      label: 'CODELISTCONSTANTS.MDNCode.M040401.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04040101',
      label: 'CODELISTCONSTANTS.MDNCode.M04040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04040102',
      label: 'CODELISTCONSTANTS.MDNCode.M04040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040402',
      label: 'CODELISTCONSTANTS.MDNCode.M040402.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04040201',
      label: 'CODELISTCONSTANTS.MDNCode.M04040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04040202',
      label: 'CODELISTCONSTANTS.MDNCode.M04040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040403',
      label: 'CODELISTCONSTANTS.MDNCode.M040403.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040403.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04040301',
      label: 'CODELISTCONSTANTS.MDNCode.M04040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04040302',
      label: 'CODELISTCONSTANTS.MDNCode.M04040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040404',
      label: 'CODELISTCONSTANTS.MDNCode.M040404.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040405',
      label: 'CODELISTCONSTANTS.MDNCode.M040405.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040405.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04040501',
      label: 'CODELISTCONSTANTS.MDNCode.M04040501.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04040502',
      label: 'CODELISTCONSTANTS.MDNCode.M04040502.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040406',
      label: 'CODELISTCONSTANTS.MDNCode.M040406.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040406.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04040601',
      label: 'CODELISTCONSTANTS.MDNCode.M04040601.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04040602',
      label: 'CODELISTCONSTANTS.MDNCode.M04040602.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040407',
      label: 'CODELISTCONSTANTS.MDNCode.M040407.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040407.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04040701',
      label: 'CODELISTCONSTANTS.MDNCode.M04040701.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04040702',
      label: 'CODELISTCONSTANTS.MDNCode.M04040702.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040408',
      label: 'CODELISTCONSTANTS.MDNCode.M040408.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040408.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04040801',
      label: 'CODELISTCONSTANTS.MDNCode.M04040801.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04040802',
      label: 'CODELISTCONSTANTS.MDNCode.M04040802.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040802.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040409',
      label: 'CODELISTCONSTANTS.MDNCode.M040409.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040409.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04040901',
      label: 'CODELISTCONSTANTS.MDNCode.M04040901.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040901.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04040902',
      label: 'CODELISTCONSTANTS.MDNCode.M04040902.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04040902.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040410',
      label: 'CODELISTCONSTANTS.MDNCode.M040410.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040410.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04041001',
      label: 'CODELISTCONSTANTS.MDNCode.M04041001.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04041001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04041002',
      label: 'CODELISTCONSTANTS.MDNCode.M04041002.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04041002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040411',
      label: 'CODELISTCONSTANTS.MDNCode.M040411.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040411.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040412',
      label: 'CODELISTCONSTANTS.MDNCode.M040412.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040412.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040413',
      label: 'CODELISTCONSTANTS.MDNCode.M040413.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040413.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040414',
      label: 'CODELISTCONSTANTS.MDNCode.M040414.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040414.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040415',
      label: 'CODELISTCONSTANTS.MDNCode.M040415.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040415.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04041501',
      label: 'CODELISTCONSTANTS.MDNCode.M04041501.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04041501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04041502',
      label: 'CODELISTCONSTANTS.MDNCode.M04041502.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04041502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04041503',
      label: 'CODELISTCONSTANTS.MDNCode.M04041503.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04041503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040416',
      label: 'CODELISTCONSTANTS.MDNCode.M040416.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040416.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040499',
      label: 'CODELISTCONSTANTS.MDNCode.M040499.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0405',
      label: 'CODELISTCONSTANTS.MDNCode.M0405.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0405.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M040501',
      label: 'CODELISTCONSTANTS.MDNCode.M040501.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040502',
      label: 'CODELISTCONSTANTS.MDNCode.M040502.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040503',
      label: 'CODELISTCONSTANTS.MDNCode.M040503.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040503.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040504',
      label: 'CODELISTCONSTANTS.MDNCode.M040504.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040504.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040505',
      label: 'CODELISTCONSTANTS.MDNCode.M040505.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040505.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'M04050501',
      label: 'CODELISTCONSTANTS.MDNCode.M04050501.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04050501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M04050502',
      label: 'CODELISTCONSTANTS.MDNCode.M04050502.label',
      description: 'CODELISTCONSTANTS.MDNCode.M04050502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'M040506',
      label: 'CODELISTCONSTANTS.MDNCode.M040506.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040506.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040599',
      label: 'CODELISTCONSTANTS.MDNCode.M040599.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0406',
      label: 'CODELISTCONSTANTS.MDNCode.M0406.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0406.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M0407',
      label: 'CODELISTCONSTANTS.MDNCode.M0407.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0407.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M040701',
      label: 'CODELISTCONSTANTS.MDNCode.M040701.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040702',
      label: 'CODELISTCONSTANTS.MDNCode.M040702.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040703',
      label: 'CODELISTCONSTANTS.MDNCode.M040703.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M040799',
      label: 'CODELISTCONSTANTS.MDNCode.M040799.label',
      description: 'CODELISTCONSTANTS.MDNCode.M040799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0408',
      label: 'CODELISTCONSTANTS.MDNCode.M0408.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0408.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M0409',
      label: 'CODELISTCONSTANTS.MDNCode.M0409.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0409.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M0410',
      label: 'CODELISTCONSTANTS.MDNCode.M0410.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0410.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M0411',
      label: 'CODELISTCONSTANTS.MDNCode.M0411.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0411.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M0412',
      label: 'CODELISTCONSTANTS.MDNCode.M0412.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0412.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M0499',
      label: 'CODELISTCONSTANTS.MDNCode.M0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M05',
      label: 'CODELISTCONSTANTS.MDNCode.M05.label',
      description: 'CODELISTCONSTANTS.MDNCode.M05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'M0501',
      label: 'CODELISTCONSTANTS.MDNCode.M0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0501.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M050101',
      label: 'CODELISTCONSTANTS.MDNCode.M050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.M050101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M050102',
      label: 'CODELISTCONSTANTS.MDNCode.M050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.M050102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M050199',
      label: 'CODELISTCONSTANTS.MDNCode.M050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.M050199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0502',
      label: 'CODELISTCONSTANTS.MDNCode.M0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0502.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'M050201',
      label: 'CODELISTCONSTANTS.MDNCode.M050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.M050201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M050202',
      label: 'CODELISTCONSTANTS.MDNCode.M050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.M050202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M050299',
      label: 'CODELISTCONSTANTS.MDNCode.M050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.M050299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'M0599',
      label: 'CODELISTCONSTANTS.MDNCode.M0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.M0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M90',
      label: 'CODELISTCONSTANTS.MDNCode.M90.label',
      description: 'CODELISTCONSTANTS.MDNCode.M90.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'M9001',
      label: 'CODELISTCONSTANTS.MDNCode.M9001.label',
      description: 'CODELISTCONSTANTS.MDNCode.M9001.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M9002',
      label: 'CODELISTCONSTANTS.MDNCode.M9002.label',
      description: 'CODELISTCONSTANTS.MDNCode.M9002.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M9003',
      label: 'CODELISTCONSTANTS.MDNCode.M9003.label',
      description: 'CODELISTCONSTANTS.MDNCode.M9003.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'M9099',
      label: 'CODELISTCONSTANTS.MDNCode.M9099.label',
      description: 'CODELISTCONSTANTS.MDNCode.M9099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'N',
      label: 'CODELISTCONSTANTS.MDNCode.N.label',
      description: 'CODELISTCONSTANTS.MDNCode.N.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'N01',
      label: 'CODELISTCONSTANTS.MDNCode.N01.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'N0101',
      label: 'CODELISTCONSTANTS.MDNCode.N0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N010101',
      label: 'CODELISTCONSTANTS.MDNCode.N010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010102',
      label: 'CODELISTCONSTANTS.MDNCode.N010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'N01010201',
      label: 'CODELISTCONSTANTS.MDNCode.N01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'N0101020101',
      label: 'CODELISTCONSTANTS.MDNCode.N0101020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0101020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'N0101020102',
      label: 'CODELISTCONSTANTS.MDNCode.N0101020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0101020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'N0101020199',
      label: 'CODELISTCONSTANTS.MDNCode.N0101020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0101020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'N01010202',
      label: 'CODELISTCONSTANTS.MDNCode.N01010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01010203',
      label: 'CODELISTCONSTANTS.MDNCode.N01010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01010299',
      label: 'CODELISTCONSTANTS.MDNCode.N01010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N010103',
      label: 'CODELISTCONSTANTS.MDNCode.N010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010180',
      label: 'CODELISTCONSTANTS.MDNCode.N010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010199',
      label: 'CODELISTCONSTANTS.MDNCode.N010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0102',
      label: 'CODELISTCONSTANTS.MDNCode.N0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N010201',
      label: 'CODELISTCONSTANTS.MDNCode.N010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010202',
      label: 'CODELISTCONSTANTS.MDNCode.N010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010280',
      label: 'CODELISTCONSTANTS.MDNCode.N010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010280.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'N01028001',
      label: 'CODELISTCONSTANTS.MDNCode.N01028001.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01028001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01028002',
      label: 'CODELISTCONSTANTS.MDNCode.N01028002.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01028002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01028003',
      label: 'CODELISTCONSTANTS.MDNCode.N01028003.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01028003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01028099',
      label: 'CODELISTCONSTANTS.MDNCode.N01028099.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01028099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N010299',
      label: 'CODELISTCONSTANTS.MDNCode.N010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0103',
      label: 'CODELISTCONSTANTS.MDNCode.N0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N010301',
      label: 'CODELISTCONSTANTS.MDNCode.N010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010302',
      label: 'CODELISTCONSTANTS.MDNCode.N010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010303',
      label: 'CODELISTCONSTANTS.MDNCode.N010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010380',
      label: 'CODELISTCONSTANTS.MDNCode.N010380.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010380.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'N01038001',
      label: 'CODELISTCONSTANTS.MDNCode.N01038001.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01038001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01038002',
      label: 'CODELISTCONSTANTS.MDNCode.N01038002.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01038002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01038099',
      label: 'CODELISTCONSTANTS.MDNCode.N01038099.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01038099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N010399',
      label: 'CODELISTCONSTANTS.MDNCode.N010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0104',
      label: 'CODELISTCONSTANTS.MDNCode.N0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N010401',
      label: 'CODELISTCONSTANTS.MDNCode.N010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010480',
      label: 'CODELISTCONSTANTS.MDNCode.N010480.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N010499',
      label: 'CODELISTCONSTANTS.MDNCode.N010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.N010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0180',
      label: 'CODELISTCONSTANTS.MDNCode.N0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0180.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N018001',
      label: 'CODELISTCONSTANTS.MDNCode.N018001.label',
      description: 'CODELISTCONSTANTS.MDNCode.N018001.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'N01800101',
      label: 'CODELISTCONSTANTS.MDNCode.N01800101.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01800101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01800102',
      label: 'CODELISTCONSTANTS.MDNCode.N01800102.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01800102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01800103',
      label: 'CODELISTCONSTANTS.MDNCode.N01800103.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01800103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01800104',
      label: 'CODELISTCONSTANTS.MDNCode.N01800104.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01800104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01800105',
      label: 'CODELISTCONSTANTS.MDNCode.N01800105.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01800105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01800106',
      label: 'CODELISTCONSTANTS.MDNCode.N01800106.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01800106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N01800199',
      label: 'CODELISTCONSTANTS.MDNCode.N01800199.label',
      description: 'CODELISTCONSTANTS.MDNCode.N01800199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N018099',
      label: 'CODELISTCONSTANTS.MDNCode.N018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.N018099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0199',
      label: 'CODELISTCONSTANTS.MDNCode.N0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'N02',
      label: 'CODELISTCONSTANTS.MDNCode.N02.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'N0201',
      label: 'CODELISTCONSTANTS.MDNCode.N0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N020101',
      label: 'CODELISTCONSTANTS.MDNCode.N020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'N02010101',
      label: 'CODELISTCONSTANTS.MDNCode.N02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02010102',
      label: 'CODELISTCONSTANTS.MDNCode.N02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02010180',
      label: 'CODELISTCONSTANTS.MDNCode.N02010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02010180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02010199',
      label: 'CODELISTCONSTANTS.MDNCode.N02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N020102',
      label: 'CODELISTCONSTANTS.MDNCode.N020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N020180',
      label: 'CODELISTCONSTANTS.MDNCode.N020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N020199',
      label: 'CODELISTCONSTANTS.MDNCode.N020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0202',
      label: 'CODELISTCONSTANTS.MDNCode.N0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N020201',
      label: 'CODELISTCONSTANTS.MDNCode.N020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N020202',
      label: 'CODELISTCONSTANTS.MDNCode.N020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N020280',
      label: 'CODELISTCONSTANTS.MDNCode.N020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N020299',
      label: 'CODELISTCONSTANTS.MDNCode.N020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0203',
      label: 'CODELISTCONSTANTS.MDNCode.N0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N020301',
      label: 'CODELISTCONSTANTS.MDNCode.N020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N020302',
      label: 'CODELISTCONSTANTS.MDNCode.N020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N020380',
      label: 'CODELISTCONSTANTS.MDNCode.N020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N020399',
      label: 'CODELISTCONSTANTS.MDNCode.N020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.N020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0280',
      label: 'CODELISTCONSTANTS.MDNCode.N0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0280.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'N028001',
      label: 'CODELISTCONSTANTS.MDNCode.N028001.label',
      description: 'CODELISTCONSTANTS.MDNCode.N028001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N028002',
      label: 'CODELISTCONSTANTS.MDNCode.N028002.label',
      description: 'CODELISTCONSTANTS.MDNCode.N028002.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'N02800201',
      label: 'CODELISTCONSTANTS.MDNCode.N02800201.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800202',
      label: 'CODELISTCONSTANTS.MDNCode.N02800202.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800203',
      label: 'CODELISTCONSTANTS.MDNCode.N02800203.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800204',
      label: 'CODELISTCONSTANTS.MDNCode.N02800204.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800205',
      label: 'CODELISTCONSTANTS.MDNCode.N02800205.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800206',
      label: 'CODELISTCONSTANTS.MDNCode.N02800206.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800206.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800207',
      label: 'CODELISTCONSTANTS.MDNCode.N02800207.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800207.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800208',
      label: 'CODELISTCONSTANTS.MDNCode.N02800208.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800208.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800209',
      label: 'CODELISTCONSTANTS.MDNCode.N02800209.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800209.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N02800299',
      label: 'CODELISTCONSTANTS.MDNCode.N02800299.label',
      description: 'CODELISTCONSTANTS.MDNCode.N02800299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'N028099',
      label: 'CODELISTCONSTANTS.MDNCode.N028099.label',
      description: 'CODELISTCONSTANTS.MDNCode.N028099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'N0299',
      label: 'CODELISTCONSTANTS.MDNCode.N0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.N0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'N99',
      label: 'CODELISTCONSTANTS.MDNCode.N99.label',
      description: 'CODELISTCONSTANTS.MDNCode.N99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'P',
      label: 'CODELISTCONSTANTS.MDNCode.P.label',
      description: 'CODELISTCONSTANTS.MDNCode.P.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'P01',
      label: 'CODELISTCONSTANTS.MDNCode.P01.label',
      description: 'CODELISTCONSTANTS.MDNCode.P01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0101',
      label: 'CODELISTCONSTANTS.MDNCode.P0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P010101',
      label: 'CODELISTCONSTANTS.MDNCode.P010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P010101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P010102',
      label: 'CODELISTCONSTANTS.MDNCode.P010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P010103',
      label: 'CODELISTCONSTANTS.MDNCode.P010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P010103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P010199',
      label: 'CODELISTCONSTANTS.MDNCode.P010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0102',
      label: 'CODELISTCONSTANTS.MDNCode.P0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P010201',
      label: 'CODELISTCONSTANTS.MDNCode.P010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P01020101',
      label: 'CODELISTCONSTANTS.MDNCode.P01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P01020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P01020180',
      label: 'CODELISTCONSTANTS.MDNCode.P01020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.P01020180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P010299',
      label: 'CODELISTCONSTANTS.MDNCode.P010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0180',
      label: 'CODELISTCONSTANTS.MDNCode.P0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0180.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0199',
      label: 'CODELISTCONSTANTS.MDNCode.P0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P02',
      label: 'CODELISTCONSTANTS.MDNCode.P02.label',
      description: 'CODELISTCONSTANTS.MDNCode.P02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0201',
      label: 'CODELISTCONSTANTS.MDNCode.P0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P020101',
      label: 'CODELISTCONSTANTS.MDNCode.P020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P02010101',
      label: 'CODELISTCONSTANTS.MDNCode.P02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P02010102',
      label: 'CODELISTCONSTANTS.MDNCode.P02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P02010103',
      label: 'CODELISTCONSTANTS.MDNCode.P02010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P02010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P02010199',
      label: 'CODELISTCONSTANTS.MDNCode.P02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P020102',
      label: 'CODELISTCONSTANTS.MDNCode.P020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P02010201',
      label: 'CODELISTCONSTANTS.MDNCode.P02010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P02010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P02010202',
      label: 'CODELISTCONSTANTS.MDNCode.P02010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P02010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P02010299',
      label: 'CODELISTCONSTANTS.MDNCode.P02010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P02010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P020103',
      label: 'CODELISTCONSTANTS.MDNCode.P020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P020180',
      label: 'CODELISTCONSTANTS.MDNCode.P020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P020199',
      label: 'CODELISTCONSTANTS.MDNCode.P020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0202',
      label: 'CODELISTCONSTANTS.MDNCode.P0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P020201',
      label: 'CODELISTCONSTANTS.MDNCode.P020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P020202',
      label: 'CODELISTCONSTANTS.MDNCode.P020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P020203',
      label: 'CODELISTCONSTANTS.MDNCode.P020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P020299',
      label: 'CODELISTCONSTANTS.MDNCode.P020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0203',
      label: 'CODELISTCONSTANTS.MDNCode.P0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P020301',
      label: 'CODELISTCONSTANTS.MDNCode.P020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P020399',
      label: 'CODELISTCONSTANTS.MDNCode.P020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0204',
      label: 'CODELISTCONSTANTS.MDNCode.P0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0204.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0205',
      label: 'CODELISTCONSTANTS.MDNCode.P0205.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0205.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0280',
      label: 'CODELISTCONSTANTS.MDNCode.P0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0280.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0299',
      label: 'CODELISTCONSTANTS.MDNCode.P0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P03',
      label: 'CODELISTCONSTANTS.MDNCode.P03.label',
      description: 'CODELISTCONSTANTS.MDNCode.P03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0301',
      label: 'CODELISTCONSTANTS.MDNCode.P0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P030101',
      label: 'CODELISTCONSTANTS.MDNCode.P030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P03010103',
      label: 'CODELISTCONSTANTS.MDNCode.P03010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P03010103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0301010301',
      label: 'CODELISTCONSTANTS.MDNCode.P0301010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301010301.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030101030101',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030102',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030103',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030104',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030104.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030199',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301010302',
      label: 'CODELISTCONSTANTS.MDNCode.P0301010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301010302.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030101030201',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030202',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030203',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030299',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301010303',
      label: 'CODELISTCONSTANTS.MDNCode.P0301010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301010303.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030101030301',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030302',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030303',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030303.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101030399',
      label: 'CODELISTCONSTANTS.MDNCode.P030101030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101030399.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P03010104',
      label: 'CODELISTCONSTANTS.MDNCode.P03010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P03010104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0301010401',
      label: 'CODELISTCONSTANTS.MDNCode.P0301010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301010401.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030101040101',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040102',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040103',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040199',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301010402',
      label: 'CODELISTCONSTANTS.MDNCode.P0301010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301010402.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030101040201',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040202',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040203',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040299',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301010403',
      label: 'CODELISTCONSTANTS.MDNCode.P0301010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301010403.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030101040301',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040302',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040303',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040303.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030101040399',
      label: 'CODELISTCONSTANTS.MDNCode.P030101040399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030101040399.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102',
      label: 'CODELISTCONSTANTS.MDNCode.P030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P03010209',
      label: 'CODELISTCONSTANTS.MDNCode.P03010209.label',
      description: 'CODELISTCONSTANTS.MDNCode.P03010209.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0301020901',
      label: 'CODELISTCONSTANTS.MDNCode.P0301020901.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301020901.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102090101',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090102',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090103',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090104',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090104.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090199',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301020902',
      label: 'CODELISTCONSTANTS.MDNCode.P0301020902.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301020902.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102090201',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090202',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090203',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090299',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301020903',
      label: 'CODELISTCONSTANTS.MDNCode.P0301020903.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301020903.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102090301',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090302',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090303',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090303.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102090399',
      label: 'CODELISTCONSTANTS.MDNCode.P030102090399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102090399.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P03010210',
      label: 'CODELISTCONSTANTS.MDNCode.P03010210.label',
      description: 'CODELISTCONSTANTS.MDNCode.P03010210.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0301021001',
      label: 'CODELISTCONSTANTS.MDNCode.P0301021001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301021001.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102100101',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100102',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100103',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100199',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301021002',
      label: 'CODELISTCONSTANTS.MDNCode.P0301021002.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301021002.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102100201',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100202',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100203',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100299',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301021003',
      label: 'CODELISTCONSTANTS.MDNCode.P0301021003.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301021003.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102100301',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100302',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100303',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100303.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102100399',
      label: 'CODELISTCONSTANTS.MDNCode.P030102100399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102100399.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P03010211',
      label: 'CODELISTCONSTANTS.MDNCode.P03010211.label',
      description: 'CODELISTCONSTANTS.MDNCode.P03010211.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0301021101',
      label: 'CODELISTCONSTANTS.MDNCode.P0301021101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301021101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102110101',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110102',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110103',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110199',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301021102',
      label: 'CODELISTCONSTANTS.MDNCode.P0301021102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301021102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102110201',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110202',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110203',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110299',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0301021103',
      label: 'CODELISTCONSTANTS.MDNCode.P0301021103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0301021103.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P030102110301',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110302',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110303',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110303.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030102110399',
      label: 'CODELISTCONSTANTS.MDNCode.P030102110399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030102110399.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P030190',
      label: 'CODELISTCONSTANTS.MDNCode.P030190.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030190.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P03019001',
      label: 'CODELISTCONSTANTS.MDNCode.P03019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P03019001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P030199',
      label: 'CODELISTCONSTANTS.MDNCode.P030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0380',
      label: 'CODELISTCONSTANTS.MDNCode.P0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0399',
      label: 'CODELISTCONSTANTS.MDNCode.P0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P04',
      label: 'CODELISTCONSTANTS.MDNCode.P04.label',
      description: 'CODELISTCONSTANTS.MDNCode.P04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0401',
      label: 'CODELISTCONSTANTS.MDNCode.P0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0401.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0480',
      label: 'CODELISTCONSTANTS.MDNCode.P0480.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0480.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0499',
      label: 'CODELISTCONSTANTS.MDNCode.P0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P05',
      label: 'CODELISTCONSTANTS.MDNCode.P05.label',
      description: 'CODELISTCONSTANTS.MDNCode.P05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0501',
      label: 'CODELISTCONSTANTS.MDNCode.P0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0501.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P050101',
      label: 'CODELISTCONSTANTS.MDNCode.P050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P050101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P050199',
      label: 'CODELISTCONSTANTS.MDNCode.P050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P050199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0502',
      label: 'CODELISTCONSTANTS.MDNCode.P0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0502.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0503',
      label: 'CODELISTCONSTANTS.MDNCode.P0503.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0503.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0504',
      label: 'CODELISTCONSTANTS.MDNCode.P0504.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0504.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P050401',
      label: 'CODELISTCONSTANTS.MDNCode.P050401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P050401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P050499',
      label: 'CODELISTCONSTANTS.MDNCode.P050499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P050499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0580',
      label: 'CODELISTCONSTANTS.MDNCode.P0580.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0580.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P058001',
      label: 'CODELISTCONSTANTS.MDNCode.P058001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P058001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P058099',
      label: 'CODELISTCONSTANTS.MDNCode.P058099.label',
      description: 'CODELISTCONSTANTS.MDNCode.P058099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0599',
      label: 'CODELISTCONSTANTS.MDNCode.P0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P06',
      label: 'CODELISTCONSTANTS.MDNCode.P06.label',
      description: 'CODELISTCONSTANTS.MDNCode.P06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0601',
      label: 'CODELISTCONSTANTS.MDNCode.P0601.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P060101',
      label: 'CODELISTCONSTANTS.MDNCode.P060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P060101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P06010101',
      label: 'CODELISTCONSTANTS.MDNCode.P06010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P06010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0601010101',
      label: 'CODELISTCONSTANTS.MDNCode.P0601010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0601010102',
      label: 'CODELISTCONSTANTS.MDNCode.P0601010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0601010199',
      label: 'CODELISTCONSTANTS.MDNCode.P0601010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P06010102',
      label: 'CODELISTCONSTANTS.MDNCode.P06010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P06010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0601010201',
      label: 'CODELISTCONSTANTS.MDNCode.P0601010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0601010202',
      label: 'CODELISTCONSTANTS.MDNCode.P0601010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0601010299',
      label: 'CODELISTCONSTANTS.MDNCode.P0601010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P060102',
      label: 'CODELISTCONSTANTS.MDNCode.P060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P060102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P06010201',
      label: 'CODELISTCONSTANTS.MDNCode.P06010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P06010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0601020101',
      label: 'CODELISTCONSTANTS.MDNCode.P0601020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0601020102',
      label: 'CODELISTCONSTANTS.MDNCode.P0601020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0601020199',
      label: 'CODELISTCONSTANTS.MDNCode.P0601020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P06010202',
      label: 'CODELISTCONSTANTS.MDNCode.P06010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P06010202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0601020201',
      label: 'CODELISTCONSTANTS.MDNCode.P0601020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0601020202',
      label: 'CODELISTCONSTANTS.MDNCode.P0601020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0601020299',
      label: 'CODELISTCONSTANTS.MDNCode.P0601020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0601020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P060199',
      label: 'CODELISTCONSTANTS.MDNCode.P060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P060199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0602',
      label: 'CODELISTCONSTANTS.MDNCode.P0602.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0602.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P060201',
      label: 'CODELISTCONSTANTS.MDNCode.P060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P060201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P060299',
      label: 'CODELISTCONSTANTS.MDNCode.P060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P060299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0680',
      label: 'CODELISTCONSTANTS.MDNCode.P0680.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0680.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0699',
      label: 'CODELISTCONSTANTS.MDNCode.P0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P07',
      label: 'CODELISTCONSTANTS.MDNCode.P07.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0701',
      label: 'CODELISTCONSTANTS.MDNCode.P0701.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P070101',
      label: 'CODELISTCONSTANTS.MDNCode.P070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07010101',
      label: 'CODELISTCONSTANTS.MDNCode.P07010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0701010101',
      label: 'CODELISTCONSTANTS.MDNCode.P0701010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0701010102',
      label: 'CODELISTCONSTANTS.MDNCode.P0701010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P07010102',
      label: 'CODELISTCONSTANTS.MDNCode.P07010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0701010201',
      label: 'CODELISTCONSTANTS.MDNCode.P0701010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0701010202',
      label: 'CODELISTCONSTANTS.MDNCode.P0701010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P07010199',
      label: 'CODELISTCONSTANTS.MDNCode.P07010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070102',
      label: 'CODELISTCONSTANTS.MDNCode.P070102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07010201',
      label: 'CODELISTCONSTANTS.MDNCode.P07010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0701020101',
      label: 'CODELISTCONSTANTS.MDNCode.P0701020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701020101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070102010101',
      label: 'CODELISTCONSTANTS.MDNCode.P070102010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070102010102',
      label: 'CODELISTCONSTANTS.MDNCode.P070102010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0701020102',
      label: 'CODELISTCONSTANTS.MDNCode.P0701020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701020102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070102010201',
      label: 'CODELISTCONSTANTS.MDNCode.P070102010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070102010202',
      label: 'CODELISTCONSTANTS.MDNCode.P070102010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P07010202',
      label: 'CODELISTCONSTANTS.MDNCode.P07010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07010202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0701020201',
      label: 'CODELISTCONSTANTS.MDNCode.P0701020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701020201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070102020101',
      label: 'CODELISTCONSTANTS.MDNCode.P070102020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070102020102',
      label: 'CODELISTCONSTANTS.MDNCode.P070102020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0701020202',
      label: 'CODELISTCONSTANTS.MDNCode.P0701020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0701020202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070102020201',
      label: 'CODELISTCONSTANTS.MDNCode.P070102020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070102020202',
      label: 'CODELISTCONSTANTS.MDNCode.P070102020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070102020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P07010299',
      label: 'CODELISTCONSTANTS.MDNCode.P07010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070180',
      label: 'CODELISTCONSTANTS.MDNCode.P070180.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0702',
      label: 'CODELISTCONSTANTS.MDNCode.P0702.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0702.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P070201',
      label: 'CODELISTCONSTANTS.MDNCode.P070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07020101',
      label: 'CODELISTCONSTANTS.MDNCode.P07020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07020102',
      label: 'CODELISTCONSTANTS.MDNCode.P07020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07020199',
      label: 'CODELISTCONSTANTS.MDNCode.P07020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070202',
      label: 'CODELISTCONSTANTS.MDNCode.P070202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07020201',
      label: 'CODELISTCONSTANTS.MDNCode.P07020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07020202',
      label: 'CODELISTCONSTANTS.MDNCode.P07020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07020203',
      label: 'CODELISTCONSTANTS.MDNCode.P07020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07020204',
      label: 'CODELISTCONSTANTS.MDNCode.P07020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07020204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07020299',
      label: 'CODELISTCONSTANTS.MDNCode.P07020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P0703',
      label: 'CODELISTCONSTANTS.MDNCode.P0703.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P070301',
      label: 'CODELISTCONSTANTS.MDNCode.P070301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07030101',
      label: 'CODELISTCONSTANTS.MDNCode.P07030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0703010101',
      label: 'CODELISTCONSTANTS.MDNCode.P0703010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703010101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070301010101',
      label: 'CODELISTCONSTANTS.MDNCode.P070301010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301010102',
      label: 'CODELISTCONSTANTS.MDNCode.P070301010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301010103',
      label: 'CODELISTCONSTANTS.MDNCode.P070301010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301010103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301010104',
      label: 'CODELISTCONSTANTS.MDNCode.P070301010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301010104.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0703010102',
      label: 'CODELISTCONSTANTS.MDNCode.P0703010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703010102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070301010201',
      label: 'CODELISTCONSTANTS.MDNCode.P070301010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301010202',
      label: 'CODELISTCONSTANTS.MDNCode.P070301010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301010203',
      label: 'CODELISTCONSTANTS.MDNCode.P070301010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301010203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301010204',
      label: 'CODELISTCONSTANTS.MDNCode.P070301010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301010204.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P07030102',
      label: 'CODELISTCONSTANTS.MDNCode.P07030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0703010201',
      label: 'CODELISTCONSTANTS.MDNCode.P0703010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0703010202',
      label: 'CODELISTCONSTANTS.MDNCode.P0703010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P07030103',
      label: 'CODELISTCONSTANTS.MDNCode.P07030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0703010301',
      label: 'CODELISTCONSTANTS.MDNCode.P0703010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703010301.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070301030101',
      label: 'CODELISTCONSTANTS.MDNCode.P070301030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301030101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301030102',
      label: 'CODELISTCONSTANTS.MDNCode.P070301030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301030102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301030103',
      label: 'CODELISTCONSTANTS.MDNCode.P070301030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301030103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301030104',
      label: 'CODELISTCONSTANTS.MDNCode.P070301030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301030104.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0703010302',
      label: 'CODELISTCONSTANTS.MDNCode.P0703010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703010302.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070301030201',
      label: 'CODELISTCONSTANTS.MDNCode.P070301030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301030201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301030202',
      label: 'CODELISTCONSTANTS.MDNCode.P070301030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301030202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301030203',
      label: 'CODELISTCONSTANTS.MDNCode.P070301030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301030203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070301030204',
      label: 'CODELISTCONSTANTS.MDNCode.P070301030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070301030204.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P07030104',
      label: 'CODELISTCONSTANTS.MDNCode.P07030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070380',
      label: 'CODELISTCONSTANTS.MDNCode.P070380.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070380.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07038001',
      label: 'CODELISTCONSTANTS.MDNCode.P07038001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07038001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07038002',
      label: 'CODELISTCONSTANTS.MDNCode.P07038002.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07038002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07038003',
      label: 'CODELISTCONSTANTS.MDNCode.P07038003.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07038003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07038004',
      label: 'CODELISTCONSTANTS.MDNCode.P07038004.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07038004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07038099',
      label: 'CODELISTCONSTANTS.MDNCode.P07038099.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07038099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07030199',
      label: 'CODELISTCONSTANTS.MDNCode.P07030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070302',
      label: 'CODELISTCONSTANTS.MDNCode.P070302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07030201',
      label: 'CODELISTCONSTANTS.MDNCode.P07030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07030202',
      label: 'CODELISTCONSTANTS.MDNCode.P07030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07030203',
      label: 'CODELISTCONSTANTS.MDNCode.P07030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030203.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0703020301',
      label: 'CODELISTCONSTANTS.MDNCode.P0703020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703020301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0703020302',
      label: 'CODELISTCONSTANTS.MDNCode.P0703020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703020302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0703020399',
      label: 'CODELISTCONSTANTS.MDNCode.P0703020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0703020399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P07030299',
      label: 'CODELISTCONSTANTS.MDNCode.P07030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070303',
      label: 'CODELISTCONSTANTS.MDNCode.P070303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07030301',
      label: 'CODELISTCONSTANTS.MDNCode.P07030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07030302',
      label: 'CODELISTCONSTANTS.MDNCode.P07030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07030399',
      label: 'CODELISTCONSTANTS.MDNCode.P07030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070304',
      label: 'CODELISTCONSTANTS.MDNCode.P070304.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070304.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07030401',
      label: 'CODELISTCONSTANTS.MDNCode.P07030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07030402',
      label: 'CODELISTCONSTANTS.MDNCode.P07030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07030403',
      label: 'CODELISTCONSTANTS.MDNCode.P07030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07030499',
      label: 'CODELISTCONSTANTS.MDNCode.P07030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07030499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070399',
      label: 'CODELISTCONSTANTS.MDNCode.P070399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0704',
      label: 'CODELISTCONSTANTS.MDNCode.P0704.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P070401',
      label: 'CODELISTCONSTANTS.MDNCode.P070401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07040101',
      label: 'CODELISTCONSTANTS.MDNCode.P07040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0704010101',
      label: 'CODELISTCONSTANTS.MDNCode.P0704010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704010101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070401010101',
      label: 'CODELISTCONSTANTS.MDNCode.P070401010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070401010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070401010102',
      label: 'CODELISTCONSTANTS.MDNCode.P070401010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070401010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070401010199',
      label: 'CODELISTCONSTANTS.MDNCode.P070401010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070401010199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0704010102',
      label: 'CODELISTCONSTANTS.MDNCode.P0704010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0704010103',
      label: 'CODELISTCONSTANTS.MDNCode.P0704010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P07040102',
      label: 'CODELISTCONSTANTS.MDNCode.P07040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0704010201',
      label: 'CODELISTCONSTANTS.MDNCode.P0704010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704010201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070401020101',
      label: 'CODELISTCONSTANTS.MDNCode.P070401020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070401020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070401020102',
      label: 'CODELISTCONSTANTS.MDNCode.P070401020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070401020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070401020103',
      label: 'CODELISTCONSTANTS.MDNCode.P070401020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070401020103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070401020199',
      label: 'CODELISTCONSTANTS.MDNCode.P070401020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070401020199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0704010202',
      label: 'CODELISTCONSTANTS.MDNCode.P0704010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0704010203',
      label: 'CODELISTCONSTANTS.MDNCode.P0704010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P07040199',
      label: 'CODELISTCONSTANTS.MDNCode.P07040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070402',
      label: 'CODELISTCONSTANTS.MDNCode.P070402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07040201',
      label: 'CODELISTCONSTANTS.MDNCode.P07040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0704020101',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070402010101',
      label: 'CODELISTCONSTANTS.MDNCode.P070402010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070402010102',
      label: 'CODELISTCONSTANTS.MDNCode.P070402010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070402010199',
      label: 'CODELISTCONSTANTS.MDNCode.P070402010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402010199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0704020102',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0704020103',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020103.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070402010301',
      label: 'CODELISTCONSTANTS.MDNCode.P070402010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402010301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070402010302',
      label: 'CODELISTCONSTANTS.MDNCode.P070402010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402010302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0704020104',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0704020105',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0704020199',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P07040202',
      label: 'CODELISTCONSTANTS.MDNCode.P07040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0704020201',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P070402020101',
      label: 'CODELISTCONSTANTS.MDNCode.P070402020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070402020102',
      label: 'CODELISTCONSTANTS.MDNCode.P070402020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P070402020199',
      label: 'CODELISTCONSTANTS.MDNCode.P070402020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070402020199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0704020202',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0704020203',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0704020204',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0704020299',
      label: 'CODELISTCONSTANTS.MDNCode.P0704020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0704020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P070403',
      label: 'CODELISTCONSTANTS.MDNCode.P070403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070403.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P07040301',
      label: 'CODELISTCONSTANTS.MDNCode.P07040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07040302',
      label: 'CODELISTCONSTANTS.MDNCode.P07040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07040303',
      label: 'CODELISTCONSTANTS.MDNCode.P07040303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P07040399',
      label: 'CODELISTCONSTANTS.MDNCode.P07040399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P07040399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P070404',
      label: 'CODELISTCONSTANTS.MDNCode.P070404.label',
      description: 'CODELISTCONSTANTS.MDNCode.P070404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0780',
      label: 'CODELISTCONSTANTS.MDNCode.P0780.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0780.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0799',
      label: 'CODELISTCONSTANTS.MDNCode.P0799.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0799.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P08',
      label: 'CODELISTCONSTANTS.MDNCode.P08.label',
      description: 'CODELISTCONSTANTS.MDNCode.P08.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0801',
      label: 'CODELISTCONSTANTS.MDNCode.P0801.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0801.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P080102',
      label: 'CODELISTCONSTANTS.MDNCode.P080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P080199',
      label: 'CODELISTCONSTANTS.MDNCode.P080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0802',
      label: 'CODELISTCONSTANTS.MDNCode.P0802.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0802.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P080201',
      label: 'CODELISTCONSTANTS.MDNCode.P080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P080202',
      label: 'CODELISTCONSTANTS.MDNCode.P080202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P080299',
      label: 'CODELISTCONSTANTS.MDNCode.P080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0803',
      label: 'CODELISTCONSTANTS.MDNCode.P0803.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0803.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P080301',
      label: 'CODELISTCONSTANTS.MDNCode.P080301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P08030101',
      label: 'CODELISTCONSTANTS.MDNCode.P08030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P08030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P08030102',
      label: 'CODELISTCONSTANTS.MDNCode.P08030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P08030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P08030199',
      label: 'CODELISTCONSTANTS.MDNCode.P08030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P08030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P080302',
      label: 'CODELISTCONSTANTS.MDNCode.P080302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P080399',
      label: 'CODELISTCONSTANTS.MDNCode.P080399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0804',
      label: 'CODELISTCONSTANTS.MDNCode.P0804.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0804.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P080401',
      label: 'CODELISTCONSTANTS.MDNCode.P080401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P080499',
      label: 'CODELISTCONSTANTS.MDNCode.P080499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P080499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0805',
      label: 'CODELISTCONSTANTS.MDNCode.P0805.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0805.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0880',
      label: 'CODELISTCONSTANTS.MDNCode.P0880.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0880.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0899',
      label: 'CODELISTCONSTANTS.MDNCode.P0899.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0899.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P09',
      label: 'CODELISTCONSTANTS.MDNCode.P09.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P0901',
      label: 'CODELISTCONSTANTS.MDNCode.P0901.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090103',
      label: 'CODELISTCONSTANTS.MDNCode.P090103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09010301',
      label: 'CODELISTCONSTANTS.MDNCode.P09010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09010302',
      label: 'CODELISTCONSTANTS.MDNCode.P09010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09010303',
      label: 'CODELISTCONSTANTS.MDNCode.P09010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09010304',
      label: 'CODELISTCONSTANTS.MDNCode.P09010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09010399',
      label: 'CODELISTCONSTANTS.MDNCode.P09010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090104',
      label: 'CODELISTCONSTANTS.MDNCode.P090104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09010401',
      label: 'CODELISTCONSTANTS.MDNCode.P09010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0901040101',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090104010101',
      label: 'CODELISTCONSTANTS.MDNCode.P090104010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090104010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090104010102',
      label: 'CODELISTCONSTANTS.MDNCode.P090104010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090104010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0901040102',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090104010201',
      label: 'CODELISTCONSTANTS.MDNCode.P090104010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090104010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090104010202',
      label: 'CODELISTCONSTANTS.MDNCode.P090104010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090104010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0901040199',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09010402',
      label: 'CODELISTCONSTANTS.MDNCode.P09010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010402.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0901040201',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0901040202',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0901040203',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040203.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090104020301',
      label: 'CODELISTCONSTANTS.MDNCode.P090104020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090104020301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090104020302',
      label: 'CODELISTCONSTANTS.MDNCode.P090104020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090104020302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0901040204',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0901040205',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040205.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0901040299',
      label: 'CODELISTCONSTANTS.MDNCode.P0901040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901040299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09010403',
      label: 'CODELISTCONSTANTS.MDNCode.P09010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09010499',
      label: 'CODELISTCONSTANTS.MDNCode.P09010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09010499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090180',
      label: 'CODELISTCONSTANTS.MDNCode.P090180.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090180.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09018001',
      label: 'CODELISTCONSTANTS.MDNCode.P09018001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09018001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09018002',
      label: 'CODELISTCONSTANTS.MDNCode.P09018002.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09018002.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0901800201',
      label: 'CODELISTCONSTANTS.MDNCode.P0901800201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901800201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0901800202',
      label: 'CODELISTCONSTANTS.MDNCode.P0901800202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0901800202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09018003',
      label: 'CODELISTCONSTANTS.MDNCode.P09018003.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09018003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09018099',
      label: 'CODELISTCONSTANTS.MDNCode.P09018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09018099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090199',
      label: 'CODELISTCONSTANTS.MDNCode.P090199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0902',
      label: 'CODELISTCONSTANTS.MDNCode.P0902.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0902.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090203',
      label: 'CODELISTCONSTANTS.MDNCode.P090203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090204',
      label: 'CODELISTCONSTANTS.MDNCode.P090204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090205',
      label: 'CODELISTCONSTANTS.MDNCode.P090205.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090280',
      label: 'CODELISTCONSTANTS.MDNCode.P090280.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090299',
      label: 'CODELISTCONSTANTS.MDNCode.P090299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0903',
      label: 'CODELISTCONSTANTS.MDNCode.P0903.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0903.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090303',
      label: 'CODELISTCONSTANTS.MDNCode.P090303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090304',
      label: 'CODELISTCONSTANTS.MDNCode.P090304.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090304.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09030401',
      label: 'CODELISTCONSTANTS.MDNCode.P09030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09030401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09030402',
      label: 'CODELISTCONSTANTS.MDNCode.P09030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09030402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090305',
      label: 'CODELISTCONSTANTS.MDNCode.P090305.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090380',
      label: 'CODELISTCONSTANTS.MDNCode.P090380.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090399',
      label: 'CODELISTCONSTANTS.MDNCode.P090399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0904',
      label: 'CODELISTCONSTANTS.MDNCode.P0904.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0904.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090403',
      label: 'CODELISTCONSTANTS.MDNCode.P090403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090404',
      label: 'CODELISTCONSTANTS.MDNCode.P090404.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090480',
      label: 'CODELISTCONSTANTS.MDNCode.P090480.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090499',
      label: 'CODELISTCONSTANTS.MDNCode.P090499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0905',
      label: 'CODELISTCONSTANTS.MDNCode.P0905.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090506',
      label: 'CODELISTCONSTANTS.MDNCode.P090506.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090506.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09050601',
      label: 'CODELISTCONSTANTS.MDNCode.P09050601.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09050601.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0905060101',
      label: 'CODELISTCONSTANTS.MDNCode.P0905060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905060101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0905060102',
      label: 'CODELISTCONSTANTS.MDNCode.P0905060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905060102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09050602',
      label: 'CODELISTCONSTANTS.MDNCode.P09050602.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09050602.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0905060201',
      label: 'CODELISTCONSTANTS.MDNCode.P0905060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905060201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0905060202',
      label: 'CODELISTCONSTANTS.MDNCode.P0905060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905060202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09050603',
      label: 'CODELISTCONSTANTS.MDNCode.P09050603.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09050603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090507',
      label: 'CODELISTCONSTANTS.MDNCode.P090507.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090507.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09050701',
      label: 'CODELISTCONSTANTS.MDNCode.P09050701.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09050701.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0905070101',
      label: 'CODELISTCONSTANTS.MDNCode.P0905070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905070101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0905070102',
      label: 'CODELISTCONSTANTS.MDNCode.P0905070102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905070102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09050702',
      label: 'CODELISTCONSTANTS.MDNCode.P09050702.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09050702.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0905070201',
      label: 'CODELISTCONSTANTS.MDNCode.P0905070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905070201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0905070202',
      label: 'CODELISTCONSTANTS.MDNCode.P0905070202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0905070202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09050703',
      label: 'CODELISTCONSTANTS.MDNCode.P09050703.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09050703.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090508',
      label: 'CODELISTCONSTANTS.MDNCode.P090508.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090508.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090580',
      label: 'CODELISTCONSTANTS.MDNCode.P090580.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090580.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09058001',
      label: 'CODELISTCONSTANTS.MDNCode.P09058001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09058001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09058099',
      label: 'CODELISTCONSTANTS.MDNCode.P09058099.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09058099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090599',
      label: 'CODELISTCONSTANTS.MDNCode.P090599.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0906',
      label: 'CODELISTCONSTANTS.MDNCode.P0906.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0906.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090603',
      label: 'CODELISTCONSTANTS.MDNCode.P090603.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090604',
      label: 'CODELISTCONSTANTS.MDNCode.P090604.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090604.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090605',
      label: 'CODELISTCONSTANTS.MDNCode.P090605.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090605.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090680',
      label: 'CODELISTCONSTANTS.MDNCode.P090680.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090680.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090699',
      label: 'CODELISTCONSTANTS.MDNCode.P090699.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0907',
      label: 'CODELISTCONSTANTS.MDNCode.P0907.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0907.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090701',
      label: 'CODELISTCONSTANTS.MDNCode.P090701.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090701.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09070101',
      label: 'CODELISTCONSTANTS.MDNCode.P09070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09070101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09070199',
      label: 'CODELISTCONSTANTS.MDNCode.P09070199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09070199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090702',
      label: 'CODELISTCONSTANTS.MDNCode.P090702.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090702.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09070201',
      label: 'CODELISTCONSTANTS.MDNCode.P09070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09070201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090703',
      label: 'CODELISTCONSTANTS.MDNCode.P090703.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090703.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09070301',
      label: 'CODELISTCONSTANTS.MDNCode.P09070301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09070301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09070302',
      label: 'CODELISTCONSTANTS.MDNCode.P09070302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09070302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09070304',
      label: 'CODELISTCONSTANTS.MDNCode.P09070304.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09070304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09070305',
      label: 'CODELISTCONSTANTS.MDNCode.P09070305.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09070305.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09070399',
      label: 'CODELISTCONSTANTS.MDNCode.P09070399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09070399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090780',
      label: 'CODELISTCONSTANTS.MDNCode.P090780.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090780.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090799',
      label: 'CODELISTCONSTANTS.MDNCode.P090799.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0908',
      label: 'CODELISTCONSTANTS.MDNCode.P0908.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090803',
      label: 'CODELISTCONSTANTS.MDNCode.P090803.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09080301',
      label: 'CODELISTCONSTANTS.MDNCode.P09080301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0908030101',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090803010101',
      label: 'CODELISTCONSTANTS.MDNCode.P090803010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090803010102',
      label: 'CODELISTCONSTANTS.MDNCode.P090803010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090803010199',
      label: 'CODELISTCONSTANTS.MDNCode.P090803010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803010199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0908030102',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090803010201',
      label: 'CODELISTCONSTANTS.MDNCode.P090803010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090803010202',
      label: 'CODELISTCONSTANTS.MDNCode.P090803010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090803010203',
      label: 'CODELISTCONSTANTS.MDNCode.P090803010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803010203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090803010299',
      label: 'CODELISTCONSTANTS.MDNCode.P090803010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803010299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P09080302',
      label: 'CODELISTCONSTANTS.MDNCode.P09080302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09080303',
      label: 'CODELISTCONSTANTS.MDNCode.P09080303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080303.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0908030301',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0908030302',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09080304',
      label: 'CODELISTCONSTANTS.MDNCode.P09080304.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080304.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0908030401',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030401.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090803040101',
      label: 'CODELISTCONSTANTS.MDNCode.P090803040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803040101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090803040102',
      label: 'CODELISTCONSTANTS.MDNCode.P090803040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803040102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090803040103',
      label: 'CODELISTCONSTANTS.MDNCode.P090803040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803040103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0908030402',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0908030403',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0908030499',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09080305',
      label: 'CODELISTCONSTANTS.MDNCode.P09080305.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080305.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0908030501',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030501.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090803050101',
      label: 'CODELISTCONSTANTS.MDNCode.P090803050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803050101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090803050102',
      label: 'CODELISTCONSTANTS.MDNCode.P090803050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090803050102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0908030502',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030502.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09080306',
      label: 'CODELISTCONSTANTS.MDNCode.P09080306.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080306.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0908030601',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030601.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0908030602',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030602.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0908030699',
      label: 'CODELISTCONSTANTS.MDNCode.P0908030699.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908030699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P090804',
      label: 'CODELISTCONSTANTS.MDNCode.P090804.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09080401',
      label: 'CODELISTCONSTANTS.MDNCode.P09080401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0908040101',
      label: 'CODELISTCONSTANTS.MDNCode.P0908040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908040101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090804010101',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804010102',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804010105',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010105.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804010106',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010106.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804010107',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010107.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0908040102',
      label: 'CODELISTCONSTANTS.MDNCode.P0908040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908040102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090804010201',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804010202',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804010205',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010205.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804010206',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010206.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804010207',
      label: 'CODELISTCONSTANTS.MDNCode.P090804010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804010207.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P09080402',
      label: 'CODELISTCONSTANTS.MDNCode.P09080402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09080403',
      label: 'CODELISTCONSTANTS.MDNCode.P09080403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080403.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0908040301',
      label: 'CODELISTCONSTANTS.MDNCode.P0908040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908040301.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090804030101',
      label: 'CODELISTCONSTANTS.MDNCode.P090804030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804030101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804030102',
      label: 'CODELISTCONSTANTS.MDNCode.P090804030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804030102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0908040302',
      label: 'CODELISTCONSTANTS.MDNCode.P0908040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908040302.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090804030201',
      label: 'CODELISTCONSTANTS.MDNCode.P090804030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804030201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804030202',
      label: 'CODELISTCONSTANTS.MDNCode.P090804030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804030202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P09080404',
      label: 'CODELISTCONSTANTS.MDNCode.P09080404.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09080405',
      label: 'CODELISTCONSTANTS.MDNCode.P09080405.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080405.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0908040501',
      label: 'CODELISTCONSTANTS.MDNCode.P0908040501.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908040501.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090804050101',
      label: 'CODELISTCONSTANTS.MDNCode.P090804050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804050101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804050102',
      label: 'CODELISTCONSTANTS.MDNCode.P090804050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804050102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804050199',
      label: 'CODELISTCONSTANTS.MDNCode.P090804050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804050199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0908040502',
      label: 'CODELISTCONSTANTS.MDNCode.P0908040502.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908040502.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090804050201',
      label: 'CODELISTCONSTANTS.MDNCode.P090804050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804050201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804050202',
      label: 'CODELISTCONSTANTS.MDNCode.P090804050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804050202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090804050299',
      label: 'CODELISTCONSTANTS.MDNCode.P090804050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090804050299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0908040503',
      label: 'CODELISTCONSTANTS.MDNCode.P0908040503.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908040503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0908040504',
      label: 'CODELISTCONSTANTS.MDNCode.P0908040504.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0908040504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09080406',
      label: 'CODELISTCONSTANTS.MDNCode.P09080406.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080406.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09080407',
      label: 'CODELISTCONSTANTS.MDNCode.P09080407.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09080407.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090805',
      label: 'CODELISTCONSTANTS.MDNCode.P090805.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090805.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090880',
      label: 'CODELISTCONSTANTS.MDNCode.P090880.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090880.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09088001',
      label: 'CODELISTCONSTANTS.MDNCode.P09088001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09088001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09088002',
      label: 'CODELISTCONSTANTS.MDNCode.P09088002.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09088002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09088003',
      label: 'CODELISTCONSTANTS.MDNCode.P09088003.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09088003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09088004',
      label: 'CODELISTCONSTANTS.MDNCode.P09088004.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09088004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09088005',
      label: 'CODELISTCONSTANTS.MDNCode.P09088005.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09088005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09088006',
      label: 'CODELISTCONSTANTS.MDNCode.P09088006.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09088006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09088007',
      label: 'CODELISTCONSTANTS.MDNCode.P09088007.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09088007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09088099',
      label: 'CODELISTCONSTANTS.MDNCode.P09088099.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09088099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090899',
      label: 'CODELISTCONSTANTS.MDNCode.P090899.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0909',
      label: 'CODELISTCONSTANTS.MDNCode.P0909.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P090903',
      label: 'CODELISTCONSTANTS.MDNCode.P090903.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09090301',
      label: 'CODELISTCONSTANTS.MDNCode.P09090301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09090301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0909030101',
      label: 'CODELISTCONSTANTS.MDNCode.P0909030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0909030102',
      label: 'CODELISTCONSTANTS.MDNCode.P0909030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0909030103',
      label: 'CODELISTCONSTANTS.MDNCode.P0909030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909030103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09090302',
      label: 'CODELISTCONSTANTS.MDNCode.P09090302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09090302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0909030201',
      label: 'CODELISTCONSTANTS.MDNCode.P0909030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909030201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090903020101',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090903020102',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090903020103',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090903020104',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020104.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090903020105',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020105.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090903020106',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020106.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020106.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0909030202',
      label: 'CODELISTCONSTANTS.MDNCode.P0909030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909030202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090903020201',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090903020202',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0909030203',
      label: 'CODELISTCONSTANTS.MDNCode.P0909030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909030203.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090903020301',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090903020399',
      label: 'CODELISTCONSTANTS.MDNCode.P090903020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090903020399.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090904',
      label: 'CODELISTCONSTANTS.MDNCode.P090904.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09090401',
      label: 'CODELISTCONSTANTS.MDNCode.P09090401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09090401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0909040101',
      label: 'CODELISTCONSTANTS.MDNCode.P0909040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909040101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0909040102',
      label: 'CODELISTCONSTANTS.MDNCode.P0909040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09090402',
      label: 'CODELISTCONSTANTS.MDNCode.P09090402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09090402.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0909040201',
      label: 'CODELISTCONSTANTS.MDNCode.P0909040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909040201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090904020101',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090904020102',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090904020103',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090904020104',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020104.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0909040202',
      label: 'CODELISTCONSTANTS.MDNCode.P0909040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909040202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090904020201',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090904020202',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0909040203',
      label: 'CODELISTCONSTANTS.MDNCode.P0909040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909040203.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090904020301',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090904020302',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0909040204',
      label: 'CODELISTCONSTANTS.MDNCode.P0909040204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909040204.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090904020401',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020401.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090904020499',
      label: 'CODELISTCONSTANTS.MDNCode.P090904020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090904020499.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090905',
      label: 'CODELISTCONSTANTS.MDNCode.P090905.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090905.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09090501',
      label: 'CODELISTCONSTANTS.MDNCode.P09090501.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09090501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09090502',
      label: 'CODELISTCONSTANTS.MDNCode.P09090502.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09090502.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0909050201',
      label: 'CODELISTCONSTANTS.MDNCode.P0909050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909050201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090905020101',
      label: 'CODELISTCONSTANTS.MDNCode.P090905020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090905020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090905020102',
      label: 'CODELISTCONSTANTS.MDNCode.P090905020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090905020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P0909050202',
      label: 'CODELISTCONSTANTS.MDNCode.P0909050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909050202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'P090905020201',
      label: 'CODELISTCONSTANTS.MDNCode.P090905020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090905020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090905020202',
      label: 'CODELISTCONSTANTS.MDNCode.P090905020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090905020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'P090906',
      label: 'CODELISTCONSTANTS.MDNCode.P090906.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090906.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090907',
      label: 'CODELISTCONSTANTS.MDNCode.P090907.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090907.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09090701',
      label: 'CODELISTCONSTANTS.MDNCode.P09090701.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09090701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09090702',
      label: 'CODELISTCONSTANTS.MDNCode.P09090702.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09090702.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0909070201',
      label: 'CODELISTCONSTANTS.MDNCode.P0909070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909070201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0909070202',
      label: 'CODELISTCONSTANTS.MDNCode.P0909070202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909070202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P090908',
      label: 'CODELISTCONSTANTS.MDNCode.P090908.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090908.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P090980',
      label: 'CODELISTCONSTANTS.MDNCode.P090980.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090980.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09098001',
      label: 'CODELISTCONSTANTS.MDNCode.P09098001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09098001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09098002',
      label: 'CODELISTCONSTANTS.MDNCode.P09098002.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09098002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09098003',
      label: 'CODELISTCONSTANTS.MDNCode.P09098003.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09098003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09098004',
      label: 'CODELISTCONSTANTS.MDNCode.P09098004.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09098004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09098006',
      label: 'CODELISTCONSTANTS.MDNCode.P09098006.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09098006.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0909800601',
      label: 'CODELISTCONSTANTS.MDNCode.P0909800601.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909800601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0909800602',
      label: 'CODELISTCONSTANTS.MDNCode.P0909800602.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0909800602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09098099',
      label: 'CODELISTCONSTANTS.MDNCode.P09098099.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09098099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P090999',
      label: 'CODELISTCONSTANTS.MDNCode.P090999.label',
      description: 'CODELISTCONSTANTS.MDNCode.P090999.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0910',
      label: 'CODELISTCONSTANTS.MDNCode.P0910.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0910.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P0912',
      label: 'CODELISTCONSTANTS.MDNCode.P0912.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0912.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P091201',
      label: 'CODELISTCONSTANTS.MDNCode.P091201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09120101',
      label: 'CODELISTCONSTANTS.MDNCode.P09120101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120102',
      label: 'CODELISTCONSTANTS.MDNCode.P09120102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P091202',
      label: 'CODELISTCONSTANTS.MDNCode.P091202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09120201',
      label: 'CODELISTCONSTANTS.MDNCode.P09120201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120202',
      label: 'CODELISTCONSTANTS.MDNCode.P09120202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120299',
      label: 'CODELISTCONSTANTS.MDNCode.P09120299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P091203',
      label: 'CODELISTCONSTANTS.MDNCode.P091203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09120301',
      label: 'CODELISTCONSTANTS.MDNCode.P09120301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120302',
      label: 'CODELISTCONSTANTS.MDNCode.P09120302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120399',
      label: 'CODELISTCONSTANTS.MDNCode.P09120399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P091204',
      label: 'CODELISTCONSTANTS.MDNCode.P091204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09120401',
      label: 'CODELISTCONSTANTS.MDNCode.P09120401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'P0912040101',
      label: 'CODELISTCONSTANTS.MDNCode.P0912040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0912040101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P0912040102',
      label: 'CODELISTCONSTANTS.MDNCode.P0912040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0912040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'P09120402',
      label: 'CODELISTCONSTANTS.MDNCode.P09120402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120403',
      label: 'CODELISTCONSTANTS.MDNCode.P09120403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120499',
      label: 'CODELISTCONSTANTS.MDNCode.P09120499.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P091205',
      label: 'CODELISTCONSTANTS.MDNCode.P091205.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091205.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09120501',
      label: 'CODELISTCONSTANTS.MDNCode.P09120501.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120502',
      label: 'CODELISTCONSTANTS.MDNCode.P09120502.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120503',
      label: 'CODELISTCONSTANTS.MDNCode.P09120503.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120504',
      label: 'CODELISTCONSTANTS.MDNCode.P09120504.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120504.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120599',
      label: 'CODELISTCONSTANTS.MDNCode.P09120599.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P091206',
      label: 'CODELISTCONSTANTS.MDNCode.P091206.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091206.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'P09120601',
      label: 'CODELISTCONSTANTS.MDNCode.P09120601.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120602',
      label: 'CODELISTCONSTANTS.MDNCode.P09120602.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120603',
      label: 'CODELISTCONSTANTS.MDNCode.P09120603.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120604',
      label: 'CODELISTCONSTANTS.MDNCode.P09120604.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120604.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120605',
      label: 'CODELISTCONSTANTS.MDNCode.P09120605.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120605.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120606',
      label: 'CODELISTCONSTANTS.MDNCode.P09120606.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120606.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P09120699',
      label: 'CODELISTCONSTANTS.MDNCode.P09120699.label',
      description: 'CODELISTCONSTANTS.MDNCode.P09120699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'P091280',
      label: 'CODELISTCONSTANTS.MDNCode.P091280.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P091299',
      label: 'CODELISTCONSTANTS.MDNCode.P091299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0913',
      label: 'CODELISTCONSTANTS.MDNCode.P0913.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0913.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P091301',
      label: 'CODELISTCONSTANTS.MDNCode.P091301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P091302',
      label: 'CODELISTCONSTANTS.MDNCode.P091302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P091303',
      label: 'CODELISTCONSTANTS.MDNCode.P091303.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P091304',
      label: 'CODELISTCONSTANTS.MDNCode.P091304.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P091305',
      label: 'CODELISTCONSTANTS.MDNCode.P091305.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P091399',
      label: 'CODELISTCONSTANTS.MDNCode.P091399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P091399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0990',
      label: 'CODELISTCONSTANTS.MDNCode.P0990.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0990.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P099001',
      label: 'CODELISTCONSTANTS.MDNCode.P099001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P099001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P099002',
      label: 'CODELISTCONSTANTS.MDNCode.P099002.label',
      description: 'CODELISTCONSTANTS.MDNCode.P099002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P099003',
      label: 'CODELISTCONSTANTS.MDNCode.P099003.label',
      description: 'CODELISTCONSTANTS.MDNCode.P099003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P0999',
      label: 'CODELISTCONSTANTS.MDNCode.P0999.label',
      description: 'CODELISTCONSTANTS.MDNCode.P0999.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P10',
      label: 'CODELISTCONSTANTS.MDNCode.P10.label',
      description: 'CODELISTCONSTANTS.MDNCode.P10.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P1001',
      label: 'CODELISTCONSTANTS.MDNCode.P1001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P1001.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P1002',
      label: 'CODELISTCONSTANTS.MDNCode.P1002.label',
      description: 'CODELISTCONSTANTS.MDNCode.P1002.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P1099',
      label: 'CODELISTCONSTANTS.MDNCode.P1099.label',
      description: 'CODELISTCONSTANTS.MDNCode.P1099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'P90',
      label: 'CODELISTCONSTANTS.MDNCode.P90.label',
      description: 'CODELISTCONSTANTS.MDNCode.P90.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'P9001',
      label: 'CODELISTCONSTANTS.MDNCode.P9001.label',
      description: 'CODELISTCONSTANTS.MDNCode.P9001.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P900101',
      label: 'CODELISTCONSTANTS.MDNCode.P900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900102',
      label: 'CODELISTCONSTANTS.MDNCode.P900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900103',
      label: 'CODELISTCONSTANTS.MDNCode.P900103.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900104',
      label: 'CODELISTCONSTANTS.MDNCode.P900104.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900105',
      label: 'CODELISTCONSTANTS.MDNCode.P900105.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900106',
      label: 'CODELISTCONSTANTS.MDNCode.P900106.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900199',
      label: 'CODELISTCONSTANTS.MDNCode.P900199.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P9002',
      label: 'CODELISTCONSTANTS.MDNCode.P9002.label',
      description: 'CODELISTCONSTANTS.MDNCode.P9002.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P900201',
      label: 'CODELISTCONSTANTS.MDNCode.P900201.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900202',
      label: 'CODELISTCONSTANTS.MDNCode.P900202.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900203',
      label: 'CODELISTCONSTANTS.MDNCode.P900203.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900204',
      label: 'CODELISTCONSTANTS.MDNCode.P900204.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900205',
      label: 'CODELISTCONSTANTS.MDNCode.P900205.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900206',
      label: 'CODELISTCONSTANTS.MDNCode.P900206.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900299',
      label: 'CODELISTCONSTANTS.MDNCode.P900299.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P9003',
      label: 'CODELISTCONSTANTS.MDNCode.P9003.label',
      description: 'CODELISTCONSTANTS.MDNCode.P9003.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P900301',
      label: 'CODELISTCONSTANTS.MDNCode.P900301.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900302',
      label: 'CODELISTCONSTANTS.MDNCode.P900302.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900399',
      label: 'CODELISTCONSTANTS.MDNCode.P900399.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P9004',
      label: 'CODELISTCONSTANTS.MDNCode.P9004.label',
      description: 'CODELISTCONSTANTS.MDNCode.P9004.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'P900401',
      label: 'CODELISTCONSTANTS.MDNCode.P900401.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900402',
      label: 'CODELISTCONSTANTS.MDNCode.P900402.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900403',
      label: 'CODELISTCONSTANTS.MDNCode.P900403.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P900404',
      label: 'CODELISTCONSTANTS.MDNCode.P900404.label',
      description: 'CODELISTCONSTANTS.MDNCode.P900404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'P9099',
      label: 'CODELISTCONSTANTS.MDNCode.P9099.label',
      description: 'CODELISTCONSTANTS.MDNCode.P9099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Q',
      label: 'CODELISTCONSTANTS.MDNCode.Q.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'Q01',
      label: 'CODELISTCONSTANTS.MDNCode.Q01.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Q0101',
      label: 'CODELISTCONSTANTS.MDNCode.Q0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q010101',
      label: 'CODELISTCONSTANTS.MDNCode.Q010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q01010101',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010102',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Q0101010201',
      label: 'CODELISTCONSTANTS.MDNCode.Q0101010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0101010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0101010202',
      label: 'CODELISTCONSTANTS.MDNCode.Q0101010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0101010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0101010203',
      label: 'CODELISTCONSTANTS.MDNCode.Q0101010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0101010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0101010204',
      label: 'CODELISTCONSTANTS.MDNCode.Q0101010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0101010204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0101010299',
      label: 'CODELISTCONSTANTS.MDNCode.Q0101010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0101010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q01010103',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010104',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010105',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010106',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010199',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q010102',
      label: 'CODELISTCONSTANTS.MDNCode.Q010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q01010201',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010202',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010203',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010299',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q010103',
      label: 'CODELISTCONSTANTS.MDNCode.Q010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q01010301',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010399',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q010104',
      label: 'CODELISTCONSTANTS.MDNCode.Q010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q01010401',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010402',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01010403',
      label: 'CODELISTCONSTANTS.MDNCode.Q01010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01010403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q010105',
      label: 'CODELISTCONSTANTS.MDNCode.Q010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010199',
      label: 'CODELISTCONSTANTS.MDNCode.Q010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0102',
      label: 'CODELISTCONSTANTS.MDNCode.Q0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q010201',
      label: 'CODELISTCONSTANTS.MDNCode.Q010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q01020101',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020102',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020103',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020104',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020199',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q010202',
      label: 'CODELISTCONSTANTS.MDNCode.Q010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q01020201',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020202',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020203',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020299',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q010204',
      label: 'CODELISTCONSTANTS.MDNCode.Q010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010205',
      label: 'CODELISTCONSTANTS.MDNCode.Q010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010205.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q01020501',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020501.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Q0102050101',
      label: 'CODELISTCONSTANTS.MDNCode.Q0102050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0102050101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0102050102',
      label: 'CODELISTCONSTANTS.MDNCode.Q0102050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0102050102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0102050199',
      label: 'CODELISTCONSTANTS.MDNCode.Q0102050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0102050199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q01020502',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q010206',
      label: 'CODELISTCONSTANTS.MDNCode.Q010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010206.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q01020601',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020602',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020603',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020604',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020604.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020604.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q01020605',
      label: 'CODELISTCONSTANTS.MDNCode.Q01020605.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q01020605.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q010207',
      label: 'CODELISTCONSTANTS.MDNCode.Q010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010207.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010280',
      label: 'CODELISTCONSTANTS.MDNCode.Q010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010299',
      label: 'CODELISTCONSTANTS.MDNCode.Q010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0103',
      label: 'CODELISTCONSTANTS.MDNCode.Q0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q010301',
      label: 'CODELISTCONSTANTS.MDNCode.Q010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010302',
      label: 'CODELISTCONSTANTS.MDNCode.Q010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010303',
      label: 'CODELISTCONSTANTS.MDNCode.Q010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010304',
      label: 'CODELISTCONSTANTS.MDNCode.Q010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010305',
      label: 'CODELISTCONSTANTS.MDNCode.Q010305.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010399',
      label: 'CODELISTCONSTANTS.MDNCode.Q010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0104',
      label: 'CODELISTCONSTANTS.MDNCode.Q0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q010401',
      label: 'CODELISTCONSTANTS.MDNCode.Q010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010402',
      label: 'CODELISTCONSTANTS.MDNCode.Q010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010403',
      label: 'CODELISTCONSTANTS.MDNCode.Q010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010404',
      label: 'CODELISTCONSTANTS.MDNCode.Q010404.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010405',
      label: 'CODELISTCONSTANTS.MDNCode.Q010405.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010405.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010406',
      label: 'CODELISTCONSTANTS.MDNCode.Q010406.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010406.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010407',
      label: 'CODELISTCONSTANTS.MDNCode.Q010407.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010407.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010499',
      label: 'CODELISTCONSTANTS.MDNCode.Q010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0105',
      label: 'CODELISTCONSTANTS.MDNCode.Q0105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0105.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q010501',
      label: 'CODELISTCONSTANTS.MDNCode.Q010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010502',
      label: 'CODELISTCONSTANTS.MDNCode.Q010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010503',
      label: 'CODELISTCONSTANTS.MDNCode.Q010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010503.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010504',
      label: 'CODELISTCONSTANTS.MDNCode.Q010504.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010504.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010505',
      label: 'CODELISTCONSTANTS.MDNCode.Q010505.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010505.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010506',
      label: 'CODELISTCONSTANTS.MDNCode.Q010506.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010506.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010507',
      label: 'CODELISTCONSTANTS.MDNCode.Q010507.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010507.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010508',
      label: 'CODELISTCONSTANTS.MDNCode.Q010508.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010508.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010599',
      label: 'CODELISTCONSTANTS.MDNCode.Q010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0106',
      label: 'CODELISTCONSTANTS.MDNCode.Q0106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0106.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q010601',
      label: 'CODELISTCONSTANTS.MDNCode.Q010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q010699',
      label: 'CODELISTCONSTANTS.MDNCode.Q010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q010699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0190',
      label: 'CODELISTCONSTANTS.MDNCode.Q0190.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0190.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q019001',
      label: 'CODELISTCONSTANTS.MDNCode.Q019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q019001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q019002',
      label: 'CODELISTCONSTANTS.MDNCode.Q019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q019002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q019003',
      label: 'CODELISTCONSTANTS.MDNCode.Q019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q019003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q019004',
      label: 'CODELISTCONSTANTS.MDNCode.Q019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q019004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q019005',
      label: 'CODELISTCONSTANTS.MDNCode.Q019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q019005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q019006',
      label: 'CODELISTCONSTANTS.MDNCode.Q019006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q019006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q019007',
      label: 'CODELISTCONSTANTS.MDNCode.Q019007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q019007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q019008',
      label: 'CODELISTCONSTANTS.MDNCode.Q019008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q019008.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0199',
      label: 'CODELISTCONSTANTS.MDNCode.Q0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Q02',
      label: 'CODELISTCONSTANTS.MDNCode.Q02.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Q0201',
      label: 'CODELISTCONSTANTS.MDNCode.Q0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q020101',
      label: 'CODELISTCONSTANTS.MDNCode.Q020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02010101',
      label: 'CODELISTCONSTANTS.MDNCode.Q02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02010102',
      label: 'CODELISTCONSTANTS.MDNCode.Q02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02010103',
      label: 'CODELISTCONSTANTS.MDNCode.Q02010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02010199',
      label: 'CODELISTCONSTANTS.MDNCode.Q02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q020102',
      label: 'CODELISTCONSTANTS.MDNCode.Q020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020199',
      label: 'CODELISTCONSTANTS.MDNCode.Q020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0202',
      label: 'CODELISTCONSTANTS.MDNCode.Q0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q020201',
      label: 'CODELISTCONSTANTS.MDNCode.Q020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020202',
      label: 'CODELISTCONSTANTS.MDNCode.Q020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020203',
      label: 'CODELISTCONSTANTS.MDNCode.Q020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020204',
      label: 'CODELISTCONSTANTS.MDNCode.Q020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020299',
      label: 'CODELISTCONSTANTS.MDNCode.Q020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0203',
      label: 'CODELISTCONSTANTS.MDNCode.Q0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q020301',
      label: 'CODELISTCONSTANTS.MDNCode.Q020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02030101',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02030199',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q020302',
      label: 'CODELISTCONSTANTS.MDNCode.Q020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02030201',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02030202',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02030203',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02030204',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02030205',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02030206',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030206.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02030299',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q020303',
      label: 'CODELISTCONSTANTS.MDNCode.Q020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02030301',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02030399',
      label: 'CODELISTCONSTANTS.MDNCode.Q02030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02030399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q0204',
      label: 'CODELISTCONSTANTS.MDNCode.Q0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0204.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q020401',
      label: 'CODELISTCONSTANTS.MDNCode.Q020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020402',
      label: 'CODELISTCONSTANTS.MDNCode.Q020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020403',
      label: 'CODELISTCONSTANTS.MDNCode.Q020403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020403.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02040301',
      label: 'CODELISTCONSTANTS.MDNCode.Q02040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02040301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02040302',
      label: 'CODELISTCONSTANTS.MDNCode.Q02040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02040302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02040399',
      label: 'CODELISTCONSTANTS.MDNCode.Q02040399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02040399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q020499',
      label: 'CODELISTCONSTANTS.MDNCode.Q020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0205',
      label: 'CODELISTCONSTANTS.MDNCode.Q0205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0205.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q020501',
      label: 'CODELISTCONSTANTS.MDNCode.Q020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020502',
      label: 'CODELISTCONSTANTS.MDNCode.Q020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020503',
      label: 'CODELISTCONSTANTS.MDNCode.Q020503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020503.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020504',
      label: 'CODELISTCONSTANTS.MDNCode.Q020504.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020504.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020599',
      label: 'CODELISTCONSTANTS.MDNCode.Q020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0206',
      label: 'CODELISTCONSTANTS.MDNCode.Q0206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0206.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q020601',
      label: 'CODELISTCONSTANTS.MDNCode.Q020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020680',
      label: 'CODELISTCONSTANTS.MDNCode.Q020680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020680.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020699',
      label: 'CODELISTCONSTANTS.MDNCode.Q020699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0207',
      label: 'CODELISTCONSTANTS.MDNCode.Q0207.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0207.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q020701',
      label: 'CODELISTCONSTANTS.MDNCode.Q020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020702',
      label: 'CODELISTCONSTANTS.MDNCode.Q020702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020703',
      label: 'CODELISTCONSTANTS.MDNCode.Q020703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020704',
      label: 'CODELISTCONSTANTS.MDNCode.Q020704.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020704.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020705',
      label: 'CODELISTCONSTANTS.MDNCode.Q020705.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020705.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020706',
      label: 'CODELISTCONSTANTS.MDNCode.Q020706.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020706.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q020799',
      label: 'CODELISTCONSTANTS.MDNCode.Q020799.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q020799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0208',
      label: 'CODELISTCONSTANTS.MDNCode.Q0208.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0208.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Q0209',
      label: 'CODELISTCONSTANTS.MDNCode.Q0209.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0209.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Q0210',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q021001',
      label: 'CODELISTCONSTANTS.MDNCode.Q021001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021001.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02100101',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Q0210010101',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210010102',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210010103',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210010199',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q02100102',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Q0210010201',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210010202',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210010203',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210010299',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q021002',
      label: 'CODELISTCONSTANTS.MDNCode.Q021002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021002.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02100201',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02100202',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02100203',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02100299',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q021003',
      label: 'CODELISTCONSTANTS.MDNCode.Q021003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021003.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02100301',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Q0210030101',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210030102',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210030103',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210030103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210030199',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q02100302',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Q0210030201',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210030202',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210030203',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210030203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q0210030299',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210030299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q021004',
      label: 'CODELISTCONSTANTS.MDNCode.Q021004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021004.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q02100401',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Q0210040101',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210040101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'Q021004010101',
      label: 'CODELISTCONSTANTS.MDNCode.Q021004010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021004010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Q021004010102',
      label: 'CODELISTCONSTANTS.MDNCode.Q021004010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021004010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Q0210040102',
      label: 'CODELISTCONSTANTS.MDNCode.Q0210040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0210040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Q02100402',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q02100499',
      label: 'CODELISTCONSTANTS.MDNCode.Q02100499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q02100499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q021099',
      label: 'CODELISTCONSTANTS.MDNCode.Q021099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0211',
      label: 'CODELISTCONSTANTS.MDNCode.Q0211.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0211.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q021101',
      label: 'CODELISTCONSTANTS.MDNCode.Q021101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021102',
      label: 'CODELISTCONSTANTS.MDNCode.Q021102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021103',
      label: 'CODELISTCONSTANTS.MDNCode.Q021103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021104',
      label: 'CODELISTCONSTANTS.MDNCode.Q021104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021105',
      label: 'CODELISTCONSTANTS.MDNCode.Q021105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021106',
      label: 'CODELISTCONSTANTS.MDNCode.Q021106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021107',
      label: 'CODELISTCONSTANTS.MDNCode.Q021107.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021107.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021108',
      label: 'CODELISTCONSTANTS.MDNCode.Q021108.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021108.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021109',
      label: 'CODELISTCONSTANTS.MDNCode.Q021109.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021109.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021110',
      label: 'CODELISTCONSTANTS.MDNCode.Q021110.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021110.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021111',
      label: 'CODELISTCONSTANTS.MDNCode.Q021111.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021111.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021112',
      label: 'CODELISTCONSTANTS.MDNCode.Q021112.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021112.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021113',
      label: 'CODELISTCONSTANTS.MDNCode.Q021113.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021113.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q021199',
      label: 'CODELISTCONSTANTS.MDNCode.Q021199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q021199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0290',
      label: 'CODELISTCONSTANTS.MDNCode.Q0290.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0290.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q029001',
      label: 'CODELISTCONSTANTS.MDNCode.Q029001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q029001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q029002',
      label: 'CODELISTCONSTANTS.MDNCode.Q029002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q029002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q029003',
      label: 'CODELISTCONSTANTS.MDNCode.Q029003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q029003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0299',
      label: 'CODELISTCONSTANTS.MDNCode.Q0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Q03',
      label: 'CODELISTCONSTANTS.MDNCode.Q03.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Q0301',
      label: 'CODELISTCONSTANTS.MDNCode.Q0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q030101',
      label: 'CODELISTCONSTANTS.MDNCode.Q030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q03010101',
      label: 'CODELISTCONSTANTS.MDNCode.Q03010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q03010199',
      label: 'CODELISTCONSTANTS.MDNCode.Q03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q030102',
      label: 'CODELISTCONSTANTS.MDNCode.Q030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q03010201',
      label: 'CODELISTCONSTANTS.MDNCode.Q03010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q03010299',
      label: 'CODELISTCONSTANTS.MDNCode.Q03010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q030103',
      label: 'CODELISTCONSTANTS.MDNCode.Q030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030199',
      label: 'CODELISTCONSTANTS.MDNCode.Q030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0302',
      label: 'CODELISTCONSTANTS.MDNCode.Q0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q030201',
      label: 'CODELISTCONSTANTS.MDNCode.Q030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030299',
      label: 'CODELISTCONSTANTS.MDNCode.Q030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0303',
      label: 'CODELISTCONSTANTS.MDNCode.Q0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0303.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q030301',
      label: 'CODELISTCONSTANTS.MDNCode.Q030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030302',
      label: 'CODELISTCONSTANTS.MDNCode.Q030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030303',
      label: 'CODELISTCONSTANTS.MDNCode.Q030303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030304',
      label: 'CODELISTCONSTANTS.MDNCode.Q030304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030305',
      label: 'CODELISTCONSTANTS.MDNCode.Q030305.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030306',
      label: 'CODELISTCONSTANTS.MDNCode.Q030306.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030306.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030307',
      label: 'CODELISTCONSTANTS.MDNCode.Q030307.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030307.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030308',
      label: 'CODELISTCONSTANTS.MDNCode.Q030308.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030308.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030309',
      label: 'CODELISTCONSTANTS.MDNCode.Q030309.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030309.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030310',
      label: 'CODELISTCONSTANTS.MDNCode.Q030310.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030310.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030311',
      label: 'CODELISTCONSTANTS.MDNCode.Q030311.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030311.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030312',
      label: 'CODELISTCONSTANTS.MDNCode.Q030312.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030312.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030313',
      label: 'CODELISTCONSTANTS.MDNCode.Q030313.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030313.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030314',
      label: 'CODELISTCONSTANTS.MDNCode.Q030314.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030314.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030315',
      label: 'CODELISTCONSTANTS.MDNCode.Q030315.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030315.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030316',
      label: 'CODELISTCONSTANTS.MDNCode.Q030316.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030316.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q030399',
      label: 'CODELISTCONSTANTS.MDNCode.Q030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0304',
      label: 'CODELISTCONSTANTS.MDNCode.Q0304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0304.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Q030401',
      label: 'CODELISTCONSTANTS.MDNCode.Q030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q03040101',
      label: 'CODELISTCONSTANTS.MDNCode.Q03040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03040101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q03040102',
      label: 'CODELISTCONSTANTS.MDNCode.Q03040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03040102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q03040199',
      label: 'CODELISTCONSTANTS.MDNCode.Q03040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q030402',
      label: 'CODELISTCONSTANTS.MDNCode.Q030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Q03040201',
      label: 'CODELISTCONSTANTS.MDNCode.Q03040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q03040299',
      label: 'CODELISTCONSTANTS.MDNCode.Q03040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q03040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Q030499',
      label: 'CODELISTCONSTANTS.MDNCode.Q030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q030499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Q0399',
      label: 'CODELISTCONSTANTS.MDNCode.Q0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Q0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R',
      label: 'CODELISTCONSTANTS.MDNCode.R.label',
      description: 'CODELISTCONSTANTS.MDNCode.R.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'R01',
      label: 'CODELISTCONSTANTS.MDNCode.R01.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'R0101',
      label: 'CODELISTCONSTANTS.MDNCode.R0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R010101',
      label: 'CODELISTCONSTANTS.MDNCode.R010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010102',
      label: 'CODELISTCONSTANTS.MDNCode.R010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010199',
      label: 'CODELISTCONSTANTS.MDNCode.R010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0102',
      label: 'CODELISTCONSTANTS.MDNCode.R0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R010201',
      label: 'CODELISTCONSTANTS.MDNCode.R010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01020101',
      label: 'CODELISTCONSTANTS.MDNCode.R01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01020102',
      label: 'CODELISTCONSTANTS.MDNCode.R01020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01020199',
      label: 'CODELISTCONSTANTS.MDNCode.R01020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R010202',
      label: 'CODELISTCONSTANTS.MDNCode.R010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010203',
      label: 'CODELISTCONSTANTS.MDNCode.R010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010280',
      label: 'CODELISTCONSTANTS.MDNCode.R010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010299',
      label: 'CODELISTCONSTANTS.MDNCode.R010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0103',
      label: 'CODELISTCONSTANTS.MDNCode.R0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R010301',
      label: 'CODELISTCONSTANTS.MDNCode.R010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01030101',
      label: 'CODELISTCONSTANTS.MDNCode.R01030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01030102',
      label: 'CODELISTCONSTANTS.MDNCode.R01030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R010302',
      label: 'CODELISTCONSTANTS.MDNCode.R010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01030201',
      label: 'CODELISTCONSTANTS.MDNCode.R01030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01030202',
      label: 'CODELISTCONSTANTS.MDNCode.R01030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01030202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R010380',
      label: 'CODELISTCONSTANTS.MDNCode.R010380.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010380.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01038001',
      label: 'CODELISTCONSTANTS.MDNCode.R01038001.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01038001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01038099',
      label: 'CODELISTCONSTANTS.MDNCode.R01038099.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01038099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R010399',
      label: 'CODELISTCONSTANTS.MDNCode.R010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0104',
      label: 'CODELISTCONSTANTS.MDNCode.R0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R010401',
      label: 'CODELISTCONSTANTS.MDNCode.R010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01040101',
      label: 'CODELISTCONSTANTS.MDNCode.R01040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01040101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01040102',
      label: 'CODELISTCONSTANTS.MDNCode.R01040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01040102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01040103',
      label: 'CODELISTCONSTANTS.MDNCode.R01040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01040103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01040199',
      label: 'CODELISTCONSTANTS.MDNCode.R01040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R010402',
      label: 'CODELISTCONSTANTS.MDNCode.R010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01040201',
      label: 'CODELISTCONSTANTS.MDNCode.R01040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01040202',
      label: 'CODELISTCONSTANTS.MDNCode.R01040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01040202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01040203',
      label: 'CODELISTCONSTANTS.MDNCode.R01040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01040203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01040299',
      label: 'CODELISTCONSTANTS.MDNCode.R01040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R010480',
      label: 'CODELISTCONSTANTS.MDNCode.R010480.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010480.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010499',
      label: 'CODELISTCONSTANTS.MDNCode.R010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0105',
      label: 'CODELISTCONSTANTS.MDNCode.R0105.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R010501',
      label: 'CODELISTCONSTANTS.MDNCode.R010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01050101',
      label: 'CODELISTCONSTANTS.MDNCode.R01050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01050101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'R0105010101',
      label: 'CODELISTCONSTANTS.MDNCode.R0105010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R0105010102',
      label: 'CODELISTCONSTANTS.MDNCode.R0105010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R01050102',
      label: 'CODELISTCONSTANTS.MDNCode.R01050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01050102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'R0105010201',
      label: 'CODELISTCONSTANTS.MDNCode.R0105010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R0105010202',
      label: 'CODELISTCONSTANTS.MDNCode.R0105010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R010502',
      label: 'CODELISTCONSTANTS.MDNCode.R010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01050201',
      label: 'CODELISTCONSTANTS.MDNCode.R01050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01050201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'R0105020101',
      label: 'CODELISTCONSTANTS.MDNCode.R0105020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R0105020102',
      label: 'CODELISTCONSTANTS.MDNCode.R0105020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R01050202',
      label: 'CODELISTCONSTANTS.MDNCode.R01050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01050202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'R0105020201',
      label: 'CODELISTCONSTANTS.MDNCode.R0105020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R0105020202',
      label: 'CODELISTCONSTANTS.MDNCode.R0105020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0105020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R010503',
      label: 'CODELISTCONSTANTS.MDNCode.R010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010503.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010580',
      label: 'CODELISTCONSTANTS.MDNCode.R010580.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010580.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R01058001',
      label: 'CODELISTCONSTANTS.MDNCode.R01058001.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01058001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01058002',
      label: 'CODELISTCONSTANTS.MDNCode.R01058002.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01058002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R01058099',
      label: 'CODELISTCONSTANTS.MDNCode.R01058099.label',
      description: 'CODELISTCONSTANTS.MDNCode.R01058099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R010599',
      label: 'CODELISTCONSTANTS.MDNCode.R010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0106',
      label: 'CODELISTCONSTANTS.MDNCode.R0106.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0106.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R010601',
      label: 'CODELISTCONSTANTS.MDNCode.R010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010602',
      label: 'CODELISTCONSTANTS.MDNCode.R010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010603',
      label: 'CODELISTCONSTANTS.MDNCode.R010603.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010604',
      label: 'CODELISTCONSTANTS.MDNCode.R010604.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010604.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R010699',
      label: 'CODELISTCONSTANTS.MDNCode.R010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.R010699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0180',
      label: 'CODELISTCONSTANTS.MDNCode.R0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0180.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R018001',
      label: 'CODELISTCONSTANTS.MDNCode.R018001.label',
      description: 'CODELISTCONSTANTS.MDNCode.R018001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R018002',
      label: 'CODELISTCONSTANTS.MDNCode.R018002.label',
      description: 'CODELISTCONSTANTS.MDNCode.R018002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R018003',
      label: 'CODELISTCONSTANTS.MDNCode.R018003.label',
      description: 'CODELISTCONSTANTS.MDNCode.R018003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R018004',
      label: 'CODELISTCONSTANTS.MDNCode.R018004.label',
      description: 'CODELISTCONSTANTS.MDNCode.R018004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R018099',
      label: 'CODELISTCONSTANTS.MDNCode.R018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.R018099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0199',
      label: 'CODELISTCONSTANTS.MDNCode.R0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R02',
      label: 'CODELISTCONSTANTS.MDNCode.R02.label',
      description: 'CODELISTCONSTANTS.MDNCode.R02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'R0201',
      label: 'CODELISTCONSTANTS.MDNCode.R0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R020101',
      label: 'CODELISTCONSTANTS.MDNCode.R020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R02010101',
      label: 'CODELISTCONSTANTS.MDNCode.R02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R02010102',
      label: 'CODELISTCONSTANTS.MDNCode.R02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R02010199',
      label: 'CODELISTCONSTANTS.MDNCode.R02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R020102',
      label: 'CODELISTCONSTANTS.MDNCode.R020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020103',
      label: 'CODELISTCONSTANTS.MDNCode.R020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020104',
      label: 'CODELISTCONSTANTS.MDNCode.R020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R02010401',
      label: 'CODELISTCONSTANTS.MDNCode.R02010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.R02010401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R02010402',
      label: 'CODELISTCONSTANTS.MDNCode.R02010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.R02010402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R020106',
      label: 'CODELISTCONSTANTS.MDNCode.R020106.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020107',
      label: 'CODELISTCONSTANTS.MDNCode.R020107.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020107.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020108',
      label: 'CODELISTCONSTANTS.MDNCode.R020108.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020108.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020109',
      label: 'CODELISTCONSTANTS.MDNCode.R020109.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020109.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020199',
      label: 'CODELISTCONSTANTS.MDNCode.R020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0202',
      label: 'CODELISTCONSTANTS.MDNCode.R0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R020201',
      label: 'CODELISTCONSTANTS.MDNCode.R020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020202',
      label: 'CODELISTCONSTANTS.MDNCode.R020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020299',
      label: 'CODELISTCONSTANTS.MDNCode.R020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0203',
      label: 'CODELISTCONSTANTS.MDNCode.R0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R020301',
      label: 'CODELISTCONSTANTS.MDNCode.R020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020302',
      label: 'CODELISTCONSTANTS.MDNCode.R020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R020399',
      label: 'CODELISTCONSTANTS.MDNCode.R020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.R020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0280',
      label: 'CODELISTCONSTANTS.MDNCode.R0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0280.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R028001',
      label: 'CODELISTCONSTANTS.MDNCode.R028001.label',
      description: 'CODELISTCONSTANTS.MDNCode.R028001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R028002',
      label: 'CODELISTCONSTANTS.MDNCode.R028002.label',
      description: 'CODELISTCONSTANTS.MDNCode.R028002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R028003',
      label: 'CODELISTCONSTANTS.MDNCode.R028003.label',
      description: 'CODELISTCONSTANTS.MDNCode.R028003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R028099',
      label: 'CODELISTCONSTANTS.MDNCode.R028099.label',
      description: 'CODELISTCONSTANTS.MDNCode.R028099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R03',
      label: 'CODELISTCONSTANTS.MDNCode.R03.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'R0301',
      label: 'CODELISTCONSTANTS.MDNCode.R0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R030101',
      label: 'CODELISTCONSTANTS.MDNCode.R030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R03010101',
      label: 'CODELISTCONSTANTS.MDNCode.R03010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010102',
      label: 'CODELISTCONSTANTS.MDNCode.R03010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'R0301010201',
      label: 'CODELISTCONSTANTS.MDNCode.R0301010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0301010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R0301010202',
      label: 'CODELISTCONSTANTS.MDNCode.R0301010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0301010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R03010104',
      label: 'CODELISTCONSTANTS.MDNCode.R03010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'R0301010401',
      label: 'CODELISTCONSTANTS.MDNCode.R0301010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0301010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R0301010402',
      label: 'CODELISTCONSTANTS.MDNCode.R0301010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0301010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R03010180',
      label: 'CODELISTCONSTANTS.MDNCode.R03010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010199',
      label: 'CODELISTCONSTANTS.MDNCode.R03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R030102',
      label: 'CODELISTCONSTANTS.MDNCode.R030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R03010201',
      label: 'CODELISTCONSTANTS.MDNCode.R03010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010202',
      label: 'CODELISTCONSTANTS.MDNCode.R03010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010203',
      label: 'CODELISTCONSTANTS.MDNCode.R03010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010203.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'R0301020301',
      label: 'CODELISTCONSTANTS.MDNCode.R0301020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0301020301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R0301020302',
      label: 'CODELISTCONSTANTS.MDNCode.R0301020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0301020302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R03010204',
      label: 'CODELISTCONSTANTS.MDNCode.R03010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010205',
      label: 'CODELISTCONSTANTS.MDNCode.R03010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010206',
      label: 'CODELISTCONSTANTS.MDNCode.R03010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010206.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010207',
      label: 'CODELISTCONSTANTS.MDNCode.R03010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010207.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010280',
      label: 'CODELISTCONSTANTS.MDNCode.R03010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010299',
      label: 'CODELISTCONSTANTS.MDNCode.R03010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R030103',
      label: 'CODELISTCONSTANTS.MDNCode.R030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R03010301',
      label: 'CODELISTCONSTANTS.MDNCode.R03010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010302',
      label: 'CODELISTCONSTANTS.MDNCode.R03010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010303',
      label: 'CODELISTCONSTANTS.MDNCode.R03010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010304',
      label: 'CODELISTCONSTANTS.MDNCode.R03010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010380',
      label: 'CODELISTCONSTANTS.MDNCode.R03010380.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03010399',
      label: 'CODELISTCONSTANTS.MDNCode.R03010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R030180',
      label: 'CODELISTCONSTANTS.MDNCode.R030180.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R030199',
      label: 'CODELISTCONSTANTS.MDNCode.R030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0302',
      label: 'CODELISTCONSTANTS.MDNCode.R0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R030201',
      label: 'CODELISTCONSTANTS.MDNCode.R030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R03020101',
      label: 'CODELISTCONSTANTS.MDNCode.R03020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03020102',
      label: 'CODELISTCONSTANTS.MDNCode.R03020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03020199',
      label: 'CODELISTCONSTANTS.MDNCode.R03020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R030202',
      label: 'CODELISTCONSTANTS.MDNCode.R030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R03020201',
      label: 'CODELISTCONSTANTS.MDNCode.R03020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R03020202',
      label: 'CODELISTCONSTANTS.MDNCode.R03020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R03020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R030280',
      label: 'CODELISTCONSTANTS.MDNCode.R030280.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R030299',
      label: 'CODELISTCONSTANTS.MDNCode.R030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R04',
      label: 'CODELISTCONSTANTS.MDNCode.R04.label',
      description: 'CODELISTCONSTANTS.MDNCode.R04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'R0401',
      label: 'CODELISTCONSTANTS.MDNCode.R0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0401.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R040101',
      label: 'CODELISTCONSTANTS.MDNCode.R040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R040101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R040102',
      label: 'CODELISTCONSTANTS.MDNCode.R040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R040102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R040199',
      label: 'CODELISTCONSTANTS.MDNCode.R040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R040199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0402',
      label: 'CODELISTCONSTANTS.MDNCode.R0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0402.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R040201',
      label: 'CODELISTCONSTANTS.MDNCode.R040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R040201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R040299',
      label: 'CODELISTCONSTANTS.MDNCode.R040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R040299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0490',
      label: 'CODELISTCONSTANTS.MDNCode.R0490.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0490.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R049001',
      label: 'CODELISTCONSTANTS.MDNCode.R049001.label',
      description: 'CODELISTCONSTANTS.MDNCode.R049001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0499',
      label: 'CODELISTCONSTANTS.MDNCode.R0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R05',
      label: 'CODELISTCONSTANTS.MDNCode.R05.label',
      description: 'CODELISTCONSTANTS.MDNCode.R05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'R0501',
      label: 'CODELISTCONSTANTS.MDNCode.R0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0501.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R050101',
      label: 'CODELISTCONSTANTS.MDNCode.R050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R050101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R05010101',
      label: 'CODELISTCONSTANTS.MDNCode.R05010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R05010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R05010102',
      label: 'CODELISTCONSTANTS.MDNCode.R05010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R05010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R050102',
      label: 'CODELISTCONSTANTS.MDNCode.R050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R050102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R050103',
      label: 'CODELISTCONSTANTS.MDNCode.R050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.R050103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R05010301',
      label: 'CODELISTCONSTANTS.MDNCode.R05010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.R05010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R05010302',
      label: 'CODELISTCONSTANTS.MDNCode.R05010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.R05010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R05010399',
      label: 'CODELISTCONSTANTS.MDNCode.R05010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.R05010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R0502',
      label: 'CODELISTCONSTANTS.MDNCode.R0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0502.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R050201',
      label: 'CODELISTCONSTANTS.MDNCode.R050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R050201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R050280',
      label: 'CODELISTCONSTANTS.MDNCode.R050280.label',
      description: 'CODELISTCONSTANTS.MDNCode.R050280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R050299',
      label: 'CODELISTCONSTANTS.MDNCode.R050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R050299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0580',
      label: 'CODELISTCONSTANTS.MDNCode.R0580.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0580.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R0599',
      label: 'CODELISTCONSTANTS.MDNCode.R0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R06',
      label: 'CODELISTCONSTANTS.MDNCode.R06.label',
      description: 'CODELISTCONSTANTS.MDNCode.R06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'R0601',
      label: 'CODELISTCONSTANTS.MDNCode.R0601.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0601.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R060101',
      label: 'CODELISTCONSTANTS.MDNCode.R060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R060101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R060102',
      label: 'CODELISTCONSTANTS.MDNCode.R060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R060102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0602',
      label: 'CODELISTCONSTANTS.MDNCode.R0602.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0602.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R060201',
      label: 'CODELISTCONSTANTS.MDNCode.R060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R060201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R060202',
      label: 'CODELISTCONSTANTS.MDNCode.R060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.R060202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R060203',
      label: 'CODELISTCONSTANTS.MDNCode.R060203.label',
      description: 'CODELISTCONSTANTS.MDNCode.R060203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R060280',
      label: 'CODELISTCONSTANTS.MDNCode.R060280.label',
      description: 'CODELISTCONSTANTS.MDNCode.R060280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R060299',
      label: 'CODELISTCONSTANTS.MDNCode.R060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R060299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0680',
      label: 'CODELISTCONSTANTS.MDNCode.R0680.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0680.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R0699',
      label: 'CODELISTCONSTANTS.MDNCode.R0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R07',
      label: 'CODELISTCONSTANTS.MDNCode.R07.label',
      description: 'CODELISTCONSTANTS.MDNCode.R07.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'R0701',
      label: 'CODELISTCONSTANTS.MDNCode.R0701.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0701.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R070101',
      label: 'CODELISTCONSTANTS.MDNCode.R070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070102',
      label: 'CODELISTCONSTANTS.MDNCode.R070102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070103',
      label: 'CODELISTCONSTANTS.MDNCode.R070103.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070180',
      label: 'CODELISTCONSTANTS.MDNCode.R070180.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070199',
      label: 'CODELISTCONSTANTS.MDNCode.R070199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0702',
      label: 'CODELISTCONSTANTS.MDNCode.R0702.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0702.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R070201',
      label: 'CODELISTCONSTANTS.MDNCode.R070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R07020101',
      label: 'CODELISTCONSTANTS.MDNCode.R07020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R07020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'R0702010101',
      label: 'CODELISTCONSTANTS.MDNCode.R0702010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0702010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R0702010102',
      label: 'CODELISTCONSTANTS.MDNCode.R0702010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0702010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'R07020102',
      label: 'CODELISTCONSTANTS.MDNCode.R07020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.R07020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R07020199',
      label: 'CODELISTCONSTANTS.MDNCode.R07020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.R07020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R070203',
      label: 'CODELISTCONSTANTS.MDNCode.R070203.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'R07020301',
      label: 'CODELISTCONSTANTS.MDNCode.R07020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.R07020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'R070204',
      label: 'CODELISTCONSTANTS.MDNCode.R070204.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070205',
      label: 'CODELISTCONSTANTS.MDNCode.R070205.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070206',
      label: 'CODELISTCONSTANTS.MDNCode.R070206.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070207',
      label: 'CODELISTCONSTANTS.MDNCode.R070207.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070207.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070208',
      label: 'CODELISTCONSTANTS.MDNCode.R070208.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070208.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070280',
      label: 'CODELISTCONSTANTS.MDNCode.R070280.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R070299',
      label: 'CODELISTCONSTANTS.MDNCode.R070299.label',
      description: 'CODELISTCONSTANTS.MDNCode.R070299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R0799',
      label: 'CODELISTCONSTANTS.MDNCode.R0799.label',
      description: 'CODELISTCONSTANTS.MDNCode.R0799.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R90',
      label: 'CODELISTCONSTANTS.MDNCode.R90.label',
      description: 'CODELISTCONSTANTS.MDNCode.R90.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'R9001',
      label: 'CODELISTCONSTANTS.MDNCode.R9001.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9001.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R9002',
      label: 'CODELISTCONSTANTS.MDNCode.R9002.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9002.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R9003',
      label: 'CODELISTCONSTANTS.MDNCode.R9003.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9003.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R900301',
      label: 'CODELISTCONSTANTS.MDNCode.R900301.label',
      description: 'CODELISTCONSTANTS.MDNCode.R900301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R900302',
      label: 'CODELISTCONSTANTS.MDNCode.R900302.label',
      description: 'CODELISTCONSTANTS.MDNCode.R900302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R900399',
      label: 'CODELISTCONSTANTS.MDNCode.R900399.label',
      description: 'CODELISTCONSTANTS.MDNCode.R900399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R9004',
      label: 'CODELISTCONSTANTS.MDNCode.R9004.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9004.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R9005',
      label: 'CODELISTCONSTANTS.MDNCode.R9005.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9005.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R9006',
      label: 'CODELISTCONSTANTS.MDNCode.R9006.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9006.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R900601',
      label: 'CODELISTCONSTANTS.MDNCode.R900601.label',
      description: 'CODELISTCONSTANTS.MDNCode.R900601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R900602',
      label: 'CODELISTCONSTANTS.MDNCode.R900602.label',
      description: 'CODELISTCONSTANTS.MDNCode.R900602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R900680',
      label: 'CODELISTCONSTANTS.MDNCode.R900680.label',
      description: 'CODELISTCONSTANTS.MDNCode.R900680.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R9007',
      label: 'CODELISTCONSTANTS.MDNCode.R9007.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9007.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R9008',
      label: 'CODELISTCONSTANTS.MDNCode.R9008.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9008.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R9009',
      label: 'CODELISTCONSTANTS.MDNCode.R9009.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9009.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'R900901',
      label: 'CODELISTCONSTANTS.MDNCode.R900901.label',
      description: 'CODELISTCONSTANTS.MDNCode.R900901.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R900902',
      label: 'CODELISTCONSTANTS.MDNCode.R900902.label',
      description: 'CODELISTCONSTANTS.MDNCode.R900902.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'R9080',
      label: 'CODELISTCONSTANTS.MDNCode.R9080.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9080.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'R9099',
      label: 'CODELISTCONSTANTS.MDNCode.R9099.label',
      description: 'CODELISTCONSTANTS.MDNCode.R9099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'S',
      label: 'CODELISTCONSTANTS.MDNCode.S.label',
      description: 'CODELISTCONSTANTS.MDNCode.S.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'S01',
      label: 'CODELISTCONSTANTS.MDNCode.S01.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'S0101',
      label: 'CODELISTCONSTANTS.MDNCode.S0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.S0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'S010101',
      label: 'CODELISTCONSTANTS.MDNCode.S010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'S01010101',
      label: 'CODELISTCONSTANTS.MDNCode.S01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'S01010102',
      label: 'CODELISTCONSTANTS.MDNCode.S01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'S010102',
      label: 'CODELISTCONSTANTS.MDNCode.S010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'S01010201',
      label: 'CODELISTCONSTANTS.MDNCode.S01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'S010103',
      label: 'CODELISTCONSTANTS.MDNCode.S010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'S01010301',
      label: 'CODELISTCONSTANTS.MDNCode.S01010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'S010199',
      label: 'CODELISTCONSTANTS.MDNCode.S010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S0102',
      label: 'CODELISTCONSTANTS.MDNCode.S0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.S0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'S010201',
      label: 'CODELISTCONSTANTS.MDNCode.S010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'S01020101',
      label: 'CODELISTCONSTANTS.MDNCode.S01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'S01020102',
      label: 'CODELISTCONSTANTS.MDNCode.S01020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'S010202',
      label: 'CODELISTCONSTANTS.MDNCode.S010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'S01020201',
      label: 'CODELISTCONSTANTS.MDNCode.S01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'S010203',
      label: 'CODELISTCONSTANTS.MDNCode.S010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'S01020301',
      label: 'CODELISTCONSTANTS.MDNCode.S01020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.S01020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'S010299',
      label: 'CODELISTCONSTANTS.MDNCode.S010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S0103',
      label: 'CODELISTCONSTANTS.MDNCode.S0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.S0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'S010301',
      label: 'CODELISTCONSTANTS.MDNCode.S010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S010302',
      label: 'CODELISTCONSTANTS.MDNCode.S010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S010399',
      label: 'CODELISTCONSTANTS.MDNCode.S010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S0104',
      label: 'CODELISTCONSTANTS.MDNCode.S0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.S0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'S010401',
      label: 'CODELISTCONSTANTS.MDNCode.S010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S010402',
      label: 'CODELISTCONSTANTS.MDNCode.S010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S010499',
      label: 'CODELISTCONSTANTS.MDNCode.S010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.S010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S0199',
      label: 'CODELISTCONSTANTS.MDNCode.S0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.S0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'S90',
      label: 'CODELISTCONSTANTS.MDNCode.S90.label',
      description: 'CODELISTCONSTANTS.MDNCode.S90.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'S9001',
      label: 'CODELISTCONSTANTS.MDNCode.S9001.label',
      description: 'CODELISTCONSTANTS.MDNCode.S9001.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'S900101',
      label: 'CODELISTCONSTANTS.MDNCode.S900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.S900101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S900102',
      label: 'CODELISTCONSTANTS.MDNCode.S900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.S900102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S900103',
      label: 'CODELISTCONSTANTS.MDNCode.S900103.label',
      description: 'CODELISTCONSTANTS.MDNCode.S900103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S900199',
      label: 'CODELISTCONSTANTS.MDNCode.S900199.label',
      description: 'CODELISTCONSTANTS.MDNCode.S900199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'S9002',
      label: 'CODELISTCONSTANTS.MDNCode.S9002.label',
      description: 'CODELISTCONSTANTS.MDNCode.S9002.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'S9003',
      label: 'CODELISTCONSTANTS.MDNCode.S9003.label',
      description: 'CODELISTCONSTANTS.MDNCode.S9003.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'S9080',
      label: 'CODELISTCONSTANTS.MDNCode.S9080.label',
      description: 'CODELISTCONSTANTS.MDNCode.S9080.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'S9099',
      label: 'CODELISTCONSTANTS.MDNCode.S9099.label',
      description: 'CODELISTCONSTANTS.MDNCode.S9099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T',
      label: 'CODELISTCONSTANTS.MDNCode.T.label',
      description: 'CODELISTCONSTANTS.MDNCode.T.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'T01',
      label: 'CODELISTCONSTANTS.MDNCode.T01.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'T0101',
      label: 'CODELISTCONSTANTS.MDNCode.T0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T010101',
      label: 'CODELISTCONSTANTS.MDNCode.T010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.T010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'T01010101',
      label: 'CODELISTCONSTANTS.MDNCode.T01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T01010102',
      label: 'CODELISTCONSTANTS.MDNCode.T01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T010102',
      label: 'CODELISTCONSTANTS.MDNCode.T010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.T010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'T01010201',
      label: 'CODELISTCONSTANTS.MDNCode.T01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T01010202',
      label: 'CODELISTCONSTANTS.MDNCode.T01010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T01010203',
      label: 'CODELISTCONSTANTS.MDNCode.T01010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T01010299',
      label: 'CODELISTCONSTANTS.MDNCode.T01010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T010199',
      label: 'CODELISTCONSTANTS.MDNCode.T010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.T010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0102',
      label: 'CODELISTCONSTANTS.MDNCode.T0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T010201',
      label: 'CODELISTCONSTANTS.MDNCode.T010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.T010201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T010202',
      label: 'CODELISTCONSTANTS.MDNCode.T010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.T010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'T01020201',
      label: 'CODELISTCONSTANTS.MDNCode.T01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T01020202',
      label: 'CODELISTCONSTANTS.MDNCode.T01020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T01020203',
      label: 'CODELISTCONSTANTS.MDNCode.T01020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T01020204',
      label: 'CODELISTCONSTANTS.MDNCode.T01020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01020204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T01020299',
      label: 'CODELISTCONSTANTS.MDNCode.T01020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.T01020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T010299',
      label: 'CODELISTCONSTANTS.MDNCode.T010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.T010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0199',
      label: 'CODELISTCONSTANTS.MDNCode.T0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T02',
      label: 'CODELISTCONSTANTS.MDNCode.T02.label',
      description: 'CODELISTCONSTANTS.MDNCode.T02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'T0201',
      label: 'CODELISTCONSTANTS.MDNCode.T0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T020101',
      label: 'CODELISTCONSTANTS.MDNCode.T020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'T02010101',
      label: 'CODELISTCONSTANTS.MDNCode.T02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.T02010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T02010102',
      label: 'CODELISTCONSTANTS.MDNCode.T02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.T02010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T020102',
      label: 'CODELISTCONSTANTS.MDNCode.T020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T020103',
      label: 'CODELISTCONSTANTS.MDNCode.T020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T020199',
      label: 'CODELISTCONSTANTS.MDNCode.T020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0202',
      label: 'CODELISTCONSTANTS.MDNCode.T0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0202.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T0203',
      label: 'CODELISTCONSTANTS.MDNCode.T0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0203.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T0204',
      label: 'CODELISTCONSTANTS.MDNCode.T0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0204.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T020401',
      label: 'CODELISTCONSTANTS.MDNCode.T020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T020402',
      label: 'CODELISTCONSTANTS.MDNCode.T020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T020499',
      label: 'CODELISTCONSTANTS.MDNCode.T020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0205',
      label: 'CODELISTCONSTANTS.MDNCode.T0205.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0205.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T0206',
      label: 'CODELISTCONSTANTS.MDNCode.T0206.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0206.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T020603',
      label: 'CODELISTCONSTANTS.MDNCode.T020603.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T020604',
      label: 'CODELISTCONSTANTS.MDNCode.T020604.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020604.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T020699',
      label: 'CODELISTCONSTANTS.MDNCode.T020699.label',
      description: 'CODELISTCONSTANTS.MDNCode.T020699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0207',
      label: 'CODELISTCONSTANTS.MDNCode.T0207.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0207.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T0208',
      label: 'CODELISTCONSTANTS.MDNCode.T0208.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0208.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T0209',
      label: 'CODELISTCONSTANTS.MDNCode.T0209.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0209.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T0210',
      label: 'CODELISTCONSTANTS.MDNCode.T0210.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0210.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T0299',
      label: 'CODELISTCONSTANTS.MDNCode.T0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T03',
      label: 'CODELISTCONSTANTS.MDNCode.T03.label',
      description: 'CODELISTCONSTANTS.MDNCode.T03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'T0301',
      label: 'CODELISTCONSTANTS.MDNCode.T0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T030101',
      label: 'CODELISTCONSTANTS.MDNCode.T030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030102',
      label: 'CODELISTCONSTANTS.MDNCode.T030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030103',
      label: 'CODELISTCONSTANTS.MDNCode.T030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030199',
      label: 'CODELISTCONSTANTS.MDNCode.T030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0302',
      label: 'CODELISTCONSTANTS.MDNCode.T0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T030201',
      label: 'CODELISTCONSTANTS.MDNCode.T030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030202',
      label: 'CODELISTCONSTANTS.MDNCode.T030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030203',
      label: 'CODELISTCONSTANTS.MDNCode.T030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030299',
      label: 'CODELISTCONSTANTS.MDNCode.T030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0303',
      label: 'CODELISTCONSTANTS.MDNCode.T0303.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0303.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T030301',
      label: 'CODELISTCONSTANTS.MDNCode.T030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030399',
      label: 'CODELISTCONSTANTS.MDNCode.T030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0304',
      label: 'CODELISTCONSTANTS.MDNCode.T0304.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0304.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T030401',
      label: 'CODELISTCONSTANTS.MDNCode.T030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030402',
      label: 'CODELISTCONSTANTS.MDNCode.T030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030403',
      label: 'CODELISTCONSTANTS.MDNCode.T030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030404',
      label: 'CODELISTCONSTANTS.MDNCode.T030404.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030404.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030405',
      label: 'CODELISTCONSTANTS.MDNCode.T030405.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030405.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030406',
      label: 'CODELISTCONSTANTS.MDNCode.T030406.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030406.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030499',
      label: 'CODELISTCONSTANTS.MDNCode.T030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0305',
      label: 'CODELISTCONSTANTS.MDNCode.T0305.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0305.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T030501',
      label: 'CODELISTCONSTANTS.MDNCode.T030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030501.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030502',
      label: 'CODELISTCONSTANTS.MDNCode.T030502.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T030599',
      label: 'CODELISTCONSTANTS.MDNCode.T030599.label',
      description: 'CODELISTCONSTANTS.MDNCode.T030599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'T0306',
      label: 'CODELISTCONSTANTS.MDNCode.T0306.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0306.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T0399',
      label: 'CODELISTCONSTANTS.MDNCode.T0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T04',
      label: 'CODELISTCONSTANTS.MDNCode.T04.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'T0401',
      label: 'CODELISTCONSTANTS.MDNCode.T0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0401.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'T040101',
      label: 'CODELISTCONSTANTS.MDNCode.T040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.T040101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'T04010101',
      label: 'CODELISTCONSTANTS.MDNCode.T04010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T04010102',
      label: 'CODELISTCONSTANTS.MDNCode.T04010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T04010103',
      label: 'CODELISTCONSTANTS.MDNCode.T04010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T04010104',
      label: 'CODELISTCONSTANTS.MDNCode.T04010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T04010199',
      label: 'CODELISTCONSTANTS.MDNCode.T04010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T040102',
      label: 'CODELISTCONSTANTS.MDNCode.T040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.T040102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'T04010201',
      label: 'CODELISTCONSTANTS.MDNCode.T04010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T04010202',
      label: 'CODELISTCONSTANTS.MDNCode.T04010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T04010203',
      label: 'CODELISTCONSTANTS.MDNCode.T04010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T04010299',
      label: 'CODELISTCONSTANTS.MDNCode.T04010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.T04010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'T0499',
      label: 'CODELISTCONSTANTS.MDNCode.T0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.T0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'T99',
      label: 'CODELISTCONSTANTS.MDNCode.T99.label',
      description: 'CODELISTCONSTANTS.MDNCode.T99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'U',
      label: 'CODELISTCONSTANTS.MDNCode.U.label',
      description: 'CODELISTCONSTANTS.MDNCode.U.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'U01',
      label: 'CODELISTCONSTANTS.MDNCode.U01.label',
      description: 'CODELISTCONSTANTS.MDNCode.U01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0101',
      label: 'CODELISTCONSTANTS.MDNCode.U0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U010101',
      label: 'CODELISTCONSTANTS.MDNCode.U010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010102',
      label: 'CODELISTCONSTANTS.MDNCode.U010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010103',
      label: 'CODELISTCONSTANTS.MDNCode.U010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010104',
      label: 'CODELISTCONSTANTS.MDNCode.U010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010105',
      label: 'CODELISTCONSTANTS.MDNCode.U010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010105.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U01010501',
      label: 'CODELISTCONSTANTS.MDNCode.U01010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.U01010501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U01010502',
      label: 'CODELISTCONSTANTS.MDNCode.U01010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.U01010502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U010106',
      label: 'CODELISTCONSTANTS.MDNCode.U010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010106.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U01010601',
      label: 'CODELISTCONSTANTS.MDNCode.U01010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.U01010601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U01010602',
      label: 'CODELISTCONSTANTS.MDNCode.U01010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.U01010602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U010107',
      label: 'CODELISTCONSTANTS.MDNCode.U010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010107.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010199',
      label: 'CODELISTCONSTANTS.MDNCode.U010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0102',
      label: 'CODELISTCONSTANTS.MDNCode.U0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U010201',
      label: 'CODELISTCONSTANTS.MDNCode.U010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010202',
      label: 'CODELISTCONSTANTS.MDNCode.U010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010203',
      label: 'CODELISTCONSTANTS.MDNCode.U010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010204',
      label: 'CODELISTCONSTANTS.MDNCode.U010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010205',
      label: 'CODELISTCONSTANTS.MDNCode.U010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010206',
      label: 'CODELISTCONSTANTS.MDNCode.U010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U010299',
      label: 'CODELISTCONSTANTS.MDNCode.U010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U010299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0103',
      label: 'CODELISTCONSTANTS.MDNCode.U0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0103.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0180',
      label: 'CODELISTCONSTANTS.MDNCode.U0180.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0180.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0199',
      label: 'CODELISTCONSTANTS.MDNCode.U0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U02',
      label: 'CODELISTCONSTANTS.MDNCode.U02.label',
      description: 'CODELISTCONSTANTS.MDNCode.U02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0201',
      label: 'CODELISTCONSTANTS.MDNCode.U0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U020101',
      label: 'CODELISTCONSTANTS.MDNCode.U020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U020102',
      label: 'CODELISTCONSTANTS.MDNCode.U020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U020103',
      label: 'CODELISTCONSTANTS.MDNCode.U020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U020104',
      label: 'CODELISTCONSTANTS.MDNCode.U020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U020199',
      label: 'CODELISTCONSTANTS.MDNCode.U020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0202',
      label: 'CODELISTCONSTANTS.MDNCode.U0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U020201',
      label: 'CODELISTCONSTANTS.MDNCode.U020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U020202',
      label: 'CODELISTCONSTANTS.MDNCode.U020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U020299',
      label: 'CODELISTCONSTANTS.MDNCode.U020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0203',
      label: 'CODELISTCONSTANTS.MDNCode.U0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U020301',
      label: 'CODELISTCONSTANTS.MDNCode.U020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U020302',
      label: 'CODELISTCONSTANTS.MDNCode.U020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U020399',
      label: 'CODELISTCONSTANTS.MDNCode.U020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.U020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0204',
      label: 'CODELISTCONSTANTS.MDNCode.U0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0204.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0280',
      label: 'CODELISTCONSTANTS.MDNCode.U0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0280.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0299',
      label: 'CODELISTCONSTANTS.MDNCode.U0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U03',
      label: 'CODELISTCONSTANTS.MDNCode.U03.label',
      description: 'CODELISTCONSTANTS.MDNCode.U03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0301',
      label: 'CODELISTCONSTANTS.MDNCode.U0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U030101',
      label: 'CODELISTCONSTANTS.MDNCode.U030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U03010101',
      label: 'CODELISTCONSTANTS.MDNCode.U03010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U03010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U03010199',
      label: 'CODELISTCONSTANTS.MDNCode.U03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U030102',
      label: 'CODELISTCONSTANTS.MDNCode.U030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U030102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U03010201',
      label: 'CODELISTCONSTANTS.MDNCode.U03010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U03010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U03010202',
      label: 'CODELISTCONSTANTS.MDNCode.U03010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U03010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U0302',
      label: 'CODELISTCONSTANTS.MDNCode.U0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U030201',
      label: 'CODELISTCONSTANTS.MDNCode.U030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U030201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U030202',
      label: 'CODELISTCONSTANTS.MDNCode.U030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U030202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0380',
      label: 'CODELISTCONSTANTS.MDNCode.U0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0399',
      label: 'CODELISTCONSTANTS.MDNCode.U0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U04',
      label: 'CODELISTCONSTANTS.MDNCode.U04.label',
      description: 'CODELISTCONSTANTS.MDNCode.U04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0401',
      label: 'CODELISTCONSTANTS.MDNCode.U0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0401.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U040101',
      label: 'CODELISTCONSTANTS.MDNCode.U040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U040102',
      label: 'CODELISTCONSTANTS.MDNCode.U040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U040199',
      label: 'CODELISTCONSTANTS.MDNCode.U040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0402',
      label: 'CODELISTCONSTANTS.MDNCode.U0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0402.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U040201',
      label: 'CODELISTCONSTANTS.MDNCode.U040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U040202',
      label: 'CODELISTCONSTANTS.MDNCode.U040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U040203',
      label: 'CODELISTCONSTANTS.MDNCode.U040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U040299',
      label: 'CODELISTCONSTANTS.MDNCode.U040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0403',
      label: 'CODELISTCONSTANTS.MDNCode.U0403.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0403.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U040301',
      label: 'CODELISTCONSTANTS.MDNCode.U040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U040302',
      label: 'CODELISTCONSTANTS.MDNCode.U040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U040399',
      label: 'CODELISTCONSTANTS.MDNCode.U040399.label',
      description: 'CODELISTCONSTANTS.MDNCode.U040399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0480',
      label: 'CODELISTCONSTANTS.MDNCode.U0480.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0480.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0499',
      label: 'CODELISTCONSTANTS.MDNCode.U0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U05',
      label: 'CODELISTCONSTANTS.MDNCode.U05.label',
      description: 'CODELISTCONSTANTS.MDNCode.U05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0501',
      label: 'CODELISTCONSTANTS.MDNCode.U0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0501.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U050101',
      label: 'CODELISTCONSTANTS.MDNCode.U050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U05010101',
      label: 'CODELISTCONSTANTS.MDNCode.U05010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U05010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U05010102',
      label: 'CODELISTCONSTANTS.MDNCode.U05010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U05010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U050199',
      label: 'CODELISTCONSTANTS.MDNCode.U050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0502',
      label: 'CODELISTCONSTANTS.MDNCode.U0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0502.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U050201',
      label: 'CODELISTCONSTANTS.MDNCode.U050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U050202',
      label: 'CODELISTCONSTANTS.MDNCode.U050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U050299',
      label: 'CODELISTCONSTANTS.MDNCode.U050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0503',
      label: 'CODELISTCONSTANTS.MDNCode.U0503.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0503.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U050301',
      label: 'CODELISTCONSTANTS.MDNCode.U050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U05030101',
      label: 'CODELISTCONSTANTS.MDNCode.U05030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U05030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U05030102',
      label: 'CODELISTCONSTANTS.MDNCode.U05030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U05030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U050399',
      label: 'CODELISTCONSTANTS.MDNCode.U050399.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0504',
      label: 'CODELISTCONSTANTS.MDNCode.U0504.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0504.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U050401',
      label: 'CODELISTCONSTANTS.MDNCode.U050401.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U050402',
      label: 'CODELISTCONSTANTS.MDNCode.U050402.label',
      description: 'CODELISTCONSTANTS.MDNCode.U050402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0505',
      label: 'CODELISTCONSTANTS.MDNCode.U0505.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0505.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0580',
      label: 'CODELISTCONSTANTS.MDNCode.U0580.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0580.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0599',
      label: 'CODELISTCONSTANTS.MDNCode.U0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U06',
      label: 'CODELISTCONSTANTS.MDNCode.U06.label',
      description: 'CODELISTCONSTANTS.MDNCode.U06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0601',
      label: 'CODELISTCONSTANTS.MDNCode.U0601.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0601.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0602',
      label: 'CODELISTCONSTANTS.MDNCode.U0602.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0602.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0699',
      label: 'CODELISTCONSTANTS.MDNCode.U0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U07',
      label: 'CODELISTCONSTANTS.MDNCode.U07.label',
      description: 'CODELISTCONSTANTS.MDNCode.U07.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0701',
      label: 'CODELISTCONSTANTS.MDNCode.U0701.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0701.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U070101',
      label: 'CODELISTCONSTANTS.MDNCode.U070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U070101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U070199',
      label: 'CODELISTCONSTANTS.MDNCode.U070199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U070199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0702',
      label: 'CODELISTCONSTANTS.MDNCode.U0702.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0702.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U070201',
      label: 'CODELISTCONSTANTS.MDNCode.U070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U070201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U070299',
      label: 'CODELISTCONSTANTS.MDNCode.U070299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U070299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0703',
      label: 'CODELISTCONSTANTS.MDNCode.U0703.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0703.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U070301',
      label: 'CODELISTCONSTANTS.MDNCode.U070301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U070301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U070302',
      label: 'CODELISTCONSTANTS.MDNCode.U070302.label',
      description: 'CODELISTCONSTANTS.MDNCode.U070302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U070399',
      label: 'CODELISTCONSTANTS.MDNCode.U070399.label',
      description: 'CODELISTCONSTANTS.MDNCode.U070399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0780',
      label: 'CODELISTCONSTANTS.MDNCode.U0780.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0780.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0799',
      label: 'CODELISTCONSTANTS.MDNCode.U0799.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0799.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U08',
      label: 'CODELISTCONSTANTS.MDNCode.U08.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0801',
      label: 'CODELISTCONSTANTS.MDNCode.U0801.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0801.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U080101',
      label: 'CODELISTCONSTANTS.MDNCode.U080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U080102',
      label: 'CODELISTCONSTANTS.MDNCode.U080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U080103',
      label: 'CODELISTCONSTANTS.MDNCode.U080103.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U080199',
      label: 'CODELISTCONSTANTS.MDNCode.U080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0802',
      label: 'CODELISTCONSTANTS.MDNCode.U0802.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U080201',
      label: 'CODELISTCONSTANTS.MDNCode.U080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U08020101',
      label: 'CODELISTCONSTANTS.MDNCode.U08020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U08020102',
      label: 'CODELISTCONSTANTS.MDNCode.U08020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U08020180',
      label: 'CODELISTCONSTANTS.MDNCode.U08020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U08020199',
      label: 'CODELISTCONSTANTS.MDNCode.U08020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U080202',
      label: 'CODELISTCONSTANTS.MDNCode.U080202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U08020201',
      label: 'CODELISTCONSTANTS.MDNCode.U08020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U08020202',
      label: 'CODELISTCONSTANTS.MDNCode.U08020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U080203',
      label: 'CODELISTCONSTANTS.MDNCode.U080203.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U08020301',
      label: 'CODELISTCONSTANTS.MDNCode.U08020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'U0802030101',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030102',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030103',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030104',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030105',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030105.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030106',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030106.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030107',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030107.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030108',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030108.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030199',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U08020302',
      label: 'CODELISTCONSTANTS.MDNCode.U08020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'U0802030201',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0802030202',
      label: 'CODELISTCONSTANTS.MDNCode.U0802030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0802030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U08020380',
      label: 'CODELISTCONSTANTS.MDNCode.U08020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U08020399',
      label: 'CODELISTCONSTANTS.MDNCode.U08020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U080204',
      label: 'CODELISTCONSTANTS.MDNCode.U080204.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U08020401',
      label: 'CODELISTCONSTANTS.MDNCode.U08020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U080205',
      label: 'CODELISTCONSTANTS.MDNCode.U080205.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080205.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U08020501',
      label: 'CODELISTCONSTANTS.MDNCode.U08020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U08020502',
      label: 'CODELISTCONSTANTS.MDNCode.U08020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U08020503',
      label: 'CODELISTCONSTANTS.MDNCode.U08020503.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U08020599',
      label: 'CODELISTCONSTANTS.MDNCode.U08020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.U08020599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U080206',
      label: 'CODELISTCONSTANTS.MDNCode.U080206.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U080207',
      label: 'CODELISTCONSTANTS.MDNCode.U080207.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080207.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U080299',
      label: 'CODELISTCONSTANTS.MDNCode.U080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U080299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0803',
      label: 'CODELISTCONSTANTS.MDNCode.U0803.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0803.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U0890',
      label: 'CODELISTCONSTANTS.MDNCode.U0890.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0890.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U089002',
      label: 'CODELISTCONSTANTS.MDNCode.U089002.label',
      description: 'CODELISTCONSTANTS.MDNCode.U089002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U089003',
      label: 'CODELISTCONSTANTS.MDNCode.U089003.label',
      description: 'CODELISTCONSTANTS.MDNCode.U089003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U089005',
      label: 'CODELISTCONSTANTS.MDNCode.U089005.label',
      description: 'CODELISTCONSTANTS.MDNCode.U089005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U089006',
      label: 'CODELISTCONSTANTS.MDNCode.U089006.label',
      description: 'CODELISTCONSTANTS.MDNCode.U089006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U089009',
      label: 'CODELISTCONSTANTS.MDNCode.U089009.label',
      description: 'CODELISTCONSTANTS.MDNCode.U089009.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U089010',
      label: 'CODELISTCONSTANTS.MDNCode.U089010.label',
      description: 'CODELISTCONSTANTS.MDNCode.U089010.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0899',
      label: 'CODELISTCONSTANTS.MDNCode.U0899.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0899.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U09',
      label: 'CODELISTCONSTANTS.MDNCode.U09.label',
      description: 'CODELISTCONSTANTS.MDNCode.U09.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U0901',
      label: 'CODELISTCONSTANTS.MDNCode.U0901.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0901.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U090101',
      label: 'CODELISTCONSTANTS.MDNCode.U090101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U090102',
      label: 'CODELISTCONSTANTS.MDNCode.U090102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U090199',
      label: 'CODELISTCONSTANTS.MDNCode.U090199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0902',
      label: 'CODELISTCONSTANTS.MDNCode.U0902.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0902.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U090201',
      label: 'CODELISTCONSTANTS.MDNCode.U090201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U090202',
      label: 'CODELISTCONSTANTS.MDNCode.U090202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U090203',
      label: 'CODELISTCONSTANTS.MDNCode.U090203.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U090204',
      label: 'CODELISTCONSTANTS.MDNCode.U090204.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U090299',
      label: 'CODELISTCONSTANTS.MDNCode.U090299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0903',
      label: 'CODELISTCONSTANTS.MDNCode.U0903.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0903.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U090301',
      label: 'CODELISTCONSTANTS.MDNCode.U090301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U09030101',
      label: 'CODELISTCONSTANTS.MDNCode.U09030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U09030101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'U0903010101',
      label: 'CODELISTCONSTANTS.MDNCode.U0903010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0903010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U0903010102',
      label: 'CODELISTCONSTANTS.MDNCode.U0903010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0903010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'U09030102',
      label: 'CODELISTCONSTANTS.MDNCode.U09030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U09030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U09030199',
      label: 'CODELISTCONSTANTS.MDNCode.U09030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U09030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U090303',
      label: 'CODELISTCONSTANTS.MDNCode.U090303.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'U09030301',
      label: 'CODELISTCONSTANTS.MDNCode.U09030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U09030301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'U090399',
      label: 'CODELISTCONSTANTS.MDNCode.U090399.label',
      description: 'CODELISTCONSTANTS.MDNCode.U090399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U0999',
      label: 'CODELISTCONSTANTS.MDNCode.U0999.label',
      description: 'CODELISTCONSTANTS.MDNCode.U0999.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U10',
      label: 'CODELISTCONSTANTS.MDNCode.U10.label',
      description: 'CODELISTCONSTANTS.MDNCode.U10.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U1001',
      label: 'CODELISTCONSTANTS.MDNCode.U1001.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1001.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U100101',
      label: 'CODELISTCONSTANTS.MDNCode.U100101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U100101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U100102',
      label: 'CODELISTCONSTANTS.MDNCode.U100102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U100102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U100199',
      label: 'CODELISTCONSTANTS.MDNCode.U100199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U100199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U1002',
      label: 'CODELISTCONSTANTS.MDNCode.U1002.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1002.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U100201',
      label: 'CODELISTCONSTANTS.MDNCode.U100201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U100201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U100299',
      label: 'CODELISTCONSTANTS.MDNCode.U100299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U100299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U1003',
      label: 'CODELISTCONSTANTS.MDNCode.U1003.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1003.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1004',
      label: 'CODELISTCONSTANTS.MDNCode.U1004.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1004.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1005',
      label: 'CODELISTCONSTANTS.MDNCode.U1005.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1005.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1006',
      label: 'CODELISTCONSTANTS.MDNCode.U1006.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1006.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1007',
      label: 'CODELISTCONSTANTS.MDNCode.U1007.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1007.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1080',
      label: 'CODELISTCONSTANTS.MDNCode.U1080.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1080.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1099',
      label: 'CODELISTCONSTANTS.MDNCode.U1099.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U11',
      label: 'CODELISTCONSTANTS.MDNCode.U11.label',
      description: 'CODELISTCONSTANTS.MDNCode.U11.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U1101',
      label: 'CODELISTCONSTANTS.MDNCode.U1101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U110101',
      label: 'CODELISTCONSTANTS.MDNCode.U110101.label',
      description: 'CODELISTCONSTANTS.MDNCode.U110101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U110102',
      label: 'CODELISTCONSTANTS.MDNCode.U110102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U110102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U110199',
      label: 'CODELISTCONSTANTS.MDNCode.U110199.label',
      description: 'CODELISTCONSTANTS.MDNCode.U110199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U1102',
      label: 'CODELISTCONSTANTS.MDNCode.U1102.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'U110201',
      label: 'CODELISTCONSTANTS.MDNCode.U110201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U110201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U110202',
      label: 'CODELISTCONSTANTS.MDNCode.U110202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U110202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U110203',
      label: 'CODELISTCONSTANTS.MDNCode.U110203.label',
      description: 'CODELISTCONSTANTS.MDNCode.U110203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U110299',
      label: 'CODELISTCONSTANTS.MDNCode.U110299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U110299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'U12',
      label: 'CODELISTCONSTANTS.MDNCode.U12.label',
      description: 'CODELISTCONSTANTS.MDNCode.U12.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U1201',
      label: 'CODELISTCONSTANTS.MDNCode.U1201.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1201.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1202',
      label: 'CODELISTCONSTANTS.MDNCode.U1202.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1202.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1299',
      label: 'CODELISTCONSTANTS.MDNCode.U1299.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U13',
      label: 'CODELISTCONSTANTS.MDNCode.U13.label',
      description: 'CODELISTCONSTANTS.MDNCode.U13.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'U1301',
      label: 'CODELISTCONSTANTS.MDNCode.U1301.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1301.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U1302',
      label: 'CODELISTCONSTANTS.MDNCode.U1302.label',
      description: 'CODELISTCONSTANTS.MDNCode.U1302.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'U99',
      label: 'CODELISTCONSTANTS.MDNCode.U99.label',
      description: 'CODELISTCONSTANTS.MDNCode.U99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'V',
      label: 'CODELISTCONSTANTS.MDNCode.V.label',
      description: 'CODELISTCONSTANTS.MDNCode.V.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'V01',
      label: 'CODELISTCONSTANTS.MDNCode.V01.label',
      description: 'CODELISTCONSTANTS.MDNCode.V01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0101',
      label: 'CODELISTCONSTANTS.MDNCode.V0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V010101',
      label: 'CODELISTCONSTANTS.MDNCode.V010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V010101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V010102',
      label: 'CODELISTCONSTANTS.MDNCode.V010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V010102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0102',
      label: 'CODELISTCONSTANTS.MDNCode.V0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0102.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0103',
      label: 'CODELISTCONSTANTS.MDNCode.V0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V010301',
      label: 'CODELISTCONSTANTS.MDNCode.V010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.V010301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V010302',
      label: 'CODELISTCONSTANTS.MDNCode.V010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.V010302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0104',
      label: 'CODELISTCONSTANTS.MDNCode.V0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V010401',
      label: 'CODELISTCONSTANTS.MDNCode.V010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.V010401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V010402',
      label: 'CODELISTCONSTANTS.MDNCode.V010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.V010402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0199',
      label: 'CODELISTCONSTANTS.MDNCode.V0199.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V02',
      label: 'CODELISTCONSTANTS.MDNCode.V02.label',
      description: 'CODELISTCONSTANTS.MDNCode.V02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0201',
      label: 'CODELISTCONSTANTS.MDNCode.V0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0201.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0202',
      label: 'CODELISTCONSTANTS.MDNCode.V0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0202.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0203',
      label: 'CODELISTCONSTANTS.MDNCode.V0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V020301',
      label: 'CODELISTCONSTANTS.MDNCode.V020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.V020301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V020302',
      label: 'CODELISTCONSTANTS.MDNCode.V020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.V020302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V020303',
      label: 'CODELISTCONSTANTS.MDNCode.V020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.V020303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V020380',
      label: 'CODELISTCONSTANTS.MDNCode.V020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.V020380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V020399',
      label: 'CODELISTCONSTANTS.MDNCode.V020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.V020399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0280',
      label: 'CODELISTCONSTANTS.MDNCode.V0280.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0280.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V028002',
      label: 'CODELISTCONSTANTS.MDNCode.V028002.label',
      description: 'CODELISTCONSTANTS.MDNCode.V028002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V028099',
      label: 'CODELISTCONSTANTS.MDNCode.V028099.label',
      description: 'CODELISTCONSTANTS.MDNCode.V028099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0299',
      label: 'CODELISTCONSTANTS.MDNCode.V0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V03',
      label: 'CODELISTCONSTANTS.MDNCode.V03.label',
      description: 'CODELISTCONSTANTS.MDNCode.V03.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0301',
      label: 'CODELISTCONSTANTS.MDNCode.V0301.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V030101',
      label: 'CODELISTCONSTANTS.MDNCode.V030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V03010102',
      label: 'CODELISTCONSTANTS.MDNCode.V03010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V03010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'V0301010201',
      label: 'CODELISTCONSTANTS.MDNCode.V0301010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0301010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V0301010202',
      label: 'CODELISTCONSTANTS.MDNCode.V0301010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0301010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V0301010299',
      label: 'CODELISTCONSTANTS.MDNCode.V0301010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0301010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V03010104',
      label: 'CODELISTCONSTANTS.MDNCode.V03010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.V03010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V03010199',
      label: 'CODELISTCONSTANTS.MDNCode.V03010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.V03010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V030102',
      label: 'CODELISTCONSTANTS.MDNCode.V030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V03010201',
      label: 'CODELISTCONSTANTS.MDNCode.V03010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.V03010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V03010299',
      label: 'CODELISTCONSTANTS.MDNCode.V03010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.V03010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V030180',
      label: 'CODELISTCONSTANTS.MDNCode.V030180.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030199',
      label: 'CODELISTCONSTANTS.MDNCode.V030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0302',
      label: 'CODELISTCONSTANTS.MDNCode.V0302.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0302.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V030201',
      label: 'CODELISTCONSTANTS.MDNCode.V030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030202',
      label: 'CODELISTCONSTANTS.MDNCode.V030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030203',
      label: 'CODELISTCONSTANTS.MDNCode.V030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030204',
      label: 'CODELISTCONSTANTS.MDNCode.V030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030204.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030205',
      label: 'CODELISTCONSTANTS.MDNCode.V030205.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030206',
      label: 'CODELISTCONSTANTS.MDNCode.V030206.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030207',
      label: 'CODELISTCONSTANTS.MDNCode.V030207.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030207.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030208',
      label: 'CODELISTCONSTANTS.MDNCode.V030208.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030208.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030209',
      label: 'CODELISTCONSTANTS.MDNCode.V030209.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030209.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030210',
      label: 'CODELISTCONSTANTS.MDNCode.V030210.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030210.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V030211',
      label: 'CODELISTCONSTANTS.MDNCode.V030211.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030211.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V03021101',
      label: 'CODELISTCONSTANTS.MDNCode.V03021101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V03021101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V03021199',
      label: 'CODELISTCONSTANTS.MDNCode.V03021199.label',
      description: 'CODELISTCONSTANTS.MDNCode.V03021199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V030299',
      label: 'CODELISTCONSTANTS.MDNCode.V030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.V030299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0380',
      label: 'CODELISTCONSTANTS.MDNCode.V0380.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0380.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0399',
      label: 'CODELISTCONSTANTS.MDNCode.V0399.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0399.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V04',
      label: 'CODELISTCONSTANTS.MDNCode.V04.label',
      description: 'CODELISTCONSTANTS.MDNCode.V04.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0401',
      label: 'CODELISTCONSTANTS.MDNCode.V0401.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0401.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0402',
      label: 'CODELISTCONSTANTS.MDNCode.V0402.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0402.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0480',
      label: 'CODELISTCONSTANTS.MDNCode.V0480.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0480.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0499',
      label: 'CODELISTCONSTANTS.MDNCode.V0499.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V05',
      label: 'CODELISTCONSTANTS.MDNCode.V05.label',
      description: 'CODELISTCONSTANTS.MDNCode.V05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0501',
      label: 'CODELISTCONSTANTS.MDNCode.V0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0501.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0502',
      label: 'CODELISTCONSTANTS.MDNCode.V0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0502.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0599',
      label: 'CODELISTCONSTANTS.MDNCode.V0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V06',
      label: 'CODELISTCONSTANTS.MDNCode.V06.label',
      description: 'CODELISTCONSTANTS.MDNCode.V06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0601',
      label: 'CODELISTCONSTANTS.MDNCode.V0601.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0601.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V060101',
      label: 'CODELISTCONSTANTS.MDNCode.V060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V060101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V06010101',
      label: 'CODELISTCONSTANTS.MDNCode.V06010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V06010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V06010102',
      label: 'CODELISTCONSTANTS.MDNCode.V06010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V06010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V06010103',
      label: 'CODELISTCONSTANTS.MDNCode.V06010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.V06010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V06010199',
      label: 'CODELISTCONSTANTS.MDNCode.V06010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.V06010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V060102',
      label: 'CODELISTCONSTANTS.MDNCode.V060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V060102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0602',
      label: 'CODELISTCONSTANTS.MDNCode.V0602.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0602.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0603',
      label: 'CODELISTCONSTANTS.MDNCode.V0603.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0603.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0604',
      label: 'CODELISTCONSTANTS.MDNCode.V0604.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0604.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0605',
      label: 'CODELISTCONSTANTS.MDNCode.V0605.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0605.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0606',
      label: 'CODELISTCONSTANTS.MDNCode.V0606.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0606.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0607',
      label: 'CODELISTCONSTANTS.MDNCode.V0607.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0607.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0680',
      label: 'CODELISTCONSTANTS.MDNCode.V0680.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0680.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0699',
      label: 'CODELISTCONSTANTS.MDNCode.V0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V07',
      label: 'CODELISTCONSTANTS.MDNCode.V07.label',
      description: 'CODELISTCONSTANTS.MDNCode.V07.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0701',
      label: 'CODELISTCONSTANTS.MDNCode.V0701.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0701.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V070101',
      label: 'CODELISTCONSTANTS.MDNCode.V070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V070101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V070102',
      label: 'CODELISTCONSTANTS.MDNCode.V070102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V070102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V070103',
      label: 'CODELISTCONSTANTS.MDNCode.V070103.label',
      description: 'CODELISTCONSTANTS.MDNCode.V070103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V070104',
      label: 'CODELISTCONSTANTS.MDNCode.V070104.label',
      description: 'CODELISTCONSTANTS.MDNCode.V070104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V070105',
      label: 'CODELISTCONSTANTS.MDNCode.V070105.label',
      description: 'CODELISTCONSTANTS.MDNCode.V070105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0702',
      label: 'CODELISTCONSTANTS.MDNCode.V0702.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0702.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0703',
      label: 'CODELISTCONSTANTS.MDNCode.V0703.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0703.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0704',
      label: 'CODELISTCONSTANTS.MDNCode.V0704.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0704.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0799',
      label: 'CODELISTCONSTANTS.MDNCode.V0799.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0799.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V08',
      label: 'CODELISTCONSTANTS.MDNCode.V08.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0802',
      label: 'CODELISTCONSTANTS.MDNCode.V0802.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0802.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V080201',
      label: 'CODELISTCONSTANTS.MDNCode.V080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V08020101',
      label: 'CODELISTCONSTANTS.MDNCode.V08020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08020102',
      label: 'CODELISTCONSTANTS.MDNCode.V08020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08020199',
      label: 'CODELISTCONSTANTS.MDNCode.V08020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V080202',
      label: 'CODELISTCONSTANTS.MDNCode.V080202.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V080280',
      label: 'CODELISTCONSTANTS.MDNCode.V080280.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V080299',
      label: 'CODELISTCONSTANTS.MDNCode.V080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0803',
      label: 'CODELISTCONSTANTS.MDNCode.V0803.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0803.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V080301',
      label: 'CODELISTCONSTANTS.MDNCode.V080301.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V08030101',
      label: 'CODELISTCONSTANTS.MDNCode.V08030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08030102',
      label: 'CODELISTCONSTANTS.MDNCode.V08030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V080302',
      label: 'CODELISTCONSTANTS.MDNCode.V080302.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080302.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V080399',
      label: 'CODELISTCONSTANTS.MDNCode.V080399.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0804',
      label: 'CODELISTCONSTANTS.MDNCode.V0804.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0804.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0805',
      label: 'CODELISTCONSTANTS.MDNCode.V0805.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0805.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V080501',
      label: 'CODELISTCONSTANTS.MDNCode.V080501.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V08050101',
      label: 'CODELISTCONSTANTS.MDNCode.V08050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08050102',
      label: 'CODELISTCONSTANTS.MDNCode.V08050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08050103',
      label: 'CODELISTCONSTANTS.MDNCode.V08050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08050104',
      label: 'CODELISTCONSTANTS.MDNCode.V08050104.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08050199',
      label: 'CODELISTCONSTANTS.MDNCode.V08050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V080502',
      label: 'CODELISTCONSTANTS.MDNCode.V080502.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080502.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V080503',
      label: 'CODELISTCONSTANTS.MDNCode.V080503.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080503.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V08050301',
      label: 'CODELISTCONSTANTS.MDNCode.V08050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'V0805030101',
      label: 'CODELISTCONSTANTS.MDNCode.V0805030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0805030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V0805030102',
      label: 'CODELISTCONSTANTS.MDNCode.V0805030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0805030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V08050302',
      label: 'CODELISTCONSTANTS.MDNCode.V08050302.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08050303',
      label: 'CODELISTCONSTANTS.MDNCode.V08050303.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08050380',
      label: 'CODELISTCONSTANTS.MDNCode.V08050380.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050380.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'V0805038001',
      label: 'CODELISTCONSTANTS.MDNCode.V0805038001.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0805038001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V0805038002',
      label: 'CODELISTCONSTANTS.MDNCode.V0805038002.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0805038002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V08050399',
      label: 'CODELISTCONSTANTS.MDNCode.V08050399.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08050399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V080504',
      label: 'CODELISTCONSTANTS.MDNCode.V080504.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080504.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0806',
      label: 'CODELISTCONSTANTS.MDNCode.V0806.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0806.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V080601',
      label: 'CODELISTCONSTANTS.MDNCode.V080601.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080601.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V08060101',
      label: 'CODELISTCONSTANTS.MDNCode.V08060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08060101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08060102',
      label: 'CODELISTCONSTANTS.MDNCode.V08060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08060102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08060103',
      label: 'CODELISTCONSTANTS.MDNCode.V08060103.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08060103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08060104',
      label: 'CODELISTCONSTANTS.MDNCode.V08060104.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08060104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08060105',
      label: 'CODELISTCONSTANTS.MDNCode.V08060105.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08060105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08060106',
      label: 'CODELISTCONSTANTS.MDNCode.V08060106.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08060106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08060107',
      label: 'CODELISTCONSTANTS.MDNCode.V08060107.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08060107.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'V0806010701',
      label: 'CODELISTCONSTANTS.MDNCode.V0806010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0806010701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V0806010702',
      label: 'CODELISTCONSTANTS.MDNCode.V0806010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0806010702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'V08060199',
      label: 'CODELISTCONSTANTS.MDNCode.V08060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08060199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V080602',
      label: 'CODELISTCONSTANTS.MDNCode.V080602.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V080603',
      label: 'CODELISTCONSTANTS.MDNCode.V080603.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V080680',
      label: 'CODELISTCONSTANTS.MDNCode.V080680.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080680.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'V08068001',
      label: 'CODELISTCONSTANTS.MDNCode.V08068001.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08068001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08068002',
      label: 'CODELISTCONSTANTS.MDNCode.V08068002.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08068002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V08068099',
      label: 'CODELISTCONSTANTS.MDNCode.V08068099.label',
      description: 'CODELISTCONSTANTS.MDNCode.V08068099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'V080699',
      label: 'CODELISTCONSTANTS.MDNCode.V080699.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0807',
      label: 'CODELISTCONSTANTS.MDNCode.V0807.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0807.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V080701',
      label: 'CODELISTCONSTANTS.MDNCode.V080701.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V080702',
      label: 'CODELISTCONSTANTS.MDNCode.V080702.label',
      description: 'CODELISTCONSTANTS.MDNCode.V080702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V0808',
      label: 'CODELISTCONSTANTS.MDNCode.V0808.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0808.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0809',
      label: 'CODELISTCONSTANTS.MDNCode.V0809.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0809.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0810',
      label: 'CODELISTCONSTANTS.MDNCode.V0810.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0810.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0811',
      label: 'CODELISTCONSTANTS.MDNCode.V0811.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0811.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0812',
      label: 'CODELISTCONSTANTS.MDNCode.V0812.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0812.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0880',
      label: 'CODELISTCONSTANTS.MDNCode.V0880.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0880.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0899',
      label: 'CODELISTCONSTANTS.MDNCode.V0899.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0899.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V09',
      label: 'CODELISTCONSTANTS.MDNCode.V09.label',
      description: 'CODELISTCONSTANTS.MDNCode.V09.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V0901',
      label: 'CODELISTCONSTANTS.MDNCode.V0901.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0901.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0902',
      label: 'CODELISTCONSTANTS.MDNCode.V0902.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0902.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0903',
      label: 'CODELISTCONSTANTS.MDNCode.V0903.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0903.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0904',
      label: 'CODELISTCONSTANTS.MDNCode.V0904.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0904.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0905',
      label: 'CODELISTCONSTANTS.MDNCode.V0905.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0905.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V0999',
      label: 'CODELISTCONSTANTS.MDNCode.V0999.label',
      description: 'CODELISTCONSTANTS.MDNCode.V0999.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V80',
      label: 'CODELISTCONSTANTS.MDNCode.V80.label',
      description: 'CODELISTCONSTANTS.MDNCode.V80.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'V90',
      label: 'CODELISTCONSTANTS.MDNCode.V90.label',
      description: 'CODELISTCONSTANTS.MDNCode.V90.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'V9001',
      label: 'CODELISTCONSTANTS.MDNCode.V9001.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9001.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9002',
      label: 'CODELISTCONSTANTS.MDNCode.V9002.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9002.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9003',
      label: 'CODELISTCONSTANTS.MDNCode.V9003.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9003.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9004',
      label: 'CODELISTCONSTANTS.MDNCode.V9004.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9004.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9005',
      label: 'CODELISTCONSTANTS.MDNCode.V9005.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9005.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9007',
      label: 'CODELISTCONSTANTS.MDNCode.V9007.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9007.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9010',
      label: 'CODELISTCONSTANTS.MDNCode.V9010.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9010.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V901001',
      label: 'CODELISTCONSTANTS.MDNCode.V901001.label',
      description: 'CODELISTCONSTANTS.MDNCode.V901001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V901099',
      label: 'CODELISTCONSTANTS.MDNCode.V901099.label',
      description: 'CODELISTCONSTANTS.MDNCode.V901099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V9011',
      label: 'CODELISTCONSTANTS.MDNCode.V9011.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9011.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9012',
      label: 'CODELISTCONSTANTS.MDNCode.V9012.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9012.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9015',
      label: 'CODELISTCONSTANTS.MDNCode.V9015.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9015.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9016',
      label: 'CODELISTCONSTANTS.MDNCode.V9016.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9016.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'V901601',
      label: 'CODELISTCONSTANTS.MDNCode.V901601.label',
      description: 'CODELISTCONSTANTS.MDNCode.V901601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V901602',
      label: 'CODELISTCONSTANTS.MDNCode.V901602.label',
      description: 'CODELISTCONSTANTS.MDNCode.V901602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V901603',
      label: 'CODELISTCONSTANTS.MDNCode.V901603.label',
      description: 'CODELISTCONSTANTS.MDNCode.V901603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V901699',
      label: 'CODELISTCONSTANTS.MDNCode.V901699.label',
      description: 'CODELISTCONSTANTS.MDNCode.V901699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'V9017',
      label: 'CODELISTCONSTANTS.MDNCode.V9017.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9017.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9018',
      label: 'CODELISTCONSTANTS.MDNCode.V9018.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9018.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'V9099',
      label: 'CODELISTCONSTANTS.MDNCode.V9099.label',
      description: 'CODELISTCONSTANTS.MDNCode.V9099.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'W',
      label: 'CODELISTCONSTANTS.MDNCode.W.label',
      description: 'CODELISTCONSTANTS.MDNCode.W.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'W01',
      label: 'CODELISTCONSTANTS.MDNCode.W01.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'W0101',
      label: 'CODELISTCONSTANTS.MDNCode.W0101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W010101',
      label: 'CODELISTCONSTANTS.MDNCode.W010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010101',
      label: 'CODELISTCONSTANTS.MDNCode.W01010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010102',
      label: 'CODELISTCONSTANTS.MDNCode.W01010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010103',
      label: 'CODELISTCONSTANTS.MDNCode.W01010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010104',
      label: 'CODELISTCONSTANTS.MDNCode.W01010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010105',
      label: 'CODELISTCONSTANTS.MDNCode.W01010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010106',
      label: 'CODELISTCONSTANTS.MDNCode.W01010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010107',
      label: 'CODELISTCONSTANTS.MDNCode.W01010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010108',
      label: 'CODELISTCONSTANTS.MDNCode.W01010108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010108.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010109',
      label: 'CODELISTCONSTANTS.MDNCode.W01010109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010110',
      label: 'CODELISTCONSTANTS.MDNCode.W01010110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010110.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010111',
      label: 'CODELISTCONSTANTS.MDNCode.W01010111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010111.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010112',
      label: 'CODELISTCONSTANTS.MDNCode.W01010112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010112.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010113',
      label: 'CODELISTCONSTANTS.MDNCode.W01010113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010113.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010114',
      label: 'CODELISTCONSTANTS.MDNCode.W01010114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010114.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010115',
      label: 'CODELISTCONSTANTS.MDNCode.W01010115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010115.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010116',
      label: 'CODELISTCONSTANTS.MDNCode.W01010116.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010116.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010117',
      label: 'CODELISTCONSTANTS.MDNCode.W01010117.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010117.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010118',
      label: 'CODELISTCONSTANTS.MDNCode.W01010118.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010118.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010119',
      label: 'CODELISTCONSTANTS.MDNCode.W01010119.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010119.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010120',
      label: 'CODELISTCONSTANTS.MDNCode.W01010120.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010120.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010121',
      label: 'CODELISTCONSTANTS.MDNCode.W01010121.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010121.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010122',
      label: 'CODELISTCONSTANTS.MDNCode.W01010122.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010122.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010123',
      label: 'CODELISTCONSTANTS.MDNCode.W01010123.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010123.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010124',
      label: 'CODELISTCONSTANTS.MDNCode.W01010124.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010124.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010125',
      label: 'CODELISTCONSTANTS.MDNCode.W01010125.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010125.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010126',
      label: 'CODELISTCONSTANTS.MDNCode.W01010126.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010126.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010127',
      label: 'CODELISTCONSTANTS.MDNCode.W01010127.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010127.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010128',
      label: 'CODELISTCONSTANTS.MDNCode.W01010128.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010128.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010129',
      label: 'CODELISTCONSTANTS.MDNCode.W01010129.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010129.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010130',
      label: 'CODELISTCONSTANTS.MDNCode.W01010130.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010130.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010131',
      label: 'CODELISTCONSTANTS.MDNCode.W01010131.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010131.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010199',
      label: 'CODELISTCONSTANTS.MDNCode.W01010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010102',
      label: 'CODELISTCONSTANTS.MDNCode.W010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010201',
      label: 'CODELISTCONSTANTS.MDNCode.W01010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010202',
      label: 'CODELISTCONSTANTS.MDNCode.W01010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010203',
      label: 'CODELISTCONSTANTS.MDNCode.W01010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010204',
      label: 'CODELISTCONSTANTS.MDNCode.W01010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010205',
      label: 'CODELISTCONSTANTS.MDNCode.W01010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010206',
      label: 'CODELISTCONSTANTS.MDNCode.W01010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010206.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010207',
      label: 'CODELISTCONSTANTS.MDNCode.W01010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010207.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010208',
      label: 'CODELISTCONSTANTS.MDNCode.W01010208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010208.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010209',
      label: 'CODELISTCONSTANTS.MDNCode.W01010209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010209.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010210',
      label: 'CODELISTCONSTANTS.MDNCode.W01010210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010210.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010211',
      label: 'CODELISTCONSTANTS.MDNCode.W01010211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010211.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010212',
      label: 'CODELISTCONSTANTS.MDNCode.W01010212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010212.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010213',
      label: 'CODELISTCONSTANTS.MDNCode.W01010213.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010213.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010214',
      label: 'CODELISTCONSTANTS.MDNCode.W01010214.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010214.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010215',
      label: 'CODELISTCONSTANTS.MDNCode.W01010215.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010215.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010216',
      label: 'CODELISTCONSTANTS.MDNCode.W01010216.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010216.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010217',
      label: 'CODELISTCONSTANTS.MDNCode.W01010217.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010217.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010218',
      label: 'CODELISTCONSTANTS.MDNCode.W01010218.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010218.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010219',
      label: 'CODELISTCONSTANTS.MDNCode.W01010219.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010219.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010220',
      label: 'CODELISTCONSTANTS.MDNCode.W01010220.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010220.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010221',
      label: 'CODELISTCONSTANTS.MDNCode.W01010221.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010221.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010222',
      label: 'CODELISTCONSTANTS.MDNCode.W01010222.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010222.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010223',
      label: 'CODELISTCONSTANTS.MDNCode.W01010223.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010223.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010224',
      label: 'CODELISTCONSTANTS.MDNCode.W01010224.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010224.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010225',
      label: 'CODELISTCONSTANTS.MDNCode.W01010225.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010225.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010226',
      label: 'CODELISTCONSTANTS.MDNCode.W01010226.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010226.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010227',
      label: 'CODELISTCONSTANTS.MDNCode.W01010227.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010227.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010228',
      label: 'CODELISTCONSTANTS.MDNCode.W01010228.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010228.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010229',
      label: 'CODELISTCONSTANTS.MDNCode.W01010229.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010229.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010230',
      label: 'CODELISTCONSTANTS.MDNCode.W01010230.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010230.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010231',
      label: 'CODELISTCONSTANTS.MDNCode.W01010231.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010231.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010232',
      label: 'CODELISTCONSTANTS.MDNCode.W01010232.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010232.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010233',
      label: 'CODELISTCONSTANTS.MDNCode.W01010233.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010233.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010234',
      label: 'CODELISTCONSTANTS.MDNCode.W01010234.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010234.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010235',
      label: 'CODELISTCONSTANTS.MDNCode.W01010235.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010235.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010236',
      label: 'CODELISTCONSTANTS.MDNCode.W01010236.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010236.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010299',
      label: 'CODELISTCONSTANTS.MDNCode.W01010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010103',
      label: 'CODELISTCONSTANTS.MDNCode.W010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010301',
      label: 'CODELISTCONSTANTS.MDNCode.W01010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010302',
      label: 'CODELISTCONSTANTS.MDNCode.W01010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010303',
      label: 'CODELISTCONSTANTS.MDNCode.W01010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010304',
      label: 'CODELISTCONSTANTS.MDNCode.W01010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010305',
      label: 'CODELISTCONSTANTS.MDNCode.W01010305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010305.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010306',
      label: 'CODELISTCONSTANTS.MDNCode.W01010306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010306.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010307',
      label: 'CODELISTCONSTANTS.MDNCode.W01010307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010307.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010308',
      label: 'CODELISTCONSTANTS.MDNCode.W01010308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010308.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010309',
      label: 'CODELISTCONSTANTS.MDNCode.W01010309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010309.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010399',
      label: 'CODELISTCONSTANTS.MDNCode.W01010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010104',
      label: 'CODELISTCONSTANTS.MDNCode.W010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010401',
      label: 'CODELISTCONSTANTS.MDNCode.W01010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010402',
      label: 'CODELISTCONSTANTS.MDNCode.W01010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010403',
      label: 'CODELISTCONSTANTS.MDNCode.W01010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010404',
      label: 'CODELISTCONSTANTS.MDNCode.W01010404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010499',
      label: 'CODELISTCONSTANTS.MDNCode.W01010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010105',
      label: 'CODELISTCONSTANTS.MDNCode.W010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010105.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010501',
      label: 'CODELISTCONSTANTS.MDNCode.W01010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010501.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101050101',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050102',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050199',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010502',
      label: 'CODELISTCONSTANTS.MDNCode.W01010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010502.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101050201',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050202',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050203',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050204',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050205',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050207',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050208',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050299',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010503',
      label: 'CODELISTCONSTANTS.MDNCode.W01010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010503.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101050301',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050302',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050303',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101050399',
      label: 'CODELISTCONSTANTS.MDNCode.W0101050399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101050399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010106',
      label: 'CODELISTCONSTANTS.MDNCode.W010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010601',
      label: 'CODELISTCONSTANTS.MDNCode.W01010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010601.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101060101',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060102',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060103',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060104',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060105',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060106',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060107',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060108',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060108.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W010106010801',
      label: 'CODELISTCONSTANTS.MDNCode.W010106010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106010801.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010106010802',
      label: 'CODELISTCONSTANTS.MDNCode.W010106010802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106010802.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0101060109',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060110',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060111',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060112',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060199',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010602',
      label: 'CODELISTCONSTANTS.MDNCode.W01010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010602.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101060201',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060203',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060203.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W010106020301',
      label: 'CODELISTCONSTANTS.MDNCode.W010106020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106020301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010106020302',
      label: 'CODELISTCONSTANTS.MDNCode.W010106020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106020302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0101060204',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060205',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060206',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060206.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W010106020601',
      label: 'CODELISTCONSTANTS.MDNCode.W010106020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106020601.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010106020602',
      label: 'CODELISTCONSTANTS.MDNCode.W010106020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106020602.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0101060299',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010603',
      label: 'CODELISTCONSTANTS.MDNCode.W01010603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010603.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101060301',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060302',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060303',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060399',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010604',
      label: 'CODELISTCONSTANTS.MDNCode.W01010604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010604.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101060401',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060402',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060402.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W010106040201',
      label: 'CODELISTCONSTANTS.MDNCode.W010106040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106040201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010106040202',
      label: 'CODELISTCONSTANTS.MDNCode.W010106040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010106040202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0101060499',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010606',
      label: 'CODELISTCONSTANTS.MDNCode.W01010606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010606.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101060601',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060602',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060603',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060604',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060605',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060606',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060606.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060607',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060607.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060607.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060608',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060608.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060608.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060609',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060609.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060609.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060610',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060610.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060610.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101060699',
      label: 'CODELISTCONSTANTS.MDNCode.W0101060699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101060699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010699',
      label: 'CODELISTCONSTANTS.MDNCode.W01010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010107',
      label: 'CODELISTCONSTANTS.MDNCode.W010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010107.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010701',
      label: 'CODELISTCONSTANTS.MDNCode.W01010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010701.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101070101',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070102',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070103',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070104',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070105',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070106',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070107',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070108',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070199',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010702',
      label: 'CODELISTCONSTANTS.MDNCode.W01010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010702.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101070201',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070202',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070203',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070204',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070205',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070206',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070299',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01010703',
      label: 'CODELISTCONSTANTS.MDNCode.W01010703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010703.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0101070301',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070302',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070303',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070304',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0101070399',
      label: 'CODELISTCONSTANTS.MDNCode.W0101070399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0101070399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010108',
      label: 'CODELISTCONSTANTS.MDNCode.W010108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010108.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010801',
      label: 'CODELISTCONSTANTS.MDNCode.W01010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010802',
      label: 'CODELISTCONSTANTS.MDNCode.W01010802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010802.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010803',
      label: 'CODELISTCONSTANTS.MDNCode.W01010803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010803.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010804',
      label: 'CODELISTCONSTANTS.MDNCode.W01010804.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010804.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010805',
      label: 'CODELISTCONSTANTS.MDNCode.W01010805.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010805.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010899',
      label: 'CODELISTCONSTANTS.MDNCode.W01010899.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010899.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010109',
      label: 'CODELISTCONSTANTS.MDNCode.W010109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010109.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01010901',
      label: 'CODELISTCONSTANTS.MDNCode.W01010901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010901.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010902',
      label: 'CODELISTCONSTANTS.MDNCode.W01010902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010902.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01010999',
      label: 'CODELISTCONSTANTS.MDNCode.W01010999.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01010999.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010190',
      label: 'CODELISTCONSTANTS.MDNCode.W010190.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010190.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01019001',
      label: 'CODELISTCONSTANTS.MDNCode.W01019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01019001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01019002',
      label: 'CODELISTCONSTANTS.MDNCode.W01019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01019002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01019099',
      label: 'CODELISTCONSTANTS.MDNCode.W01019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01019099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W010201',
      label: 'CODELISTCONSTANTS.MDNCode.W010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020101',
      label: 'CODELISTCONSTANTS.MDNCode.W01020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102010101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010103',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010104',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010106',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010107',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010108',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010109',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010110',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010111',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010112',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010113',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010113.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010114',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010114.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020102',
      label: 'CODELISTCONSTANTS.MDNCode.W01020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102010201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010204',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010205',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010206',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010207',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010208',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020103',
      label: 'CODELISTCONSTANTS.MDNCode.W01020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102010301',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010302',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010303',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010304',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010305',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010306',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010306.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010307',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010307.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010308',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010308.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010309',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010309.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010399',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020104',
      label: 'CODELISTCONSTANTS.MDNCode.W01020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102010401',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010402',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010403',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010404',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010405',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010405.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102010499',
      label: 'CODELISTCONSTANTS.MDNCode.W0102010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020190',
      label: 'CODELISTCONSTANTS.MDNCode.W01020190.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020190.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102019001',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019002',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019003',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019004',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019005',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019006',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019006.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019007',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019007.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019008',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019008.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019008.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019009',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019009.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019009.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019010',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019010.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019010.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019011',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019011.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019011.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019012',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019012.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019012.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019013',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019013.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019013.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019014',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019014.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019014.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019015',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019015.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019015.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019016',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019016.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019016.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019017',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019017.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019017.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102019099',
      label: 'CODELISTCONSTANTS.MDNCode.W0102019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102019099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010202',
      label: 'CODELISTCONSTANTS.MDNCode.W010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020201',
      label: 'CODELISTCONSTANTS.MDNCode.W01020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020202',
      label: 'CODELISTCONSTANTS.MDNCode.W01020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020203',
      label: 'CODELISTCONSTANTS.MDNCode.W01020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020204',
      label: 'CODELISTCONSTANTS.MDNCode.W01020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020205',
      label: 'CODELISTCONSTANTS.MDNCode.W01020205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020206',
      label: 'CODELISTCONSTANTS.MDNCode.W01020206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020206.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020207',
      label: 'CODELISTCONSTANTS.MDNCode.W01020207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020207.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020208',
      label: 'CODELISTCONSTANTS.MDNCode.W01020208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020208.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020209',
      label: 'CODELISTCONSTANTS.MDNCode.W01020209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020209.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020299',
      label: 'CODELISTCONSTANTS.MDNCode.W01020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010203',
      label: 'CODELISTCONSTANTS.MDNCode.W010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020301',
      label: 'CODELISTCONSTANTS.MDNCode.W01020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102030101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030103',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030104',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030106',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030107',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030108',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030109',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030110',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030111',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030112',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030113',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030113.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030114',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030114.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030115',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030115.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030116',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030116.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030116.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030117',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030117.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030117.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030118',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030118.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030118.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030119',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030119.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030119.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030120',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030120.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030120.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030121',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030121.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030121.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030122',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030122.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030122.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030123',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030123.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030123.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030124',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030124.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030124.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030125',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030125.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030125.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030126',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030126.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030126.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030127',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030127.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030127.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020302',
      label: 'CODELISTCONSTANTS.MDNCode.W01020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102030201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020303',
      label: 'CODELISTCONSTANTS.MDNCode.W01020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020303.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102030301',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030302',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102030399',
      label: 'CODELISTCONSTANTS.MDNCode.W0102030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102030399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020390',
      label: 'CODELISTCONSTANTS.MDNCode.W01020390.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020390.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102039001',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039002',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039003',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039004',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039005',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039006',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039006.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039007',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039007.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039008',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039008.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039008.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039009',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039009.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039009.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039010',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039010.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039010.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039011',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039011.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039011.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039012',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039012.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039012.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039013',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039013.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039013.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039014',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039014.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039014.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039015',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039015.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039015.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039017',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039017.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039017.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039018',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039018.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039018.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039019',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039019.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039019.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102039099',
      label: 'CODELISTCONSTANTS.MDNCode.W0102039099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102039099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010204',
      label: 'CODELISTCONSTANTS.MDNCode.W010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020401',
      label: 'CODELISTCONSTANTS.MDNCode.W01020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020402',
      label: 'CODELISTCONSTANTS.MDNCode.W01020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020403',
      label: 'CODELISTCONSTANTS.MDNCode.W01020403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020404',
      label: 'CODELISTCONSTANTS.MDNCode.W01020404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020405',
      label: 'CODELISTCONSTANTS.MDNCode.W01020405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020405.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020406',
      label: 'CODELISTCONSTANTS.MDNCode.W01020406.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020406.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020407',
      label: 'CODELISTCONSTANTS.MDNCode.W01020407.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020407.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020408',
      label: 'CODELISTCONSTANTS.MDNCode.W01020408.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020408.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020409',
      label: 'CODELISTCONSTANTS.MDNCode.W01020409.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020409.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020410',
      label: 'CODELISTCONSTANTS.MDNCode.W01020410.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020410.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020411',
      label: 'CODELISTCONSTANTS.MDNCode.W01020411.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020411.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01020499',
      label: 'CODELISTCONSTANTS.MDNCode.W01020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010205',
      label: 'CODELISTCONSTANTS.MDNCode.W010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010205.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020501',
      label: 'CODELISTCONSTANTS.MDNCode.W01020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020501.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102050101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050103',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050104',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050106',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050107',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050108',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050109',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050110',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050111',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050112',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050114',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050114.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050115',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050115.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020502',
      label: 'CODELISTCONSTANTS.MDNCode.W01020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020502.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102050201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050204',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050205',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050206',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050207',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050208',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050209',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050210',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050210.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050211',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050211.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050212',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050212.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050213',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050213.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050213.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050214',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050214.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050214.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050215',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050215.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050215.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102050299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102050299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010206',
      label: 'CODELISTCONSTANTS.MDNCode.W010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010206.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020601',
      label: 'CODELISTCONSTANTS.MDNCode.W01020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020601.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102060101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060103',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060104',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060106',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060107',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060108',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060109',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020602',
      label: 'CODELISTCONSTANTS.MDNCode.W01020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020602.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102060201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060204',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060205',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060206',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060207',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060208',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020603',
      label: 'CODELISTCONSTANTS.MDNCode.W01020603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020603.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102060301',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060302',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060303',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060304',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060305',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060306',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060306.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060307',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060307.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060308',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060308.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060309',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060309.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060310',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060310.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060310.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060311',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060311.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060311.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060312',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060312.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060312.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060313',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060313.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060313.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060314',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060314.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060314.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060315',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060315.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060315.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060316',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060316.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060316.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060317',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060317.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060317.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060318',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060318.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060318.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060399',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020604',
      label: 'CODELISTCONSTANTS.MDNCode.W01020604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020604.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102060401',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060402',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060403',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060404',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060405',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060405.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060406',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060406.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060406.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060407',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060407.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060407.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060408',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060408.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060408.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060499',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020605',
      label: 'CODELISTCONSTANTS.MDNCode.W01020605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020605.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102060501',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060502',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060503',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060504',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060505',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060505.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060506',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060506.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060507',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060507.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060507.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060508',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060508.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060508.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060509',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060509.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060509.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060599',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020606',
      label: 'CODELISTCONSTANTS.MDNCode.W01020606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020606.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102060601',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102060699',
      label: 'CODELISTCONSTANTS.MDNCode.W0102060699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102060699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020690',
      label: 'CODELISTCONSTANTS.MDNCode.W01020690.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020690.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102069001',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069002',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069003',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069004',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069005',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069006',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069006.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069007',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069007.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069008',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069008.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069008.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069009',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069009.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069009.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069010',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069010.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069010.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069011',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069011.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069011.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069012',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069012.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069012.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069013',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069013.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069013.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069014',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069014.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069014.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069015',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069015.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069015.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069016',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069016.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069016.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102069099',
      label: 'CODELISTCONSTANTS.MDNCode.W0102069099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102069099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010207',
      label: 'CODELISTCONSTANTS.MDNCode.W010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010207.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020701',
      label: 'CODELISTCONSTANTS.MDNCode.W01020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020701.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102070101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070103',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070104',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070106',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070107',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020702',
      label: 'CODELISTCONSTANTS.MDNCode.W01020702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020702.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102070201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070204',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070205',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070206',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020703',
      label: 'CODELISTCONSTANTS.MDNCode.W01020703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020703.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102070301',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102070399',
      label: 'CODELISTCONSTANTS.MDNCode.W0102070399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102070399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010208',
      label: 'CODELISTCONSTANTS.MDNCode.W010208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010208.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020801',
      label: 'CODELISTCONSTANTS.MDNCode.W01020801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020801.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102080101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080103',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080104',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080106',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080107',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080108',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080109',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020802',
      label: 'CODELISTCONSTANTS.MDNCode.W01020802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020802.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102080201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080204',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080205',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080206',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080207',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080208',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080209',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080210',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080210.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080211',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080211.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080212',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080212.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080213',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080213.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080213.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080214',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080214.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080214.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080215',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080215.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080215.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020803',
      label: 'CODELISTCONSTANTS.MDNCode.W01020803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020803.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102080301',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080303',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080305',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080307',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080307.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080309',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080309.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080311',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080311.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080311.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080313',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080313.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080313.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080315',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080315.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080315.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080317',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080317.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080317.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080319',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080319.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080319.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080321',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080321.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080321.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080399',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020804',
      label: 'CODELISTCONSTANTS.MDNCode.W01020804.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020804.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102080401',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080402',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080499',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020805',
      label: 'CODELISTCONSTANTS.MDNCode.W01020805.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020805.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102080501',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080599',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020806',
      label: 'CODELISTCONSTANTS.MDNCode.W01020806.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020806.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102080601',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080602',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080603',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080604',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080605',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080606',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080606.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080699',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020807',
      label: 'CODELISTCONSTANTS.MDNCode.W01020807.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020807.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102080701',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080702',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080703',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080704',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080704.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102080799',
      label: 'CODELISTCONSTANTS.MDNCode.W0102080799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102080799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020899',
      label: 'CODELISTCONSTANTS.MDNCode.W01020899.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020899.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010209',
      label: 'CODELISTCONSTANTS.MDNCode.W010209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010209.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01020901',
      label: 'CODELISTCONSTANTS.MDNCode.W01020901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020901.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102090101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090103',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090104',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090106',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090107',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090108',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090109',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090110',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090111',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090112',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090113',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090113.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090114',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090114.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090115',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090115.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090116',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090116.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090116.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090117',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090117.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090117.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01020902',
      label: 'CODELISTCONSTANTS.MDNCode.W01020902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01020902.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102090201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090204',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090205',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090206',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090207',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090208',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090209',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090210',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090210.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090211',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090211.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090212',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090212.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090213',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090213.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090213.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090214',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090214.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090214.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090215',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090215.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090215.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090216',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090216.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090216.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090217',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090217.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090217.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090218',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090218.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090218.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090220',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090220.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090220.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102090299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102090299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102090299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010210',
      label: 'CODELISTCONSTANTS.MDNCode.W010210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010210.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01021001',
      label: 'CODELISTCONSTANTS.MDNCode.W01021001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021001.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102100101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100106',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100107',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100108',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100109',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100110',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100111',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100112',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100113',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100113.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100114',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100114.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100115',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100115.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100117',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100117.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100117.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100118',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100118.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100118.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021002',
      label: 'CODELISTCONSTANTS.MDNCode.W01021002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021002.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102100201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100204',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100205',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100206',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100207',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100208',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100209',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100210',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100210.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100211',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100211.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100212',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100212.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021003',
      label: 'CODELISTCONSTANTS.MDNCode.W01021003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021003.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102100301',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100302',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100303',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100304',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100399',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021004',
      label: 'CODELISTCONSTANTS.MDNCode.W01021004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021004.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102100401',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100402',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100403',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100499',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021005',
      label: 'CODELISTCONSTANTS.MDNCode.W01021005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021005.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102100501',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100502',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100503',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100599',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021006',
      label: 'CODELISTCONSTANTS.MDNCode.W01021006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021006.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102100601',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100602',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100603',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100604',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100699',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021007',
      label: 'CODELISTCONSTANTS.MDNCode.W01021007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021007.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102100701',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100702',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100703',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100704',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100704.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100705',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100705.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100705.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100706',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100706.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100706.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100707',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100707.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100707.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102100799',
      label: 'CODELISTCONSTANTS.MDNCode.W0102100799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102100799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021090',
      label: 'CODELISTCONSTANTS.MDNCode.W01021090.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021090.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102109005',
      label: 'CODELISTCONSTANTS.MDNCode.W0102109005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102109005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102109007',
      label: 'CODELISTCONSTANTS.MDNCode.W0102109007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102109007.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102109013',
      label: 'CODELISTCONSTANTS.MDNCode.W0102109013.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102109013.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102109014',
      label: 'CODELISTCONSTANTS.MDNCode.W0102109014.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102109014.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102109018',
      label: 'CODELISTCONSTANTS.MDNCode.W0102109018.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102109018.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102109022',
      label: 'CODELISTCONSTANTS.MDNCode.W0102109022.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102109022.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102109023',
      label: 'CODELISTCONSTANTS.MDNCode.W0102109023.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102109023.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102109099',
      label: 'CODELISTCONSTANTS.MDNCode.W0102109099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102109099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010211',
      label: 'CODELISTCONSTANTS.MDNCode.W010211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010211.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01021101',
      label: 'CODELISTCONSTANTS.MDNCode.W01021101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021102',
      label: 'CODELISTCONSTANTS.MDNCode.W01021102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021103',
      label: 'CODELISTCONSTANTS.MDNCode.W01021103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021104',
      label: 'CODELISTCONSTANTS.MDNCode.W01021104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021105',
      label: 'CODELISTCONSTANTS.MDNCode.W01021105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021106',
      label: 'CODELISTCONSTANTS.MDNCode.W01021106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021107',
      label: 'CODELISTCONSTANTS.MDNCode.W01021107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021108',
      label: 'CODELISTCONSTANTS.MDNCode.W01021108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021108.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021109',
      label: 'CODELISTCONSTANTS.MDNCode.W01021109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021110',
      label: 'CODELISTCONSTANTS.MDNCode.W01021110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021110.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021111',
      label: 'CODELISTCONSTANTS.MDNCode.W01021111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021111.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021112',
      label: 'CODELISTCONSTANTS.MDNCode.W01021112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021112.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021199',
      label: 'CODELISTCONSTANTS.MDNCode.W01021199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010212',
      label: 'CODELISTCONSTANTS.MDNCode.W010212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010212.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01021201',
      label: 'CODELISTCONSTANTS.MDNCode.W01021201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102120101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102120101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102120101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102120102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102120102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102120102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102120103',
      label: 'CODELISTCONSTANTS.MDNCode.W0102120103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102120103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102120104',
      label: 'CODELISTCONSTANTS.MDNCode.W0102120104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102120104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102120105',
      label: 'CODELISTCONSTANTS.MDNCode.W0102120105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102120105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102120199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102120199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102120199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021299',
      label: 'CODELISTCONSTANTS.MDNCode.W01021299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010213',
      label: 'CODELISTCONSTANTS.MDNCode.W010213.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010213.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01021301',
      label: 'CODELISTCONSTANTS.MDNCode.W01021301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021302',
      label: 'CODELISTCONSTANTS.MDNCode.W01021302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021303',
      label: 'CODELISTCONSTANTS.MDNCode.W01021303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021304',
      label: 'CODELISTCONSTANTS.MDNCode.W01021304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021305',
      label: 'CODELISTCONSTANTS.MDNCode.W01021305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021305.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021306',
      label: 'CODELISTCONSTANTS.MDNCode.W01021306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021306.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021307',
      label: 'CODELISTCONSTANTS.MDNCode.W01021307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021307.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021308',
      label: 'CODELISTCONSTANTS.MDNCode.W01021308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021308.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021309',
      label: 'CODELISTCONSTANTS.MDNCode.W01021309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021309.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021310',
      label: 'CODELISTCONSTANTS.MDNCode.W01021310.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021310.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021311',
      label: 'CODELISTCONSTANTS.MDNCode.W01021311.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021311.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021312',
      label: 'CODELISTCONSTANTS.MDNCode.W01021312.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021312.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021313',
      label: 'CODELISTCONSTANTS.MDNCode.W01021313.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021313.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021314',
      label: 'CODELISTCONSTANTS.MDNCode.W01021314.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021314.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021399',
      label: 'CODELISTCONSTANTS.MDNCode.W01021399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010214',
      label: 'CODELISTCONSTANTS.MDNCode.W010214.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010214.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01021499',
      label: 'CODELISTCONSTANTS.MDNCode.W01021499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010215',
      label: 'CODELISTCONSTANTS.MDNCode.W010215.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010215.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01021520',
      label: 'CODELISTCONSTANTS.MDNCode.W01021520.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021520.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102152001',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152002',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152003',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152004',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152005',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152006',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152006.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152008',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152008.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152008.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152009',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152009.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152009.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152010',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152010.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152010.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152011',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152011.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152011.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152099',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021521',
      label: 'CODELISTCONSTANTS.MDNCode.W01021521.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021521.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01021522',
      label: 'CODELISTCONSTANTS.MDNCode.W01021522.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021522.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102152201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152204',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152205',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152206',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152208',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152209',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152210',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152210.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152211',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152211.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102152299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102152299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102152299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010216',
      label: 'CODELISTCONSTANTS.MDNCode.W010216.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010216.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01021601',
      label: 'CODELISTCONSTANTS.MDNCode.W01021601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021601.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160101',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160102',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160199',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021602',
      label: 'CODELISTCONSTANTS.MDNCode.W01021602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021602.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160201',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160202',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160203',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160299',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021603',
      label: 'CODELISTCONSTANTS.MDNCode.W01021603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021603.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160301',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160302',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160303',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160304',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160399',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021604',
      label: 'CODELISTCONSTANTS.MDNCode.W01021604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021604.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160401',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160499',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021605',
      label: 'CODELISTCONSTANTS.MDNCode.W01021605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021605.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160501',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160502',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160503',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160504',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160505',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160505.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160506',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160506.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160507',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160507.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160507.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160508',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160508.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160508.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160509',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160509.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160509.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160510',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160510.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160510.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160511',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160511.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160511.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160599',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021606',
      label: 'CODELISTCONSTANTS.MDNCode.W01021606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021606.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160601',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160699',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021607',
      label: 'CODELISTCONSTANTS.MDNCode.W01021607.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021607.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160701',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160702',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160703',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160704',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160704.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160705',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160705.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160705.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160706',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160706.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160706.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160799',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021608',
      label: 'CODELISTCONSTANTS.MDNCode.W01021608.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021608.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160801',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160802',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021609',
      label: 'CODELISTCONSTANTS.MDNCode.W01021609.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021609.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0102160901',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160902',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160902.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160903',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160903.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160903.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0102160999',
      label: 'CODELISTCONSTANTS.MDNCode.W0102160999.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0102160999.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01021699',
      label: 'CODELISTCONSTANTS.MDNCode.W01021699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01021699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010290',
      label: 'CODELISTCONSTANTS.MDNCode.W010290.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010290.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01029001',
      label: 'CODELISTCONSTANTS.MDNCode.W01029001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01029001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01029002',
      label: 'CODELISTCONSTANTS.MDNCode.W01029002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01029002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01029003',
      label: 'CODELISTCONSTANTS.MDNCode.W01029003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01029003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01029004',
      label: 'CODELISTCONSTANTS.MDNCode.W01029004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01029004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01029099',
      label: 'CODELISTCONSTANTS.MDNCode.W01029099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01029099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0103',
      label: 'CODELISTCONSTANTS.MDNCode.W0103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W010301',
      label: 'CODELISTCONSTANTS.MDNCode.W010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01030101',
      label: 'CODELISTCONSTANTS.MDNCode.W01030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103010102',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010103',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010104',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010105',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010199',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030102',
      label: 'CODELISTCONSTANTS.MDNCode.W01030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103010201',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010202',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010299',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030103',
      label: 'CODELISTCONSTANTS.MDNCode.W01030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103010301',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010302',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010399',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030105',
      label: 'CODELISTCONSTANTS.MDNCode.W01030105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103010501',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010502',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010503',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010504',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010505',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010505.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010506',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010506.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010507',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010507.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010507.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010599',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030106',
      label: 'CODELISTCONSTANTS.MDNCode.W01030106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030106.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103010601',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010602',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010603',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010604',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010605',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010606',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010606.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010607',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010607.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010607.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103010699',
      label: 'CODELISTCONSTANTS.MDNCode.W0103010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103010699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030107',
      label: 'CODELISTCONSTANTS.MDNCode.W01030107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030190',
      label: 'CODELISTCONSTANTS.MDNCode.W01030190.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030190.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103019001',
      label: 'CODELISTCONSTANTS.MDNCode.W0103019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103019001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030199',
      label: 'CODELISTCONSTANTS.MDNCode.W01030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010302',
      label: 'CODELISTCONSTANTS.MDNCode.W010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01030201',
      label: 'CODELISTCONSTANTS.MDNCode.W01030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020101',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020102',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020103',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020104',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020199',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030202',
      label: 'CODELISTCONSTANTS.MDNCode.W01030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020201',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020202',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020203',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020204',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020205',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020206',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020207',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020208',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020209',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020210',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020210.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020211',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020211.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020212',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020212.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020213',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020213.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020213.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020214',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020214.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020214.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020215',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020215.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020215.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020216',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020216.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020216.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020217',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020217.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020217.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020218',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020218.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020218.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020299',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030203',
      label: 'CODELISTCONSTANTS.MDNCode.W01030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030203.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020301',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020302',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020303',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020304',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020305',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020306',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020306.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020307',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020307.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020308',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020308.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020309',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020309.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020310',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020310.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020310.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020399',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030204',
      label: 'CODELISTCONSTANTS.MDNCode.W01030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030204.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020401',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020402',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020403',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020404',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020405',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020405.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020406',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020406.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020406.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020407',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020407.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020407.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020499',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030205',
      label: 'CODELISTCONSTANTS.MDNCode.W01030205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030205.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020501',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020502',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020503',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020504',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020505',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020505.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020506',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020506.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020507',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020507.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020507.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020508',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020508.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020508.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020509',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020509.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020509.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020599',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030206',
      label: 'CODELISTCONSTANTS.MDNCode.W01030206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030206.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020601',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020602',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020603',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020604',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020605',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020606',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020606.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020607',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020607.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020607.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020608',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020608.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020608.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020609',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020609.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020609.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020610',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020610.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020610.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020611',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020611.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020611.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020612',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020612.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020612.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020613',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020613.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020613.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020614',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020614.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020614.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020699',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030207',
      label: 'CODELISTCONSTANTS.MDNCode.W01030207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030207.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020701',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020702',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020799',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030208',
      label: 'CODELISTCONSTANTS.MDNCode.W01030208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030208.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020801',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020802',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020803',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020803.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020804',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020804.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020804.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020805',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020805.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020805.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W010302080501',
      label: 'CODELISTCONSTANTS.MDNCode.W010302080501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010302080501.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010302080502',
      label: 'CODELISTCONSTANTS.MDNCode.W010302080502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010302080502.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0103020899',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020899.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020899.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030209',
      label: 'CODELISTCONSTANTS.MDNCode.W01030209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030209.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103020901',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020902',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020902.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020903',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020903.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020903.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020904',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020904.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020904.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103020999',
      label: 'CODELISTCONSTANTS.MDNCode.W0103020999.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103020999.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030299',
      label: 'CODELISTCONSTANTS.MDNCode.W01030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010303',
      label: 'CODELISTCONSTANTS.MDNCode.W010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01030301',
      label: 'CODELISTCONSTANTS.MDNCode.W01030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103030101',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030102',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030199',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030302',
      label: 'CODELISTCONSTANTS.MDNCode.W01030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103030201',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030202',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030299',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030303',
      label: 'CODELISTCONSTANTS.MDNCode.W01030303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030303.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103030301',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030302',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030303',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030304',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030305',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030306',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030306.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030307',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030307.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030308',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030308.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030399',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030304',
      label: 'CODELISTCONSTANTS.MDNCode.W01030304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030304.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103030401',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030402',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030499',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030305',
      label: 'CODELISTCONSTANTS.MDNCode.W01030305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030305.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103030501',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030309',
      label: 'CODELISTCONSTANTS.MDNCode.W01030309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030309.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103030901',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030902',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030902.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103030999',
      label: 'CODELISTCONSTANTS.MDNCode.W0103030999.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103030999.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030399',
      label: 'CODELISTCONSTANTS.MDNCode.W01030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010304',
      label: 'CODELISTCONSTANTS.MDNCode.W010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010304.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01030401',
      label: 'CODELISTCONSTANTS.MDNCode.W01030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030402',
      label: 'CODELISTCONSTANTS.MDNCode.W01030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030403',
      label: 'CODELISTCONSTANTS.MDNCode.W01030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030404',
      label: 'CODELISTCONSTANTS.MDNCode.W01030404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030405',
      label: 'CODELISTCONSTANTS.MDNCode.W01030405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030405.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030406',
      label: 'CODELISTCONSTANTS.MDNCode.W01030406.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030406.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030499',
      label: 'CODELISTCONSTANTS.MDNCode.W01030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010306',
      label: 'CODELISTCONSTANTS.MDNCode.W010306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010306.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01030601',
      label: 'CODELISTCONSTANTS.MDNCode.W01030601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030602',
      label: 'CODELISTCONSTANTS.MDNCode.W01030602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030603',
      label: 'CODELISTCONSTANTS.MDNCode.W01030603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030604',
      label: 'CODELISTCONSTANTS.MDNCode.W01030604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030604.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030605',
      label: 'CODELISTCONSTANTS.MDNCode.W01030605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030605.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030606',
      label: 'CODELISTCONSTANTS.MDNCode.W01030606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030606.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030607',
      label: 'CODELISTCONSTANTS.MDNCode.W01030607.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030607.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030608',
      label: 'CODELISTCONSTANTS.MDNCode.W01030608.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030608.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030609',
      label: 'CODELISTCONSTANTS.MDNCode.W01030609.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030609.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030699',
      label: 'CODELISTCONSTANTS.MDNCode.W01030699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010307',
      label: 'CODELISTCONSTANTS.MDNCode.W010307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010307.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01030701',
      label: 'CODELISTCONSTANTS.MDNCode.W01030701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030702',
      label: 'CODELISTCONSTANTS.MDNCode.W01030702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030703',
      label: 'CODELISTCONSTANTS.MDNCode.W01030703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030703.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030704',
      label: 'CODELISTCONSTANTS.MDNCode.W01030704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030704.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030705',
      label: 'CODELISTCONSTANTS.MDNCode.W01030705.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030705.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030706',
      label: 'CODELISTCONSTANTS.MDNCode.W01030706.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030706.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030707',
      label: 'CODELISTCONSTANTS.MDNCode.W01030707.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030707.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030708',
      label: 'CODELISTCONSTANTS.MDNCode.W01030708.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030708.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030709',
      label: 'CODELISTCONSTANTS.MDNCode.W01030709.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030709.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030710',
      label: 'CODELISTCONSTANTS.MDNCode.W01030710.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030710.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030711',
      label: 'CODELISTCONSTANTS.MDNCode.W01030711.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030711.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030799',
      label: 'CODELISTCONSTANTS.MDNCode.W01030799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030799.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010308',
      label: 'CODELISTCONSTANTS.MDNCode.W010308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010308.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01030801',
      label: 'CODELISTCONSTANTS.MDNCode.W01030801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030801.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103080101',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080102',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080103',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080104',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080105',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080106',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080199',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030802',
      label: 'CODELISTCONSTANTS.MDNCode.W01030802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030802.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103080201',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080202',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080203',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080204',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080299',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030803',
      label: 'CODELISTCONSTANTS.MDNCode.W01030803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030803.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01030804',
      label: 'CODELISTCONSTANTS.MDNCode.W01030804.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030804.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103080401',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080402',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080403',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080404',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080499',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030805',
      label: 'CODELISTCONSTANTS.MDNCode.W01030805.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030805.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103080501',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080502',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0103080599',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01030806',
      label: 'CODELISTCONSTANTS.MDNCode.W01030806.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01030806.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0103080699',
      label: 'CODELISTCONSTANTS.MDNCode.W0103080699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0103080699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010399',
      label: 'CODELISTCONSTANTS.MDNCode.W010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W0104',
      label: 'CODELISTCONSTANTS.MDNCode.W0104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W010401',
      label: 'CODELISTCONSTANTS.MDNCode.W010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01040101',
      label: 'CODELISTCONSTANTS.MDNCode.W01040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010101',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010103',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010104',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010199',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040102',
      label: 'CODELISTCONSTANTS.MDNCode.W01040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010203',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010204',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010206',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010299',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040103',
      label: 'CODELISTCONSTANTS.MDNCode.W01040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010306',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010306.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040104',
      label: 'CODELISTCONSTANTS.MDNCode.W01040104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010402',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010403',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010404',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010405',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010405.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010407',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010407.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010407.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010499',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040105',
      label: 'CODELISTCONSTANTS.MDNCode.W01040105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010501',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010504',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010599',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040106',
      label: 'CODELISTCONSTANTS.MDNCode.W01040106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040106.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010603',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010604',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010699',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040107',
      label: 'CODELISTCONSTANTS.MDNCode.W01040107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040107.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010701',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010702',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010703',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010799',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040108',
      label: 'CODELISTCONSTANTS.MDNCode.W01040108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040108.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010804',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010804.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010804.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010805',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010805.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010805.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010899',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010899.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010899.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040109',
      label: 'CODELISTCONSTANTS.MDNCode.W01040109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040109.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104010901',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104010999',
      label: 'CODELISTCONSTANTS.MDNCode.W0104010999.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104010999.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010403',
      label: 'CODELISTCONSTANTS.MDNCode.W010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010403.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01040301',
      label: 'CODELISTCONSTANTS.MDNCode.W01040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104030101',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030199',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040302',
      label: 'CODELISTCONSTANTS.MDNCode.W01040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104030201',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030202',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030299',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040303',
      label: 'CODELISTCONSTANTS.MDNCode.W01040303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040303.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104030301',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030302',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030303',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030399',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040304',
      label: 'CODELISTCONSTANTS.MDNCode.W01040304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040304.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104030403',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030404',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030405',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030405.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030499',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040305',
      label: 'CODELISTCONSTANTS.MDNCode.W01040305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040305.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104030501',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104030599',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040306',
      label: 'CODELISTCONSTANTS.MDNCode.W01040306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040306.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104030601',
      label: 'CODELISTCONSTANTS.MDNCode.W0104030601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104030601.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W010403060101',
      label: 'CODELISTCONSTANTS.MDNCode.W010403060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010403060101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010403060102',
      label: 'CODELISTCONSTANTS.MDNCode.W010403060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010403060102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010403060199',
      label: 'CODELISTCONSTANTS.MDNCode.W010403060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010403060199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010404',
      label: 'CODELISTCONSTANTS.MDNCode.W010404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010404.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01040401',
      label: 'CODELISTCONSTANTS.MDNCode.W01040401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01040402',
      label: 'CODELISTCONSTANTS.MDNCode.W01040402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01040403',
      label: 'CODELISTCONSTANTS.MDNCode.W01040403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01040404',
      label: 'CODELISTCONSTANTS.MDNCode.W01040404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01040499',
      label: 'CODELISTCONSTANTS.MDNCode.W01040499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010405',
      label: 'CODELISTCONSTANTS.MDNCode.W010405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010405.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01040501',
      label: 'CODELISTCONSTANTS.MDNCode.W01040501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040501.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104050101',
      label: 'CODELISTCONSTANTS.MDNCode.W0104050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104050101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104050102',
      label: 'CODELISTCONSTANTS.MDNCode.W0104050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104050102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104050199',
      label: 'CODELISTCONSTANTS.MDNCode.W0104050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104050199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040502',
      label: 'CODELISTCONSTANTS.MDNCode.W01040502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040502.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104050201',
      label: 'CODELISTCONSTANTS.MDNCode.W0104050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104050201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104050202',
      label: 'CODELISTCONSTANTS.MDNCode.W0104050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104050202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104050203',
      label: 'CODELISTCONSTANTS.MDNCode.W0104050203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104050203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104050299',
      label: 'CODELISTCONSTANTS.MDNCode.W0104050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104050299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010406',
      label: 'CODELISTCONSTANTS.MDNCode.W010406.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010406.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01040601',
      label: 'CODELISTCONSTANTS.MDNCode.W01040601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01040699',
      label: 'CODELISTCONSTANTS.MDNCode.W01040699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010407',
      label: 'CODELISTCONSTANTS.MDNCode.W010407.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010407.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W010408',
      label: 'CODELISTCONSTANTS.MDNCode.W010408.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010408.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01040801',
      label: 'CODELISTCONSTANTS.MDNCode.W01040801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040801.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104080101',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080103',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080105',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080107',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080108',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080109',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080110',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080111',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080112',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080113',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080113.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040802',
      label: 'CODELISTCONSTANTS.MDNCode.W01040802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040802.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104080201',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080203',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080207',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080208',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080209',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080299',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040803',
      label: 'CODELISTCONSTANTS.MDNCode.W01040803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040803.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104080301',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080302',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080303',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080304',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080399',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040805',
      label: 'CODELISTCONSTANTS.MDNCode.W01040805.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040805.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104080501',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080502',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080503',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080504',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080505',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080505.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080506',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080506.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080590',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080590.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080590.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080599',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01040806',
      label: 'CODELISTCONSTANTS.MDNCode.W01040806.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01040806.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0104080601',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080602',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080604',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080605',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080606',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080606.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080607',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080607.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080607.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0104080699',
      label: 'CODELISTCONSTANTS.MDNCode.W0104080699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0104080699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010499',
      label: 'CODELISTCONSTANTS.MDNCode.W010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W0105',
      label: 'CODELISTCONSTANTS.MDNCode.W0105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W010501',
      label: 'CODELISTCONSTANTS.MDNCode.W010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050101',
      label: 'CODELISTCONSTANTS.MDNCode.W01050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010107',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010108',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010109',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010110',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010111',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010112',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010113',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010113.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010114',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010114.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010115',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010115.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010116',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010116.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010116.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010117',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010117.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010117.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010118',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010118.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010118.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010199',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050102',
      label: 'CODELISTCONSTANTS.MDNCode.W01050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010202',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010203',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010299',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050103',
      label: 'CODELISTCONSTANTS.MDNCode.W01050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010301',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010302',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010303',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010304',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010305',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010399',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050104',
      label: 'CODELISTCONSTANTS.MDNCode.W01050104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010401',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010402',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010403',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010499',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050105',
      label: 'CODELISTCONSTANTS.MDNCode.W01050105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010501',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010502',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010503',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010599',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050106',
      label: 'CODELISTCONSTANTS.MDNCode.W01050106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050106.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010601',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010602',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010603',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010604',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010605',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010699',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050107',
      label: 'CODELISTCONSTANTS.MDNCode.W01050107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050107.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010701',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010702',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010703',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010799',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050108',
      label: 'CODELISTCONSTANTS.MDNCode.W01050108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050108.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010801',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010802',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010803',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010803.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010899',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010899.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010899.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050109',
      label: 'CODELISTCONSTANTS.MDNCode.W01050109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050109.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105010901',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010902',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010902.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105010999',
      label: 'CODELISTCONSTANTS.MDNCode.W0105010999.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105010999.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050110',
      label: 'CODELISTCONSTANTS.MDNCode.W01050110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050110.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011001',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011002',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011003',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011099',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050111',
      label: 'CODELISTCONSTANTS.MDNCode.W01050111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050111.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011101',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011102',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011103',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011104',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011105',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011199',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050112',
      label: 'CODELISTCONSTANTS.MDNCode.W01050112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050112.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011202',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011203',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011299',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050113',
      label: 'CODELISTCONSTANTS.MDNCode.W01050113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050113.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011301',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011302',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011303',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011304',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011305',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011399',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050114',
      label: 'CODELISTCONSTANTS.MDNCode.W01050114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050114.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011401',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011402',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011499',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050115',
      label: 'CODELISTCONSTANTS.MDNCode.W01050115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050115.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011501',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011502',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011503',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011599',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050116',
      label: 'CODELISTCONSTANTS.MDNCode.W01050116.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050116.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011601',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011603',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011604',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011605',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011606',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011606.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011607',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011607.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011607.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011608',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011608.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011608.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011609',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011609.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011609.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011610',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011610.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011610.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011611',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011611.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011611.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011612',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011612.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011612.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011613',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011613.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011613.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011614',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011614.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011614.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011699',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050117',
      label: 'CODELISTCONSTANTS.MDNCode.W01050117.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050117.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011701',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011703',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011704',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011704.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011705',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011705.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011705.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011706',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011706.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011706.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011707',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011707.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011707.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011708',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011708.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011708.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011709',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011709.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011709.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011710',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011710.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011710.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011711',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011711.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011711.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011712',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011712.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011712.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011713',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011713.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011713.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011714',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011714.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011714.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011799',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050118',
      label: 'CODELISTCONSTANTS.MDNCode.W01050118.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050118.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105011801',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011802',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011803',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011803.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011804',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011804.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011804.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105011899',
      label: 'CODELISTCONSTANTS.MDNCode.W0105011899.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105011899.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010502',
      label: 'CODELISTCONSTANTS.MDNCode.W010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050201',
      label: 'CODELISTCONSTANTS.MDNCode.W01050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105020101',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020102',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020103',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020104',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020105',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020199',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050202',
      label: 'CODELISTCONSTANTS.MDNCode.W01050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105020201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020202',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020203',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020204',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020205',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020206',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020207',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020208',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020209',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020210',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020210.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020211',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020211.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020212',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020212.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020213',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020213.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020213.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020214',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020214.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020214.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020215',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020215.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020215.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020216',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020216.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020216.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020217',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020217.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020217.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020218',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020218.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020218.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020299',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050203',
      label: 'CODELISTCONSTANTS.MDNCode.W01050203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050203.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105020301',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020302',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020303',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020304',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020305',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020306',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020306.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020307',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020307.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020308',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020308.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020309',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020309.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020399',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050204',
      label: 'CODELISTCONSTANTS.MDNCode.W01050204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050204.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105020401',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020402',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020403',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020404',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020405',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020405.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020406',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020406.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020406.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020499',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050205',
      label: 'CODELISTCONSTANTS.MDNCode.W01050205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050205.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105020501',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020502',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020503',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020504',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020505',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020505.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020506',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020506.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020507',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020507.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020507.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020599',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050206',
      label: 'CODELISTCONSTANTS.MDNCode.W01050206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050206.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105020601',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020602',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020603',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020604',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020605',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020606',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020606.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020699',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050207',
      label: 'CODELISTCONSTANTS.MDNCode.W01050207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050207.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105020701',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020702',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020703',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020704',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020704.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020705',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020705.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020705.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020706',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020706.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020706.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105020799',
      label: 'CODELISTCONSTANTS.MDNCode.W0105020799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105020799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050290',
      label: 'CODELISTCONSTANTS.MDNCode.W01050290.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050290.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105029099',
      label: 'CODELISTCONSTANTS.MDNCode.W0105029099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105029099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010503',
      label: 'CODELISTCONSTANTS.MDNCode.W010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010503.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050301',
      label: 'CODELISTCONSTANTS.MDNCode.W01050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105030101',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030102',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030103',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030104',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030105',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030106',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030107',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030108',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030199',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050302',
      label: 'CODELISTCONSTANTS.MDNCode.W01050302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105030201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030202',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030203',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030204',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030205',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030299',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050303',
      label: 'CODELISTCONSTANTS.MDNCode.W01050303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050303.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105030301',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030302',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030303',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030399',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050304',
      label: 'CODELISTCONSTANTS.MDNCode.W01050304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050304.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105030401',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030402',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030403',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030404',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030405',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030405.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030499',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050305',
      label: 'CODELISTCONSTANTS.MDNCode.W01050305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050305.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105030501',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030502',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030503',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030504',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030599',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050306',
      label: 'CODELISTCONSTANTS.MDNCode.W01050306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050306.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105030601',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030602',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030603',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030604',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030699',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050307',
      label: 'CODELISTCONSTANTS.MDNCode.W01050307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050307.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105030701',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030702',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030703',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030704',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030704.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105030799',
      label: 'CODELISTCONSTANTS.MDNCode.W0105030799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105030799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010504',
      label: 'CODELISTCONSTANTS.MDNCode.W010504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050401',
      label: 'CODELISTCONSTANTS.MDNCode.W01050401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040101',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040102',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040103',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040104',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040199',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050402',
      label: 'CODELISTCONSTANTS.MDNCode.W01050402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050402.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040202',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040203',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040204',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040205',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040206',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040299',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050403',
      label: 'CODELISTCONSTANTS.MDNCode.W01050403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050403.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040301',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040302',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040303',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040304',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040305',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040306',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040306.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040307',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040307.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040307.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040308',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040308.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040308.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040309',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040309.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040309.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040310',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040310.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040310.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040311',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040311.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040311.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040399',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050404',
      label: 'CODELISTCONSTANTS.MDNCode.W01050404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050404.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040401',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040401.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W010504040101',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010504040102',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010504040103',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010504040104',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040104.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010504040105',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040105.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010504040106',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040106.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010504040107',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040107.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010504040108',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040108.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W010504040199',
      label: 'CODELISTCONSTANTS.MDNCode.W010504040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010504040199.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0105040403',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040499',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050405',
      label: 'CODELISTCONSTANTS.MDNCode.W01050405.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050405.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040501',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040502',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040504',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040505',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040505.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040506',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040506.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040509',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040509.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040509.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040511',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040511.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040511.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040512',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040512.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040512.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040513',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040513.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040513.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040514',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040514.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040514.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040515',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040515.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040515.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040516',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040516.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040516.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040517',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040517.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040517.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040518',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040518.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040518.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040519',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040519.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040519.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040520',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040520.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040520.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040521',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040521.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040521.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040522',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040522.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040522.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040523',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040523.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040523.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040524',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040524.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040524.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040525',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040525.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040525.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040526',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040526.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040526.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040528',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040528.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040528.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040529',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040529.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040529.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040530',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040530.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040530.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040531',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040531.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040531.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040599',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050406',
      label: 'CODELISTCONSTANTS.MDNCode.W01050406.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050406.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040601',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040602',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040604',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040604.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040605',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040605.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040605.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040606',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040606.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040606.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040609',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040609.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040609.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040611',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040611.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040611.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040612',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040612.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040612.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040613',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040613.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040613.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040614',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040614.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040614.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040615',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040615.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040615.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040616',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040616.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040616.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040617',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040617.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040617.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040618',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040618.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040618.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040619',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040619.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040619.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040620',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040620.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040620.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040621',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040621.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040621.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040622',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040622.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040622.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040623',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040623.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040623.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040624',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040624.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040624.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040625',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040625.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040625.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040626',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040626.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040626.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040627',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040627.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040627.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040628',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040628.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040628.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040629',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040629.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040629.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040630',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040630.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040630.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040699',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050407',
      label: 'CODELISTCONSTANTS.MDNCode.W01050407.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050407.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040701',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040702',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040703',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040704',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040704.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040799',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050408',
      label: 'CODELISTCONSTANTS.MDNCode.W01050408.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050408.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040801',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040802',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040803',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040803.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040804',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040804.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040804.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040899',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040899.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040899.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050409',
      label: 'CODELISTCONSTANTS.MDNCode.W01050409.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050409.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105040901',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040902',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040902.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040903',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040903.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040903.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040904',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040904.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040904.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105040999',
      label: 'CODELISTCONSTANTS.MDNCode.W0105040999.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105040999.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050410',
      label: 'CODELISTCONSTANTS.MDNCode.W01050410.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050410.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105041001',
      label: 'CODELISTCONSTANTS.MDNCode.W0105041001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105041001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105041002',
      label: 'CODELISTCONSTANTS.MDNCode.W0105041002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105041002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105041003',
      label: 'CODELISTCONSTANTS.MDNCode.W0105041003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105041003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105041004',
      label: 'CODELISTCONSTANTS.MDNCode.W0105041004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105041004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105041005',
      label: 'CODELISTCONSTANTS.MDNCode.W0105041005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105041005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105041099',
      label: 'CODELISTCONSTANTS.MDNCode.W0105041099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105041099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050499',
      label: 'CODELISTCONSTANTS.MDNCode.W01050499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010505',
      label: 'CODELISTCONSTANTS.MDNCode.W010505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010505.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050501',
      label: 'CODELISTCONSTANTS.MDNCode.W01050501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050501.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105050101',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050102',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050103',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050104',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050105',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050199',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050502',
      label: 'CODELISTCONSTANTS.MDNCode.W01050502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050502.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105050201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050202',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050203',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050204',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050205',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050206',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050207',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050208',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050209',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050210',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050210.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050211',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050211.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105050299',
      label: 'CODELISTCONSTANTS.MDNCode.W0105050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105050299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010506',
      label: 'CODELISTCONSTANTS.MDNCode.W010506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010506.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050603',
      label: 'CODELISTCONSTANTS.MDNCode.W01050603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050603.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105060301',
      label: 'CODELISTCONSTANTS.MDNCode.W0105060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105060301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105060302',
      label: 'CODELISTCONSTANTS.MDNCode.W0105060302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105060302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105060303',
      label: 'CODELISTCONSTANTS.MDNCode.W0105060303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105060303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105060399',
      label: 'CODELISTCONSTANTS.MDNCode.W0105060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105060399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050604',
      label: 'CODELISTCONSTANTS.MDNCode.W01050604.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050604.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105060401',
      label: 'CODELISTCONSTANTS.MDNCode.W0105060401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105060401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105060402',
      label: 'CODELISTCONSTANTS.MDNCode.W0105060402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105060402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105060403',
      label: 'CODELISTCONSTANTS.MDNCode.W0105060403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105060403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105060499',
      label: 'CODELISTCONSTANTS.MDNCode.W0105060499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105060499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010507',
      label: 'CODELISTCONSTANTS.MDNCode.W010507.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010507.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050702',
      label: 'CODELISTCONSTANTS.MDNCode.W01050702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050702.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105070201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070202',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070203',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050703',
      label: 'CODELISTCONSTANTS.MDNCode.W01050703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050703.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105070301',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070302',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070303',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070304',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070399',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050704',
      label: 'CODELISTCONSTANTS.MDNCode.W01050704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050704.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01050705',
      label: 'CODELISTCONSTANTS.MDNCode.W01050705.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050705.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105070501',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070502',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070503',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070504',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070504.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070505',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070505.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070505.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070506',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070506.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070506.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105070599',
      label: 'CODELISTCONSTANTS.MDNCode.W0105070599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105070599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050799',
      label: 'CODELISTCONSTANTS.MDNCode.W01050799.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050799.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010508',
      label: 'CODELISTCONSTANTS.MDNCode.W010508.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010508.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050808',
      label: 'CODELISTCONSTANTS.MDNCode.W01050808.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050808.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105080801',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080801.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080802',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080802.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080802.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080803',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080803.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080803.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080804',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080804.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080804.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080805',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080805.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080805.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080806',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080806.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080806.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080807',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080807.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080807.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080808',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080808.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080808.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080899',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080899.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080899.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050809',
      label: 'CODELISTCONSTANTS.MDNCode.W01050809.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050809.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105080901',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080901.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080902',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080902.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080903',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080903.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080903.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080904',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080904.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080904.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080905',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080905.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080905.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080906',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080906.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080906.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080907',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080907.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080907.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080908',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080908.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080908.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105080999',
      label: 'CODELISTCONSTANTS.MDNCode.W0105080999.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105080999.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010509',
      label: 'CODELISTCONSTANTS.MDNCode.W010509.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010509.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01050901',
      label: 'CODELISTCONSTANTS.MDNCode.W01050901.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050901.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105090101',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090102',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090103',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090104',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090105',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090106',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090107',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090108',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090109',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090199',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050902',
      label: 'CODELISTCONSTANTS.MDNCode.W01050902.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050902.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105090201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090202',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090299',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050903',
      label: 'CODELISTCONSTANTS.MDNCode.W01050903.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050903.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105090301',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090302',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090303',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090399',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050904',
      label: 'CODELISTCONSTANTS.MDNCode.W01050904.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050904.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105090401',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105090499',
      label: 'CODELISTCONSTANTS.MDNCode.W0105090499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105090499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050940',
      label: 'CODELISTCONSTANTS.MDNCode.W01050940.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050940.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105094001',
      label: 'CODELISTCONSTANTS.MDNCode.W0105094001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105094001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105094002',
      label: 'CODELISTCONSTANTS.MDNCode.W0105094002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105094002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01050990',
      label: 'CODELISTCONSTANTS.MDNCode.W01050990.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01050990.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105099001',
      label: 'CODELISTCONSTANTS.MDNCode.W0105099001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105099001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105099002',
      label: 'CODELISTCONSTANTS.MDNCode.W0105099002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105099002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105099003',
      label: 'CODELISTCONSTANTS.MDNCode.W0105099003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105099003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105099004',
      label: 'CODELISTCONSTANTS.MDNCode.W0105099004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105099004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105099006',
      label: 'CODELISTCONSTANTS.MDNCode.W0105099006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105099006.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105099007',
      label: 'CODELISTCONSTANTS.MDNCode.W0105099007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105099007.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105099099',
      label: 'CODELISTCONSTANTS.MDNCode.W0105099099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105099099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010590',
      label: 'CODELISTCONSTANTS.MDNCode.W010590.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010590.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01059001',
      label: 'CODELISTCONSTANTS.MDNCode.W01059001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01059001.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105900101',
      label: 'CODELISTCONSTANTS.MDNCode.W0105900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105900101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105900102',
      label: 'CODELISTCONSTANTS.MDNCode.W0105900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105900102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105900103',
      label: 'CODELISTCONSTANTS.MDNCode.W0105900103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105900103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01059002',
      label: 'CODELISTCONSTANTS.MDNCode.W01059002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01059002.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0105900201',
      label: 'CODELISTCONSTANTS.MDNCode.W0105900201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105900201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0105900299',
      label: 'CODELISTCONSTANTS.MDNCode.W0105900299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0105900299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106',
      label: 'CODELISTCONSTANTS.MDNCode.W0106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W010601',
      label: 'CODELISTCONSTANTS.MDNCode.W010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010601.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01060101',
      label: 'CODELISTCONSTANTS.MDNCode.W01060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0106010101',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010102',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010103',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010104',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010105',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010106',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010107',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010108',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010109',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010109.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010110',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010110.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010111',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010111.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010112',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010112.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010113',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010113.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010114',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010114.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010115',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010115.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010199',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01060102',
      label: 'CODELISTCONSTANTS.MDNCode.W01060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0106010201',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010202',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010203',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010204',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010205',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010206',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010207',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010299',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01060103',
      label: 'CODELISTCONSTANTS.MDNCode.W01060103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060103.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0106010301',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010302',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010303',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010304',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010305',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010305.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010399',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010399.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W01060104',
      label: 'CODELISTCONSTANTS.MDNCode.W01060104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0106010401',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106010499',
      label: 'CODELISTCONSTANTS.MDNCode.W0106010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010602',
      label: 'CODELISTCONSTANTS.MDNCode.W010602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010602.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01060201',
      label: 'CODELISTCONSTANTS.MDNCode.W01060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060202',
      label: 'CODELISTCONSTANTS.MDNCode.W01060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060203',
      label: 'CODELISTCONSTANTS.MDNCode.W01060203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060204',
      label: 'CODELISTCONSTANTS.MDNCode.W01060204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060205',
      label: 'CODELISTCONSTANTS.MDNCode.W01060205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060206',
      label: 'CODELISTCONSTANTS.MDNCode.W01060206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060206.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060207',
      label: 'CODELISTCONSTANTS.MDNCode.W01060207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060207.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060208',
      label: 'CODELISTCONSTANTS.MDNCode.W01060208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060208.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060209',
      label: 'CODELISTCONSTANTS.MDNCode.W01060209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060209.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060210',
      label: 'CODELISTCONSTANTS.MDNCode.W01060210.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060210.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060211',
      label: 'CODELISTCONSTANTS.MDNCode.W01060211.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060211.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060212',
      label: 'CODELISTCONSTANTS.MDNCode.W01060212.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060212.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060213',
      label: 'CODELISTCONSTANTS.MDNCode.W01060213.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060213.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060214',
      label: 'CODELISTCONSTANTS.MDNCode.W01060214.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060214.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060215',
      label: 'CODELISTCONSTANTS.MDNCode.W01060215.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060215.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W01060299',
      label: 'CODELISTCONSTANTS.MDNCode.W01060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W010603',
      label: 'CODELISTCONSTANTS.MDNCode.W010603.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010603.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W01060301',
      label: 'CODELISTCONSTANTS.MDNCode.W01060301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W01060301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0106030101',
      label: 'CODELISTCONSTANTS.MDNCode.W0106030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0106030102',
      label: 'CODELISTCONSTANTS.MDNCode.W0106030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0106030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W010699',
      label: 'CODELISTCONSTANTS.MDNCode.W010699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W010699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W02',
      label: 'CODELISTCONSTANTS.MDNCode.W02.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'W0201',
      label: 'CODELISTCONSTANTS.MDNCode.W0201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W020101',
      label: 'CODELISTCONSTANTS.MDNCode.W020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02010101',
      label: 'CODELISTCONSTANTS.MDNCode.W02010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201010101',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010102',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010103',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010104',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010105',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010106',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010107',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010107.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010108',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010108.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010180',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010182',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010185',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02010102',
      label: 'CODELISTCONSTANTS.MDNCode.W02010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201010201',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W020101020101',
      label: 'CODELISTCONSTANTS.MDNCode.W020101020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020101020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020101020102',
      label: 'CODELISTCONSTANTS.MDNCode.W020101020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020101020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020101020103',
      label: 'CODELISTCONSTANTS.MDNCode.W020101020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020101020103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020101020180',
      label: 'CODELISTCONSTANTS.MDNCode.W020101020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020101020180.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020101020182',
      label: 'CODELISTCONSTANTS.MDNCode.W020101020182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020101020182.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020101020185',
      label: 'CODELISTCONSTANTS.MDNCode.W020101020185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020101020185.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0201010202',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010203',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010280',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010282',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010282.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010285',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010285.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201010299',
      label: 'CODELISTCONSTANTS.MDNCode.W0201010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02010199',
      label: 'CODELISTCONSTANTS.MDNCode.W02010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020102',
      label: 'CODELISTCONSTANTS.MDNCode.W020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02010201',
      label: 'CODELISTCONSTANTS.MDNCode.W02010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201020101',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020102',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020103',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020104',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020180',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020182',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020185',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02010202',
      label: 'CODELISTCONSTANTS.MDNCode.W02010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201020201',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020202',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020203',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020204',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020205',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020280',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020282',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020282.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020285',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020285.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201020299',
      label: 'CODELISTCONSTANTS.MDNCode.W0201020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02010299',
      label: 'CODELISTCONSTANTS.MDNCode.W02010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020103',
      label: 'CODELISTCONSTANTS.MDNCode.W020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02010301',
      label: 'CODELISTCONSTANTS.MDNCode.W02010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201030101',
      label: 'CODELISTCONSTANTS.MDNCode.W0201030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201030102',
      label: 'CODELISTCONSTANTS.MDNCode.W0201030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201030103',
      label: 'CODELISTCONSTANTS.MDNCode.W0201030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201030103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201030180',
      label: 'CODELISTCONSTANTS.MDNCode.W0201030180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201030180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201030182',
      label: 'CODELISTCONSTANTS.MDNCode.W0201030182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201030182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201030185',
      label: 'CODELISTCONSTANTS.MDNCode.W0201030185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201030185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02010399',
      label: 'CODELISTCONSTANTS.MDNCode.W02010399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020104',
      label: 'CODELISTCONSTANTS.MDNCode.W020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02010401',
      label: 'CODELISTCONSTANTS.MDNCode.W02010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201040101',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040102',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040180',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040182',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040185',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02010402',
      label: 'CODELISTCONSTANTS.MDNCode.W02010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010402.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201040201',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040202',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040203',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040280',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040282',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040282.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040285',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040285.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201040299',
      label: 'CODELISTCONSTANTS.MDNCode.W0201040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201040299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02010499',
      label: 'CODELISTCONSTANTS.MDNCode.W02010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020105',
      label: 'CODELISTCONSTANTS.MDNCode.W020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02010501',
      label: 'CODELISTCONSTANTS.MDNCode.W02010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010501.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201050101',
      label: 'CODELISTCONSTANTS.MDNCode.W0201050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201050101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201050102',
      label: 'CODELISTCONSTANTS.MDNCode.W0201050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201050102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W020105010201',
      label: 'CODELISTCONSTANTS.MDNCode.W020105010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105010202',
      label: 'CODELISTCONSTANTS.MDNCode.W020105010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105010203',
      label: 'CODELISTCONSTANTS.MDNCode.W020105010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105010203.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105010280',
      label: 'CODELISTCONSTANTS.MDNCode.W020105010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105010280.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105010282',
      label: 'CODELISTCONSTANTS.MDNCode.W020105010282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105010282.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105010285',
      label: 'CODELISTCONSTANTS.MDNCode.W020105010285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105010285.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105010299',
      label: 'CODELISTCONSTANTS.MDNCode.W020105010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105010299.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0201050190',
      label: 'CODELISTCONSTANTS.MDNCode.W0201050190.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201050190.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W020105019001',
      label: 'CODELISTCONSTANTS.MDNCode.W020105019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105019001.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105019002',
      label: 'CODELISTCONSTANTS.MDNCode.W020105019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105019002.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105019080',
      label: 'CODELISTCONSTANTS.MDNCode.W020105019080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105019080.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105019082',
      label: 'CODELISTCONSTANTS.MDNCode.W020105019082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105019082.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105019085',
      label: 'CODELISTCONSTANTS.MDNCode.W020105019085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105019085.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020105019099',
      label: 'CODELISTCONSTANTS.MDNCode.W020105019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020105019099.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W02010502',
      label: 'CODELISTCONSTANTS.MDNCode.W02010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010502.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201050201',
      label: 'CODELISTCONSTANTS.MDNCode.W0201050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201050201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201050280',
      label: 'CODELISTCONSTANTS.MDNCode.W0201050280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201050280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201050282',
      label: 'CODELISTCONSTANTS.MDNCode.W0201050282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201050282.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201050285',
      label: 'CODELISTCONSTANTS.MDNCode.W0201050285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201050285.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201050299',
      label: 'CODELISTCONSTANTS.MDNCode.W0201050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201050299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W020106',
      label: 'CODELISTCONSTANTS.MDNCode.W020106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020106.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02010601',
      label: 'CODELISTCONSTANTS.MDNCode.W02010601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010601.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201060101',
      label: 'CODELISTCONSTANTS.MDNCode.W0201060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201060101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201060102',
      label: 'CODELISTCONSTANTS.MDNCode.W0201060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201060102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201060190',
      label: 'CODELISTCONSTANTS.MDNCode.W0201060190.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201060190.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W020106019080',
      label: 'CODELISTCONSTANTS.MDNCode.W020106019080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020106019080.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020106019082',
      label: 'CODELISTCONSTANTS.MDNCode.W020106019082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020106019082.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020106019085',
      label: 'CODELISTCONSTANTS.MDNCode.W020106019085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020106019085.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W020106019099',
      label: 'CODELISTCONSTANTS.MDNCode.W020106019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020106019099.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W02010690',
      label: 'CODELISTCONSTANTS.MDNCode.W02010690.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010690.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0201069001',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201069002',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201069003',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201069004',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201069005',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201069080',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069080.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201069082',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069082.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201069085',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069085.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0201069099',
      label: 'CODELISTCONSTANTS.MDNCode.W0201069099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0201069099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W020107',
      label: 'CODELISTCONSTANTS.MDNCode.W020107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020107.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02010701',
      label: 'CODELISTCONSTANTS.MDNCode.W02010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02010780',
      label: 'CODELISTCONSTANTS.MDNCode.W02010780.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02010782',
      label: 'CODELISTCONSTANTS.MDNCode.W02010782.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010782.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02010785',
      label: 'CODELISTCONSTANTS.MDNCode.W02010785.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02010785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0202',
      label: 'CODELISTCONSTANTS.MDNCode.W0202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W020201',
      label: 'CODELISTCONSTANTS.MDNCode.W020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02020101',
      label: 'CODELISTCONSTANTS.MDNCode.W02020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202010101',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010102',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010103',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010180',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010182',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010185',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02020102',
      label: 'CODELISTCONSTANTS.MDNCode.W02020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202010201',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010202',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010280',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010282',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010282.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010285',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010285.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202010299',
      label: 'CODELISTCONSTANTS.MDNCode.W0202010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02020199',
      label: 'CODELISTCONSTANTS.MDNCode.W02020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020202',
      label: 'CODELISTCONSTANTS.MDNCode.W020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02020201',
      label: 'CODELISTCONSTANTS.MDNCode.W02020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202020101',
      label: 'CODELISTCONSTANTS.MDNCode.W0202020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202020102',
      label: 'CODELISTCONSTANTS.MDNCode.W0202020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202020180',
      label: 'CODELISTCONSTANTS.MDNCode.W0202020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202020180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202020182',
      label: 'CODELISTCONSTANTS.MDNCode.W0202020182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202020182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202020185',
      label: 'CODELISTCONSTANTS.MDNCode.W0202020185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202020185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02020290',
      label: 'CODELISTCONSTANTS.MDNCode.W02020290.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020290.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202029001',
      label: 'CODELISTCONSTANTS.MDNCode.W0202029001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202029001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202029080',
      label: 'CODELISTCONSTANTS.MDNCode.W0202029080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202029080.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202029082',
      label: 'CODELISTCONSTANTS.MDNCode.W0202029082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202029082.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202029085',
      label: 'CODELISTCONSTANTS.MDNCode.W0202029085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202029085.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202029099',
      label: 'CODELISTCONSTANTS.MDNCode.W0202029099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202029099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W020203',
      label: 'CODELISTCONSTANTS.MDNCode.W020203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02020301',
      label: 'CODELISTCONSTANTS.MDNCode.W02020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202030101',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030102',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030180',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030182',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030185',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02020302',
      label: 'CODELISTCONSTANTS.MDNCode.W02020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020302.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202030201',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030202',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030203',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030280',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030282',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030282.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202030285',
      label: 'CODELISTCONSTANTS.MDNCode.W0202030285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202030285.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02020399',
      label: 'CODELISTCONSTANTS.MDNCode.W02020399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020204',
      label: 'CODELISTCONSTANTS.MDNCode.W020204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02020401',
      label: 'CODELISTCONSTANTS.MDNCode.W02020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202040101',
      label: 'CODELISTCONSTANTS.MDNCode.W0202040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202040101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202040102',
      label: 'CODELISTCONSTANTS.MDNCode.W0202040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202040103',
      label: 'CODELISTCONSTANTS.MDNCode.W0202040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202040103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202040180',
      label: 'CODELISTCONSTANTS.MDNCode.W0202040180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202040180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202040182',
      label: 'CODELISTCONSTANTS.MDNCode.W0202040182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202040182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202040185',
      label: 'CODELISTCONSTANTS.MDNCode.W0202040185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202040185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02020499',
      label: 'CODELISTCONSTANTS.MDNCode.W02020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020205',
      label: 'CODELISTCONSTANTS.MDNCode.W020205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020205.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02020501',
      label: 'CODELISTCONSTANTS.MDNCode.W02020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02020502',
      label: 'CODELISTCONSTANTS.MDNCode.W02020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02020503',
      label: 'CODELISTCONSTANTS.MDNCode.W02020503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020503.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202050301',
      label: 'CODELISTCONSTANTS.MDNCode.W0202050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202050301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202050302',
      label: 'CODELISTCONSTANTS.MDNCode.W0202050302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202050302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202050303',
      label: 'CODELISTCONSTANTS.MDNCode.W0202050303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202050303.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202050304',
      label: 'CODELISTCONSTANTS.MDNCode.W0202050304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202050304.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202050380',
      label: 'CODELISTCONSTANTS.MDNCode.W0202050380.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202050380.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202050382',
      label: 'CODELISTCONSTANTS.MDNCode.W0202050382.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202050382.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202050385',
      label: 'CODELISTCONSTANTS.MDNCode.W0202050385.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202050385.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02020590',
      label: 'CODELISTCONSTANTS.MDNCode.W02020590.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020590.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0202059001',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059002',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059003',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059004',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059005',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059006',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059006.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059007',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059007.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059008',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059008.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059008.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059009',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059009.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059009.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059010',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059010.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059010.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059011',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059011.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059011.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059012',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059012.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059012.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059013',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059013.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059013.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059014',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059014.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059014.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059015',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059015.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059015.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059016',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059016.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059016.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059017',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059017.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059017.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059080',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059080.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059082',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059082.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059085',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059085.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0202059099',
      label: 'CODELISTCONSTANTS.MDNCode.W0202059099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0202059099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W020206',
      label: 'CODELISTCONSTANTS.MDNCode.W020206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020206.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02020601',
      label: 'CODELISTCONSTANTS.MDNCode.W02020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02020602',
      label: 'CODELISTCONSTANTS.MDNCode.W02020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02020680',
      label: 'CODELISTCONSTANTS.MDNCode.W02020680.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020680.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02020682',
      label: 'CODELISTCONSTANTS.MDNCode.W02020682.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020682.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02020685',
      label: 'CODELISTCONSTANTS.MDNCode.W02020685.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020685.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02020699',
      label: 'CODELISTCONSTANTS.MDNCode.W02020699.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02020699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020290',
      label: 'CODELISTCONSTANTS.MDNCode.W020290.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020290.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02029001',
      label: 'CODELISTCONSTANTS.MDNCode.W02029001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029002',
      label: 'CODELISTCONSTANTS.MDNCode.W02029002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029003',
      label: 'CODELISTCONSTANTS.MDNCode.W02029003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029005',
      label: 'CODELISTCONSTANTS.MDNCode.W02029005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029006',
      label: 'CODELISTCONSTANTS.MDNCode.W02029006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029007',
      label: 'CODELISTCONSTANTS.MDNCode.W02029007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029008',
      label: 'CODELISTCONSTANTS.MDNCode.W02029008.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029009',
      label: 'CODELISTCONSTANTS.MDNCode.W02029009.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029010',
      label: 'CODELISTCONSTANTS.MDNCode.W02029010.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029010.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029080',
      label: 'CODELISTCONSTANTS.MDNCode.W02029080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029082',
      label: 'CODELISTCONSTANTS.MDNCode.W02029082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029085',
      label: 'CODELISTCONSTANTS.MDNCode.W02029085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02029099',
      label: 'CODELISTCONSTANTS.MDNCode.W02029099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02029099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0203',
      label: 'CODELISTCONSTANTS.MDNCode.W0203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W020301',
      label: 'CODELISTCONSTANTS.MDNCode.W020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02030101',
      label: 'CODELISTCONSTANTS.MDNCode.W02030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02030101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0203010180',
      label: 'CODELISTCONSTANTS.MDNCode.W0203010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0203010180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0203010182',
      label: 'CODELISTCONSTANTS.MDNCode.W0203010182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0203010182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0203010185',
      label: 'CODELISTCONSTANTS.MDNCode.W0203010185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0203010185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W02030199',
      label: 'CODELISTCONSTANTS.MDNCode.W02030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020302',
      label: 'CODELISTCONSTANTS.MDNCode.W020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02030201',
      label: 'CODELISTCONSTANTS.MDNCode.W02030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02030299',
      label: 'CODELISTCONSTANTS.MDNCode.W02030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02030299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020303',
      label: 'CODELISTCONSTANTS.MDNCode.W020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W020304',
      label: 'CODELISTCONSTANTS.MDNCode.W020304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W020305',
      label: 'CODELISTCONSTANTS.MDNCode.W020305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020305.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W020306',
      label: 'CODELISTCONSTANTS.MDNCode.W020306.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020306.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W020390',
      label: 'CODELISTCONSTANTS.MDNCode.W020390.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020390.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02039001',
      label: 'CODELISTCONSTANTS.MDNCode.W02039001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039002',
      label: 'CODELISTCONSTANTS.MDNCode.W02039002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039003',
      label: 'CODELISTCONSTANTS.MDNCode.W02039003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039004',
      label: 'CODELISTCONSTANTS.MDNCode.W02039004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039005',
      label: 'CODELISTCONSTANTS.MDNCode.W02039005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039006',
      label: 'CODELISTCONSTANTS.MDNCode.W02039006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039080',
      label: 'CODELISTCONSTANTS.MDNCode.W02039080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039082',
      label: 'CODELISTCONSTANTS.MDNCode.W02039082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039085',
      label: 'CODELISTCONSTANTS.MDNCode.W02039085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02039099',
      label: 'CODELISTCONSTANTS.MDNCode.W02039099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02039099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0204',
      label: 'CODELISTCONSTANTS.MDNCode.W0204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0204.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'W0205',
      label: 'CODELISTCONSTANTS.MDNCode.W0205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0205.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W020501',
      label: 'CODELISTCONSTANTS.MDNCode.W020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02050101',
      label: 'CODELISTCONSTANTS.MDNCode.W02050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050102',
      label: 'CODELISTCONSTANTS.MDNCode.W02050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050103',
      label: 'CODELISTCONSTANTS.MDNCode.W02050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050104',
      label: 'CODELISTCONSTANTS.MDNCode.W02050104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050105',
      label: 'CODELISTCONSTANTS.MDNCode.W02050105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050106',
      label: 'CODELISTCONSTANTS.MDNCode.W02050106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050107',
      label: 'CODELISTCONSTANTS.MDNCode.W02050107.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050108',
      label: 'CODELISTCONSTANTS.MDNCode.W02050108.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050108.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050109',
      label: 'CODELISTCONSTANTS.MDNCode.W02050109.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050110',
      label: 'CODELISTCONSTANTS.MDNCode.W02050110.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050110.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050111',
      label: 'CODELISTCONSTANTS.MDNCode.W02050111.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050111.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050112',
      label: 'CODELISTCONSTANTS.MDNCode.W02050112.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050112.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050113',
      label: 'CODELISTCONSTANTS.MDNCode.W02050113.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050113.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050114',
      label: 'CODELISTCONSTANTS.MDNCode.W02050114.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050114.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050115',
      label: 'CODELISTCONSTANTS.MDNCode.W02050115.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050115.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050116',
      label: 'CODELISTCONSTANTS.MDNCode.W02050116.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050116.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050180',
      label: 'CODELISTCONSTANTS.MDNCode.W02050180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050182',
      label: 'CODELISTCONSTANTS.MDNCode.W02050182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050185',
      label: 'CODELISTCONSTANTS.MDNCode.W02050185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050199',
      label: 'CODELISTCONSTANTS.MDNCode.W02050199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020502',
      label: 'CODELISTCONSTANTS.MDNCode.W020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02050201',
      label: 'CODELISTCONSTANTS.MDNCode.W02050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050202',
      label: 'CODELISTCONSTANTS.MDNCode.W02050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050280',
      label: 'CODELISTCONSTANTS.MDNCode.W02050280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050282',
      label: 'CODELISTCONSTANTS.MDNCode.W02050282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050285',
      label: 'CODELISTCONSTANTS.MDNCode.W02050285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050299',
      label: 'CODELISTCONSTANTS.MDNCode.W02050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020503',
      label: 'CODELISTCONSTANTS.MDNCode.W020503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020503.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02050301',
      label: 'CODELISTCONSTANTS.MDNCode.W02050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050380',
      label: 'CODELISTCONSTANTS.MDNCode.W02050380.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050382',
      label: 'CODELISTCONSTANTS.MDNCode.W02050382.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050385',
      label: 'CODELISTCONSTANTS.MDNCode.W02050385.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02050399',
      label: 'CODELISTCONSTANTS.MDNCode.W02050399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02050399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0206',
      label: 'CODELISTCONSTANTS.MDNCode.W0206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0206.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W020601',
      label: 'CODELISTCONSTANTS.MDNCode.W020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020601.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02060101',
      label: 'CODELISTCONSTANTS.MDNCode.W02060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060102',
      label: 'CODELISTCONSTANTS.MDNCode.W02060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060180',
      label: 'CODELISTCONSTANTS.MDNCode.W02060180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060182',
      label: 'CODELISTCONSTANTS.MDNCode.W02060182.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060185',
      label: 'CODELISTCONSTANTS.MDNCode.W02060185.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060199',
      label: 'CODELISTCONSTANTS.MDNCode.W02060199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020602',
      label: 'CODELISTCONSTANTS.MDNCode.W020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020602.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02060201',
      label: 'CODELISTCONSTANTS.MDNCode.W02060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060280',
      label: 'CODELISTCONSTANTS.MDNCode.W02060280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060282',
      label: 'CODELISTCONSTANTS.MDNCode.W02060282.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060285',
      label: 'CODELISTCONSTANTS.MDNCode.W02060285.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02060299',
      label: 'CODELISTCONSTANTS.MDNCode.W02060299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02060299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020690',
      label: 'CODELISTCONSTANTS.MDNCode.W020690.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020690.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02069001',
      label: 'CODELISTCONSTANTS.MDNCode.W02069001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069002',
      label: 'CODELISTCONSTANTS.MDNCode.W02069002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069003',
      label: 'CODELISTCONSTANTS.MDNCode.W02069003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069004',
      label: 'CODELISTCONSTANTS.MDNCode.W02069004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069005',
      label: 'CODELISTCONSTANTS.MDNCode.W02069005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069006',
      label: 'CODELISTCONSTANTS.MDNCode.W02069006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069080',
      label: 'CODELISTCONSTANTS.MDNCode.W02069080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069082',
      label: 'CODELISTCONSTANTS.MDNCode.W02069082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069085',
      label: 'CODELISTCONSTANTS.MDNCode.W02069085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02069099',
      label: 'CODELISTCONSTANTS.MDNCode.W02069099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02069099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0207',
      label: 'CODELISTCONSTANTS.MDNCode.W0207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0207.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W020701',
      label: 'CODELISTCONSTANTS.MDNCode.W020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020701.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02070101',
      label: 'CODELISTCONSTANTS.MDNCode.W02070101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02070102',
      label: 'CODELISTCONSTANTS.MDNCode.W02070102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02070103',
      label: 'CODELISTCONSTANTS.MDNCode.W02070103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020702',
      label: 'CODELISTCONSTANTS.MDNCode.W020702.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W020703',
      label: 'CODELISTCONSTANTS.MDNCode.W020703.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020703.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02070301',
      label: 'CODELISTCONSTANTS.MDNCode.W02070301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02070302',
      label: 'CODELISTCONSTANTS.MDNCode.W02070302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02070303',
      label: 'CODELISTCONSTANTS.MDNCode.W02070303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02070304',
      label: 'CODELISTCONSTANTS.MDNCode.W02070304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020704',
      label: 'CODELISTCONSTANTS.MDNCode.W020704.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020704.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02070401',
      label: 'CODELISTCONSTANTS.MDNCode.W02070401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02070402',
      label: 'CODELISTCONSTANTS.MDNCode.W02070402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02070403',
      label: 'CODELISTCONSTANTS.MDNCode.W02070403.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02070404',
      label: 'CODELISTCONSTANTS.MDNCode.W02070404.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02070404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W020790',
      label: 'CODELISTCONSTANTS.MDNCode.W020790.label',
      description: 'CODELISTCONSTANTS.MDNCode.W020790.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W02079001',
      label: 'CODELISTCONSTANTS.MDNCode.W02079001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079002',
      label: 'CODELISTCONSTANTS.MDNCode.W02079002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079003',
      label: 'CODELISTCONSTANTS.MDNCode.W02079003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079004',
      label: 'CODELISTCONSTANTS.MDNCode.W02079004.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079006',
      label: 'CODELISTCONSTANTS.MDNCode.W02079006.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079007',
      label: 'CODELISTCONSTANTS.MDNCode.W02079007.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079008',
      label: 'CODELISTCONSTANTS.MDNCode.W02079008.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079009',
      label: 'CODELISTCONSTANTS.MDNCode.W02079009.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079011',
      label: 'CODELISTCONSTANTS.MDNCode.W02079011.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079011.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079012',
      label: 'CODELISTCONSTANTS.MDNCode.W02079012.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079012.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079013',
      label: 'CODELISTCONSTANTS.MDNCode.W02079013.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079013.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079014',
      label: 'CODELISTCONSTANTS.MDNCode.W02079014.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079014.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079015',
      label: 'CODELISTCONSTANTS.MDNCode.W02079015.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079015.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079016',
      label: 'CODELISTCONSTANTS.MDNCode.W02079016.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079016.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079017',
      label: 'CODELISTCONSTANTS.MDNCode.W02079017.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079017.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079080',
      label: 'CODELISTCONSTANTS.MDNCode.W02079080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079082',
      label: 'CODELISTCONSTANTS.MDNCode.W02079082.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079085',
      label: 'CODELISTCONSTANTS.MDNCode.W02079085.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W02079099',
      label: 'CODELISTCONSTANTS.MDNCode.W02079099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W02079099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0299',
      label: 'CODELISTCONSTANTS.MDNCode.W0299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'W05',
      label: 'CODELISTCONSTANTS.MDNCode.W05.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'W0501',
      label: 'CODELISTCONSTANTS.MDNCode.W0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W050101',
      label: 'CODELISTCONSTANTS.MDNCode.W050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05010101',
      label: 'CODELISTCONSTANTS.MDNCode.W05010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05010101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0501010101',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W050101010101',
      label: 'CODELISTCONSTANTS.MDNCode.W050101010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050101010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W050101010102',
      label: 'CODELISTCONSTANTS.MDNCode.W050101010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050101010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0501010102',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W050101010201',
      label: 'CODELISTCONSTANTS.MDNCode.W050101010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050101010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W050101010202',
      label: 'CODELISTCONSTANTS.MDNCode.W050101010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050101010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0501010103',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0501010180',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0501010199',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W05010102',
      label: 'CODELISTCONSTANTS.MDNCode.W05010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05010102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0501010201',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0501010202',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0501010203',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0501010280',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0501010299',
      label: 'CODELISTCONSTANTS.MDNCode.W0501010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501010299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W050102',
      label: 'CODELISTCONSTANTS.MDNCode.W050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05010201',
      label: 'CODELISTCONSTANTS.MDNCode.W05010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05010201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0501020101',
      label: 'CODELISTCONSTANTS.MDNCode.W0501020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501020101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0501020102',
      label: 'CODELISTCONSTANTS.MDNCode.W0501020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0501020102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W05010202',
      label: 'CODELISTCONSTANTS.MDNCode.W05010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05010203',
      label: 'CODELISTCONSTANTS.MDNCode.W05010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05010203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05010280',
      label: 'CODELISTCONSTANTS.MDNCode.W05010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05010280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05010299',
      label: 'CODELISTCONSTANTS.MDNCode.W05010299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05010299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W050180',
      label: 'CODELISTCONSTANTS.MDNCode.W050180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050180.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W050190',
      label: 'CODELISTCONSTANTS.MDNCode.W050190.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050190.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05019001',
      label: 'CODELISTCONSTANTS.MDNCode.W05019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05019001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05019002',
      label: 'CODELISTCONSTANTS.MDNCode.W05019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05019002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05019003',
      label: 'CODELISTCONSTANTS.MDNCode.W05019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05019003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05019005',
      label: 'CODELISTCONSTANTS.MDNCode.W05019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05019005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05019099',
      label: 'CODELISTCONSTANTS.MDNCode.W05019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05019099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0502',
      label: 'CODELISTCONSTANTS.MDNCode.W0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0502.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W050201',
      label: 'CODELISTCONSTANTS.MDNCode.W050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05020101',
      label: 'CODELISTCONSTANTS.MDNCode.W05020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05020102',
      label: 'CODELISTCONSTANTS.MDNCode.W05020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05020103',
      label: 'CODELISTCONSTANTS.MDNCode.W05020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05020103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05020104',
      label: 'CODELISTCONSTANTS.MDNCode.W05020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05020104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05020105',
      label: 'CODELISTCONSTANTS.MDNCode.W05020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05020105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05020106',
      label: 'CODELISTCONSTANTS.MDNCode.W05020106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05020106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05020180',
      label: 'CODELISTCONSTANTS.MDNCode.W05020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05020180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05020199',
      label: 'CODELISTCONSTANTS.MDNCode.W05020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W050280',
      label: 'CODELISTCONSTANTS.MDNCode.W050280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W050299',
      label: 'CODELISTCONSTANTS.MDNCode.W050299.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'W0503',
      label: 'CODELISTCONSTANTS.MDNCode.W0503.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'W050301',
      label: 'CODELISTCONSTANTS.MDNCode.W050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05030101',
      label: 'CODELISTCONSTANTS.MDNCode.W05030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0503010101',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010102',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010103',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010104',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010105',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010106',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010106.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010106.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010180',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010199',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W05030102',
      label: 'CODELISTCONSTANTS.MDNCode.W05030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0503010201',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010201.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W050301020101',
      label: 'CODELISTCONSTANTS.MDNCode.W050301020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301020101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W050301020102',
      label: 'CODELISTCONSTANTS.MDNCode.W050301020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301020102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W050301020180',
      label: 'CODELISTCONSTANTS.MDNCode.W050301020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301020180.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0503010202',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010202.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W050301020201',
      label: 'CODELISTCONSTANTS.MDNCode.W050301020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301020201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W050301020202',
      label: 'CODELISTCONSTANTS.MDNCode.W050301020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301020202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W050301020280',
      label: 'CODELISTCONSTANTS.MDNCode.W050301020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301020280.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0503010203',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010203.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010204',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010204.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010205',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010205.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010205.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010206',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010206.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010206.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010207',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010207.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010207.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010208',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010208.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010208.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010209',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010209.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010209.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010280',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503010290',
      label: 'CODELISTCONSTANTS.MDNCode.W0503010290.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503010290.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W050301029001',
      label: 'CODELISTCONSTANTS.MDNCode.W050301029001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301029001.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W050301029099',
      label: 'CODELISTCONSTANTS.MDNCode.W050301029099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050301029099.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W05030199',
      label: 'CODELISTCONSTANTS.MDNCode.W05030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W050302',
      label: 'CODELISTCONSTANTS.MDNCode.W050302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05030201',
      label: 'CODELISTCONSTANTS.MDNCode.W05030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0503020101',
      label: 'CODELISTCONSTANTS.MDNCode.W0503020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503020101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'W050302010101',
      label: 'CODELISTCONSTANTS.MDNCode.W050302010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050302010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W050302010102',
      label: 'CODELISTCONSTANTS.MDNCode.W050302010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050302010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'W0503020180',
      label: 'CODELISTCONSTANTS.MDNCode.W0503020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503020180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503020199',
      label: 'CODELISTCONSTANTS.MDNCode.W0503020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503020199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W05030202',
      label: 'CODELISTCONSTANTS.MDNCode.W05030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0503020201',
      label: 'CODELISTCONSTANTS.MDNCode.W0503020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503020202',
      label: 'CODELISTCONSTANTS.MDNCode.W0503020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503020280',
      label: 'CODELISTCONSTANTS.MDNCode.W0503020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503020280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W050303',
      label: 'CODELISTCONSTANTS.MDNCode.W050303.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05030301',
      label: 'CODELISTCONSTANTS.MDNCode.W05030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0503030101',
      label: 'CODELISTCONSTANTS.MDNCode.W0503030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503030199',
      label: 'CODELISTCONSTANTS.MDNCode.W0503030199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503030199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W05030302',
      label: 'CODELISTCONSTANTS.MDNCode.W05030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05030380',
      label: 'CODELISTCONSTANTS.MDNCode.W05030380.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05030399',
      label: 'CODELISTCONSTANTS.MDNCode.W05030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W050304',
      label: 'CODELISTCONSTANTS.MDNCode.W050304.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050304.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05030401',
      label: 'CODELISTCONSTANTS.MDNCode.W05030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0503040101',
      label: 'CODELISTCONSTANTS.MDNCode.W0503040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503040101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503040102',
      label: 'CODELISTCONSTANTS.MDNCode.W0503040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503040103',
      label: 'CODELISTCONSTANTS.MDNCode.W0503040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503040103.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503040104',
      label: 'CODELISTCONSTANTS.MDNCode.W0503040104.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503040104.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503040105',
      label: 'CODELISTCONSTANTS.MDNCode.W0503040105.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503040105.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503040180',
      label: 'CODELISTCONSTANTS.MDNCode.W0503040180.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503040180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503040199',
      label: 'CODELISTCONSTANTS.MDNCode.W0503040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503040199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W05030402',
      label: 'CODELISTCONSTANTS.MDNCode.W05030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05030480',
      label: 'CODELISTCONSTANTS.MDNCode.W05030480.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05030499',
      label: 'CODELISTCONSTANTS.MDNCode.W05030499.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W050305',
      label: 'CODELISTCONSTANTS.MDNCode.W050305.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050305.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05030501',
      label: 'CODELISTCONSTANTS.MDNCode.W05030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05030502',
      label: 'CODELISTCONSTANTS.MDNCode.W05030502.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05030580',
      label: 'CODELISTCONSTANTS.MDNCode.W05030580.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05030599',
      label: 'CODELISTCONSTANTS.MDNCode.W05030599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05030599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W050390',
      label: 'CODELISTCONSTANTS.MDNCode.W050390.label',
      description: 'CODELISTCONSTANTS.MDNCode.W050390.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'W05039001',
      label: 'CODELISTCONSTANTS.MDNCode.W05039001.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05039001.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0503900101',
      label: 'CODELISTCONSTANTS.MDNCode.W0503900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503900101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503900102',
      label: 'CODELISTCONSTANTS.MDNCode.W0503900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503900102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503900199',
      label: 'CODELISTCONSTANTS.MDNCode.W0503900199.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503900199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W05039002',
      label: 'CODELISTCONSTANTS.MDNCode.W05039002.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05039002.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'W0503900201',
      label: 'CODELISTCONSTANTS.MDNCode.W0503900201.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503900201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W0503900202',
      label: 'CODELISTCONSTANTS.MDNCode.W0503900202.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0503900202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'W05039080',
      label: 'CODELISTCONSTANTS.MDNCode.W05039080.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05039080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W05039099',
      label: 'CODELISTCONSTANTS.MDNCode.W05039099.label',
      description: 'CODELISTCONSTANTS.MDNCode.W05039099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'W0580',
      label: 'CODELISTCONSTANTS.MDNCode.W0580.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0580.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'W0599',
      label: 'CODELISTCONSTANTS.MDNCode.W0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.W0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y',
      label: 'CODELISTCONSTANTS.MDNCode.Y.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'Y05',
      label: 'CODELISTCONSTANTS.MDNCode.Y05.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y05.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Y0501',
      label: 'CODELISTCONSTANTS.MDNCode.Y0501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0501.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y0502',
      label: 'CODELISTCONSTANTS.MDNCode.Y0502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0502.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y050201',
      label: 'CODELISTCONSTANTS.MDNCode.Y050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y050201.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y050202',
      label: 'CODELISTCONSTANTS.MDNCode.Y050202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y050202.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0503',
      label: 'CODELISTCONSTANTS.MDNCode.Y0503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0503.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y0504',
      label: 'CODELISTCONSTANTS.MDNCode.Y0504.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0504.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y0580',
      label: 'CODELISTCONSTANTS.MDNCode.Y0580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0580.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y0599',
      label: 'CODELISTCONSTANTS.MDNCode.Y0599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y06',
      label: 'CODELISTCONSTANTS.MDNCode.Y06.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y06.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Y0603',
      label: 'CODELISTCONSTANTS.MDNCode.Y0603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0603.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y060303',
      label: 'CODELISTCONSTANTS.MDNCode.Y060303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060306',
      label: 'CODELISTCONSTANTS.MDNCode.Y060306.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060306.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060307',
      label: 'CODELISTCONSTANTS.MDNCode.Y060307.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060307.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060308',
      label: 'CODELISTCONSTANTS.MDNCode.Y060308.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060308.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060309',
      label: 'CODELISTCONSTANTS.MDNCode.Y060309.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060309.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060312',
      label: 'CODELISTCONSTANTS.MDNCode.Y060312.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060312.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060315',
      label: 'CODELISTCONSTANTS.MDNCode.Y060315.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060315.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060318',
      label: 'CODELISTCONSTANTS.MDNCode.Y060318.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060318.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060380',
      label: 'CODELISTCONSTANTS.MDNCode.Y060380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060399',
      label: 'CODELISTCONSTANTS.MDNCode.Y060399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0604',
      label: 'CODELISTCONSTANTS.MDNCode.Y0604.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0604.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y060403',
      label: 'CODELISTCONSTANTS.MDNCode.Y060403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060406',
      label: 'CODELISTCONSTANTS.MDNCode.Y060406.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060406.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060499',
      label: 'CODELISTCONSTANTS.MDNCode.Y060499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0606',
      label: 'CODELISTCONSTANTS.MDNCode.Y0606.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0606.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y060603',
      label: 'CODELISTCONSTANTS.MDNCode.Y060603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060606',
      label: 'CODELISTCONSTANTS.MDNCode.Y060606.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060606.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060609',
      label: 'CODELISTCONSTANTS.MDNCode.Y060609.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060609.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060612',
      label: 'CODELISTCONSTANTS.MDNCode.Y060612.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060612.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060613',
      label: 'CODELISTCONSTANTS.MDNCode.Y060613.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060613.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060615',
      label: 'CODELISTCONSTANTS.MDNCode.Y060615.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060615.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060618',
      label: 'CODELISTCONSTANTS.MDNCode.Y060618.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060618.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060619',
      label: 'CODELISTCONSTANTS.MDNCode.Y060619.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060619.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060621',
      label: 'CODELISTCONSTANTS.MDNCode.Y060621.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060621.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060624',
      label: 'CODELISTCONSTANTS.MDNCode.Y060624.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060624.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060627',
      label: 'CODELISTCONSTANTS.MDNCode.Y060627.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060627.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060630',
      label: 'CODELISTCONSTANTS.MDNCode.Y060630.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060630.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060680',
      label: 'CODELISTCONSTANTS.MDNCode.Y060680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060680.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y060699',
      label: 'CODELISTCONSTANTS.MDNCode.Y060699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y060699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0612',
      label: 'CODELISTCONSTANTS.MDNCode.Y0612.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0612.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y061203',
      label: 'CODELISTCONSTANTS.MDNCode.Y061203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061206',
      label: 'CODELISTCONSTANTS.MDNCode.Y061206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061206.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Y06120601',
      label: 'CODELISTCONSTANTS.MDNCode.Y06120601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y06120601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y06120602',
      label: 'CODELISTCONSTANTS.MDNCode.Y06120602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y06120602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y061209',
      label: 'CODELISTCONSTANTS.MDNCode.Y061209.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061209.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061212',
      label: 'CODELISTCONSTANTS.MDNCode.Y061212.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061212.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Y06121201',
      label: 'CODELISTCONSTANTS.MDNCode.Y06121201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y06121201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y06121202',
      label: 'CODELISTCONSTANTS.MDNCode.Y06121202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y06121202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y06121203',
      label: 'CODELISTCONSTANTS.MDNCode.Y06121203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y06121203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y061215',
      label: 'CODELISTCONSTANTS.MDNCode.Y061215.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061215.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061216',
      label: 'CODELISTCONSTANTS.MDNCode.Y061216.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061216.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061217',
      label: 'CODELISTCONSTANTS.MDNCode.Y061217.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061217.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061218',
      label: 'CODELISTCONSTANTS.MDNCode.Y061218.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061218.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061219',
      label: 'CODELISTCONSTANTS.MDNCode.Y061219.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061219.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061280',
      label: 'CODELISTCONSTANTS.MDNCode.Y061280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061280.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061299',
      label: 'CODELISTCONSTANTS.MDNCode.Y061299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0618',
      label: 'CODELISTCONSTANTS.MDNCode.Y0618.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0618.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y061803',
      label: 'CODELISTCONSTANTS.MDNCode.Y061803.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061803.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061806',
      label: 'CODELISTCONSTANTS.MDNCode.Y061806.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061806.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061809',
      label: 'CODELISTCONSTANTS.MDNCode.Y061809.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061809.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061812',
      label: 'CODELISTCONSTANTS.MDNCode.Y061812.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061812.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061815',
      label: 'CODELISTCONSTANTS.MDNCode.Y061815.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061815.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061818',
      label: 'CODELISTCONSTANTS.MDNCode.Y061818.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061818.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y061899',
      label: 'CODELISTCONSTANTS.MDNCode.Y061899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y061899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0624',
      label: 'CODELISTCONSTANTS.MDNCode.Y0624.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0624.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y062403',
      label: 'CODELISTCONSTANTS.MDNCode.Y062403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y062403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y062409',
      label: 'CODELISTCONSTANTS.MDNCode.Y062409.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y062409.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y062412',
      label: 'CODELISTCONSTANTS.MDNCode.Y062412.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y062412.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y062415',
      label: 'CODELISTCONSTANTS.MDNCode.Y062415.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y062415.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y062418',
      label: 'CODELISTCONSTANTS.MDNCode.Y062418.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y062418.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y062499',
      label: 'CODELISTCONSTANTS.MDNCode.Y062499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y062499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0627',
      label: 'CODELISTCONSTANTS.MDNCode.Y0627.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0627.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y062703',
      label: 'CODELISTCONSTANTS.MDNCode.Y062703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y062703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0630',
      label: 'CODELISTCONSTANTS.MDNCode.Y0630.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0630.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y063018',
      label: 'CODELISTCONSTANTS.MDNCode.Y063018.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y063018.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y063021',
      label: 'CODELISTCONSTANTS.MDNCode.Y063021.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y063021.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y063099',
      label: 'CODELISTCONSTANTS.MDNCode.Y063099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y063099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0633',
      label: 'CODELISTCONSTANTS.MDNCode.Y0633.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0633.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y063303',
      label: 'CODELISTCONSTANTS.MDNCode.Y063303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y063303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0699',
      label: 'CODELISTCONSTANTS.MDNCode.Y0699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0699.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y09',
      label: 'CODELISTCONSTANTS.MDNCode.Y09.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y09.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Y0903',
      label: 'CODELISTCONSTANTS.MDNCode.Y0903.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0903.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y090312',
      label: 'CODELISTCONSTANTS.MDNCode.Y090312.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y090312.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y090327',
      label: 'CODELISTCONSTANTS.MDNCode.Y090327.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y090327.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y090342',
      label: 'CODELISTCONSTANTS.MDNCode.Y090342.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y090342.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y090399',
      label: 'CODELISTCONSTANTS.MDNCode.Y090399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y090399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0906',
      label: 'CODELISTCONSTANTS.MDNCode.Y0906.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0906.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y090612',
      label: 'CODELISTCONSTANTS.MDNCode.Y090612.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y090612.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y090618',
      label: 'CODELISTCONSTANTS.MDNCode.Y090618.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y090618.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y090699',
      label: 'CODELISTCONSTANTS.MDNCode.Y090699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y090699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0912',
      label: 'CODELISTCONSTANTS.MDNCode.Y0912.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0912.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y091203',
      label: 'CODELISTCONSTANTS.MDNCode.Y091203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y091203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y091209',
      label: 'CODELISTCONSTANTS.MDNCode.Y091209.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y091209.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y091212',
      label: 'CODELISTCONSTANTS.MDNCode.Y091212.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y091212.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y091215',
      label: 'CODELISTCONSTANTS.MDNCode.Y091215.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y091215.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y091299',
      label: 'CODELISTCONSTANTS.MDNCode.Y091299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y091299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0933',
      label: 'CODELISTCONSTANTS.MDNCode.Y0933.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0933.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y093303',
      label: 'CODELISTCONSTANTS.MDNCode.Y093303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y093303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y093312',
      label: 'CODELISTCONSTANTS.MDNCode.Y093312.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y093312.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y093399',
      label: 'CODELISTCONSTANTS.MDNCode.Y093399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y093399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y0999',
      label: 'CODELISTCONSTANTS.MDNCode.Y0999.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y0999.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y12',
      label: 'CODELISTCONSTANTS.MDNCode.Y12.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y12.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Y1203',
      label: 'CODELISTCONSTANTS.MDNCode.Y1203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y120303',
      label: 'CODELISTCONSTANTS.MDNCode.Y120303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120303.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120306',
      label: 'CODELISTCONSTANTS.MDNCode.Y120306.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120306.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Y12030601',
      label: 'CODELISTCONSTANTS.MDNCode.Y12030601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y12030601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y12030602',
      label: 'CODELISTCONSTANTS.MDNCode.Y12030602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y12030602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y12030603',
      label: 'CODELISTCONSTANTS.MDNCode.Y12030603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y12030603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y120315',
      label: 'CODELISTCONSTANTS.MDNCode.Y120315.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120315.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120318',
      label: 'CODELISTCONSTANTS.MDNCode.Y120318.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120318.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120380',
      label: 'CODELISTCONSTANTS.MDNCode.Y120380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120380.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120399',
      label: 'CODELISTCONSTANTS.MDNCode.Y120399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1206',
      label: 'CODELISTCONSTANTS.MDNCode.Y1206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1206.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y120603',
      label: 'CODELISTCONSTANTS.MDNCode.Y120603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120606',
      label: 'CODELISTCONSTANTS.MDNCode.Y120606.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120606.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120609',
      label: 'CODELISTCONSTANTS.MDNCode.Y120609.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120609.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120612',
      label: 'CODELISTCONSTANTS.MDNCode.Y120612.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120612.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120680',
      label: 'CODELISTCONSTANTS.MDNCode.Y120680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120680.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y120699',
      label: 'CODELISTCONSTANTS.MDNCode.Y120699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y120699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1218',
      label: 'CODELISTCONSTANTS.MDNCode.Y1218.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1218.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y121801',
      label: 'CODELISTCONSTANTS.MDNCode.Y121801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y121801.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y121806',
      label: 'CODELISTCONSTANTS.MDNCode.Y121806.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y121806.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y121809',
      label: 'CODELISTCONSTANTS.MDNCode.Y121809.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y121809.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y121821',
      label: 'CODELISTCONSTANTS.MDNCode.Y121821.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y121821.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y121899',
      label: 'CODELISTCONSTANTS.MDNCode.Y121899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y121899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1221',
      label: 'CODELISTCONSTANTS.MDNCode.Y1221.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1221.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y122103',
      label: 'CODELISTCONSTANTS.MDNCode.Y122103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122106',
      label: 'CODELISTCONSTANTS.MDNCode.Y122106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122109',
      label: 'CODELISTCONSTANTS.MDNCode.Y122109.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122109.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122124',
      label: 'CODELISTCONSTANTS.MDNCode.Y122124.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122124.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122127',
      label: 'CODELISTCONSTANTS.MDNCode.Y122127.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122127.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122130',
      label: 'CODELISTCONSTANTS.MDNCode.Y122130.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122130.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122199',
      label: 'CODELISTCONSTANTS.MDNCode.Y122199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1224',
      label: 'CODELISTCONSTANTS.MDNCode.Y1224.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1224.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y122403',
      label: 'CODELISTCONSTANTS.MDNCode.Y122403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122403.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122406',
      label: 'CODELISTCONSTANTS.MDNCode.Y122406.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122406.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122409',
      label: 'CODELISTCONSTANTS.MDNCode.Y122409.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122409.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122410',
      label: 'CODELISTCONSTANTS.MDNCode.Y122410.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122410.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122411',
      label: 'CODELISTCONSTANTS.MDNCode.Y122411.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122411.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122412',
      label: 'CODELISTCONSTANTS.MDNCode.Y122412.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122412.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122499',
      label: 'CODELISTCONSTANTS.MDNCode.Y122499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1227',
      label: 'CODELISTCONSTANTS.MDNCode.Y1227.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1227.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y122703',
      label: 'CODELISTCONSTANTS.MDNCode.Y122703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y122799',
      label: 'CODELISTCONSTANTS.MDNCode.Y122799.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y122799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1299',
      label: 'CODELISTCONSTANTS.MDNCode.Y1299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1299.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y15',
      label: 'CODELISTCONSTANTS.MDNCode.Y15.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y15.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Y1509',
      label: 'CODELISTCONSTANTS.MDNCode.Y1509.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1509.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y150912',
      label: 'CODELISTCONSTANTS.MDNCode.Y150912.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y150912.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y150915',
      label: 'CODELISTCONSTANTS.MDNCode.Y150915.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y150915.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y150918',
      label: 'CODELISTCONSTANTS.MDNCode.Y150918.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y150918.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y150921',
      label: 'CODELISTCONSTANTS.MDNCode.Y150921.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y150921.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y150999',
      label: 'CODELISTCONSTANTS.MDNCode.Y150999.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y150999.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1599',
      label: 'CODELISTCONSTANTS.MDNCode.Y1599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1599.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y18',
      label: 'CODELISTCONSTANTS.MDNCode.Y18.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y18.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Y1803',
      label: 'CODELISTCONSTANTS.MDNCode.Y1803.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1803.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y1809',
      label: 'CODELISTCONSTANTS.MDNCode.Y1809.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1809.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y180918',
      label: 'CODELISTCONSTANTS.MDNCode.Y180918.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y180918.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y180939',
      label: 'CODELISTCONSTANTS.MDNCode.Y180939.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y180939.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y180980',
      label: 'CODELISTCONSTANTS.MDNCode.Y180980.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y180980.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y180999',
      label: 'CODELISTCONSTANTS.MDNCode.Y180999.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y180999.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1818',
      label: 'CODELISTCONSTANTS.MDNCode.Y1818.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1818.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y181803',
      label: 'CODELISTCONSTANTS.MDNCode.Y181803.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y181803.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y181806',
      label: 'CODELISTCONSTANTS.MDNCode.Y181806.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y181806.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y181809',
      label: 'CODELISTCONSTANTS.MDNCode.Y181809.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y181809.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y181899',
      label: 'CODELISTCONSTANTS.MDNCode.Y181899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y181899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1830',
      label: 'CODELISTCONSTANTS.MDNCode.Y1830.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1830.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y183012',
      label: 'CODELISTCONSTANTS.MDNCode.Y183012.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y183012.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y183015',
      label: 'CODELISTCONSTANTS.MDNCode.Y183015.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y183015.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y183099',
      label: 'CODELISTCONSTANTS.MDNCode.Y183099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y183099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y1899',
      label: 'CODELISTCONSTANTS.MDNCode.Y1899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y1899.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y21',
      label: 'CODELISTCONSTANTS.MDNCode.Y21.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y21.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Y2103',
      label: 'CODELISTCONSTANTS.MDNCode.Y2103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y210306',
      label: 'CODELISTCONSTANTS.MDNCode.Y210306.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210306.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210321',
      label: 'CODELISTCONSTANTS.MDNCode.Y210321.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210321.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210324',
      label: 'CODELISTCONSTANTS.MDNCode.Y210324.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210324.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210330',
      label: 'CODELISTCONSTANTS.MDNCode.Y210330.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210330.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210333',
      label: 'CODELISTCONSTANTS.MDNCode.Y210333.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210333.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210336',
      label: 'CODELISTCONSTANTS.MDNCode.Y210336.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210336.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210399',
      label: 'CODELISTCONSTANTS.MDNCode.Y210399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210399.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2106',
      label: 'CODELISTCONSTANTS.MDNCode.Y2106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2106.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y210603',
      label: 'CODELISTCONSTANTS.MDNCode.Y210603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210606',
      label: 'CODELISTCONSTANTS.MDNCode.Y210606.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210606.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210609',
      label: 'CODELISTCONSTANTS.MDNCode.Y210609.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210609.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210699',
      label: 'CODELISTCONSTANTS.MDNCode.Y210699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2109',
      label: 'CODELISTCONSTANTS.MDNCode.Y2109.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2109.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y210905',
      label: 'CODELISTCONSTANTS.MDNCode.Y210905.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210905.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210906',
      label: 'CODELISTCONSTANTS.MDNCode.Y210906.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210906.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210915',
      label: 'CODELISTCONSTANTS.MDNCode.Y210915.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210915.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210924',
      label: 'CODELISTCONSTANTS.MDNCode.Y210924.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210924.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y210999',
      label: 'CODELISTCONSTANTS.MDNCode.Y210999.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y210999.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2115',
      label: 'CODELISTCONSTANTS.MDNCode.Y2115.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2115.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y211518',
      label: 'CODELISTCONSTANTS.MDNCode.Y211518.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y211518.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y211599',
      label: 'CODELISTCONSTANTS.MDNCode.Y211599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y211599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2127',
      label: 'CODELISTCONSTANTS.MDNCode.Y2127.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2127.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y212703',
      label: 'CODELISTCONSTANTS.MDNCode.Y212703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y212703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y212706',
      label: 'CODELISTCONSTANTS.MDNCode.Y212706.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y212706.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2136',
      label: 'CODELISTCONSTANTS.MDNCode.Y2136.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2136.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y213603',
      label: 'CODELISTCONSTANTS.MDNCode.Y213603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y213603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y213609',
      label: 'CODELISTCONSTANTS.MDNCode.Y213609.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y213609.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y213624',
      label: 'CODELISTCONSTANTS.MDNCode.Y213624.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y213624.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y213699',
      label: 'CODELISTCONSTANTS.MDNCode.Y213699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y213699.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2139',
      label: 'CODELISTCONSTANTS.MDNCode.Y2139.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2139.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y213924',
      label: 'CODELISTCONSTANTS.MDNCode.Y213924.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y213924.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y213999',
      label: 'CODELISTCONSTANTS.MDNCode.Y213999.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y213999.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2142',
      label: 'CODELISTCONSTANTS.MDNCode.Y2142.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2142.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y214209',
      label: 'CODELISTCONSTANTS.MDNCode.Y214209.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y214209.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y214212',
      label: 'CODELISTCONSTANTS.MDNCode.Y214212.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y214212.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y214224',
      label: 'CODELISTCONSTANTS.MDNCode.Y214224.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y214224.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y214299',
      label: 'CODELISTCONSTANTS.MDNCode.Y214299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y214299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2145',
      label: 'CODELISTCONSTANTS.MDNCode.Y2145.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2145.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y214506',
      label: 'CODELISTCONSTANTS.MDNCode.Y214506.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y214506.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Y21450601',
      label: 'CODELISTCONSTANTS.MDNCode.Y21450601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y21450601.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Y2145060101',
      label: 'CODELISTCONSTANTS.MDNCode.Y2145060101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2145060101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Y2145060102',
      label: 'CODELISTCONSTANTS.MDNCode.Y2145060102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2145060102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Y21450602',
      label: 'CODELISTCONSTANTS.MDNCode.Y21450602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y21450602.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Y2145060201',
      label: 'CODELISTCONSTANTS.MDNCode.Y2145060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2145060201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Y2145060202',
      label: 'CODELISTCONSTANTS.MDNCode.Y2145060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2145060202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Y2145060203',
      label: 'CODELISTCONSTANTS.MDNCode.Y2145060203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2145060203.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Y2145060204',
      label: 'CODELISTCONSTANTS.MDNCode.Y2145060204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2145060204.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Y21450603',
      label: 'CODELISTCONSTANTS.MDNCode.Y21450603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y21450603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y214509',
      label: 'CODELISTCONSTANTS.MDNCode.Y214509.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y214509.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Y21450901',
      label: 'CODELISTCONSTANTS.MDNCode.Y21450901.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y21450901.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y21450902',
      label: 'CODELISTCONSTANTS.MDNCode.Y21450902.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y21450902.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y21450903',
      label: 'CODELISTCONSTANTS.MDNCode.Y21450903.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y21450903.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y21450904',
      label: 'CODELISTCONSTANTS.MDNCode.Y21450904.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y21450904.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Y214580',
      label: 'CODELISTCONSTANTS.MDNCode.Y214580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y214580.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y214599',
      label: 'CODELISTCONSTANTS.MDNCode.Y214599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y214599.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2151',
      label: 'CODELISTCONSTANTS.MDNCode.Y2151.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2151.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y215112',
      label: 'CODELISTCONSTANTS.MDNCode.Y215112.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y215112.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y215199',
      label: 'CODELISTCONSTANTS.MDNCode.Y215199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y215199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2199',
      label: 'CODELISTCONSTANTS.MDNCode.Y2199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2199.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y24',
      label: 'CODELISTCONSTANTS.MDNCode.Y24.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y24.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Y2409',
      label: 'CODELISTCONSTANTS.MDNCode.Y2409.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2409.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y240918',
      label: 'CODELISTCONSTANTS.MDNCode.Y240918.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y240918.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y240999',
      label: 'CODELISTCONSTANTS.MDNCode.Y240999.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y240999.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2412',
      label: 'CODELISTCONSTANTS.MDNCode.Y2412.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2412.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y241203',
      label: 'CODELISTCONSTANTS.MDNCode.Y241203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y241203.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y241299',
      label: 'CODELISTCONSTANTS.MDNCode.Y241299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y241299.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2418',
      label: 'CODELISTCONSTANTS.MDNCode.Y2418.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2418.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y241815',
      label: 'CODELISTCONSTANTS.MDNCode.Y241815.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y241815.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y241899',
      label: 'CODELISTCONSTANTS.MDNCode.Y241899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y241899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2421',
      label: 'CODELISTCONSTANTS.MDNCode.Y2421.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2421.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Y242103',
      label: 'CODELISTCONSTANTS.MDNCode.Y242103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y242103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y242199',
      label: 'CODELISTCONSTANTS.MDNCode.Y242199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y242199.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Y2499',
      label: 'CODELISTCONSTANTS.MDNCode.Y2499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y2499.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Y99',
      label: 'CODELISTCONSTANTS.MDNCode.Y99.label',
      description: 'CODELISTCONSTANTS.MDNCode.Y99.description',
      level: 2,
      bottomLevel: true,
    },
    {
      value: 'Z',
      label: 'CODELISTCONSTANTS.MDNCode.Z.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z.description',
      level: 1,
      bottomLevel: false,
    },
    {
      value: 'Z11',
      label: 'CODELISTCONSTANTS.MDNCode.Z11.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Z1101',
      label: 'CODELISTCONSTANTS.MDNCode.Z1101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1101.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z110101',
      label: 'CODELISTCONSTANTS.MDNCode.Z110101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11010101',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010102',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010103',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010104',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010180',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010182',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010185',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010199',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110102',
      label: 'CODELISTCONSTANTS.MDNCode.Z110102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110102.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11010201',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010202',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010280',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010282',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010285',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110103',
      label: 'CODELISTCONSTANTS.MDNCode.Z110103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110103.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11010301',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010380',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010382',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010385',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110104',
      label: 'CODELISTCONSTANTS.MDNCode.Z110104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110104.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11010401',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010480',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010482',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010485',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110105',
      label: 'CODELISTCONSTANTS.MDNCode.Z110105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110105.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11010501',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010580',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010582',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010585',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110107',
      label: 'CODELISTCONSTANTS.MDNCode.Z110107.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110107.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11010701',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010780',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010782',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010782.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010785',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110108',
      label: 'CODELISTCONSTANTS.MDNCode.Z110108.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110108.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11010801',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010880',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010880.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010880.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010882',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010882.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010882.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010885',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010885.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010885.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110109',
      label: 'CODELISTCONSTANTS.MDNCode.Z110109.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110109.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11010901',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010901.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010901.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010980',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010980.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010980.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010982',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010982.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010982.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11010985',
      label: 'CODELISTCONSTANTS.MDNCode.Z11010985.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11010985.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110110',
      label: 'CODELISTCONSTANTS.MDNCode.Z110110.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110110.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11011001',
      label: 'CODELISTCONSTANTS.MDNCode.Z11011001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11011001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11011080',
      label: 'CODELISTCONSTANTS.MDNCode.Z11011080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11011080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11011082',
      label: 'CODELISTCONSTANTS.MDNCode.Z11011082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11011082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11011085',
      label: 'CODELISTCONSTANTS.MDNCode.Z11011085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11011085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110190',
      label: 'CODELISTCONSTANTS.MDNCode.Z110190.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110190.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11019001',
      label: 'CODELISTCONSTANTS.MDNCode.Z11019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11019001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11019002',
      label: 'CODELISTCONSTANTS.MDNCode.Z11019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11019002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11019004',
      label: 'CODELISTCONSTANTS.MDNCode.Z11019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11019004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11019005',
      label: 'CODELISTCONSTANTS.MDNCode.Z11019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11019005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11019080',
      label: 'CODELISTCONSTANTS.MDNCode.Z11019080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11019080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11019082',
      label: 'CODELISTCONSTANTS.MDNCode.Z11019082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11019082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11019085',
      label: 'CODELISTCONSTANTS.MDNCode.Z11019085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11019085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11019099',
      label: 'CODELISTCONSTANTS.MDNCode.Z11019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11019099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1102',
      label: 'CODELISTCONSTANTS.MDNCode.Z1102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1102.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z110201',
      label: 'CODELISTCONSTANTS.MDNCode.Z110201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11020101',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020102',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020103',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020104',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020105',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020180',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020182',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020185',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110202',
      label: 'CODELISTCONSTANTS.MDNCode.Z110202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11020201',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020280',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020282',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020285',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110203',
      label: 'CODELISTCONSTANTS.MDNCode.Z110203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11020301',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020380',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020382',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020385',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110204',
      label: 'CODELISTCONSTANTS.MDNCode.Z110204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11020401',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020480',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020482',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020485',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110205',
      label: 'CODELISTCONSTANTS.MDNCode.Z110205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110205.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110206',
      label: 'CODELISTCONSTANTS.MDNCode.Z110206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110206.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110207',
      label: 'CODELISTCONSTANTS.MDNCode.Z110207.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110207.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11020701',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020780',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020782',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020782.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11020785',
      label: 'CODELISTCONSTANTS.MDNCode.Z11020785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11020785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110290',
      label: 'CODELISTCONSTANTS.MDNCode.Z110290.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110290.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11029001',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11029002',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11029003',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11029004',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11029005',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11029080',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11029082',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11029085',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11029099',
      label: 'CODELISTCONSTANTS.MDNCode.Z11029099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11029099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1103',
      label: 'CODELISTCONSTANTS.MDNCode.Z1103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1103.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z110301',
      label: 'CODELISTCONSTANTS.MDNCode.Z110301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11030101',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030102',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030103',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030180',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030182',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030185',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110302',
      label: 'CODELISTCONSTANTS.MDNCode.Z110302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11030201',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030202',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030280',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030280.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1103028001',
      label: 'CODELISTCONSTANTS.MDNCode.Z1103028001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1103028001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1103028099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1103028099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1103028099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z11030282',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030285',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110303',
      label: 'CODELISTCONSTANTS.MDNCode.Z110303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11030301',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030301.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1103030101',
      label: 'CODELISTCONSTANTS.MDNCode.Z1103030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1103030101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1103030102',
      label: 'CODELISTCONSTANTS.MDNCode.Z1103030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1103030102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z11030302',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030380',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030382',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030385',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110304',
      label: 'CODELISTCONSTANTS.MDNCode.Z110304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110304.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11030401',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030401.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1103040101',
      label: 'CODELISTCONSTANTS.MDNCode.Z1103040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1103040101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1103040102',
      label: 'CODELISTCONSTANTS.MDNCode.Z1103040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1103040102.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z11030402',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030480',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030482',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030485',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110305',
      label: 'CODELISTCONSTANTS.MDNCode.Z110305.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110305.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11030501',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030502',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030503',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030504',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030504.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030504.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030505',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030505.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030505.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030506',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030506.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030506.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030507',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030507.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030507.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030580',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030582',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030585',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030599',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110306',
      label: 'CODELISTCONSTANTS.MDNCode.Z110306.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110306.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11030601',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030602',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030603',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030605',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030605.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030605.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030606',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030606.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030606.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030607',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030607.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030607.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030680',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030680.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030682',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030682.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030682.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030685',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030685.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030685.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030699',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110307',
      label: 'CODELISTCONSTANTS.MDNCode.Z110307.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110307.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11030701',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030702',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030703',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030703.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030704',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030704.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030704.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030780',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030782',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030782.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11030785',
      label: 'CODELISTCONSTANTS.MDNCode.Z11030785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11030785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110310',
      label: 'CODELISTCONSTANTS.MDNCode.Z110310.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110310.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110311',
      label: 'CODELISTCONSTANTS.MDNCode.Z110311.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110311.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11031101',
      label: 'CODELISTCONSTANTS.MDNCode.Z11031101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11031101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11031102',
      label: 'CODELISTCONSTANTS.MDNCode.Z11031102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11031102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11031180',
      label: 'CODELISTCONSTANTS.MDNCode.Z11031180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11031180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11031182',
      label: 'CODELISTCONSTANTS.MDNCode.Z11031182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11031182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110390',
      label: 'CODELISTCONSTANTS.MDNCode.Z110390.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110390.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11039001',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039002',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039003',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039004',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039005',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039006',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039007',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039008',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039009',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039010',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039010.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039011',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039011.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039012',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039012.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039012.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039013',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039013.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039013.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039014',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039014.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039014.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039015',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039015.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039015.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039016',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039016.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039016.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039017',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039017.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039017.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039018',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039018.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039018.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039019',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039019.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039019.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039020',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039020.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039020.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039021',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039021.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039021.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039080',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039082',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039085',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11039099',
      label: 'CODELISTCONSTANTS.MDNCode.Z11039099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11039099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1104',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z110401',
      label: 'CODELISTCONSTANTS.MDNCode.Z110401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11040101',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040102',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040103',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040104',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040105',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040106',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040107',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040107.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040180',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1104018099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104018099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z11040182',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040185',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11040199',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110402',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11040201',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040201.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1104020101',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020101.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'Z110402010101',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z110402010102',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z1104020102',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020102.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'Z110402010201',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010201.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z110402010202',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010202.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z1104020103',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020103.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'Z110402010301',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010301.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z110402010302',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010302.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z1104020104',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020104.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'Z110402010401',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010401.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z110402010402',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010402.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z1104020105',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020105.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'Z110402010501',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010501.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z110402010502',
      label: 'CODELISTCONSTANTS.MDNCode.Z110402010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110402010502.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z11040202',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1104020201',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1104020202',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z11040203',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040203.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1104020301',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1104020302',
      label: 'CODELISTCONSTANTS.MDNCode.Z1104020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1104020302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z11040299',
      label: 'CODELISTCONSTANTS.MDNCode.Z11040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110490',
      label: 'CODELISTCONSTANTS.MDNCode.Z110490.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110490.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z11049080',
      label: 'CODELISTCONSTANTS.MDNCode.Z11049080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11049080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11049082',
      label: 'CODELISTCONSTANTS.MDNCode.Z11049082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11049082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11049085',
      label: 'CODELISTCONSTANTS.MDNCode.Z11049085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11049085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11049099',
      label: 'CODELISTCONSTANTS.MDNCode.Z11049099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11049099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1105',
      label: 'CODELISTCONSTANTS.MDNCode.Z1105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1105.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z110501',
      label: 'CODELISTCONSTANTS.MDNCode.Z110501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11050101',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11050102',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11050103',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11050104',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11050105',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11050106',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11050180',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050180.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1105018001',
      label: 'CODELISTCONSTANTS.MDNCode.Z1105018001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1105018001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1105018002',
      label: 'CODELISTCONSTANTS.MDNCode.Z1105018002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1105018002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1105018099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1105018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1105018099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z11050182',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11050185',
      label: 'CODELISTCONSTANTS.MDNCode.Z11050185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11050185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110590',
      label: 'CODELISTCONSTANTS.MDNCode.Z110590.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110590.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11059001',
      label: 'CODELISTCONSTANTS.MDNCode.Z11059001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11059001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11059080',
      label: 'CODELISTCONSTANTS.MDNCode.Z11059080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11059080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11059082',
      label: 'CODELISTCONSTANTS.MDNCode.Z11059082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11059082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11059085',
      label: 'CODELISTCONSTANTS.MDNCode.Z11059085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11059085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11059099',
      label: 'CODELISTCONSTANTS.MDNCode.Z11059099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11059099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1106',
      label: 'CODELISTCONSTANTS.MDNCode.Z1106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1106.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z110601',
      label: 'CODELISTCONSTANTS.MDNCode.Z110601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110602',
      label: 'CODELISTCONSTANTS.MDNCode.Z110602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110602.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11060201',
      label: 'CODELISTCONSTANTS.MDNCode.Z11060201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11060201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11060202',
      label: 'CODELISTCONSTANTS.MDNCode.Z11060202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11060202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11060280',
      label: 'CODELISTCONSTANTS.MDNCode.Z11060280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11060280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11060282',
      label: 'CODELISTCONSTANTS.MDNCode.Z11060282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11060282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11060285',
      label: 'CODELISTCONSTANTS.MDNCode.Z11060285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11060285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110603',
      label: 'CODELISTCONSTANTS.MDNCode.Z110603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110690',
      label: 'CODELISTCONSTANTS.MDNCode.Z110690.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110690.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11069001',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11069002',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11069003',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11069004',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11069005',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11069080',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11069082',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11069085',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11069099',
      label: 'CODELISTCONSTANTS.MDNCode.Z11069099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11069099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1107',
      label: 'CODELISTCONSTANTS.MDNCode.Z1107.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1107.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z110701',
      label: 'CODELISTCONSTANTS.MDNCode.Z110701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110702',
      label: 'CODELISTCONSTANTS.MDNCode.Z110702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110703',
      label: 'CODELISTCONSTANTS.MDNCode.Z110703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110704',
      label: 'CODELISTCONSTANTS.MDNCode.Z110704.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110704.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110705',
      label: 'CODELISTCONSTANTS.MDNCode.Z110705.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110705.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110706',
      label: 'CODELISTCONSTANTS.MDNCode.Z110706.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110706.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z110707',
      label: 'CODELISTCONSTANTS.MDNCode.Z110707.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110707.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11070701',
      label: 'CODELISTCONSTANTS.MDNCode.Z11070701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11070701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11070702',
      label: 'CODELISTCONSTANTS.MDNCode.Z11070702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11070702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11070780',
      label: 'CODELISTCONSTANTS.MDNCode.Z11070780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11070780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11070785',
      label: 'CODELISTCONSTANTS.MDNCode.Z11070785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11070785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z110790',
      label: 'CODELISTCONSTANTS.MDNCode.Z110790.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z110790.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11079001',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079002',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079003',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079004',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079005',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079006',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079007',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079008',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079080',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079082',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079085',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11079099',
      label: 'CODELISTCONSTANTS.MDNCode.Z11079099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11079099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1190',
      label: 'CODELISTCONSTANTS.MDNCode.Z1190.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1190.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z119001',
      label: 'CODELISTCONSTANTS.MDNCode.Z119001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119001.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11900101',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11900102',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11900103',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11900180',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11900182',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11900185',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z119002',
      label: 'CODELISTCONSTANTS.MDNCode.Z119002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119003',
      label: 'CODELISTCONSTANTS.MDNCode.Z119003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119004',
      label: 'CODELISTCONSTANTS.MDNCode.Z119004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119005',
      label: 'CODELISTCONSTANTS.MDNCode.Z119005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119007',
      label: 'CODELISTCONSTANTS.MDNCode.Z119007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119008',
      label: 'CODELISTCONSTANTS.MDNCode.Z119008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119008.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z11900801',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11900802',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900802.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900802.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11900880',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900880.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900880.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z11900885',
      label: 'CODELISTCONSTANTS.MDNCode.Z11900885.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z11900885.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z119010',
      label: 'CODELISTCONSTANTS.MDNCode.Z119010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119010.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119011',
      label: 'CODELISTCONSTANTS.MDNCode.Z119011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119011.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119012',
      label: 'CODELISTCONSTANTS.MDNCode.Z119012.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119012.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119013',
      label: 'CODELISTCONSTANTS.MDNCode.Z119013.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119013.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119014',
      label: 'CODELISTCONSTANTS.MDNCode.Z119014.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119014.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119080',
      label: 'CODELISTCONSTANTS.MDNCode.Z119080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119080.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119082',
      label: 'CODELISTCONSTANTS.MDNCode.Z119082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119082.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119085',
      label: 'CODELISTCONSTANTS.MDNCode.Z119085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119085.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z119099',
      label: 'CODELISTCONSTANTS.MDNCode.Z119099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z119099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z12',
      label: 'CODELISTCONSTANTS.MDNCode.Z12.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Z1201',
      label: 'CODELISTCONSTANTS.MDNCode.Z1201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1201.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120101',
      label: 'CODELISTCONSTANTS.MDNCode.Z120101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120102',
      label: 'CODELISTCONSTANTS.MDNCode.Z120102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120103',
      label: 'CODELISTCONSTANTS.MDNCode.Z120103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120104',
      label: 'CODELISTCONSTANTS.MDNCode.Z120104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120105',
      label: 'CODELISTCONSTANTS.MDNCode.Z120105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120106',
      label: 'CODELISTCONSTANTS.MDNCode.Z120106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120107',
      label: 'CODELISTCONSTANTS.MDNCode.Z120107.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120107.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12010701',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010702',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010780',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010785',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010799',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010799.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010799.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120108',
      label: 'CODELISTCONSTANTS.MDNCode.Z120108.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120108.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12010801',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010880',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010880.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010880.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010882',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010882.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010882.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010885',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010885.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010885.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120109',
      label: 'CODELISTCONSTANTS.MDNCode.Z120109.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120109.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12010901',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010901.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010901.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010902',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010902.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010902.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010903',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010903.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010903.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010904',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010904.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010904.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010905',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010905.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010905.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010980',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010980.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010980.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010982',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010982.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010982.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010985',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010985.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010985.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12010999',
      label: 'CODELISTCONSTANTS.MDNCode.Z12010999.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12010999.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120110',
      label: 'CODELISTCONSTANTS.MDNCode.Z120110.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120110.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12011001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011008',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011009',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011010',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011010.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011011',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011011.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011012',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011012.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011012.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011013',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011013.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011013.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011014',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011014.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011014.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011015',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011015.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011015.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011016',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011016.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011016.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011017',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011017.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011017.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011018',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011018.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011018.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011019',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011019.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011019.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120111',
      label: 'CODELISTCONSTANTS.MDNCode.Z120111.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120111.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12011101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011180.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1201118001',
      label: 'CODELISTCONSTANTS.MDNCode.Z1201118001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1201118001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1201118099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1201118099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1201118099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12011182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120112',
      label: 'CODELISTCONSTANTS.MDNCode.Z120112.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120112.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z12011280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011299',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120113',
      label: 'CODELISTCONSTANTS.MDNCode.Z120113.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120113.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12011301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011302',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011303',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011304',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011305',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011305.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011305.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011306',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011306.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011306.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011307',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011307.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011307.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011309',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011309.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011309.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011310',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011310.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011310.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1201131001',
      label: 'CODELISTCONSTANTS.MDNCode.Z1201131001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1201131001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1201131002',
      label: 'CODELISTCONSTANTS.MDNCode.Z1201131002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1201131002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1201131003',
      label: 'CODELISTCONSTANTS.MDNCode.Z1201131003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1201131003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1201131099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1201131099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1201131099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12011311',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011311.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011311.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011312',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011312.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011312.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011313',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011313.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011313.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011314',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011314.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011314.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011399',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120114',
      label: 'CODELISTCONSTANTS.MDNCode.Z120114.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120114.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12011401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12011485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12011485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12011485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120190',
      label: 'CODELISTCONSTANTS.MDNCode.Z120190.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120190.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12019001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019008',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019009',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019010',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019010.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12019099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12019099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1202',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120201',
      label: 'CODELISTCONSTANTS.MDNCode.Z120201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020180.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1202018001',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202018001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202018001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202018002',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202018002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202018002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202018099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202018099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202018099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12020182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120202',
      label: 'CODELISTCONSTANTS.MDNCode.Z120202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120203',
      label: 'CODELISTCONSTANTS.MDNCode.Z120203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120203.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020302',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020303',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020304',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120204',
      label: 'CODELISTCONSTANTS.MDNCode.Z120204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120204.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020402',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020403',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020404',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020404.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020405',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020405.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020405.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020406',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020406.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020406.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020499',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120205',
      label: 'CODELISTCONSTANTS.MDNCode.Z120205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120205.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020501',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020502',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020503',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020504',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020504.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020504.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020505',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020505.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020505.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020506',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020506.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020506.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020507',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020507.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020507.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020508',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020508.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020508.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020509',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020509.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020509.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020510',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020510.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020510.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020511',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020511.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020511.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020580',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020582',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020585',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020599',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120206',
      label: 'CODELISTCONSTANTS.MDNCode.Z120206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120206.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020601',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020602',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020603',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020604',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020604.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020604.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020605',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020605.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020605.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020606',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020606.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020606.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020607',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020607.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020607.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020608',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020608.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020608.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020680',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020680.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020682',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020682.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020682.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020685',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020685.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020685.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020699',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120207',
      label: 'CODELISTCONSTANTS.MDNCode.Z120207.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120207.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020701',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020702',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020703',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020703.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020704',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020704.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020704.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020705',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020705.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020705.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020706',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020706.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020706.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020707',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020707.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020707.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020708',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020708.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020708.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1202070801',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202070801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202070801.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202070880',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202070880.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202070880.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202070882',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202070882.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202070882.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202070885',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202070885.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202070885.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12020709',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020709.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020709.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020710',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020710.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020710.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020711',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020711.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020711.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020712',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020712.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020712.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020713',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020713.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020713.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020780',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020782',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020782.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020785',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020799',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020799.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020799.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120208',
      label: 'CODELISTCONSTANTS.MDNCode.Z120208.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120208.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020801',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020802',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020802.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020802.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020880',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020880.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020880.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020882',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020882.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020882.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020885',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020885.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020885.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020899',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020899.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120209',
      label: 'CODELISTCONSTANTS.MDNCode.Z120209.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120209.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12020901',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020901.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020901.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020902',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020902.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020902.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020903',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020903.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020903.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020980',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020980.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020980.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020982',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020982.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020982.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020985',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020985.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020985.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12020999',
      label: 'CODELISTCONSTANTS.MDNCode.Z12020999.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12020999.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120210',
      label: 'CODELISTCONSTANTS.MDNCode.Z120210.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120210.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12021001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021008',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021009',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021010',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021010.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021011',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021011.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1202101101',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202101101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202101101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202101180',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202101180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202101180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202101182',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202101182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202101182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202101185',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202101185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202101185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12021080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120211',
      label: 'CODELISTCONSTANTS.MDNCode.Z120211.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120211.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12021101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021102',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021102.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1202110201',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202110201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202110201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202110280',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202110280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202110280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202110282',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202110282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202110282.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1202110285',
      label: 'CODELISTCONSTANTS.MDNCode.Z1202110285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1202110285.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12021180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120212',
      label: 'CODELISTCONSTANTS.MDNCode.Z120212.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120212.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12021201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021202',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12021285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12021285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12021285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120290',
      label: 'CODELISTCONSTANTS.MDNCode.Z120290.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120290.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12029001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029008',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029009',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029011',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029011.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029012',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029012.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029012.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029014',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029014.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029014.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029015',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029015.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029015.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029016',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029016.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029016.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029017',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029017.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029017.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12029099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12029099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12029099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1203',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120301',
      label: 'CODELISTCONSTANTS.MDNCode.Z120301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120301.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12030101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030101.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1203010101',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010101.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010180',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010180.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010182',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010182.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010185',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010185.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010199',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010199.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12030102',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030103',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030104',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030105',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030105.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1203010501',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010502',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010503',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010503.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010504',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010504.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010504.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010580',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010580.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010582',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010582.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010585',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010585.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203010599',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203010599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203010599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12030190',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030190.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030190.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1203019001',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203019002',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203019003',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203019004',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203019005',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203019080',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019080.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203019082',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019082.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203019085',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019085.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203019099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203019099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z120302',
      label: 'CODELISTCONSTANTS.MDNCode.Z120302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12030201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030202',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030202.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1203020201',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020201.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020202',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020202.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020280',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020280.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020282',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020282.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020285',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020285.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020299',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020299.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12030203',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030203.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1203020301',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020302',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12030204',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030204.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1203020401',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020402',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020403',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020404',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020404.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020404.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020405',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020405.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020405.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020406',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020406.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020406.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020407',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020407.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020407.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020408',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020408.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020408.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020480',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020480.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020482',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020482.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020485',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020485.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020499',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12030205',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030205.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1203020501',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1203020599',
      label: 'CODELISTCONSTANTS.MDNCode.Z1203020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1203020599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12030280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030299',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120303',
      label: 'CODELISTCONSTANTS.MDNCode.Z120303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12030301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030302',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030303',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030304',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030305',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030305.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030305.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030306',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030306.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030306.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030399',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120304',
      label: 'CODELISTCONSTANTS.MDNCode.Z120304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120304.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120305',
      label: 'CODELISTCONSTANTS.MDNCode.Z120305.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120305.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12030501',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030502',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030503',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030580',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030582',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030585',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030599',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120306',
      label: 'CODELISTCONSTANTS.MDNCode.Z120306.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120306.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12030601',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030602',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030603',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030680',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030680.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030682',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030682.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030682.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12030685',
      label: 'CODELISTCONSTANTS.MDNCode.Z12030685.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12030685.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120308',
      label: 'CODELISTCONSTANTS.MDNCode.Z120308.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120308.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120309',
      label: 'CODELISTCONSTANTS.MDNCode.Z120309.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120309.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120390',
      label: 'CODELISTCONSTANTS.MDNCode.Z120390.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120390.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12039001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12039001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12039001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12039002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12039002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12039002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12039080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12039080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12039080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12039082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12039082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12039082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12039085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12039085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12039085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12039099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12039099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12039099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1204',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120401',
      label: 'CODELISTCONSTANTS.MDNCode.Z120401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120401.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12040101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040102',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040103',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040104',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040105',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040106',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040107',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040107.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040108',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040108.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040108.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040109',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040109.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040110',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040110.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040110.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040111',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040111.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040111.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040112',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040112.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040112.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040113',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040113.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040113.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040114',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040114.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040114.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040115',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040115.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040115.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1204011501',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204011501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204011501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204011502',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204011502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204011502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204011585',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204011585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204011585.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204011599',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204011599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204011599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12040116',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040116.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040116.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040117',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040117.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040117.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040118',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040118.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040118.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120402',
      label: 'CODELISTCONSTANTS.MDNCode.Z120402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120402.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12040201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040202',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040203',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040205',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040206',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040206.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040207',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040207.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040207.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040208',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040208.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040208.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040210',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040210.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040210.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040211',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040211.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040211.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040213',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040213.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040213.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040214',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040214.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040214.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040215',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040215.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040215.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1204021501',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204021580',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021580.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204021582',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021582.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204021585',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021585.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12040216',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040216.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040216.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1204021601',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021601.description',
      level: 6,
      bottomLevel: false,
    },
    {
      value: 'Z120402160101',
      label: 'CODELISTCONSTANTS.MDNCode.Z120402160101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120402160101.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z120402160102',
      label: 'CODELISTCONSTANTS.MDNCode.Z120402160102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120402160102.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z120402160103',
      label: 'CODELISTCONSTANTS.MDNCode.Z120402160103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120402160103.description',
      level: 7,
      bottomLevel: true,
    },
    {
      value: 'Z1204021602',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204021603',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021603.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204021680',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021680.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204021685',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021685.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021685.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1204021699',
      label: 'CODELISTCONSTANTS.MDNCode.Z1204021699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1204021699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12040218',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040218.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040218.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040219',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040219.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040219.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12040299',
      label: 'CODELISTCONSTANTS.MDNCode.Z12040299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12040299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120499',
      label: 'CODELISTCONSTANTS.MDNCode.Z120499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120499.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z1205',
      label: 'CODELISTCONSTANTS.MDNCode.Z1205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1205.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120501',
      label: 'CODELISTCONSTANTS.MDNCode.Z120501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12050101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120502',
      label: 'CODELISTCONSTANTS.MDNCode.Z120502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12050201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120503',
      label: 'CODELISTCONSTANTS.MDNCode.Z120503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120503.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12050301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050302',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050303',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120504',
      label: 'CODELISTCONSTANTS.MDNCode.Z120504.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120504.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12050401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050402',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050403',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050404',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050404.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050499',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120505',
      label: 'CODELISTCONSTANTS.MDNCode.Z120505.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120505.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12050501',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050580',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050582',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050585',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120506',
      label: 'CODELISTCONSTANTS.MDNCode.Z120506.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120506.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12050601',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050602',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050603',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050680',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050680.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050682',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050682.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050682.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050685',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050685.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050685.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050699',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120507',
      label: 'CODELISTCONSTANTS.MDNCode.Z120507.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120507.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12050701',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050702',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050703',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050703.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050780',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050780.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1205078001',
      label: 'CODELISTCONSTANTS.MDNCode.Z1205078001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1205078001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1205078099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1205078099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1205078099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12050782',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050782.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050785',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120508',
      label: 'CODELISTCONSTANTS.MDNCode.Z120508.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120508.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12050801',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050802',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050802.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050802.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050803',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050803.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050803.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050804',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050804.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050804.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050880',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050880.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050880.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050882',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050882.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050882.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050885',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050885.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050885.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12050899',
      label: 'CODELISTCONSTANTS.MDNCode.Z12050899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12050899.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120590',
      label: 'CODELISTCONSTANTS.MDNCode.Z120590.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120590.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12059001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059008',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059009',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059010',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059010.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059011',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059011.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059012',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059012.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059012.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059013',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059013.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059013.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12059099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12059099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12059099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1206',
      label: 'CODELISTCONSTANTS.MDNCode.Z1206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1206.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120601',
      label: 'CODELISTCONSTANTS.MDNCode.Z120601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120601.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120602',
      label: 'CODELISTCONSTANTS.MDNCode.Z120602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120602.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120603',
      label: 'CODELISTCONSTANTS.MDNCode.Z120603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120603.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120604',
      label: 'CODELISTCONSTANTS.MDNCode.Z120604.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120604.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120605',
      label: 'CODELISTCONSTANTS.MDNCode.Z120605.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120605.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120606',
      label: 'CODELISTCONSTANTS.MDNCode.Z120606.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120606.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120607',
      label: 'CODELISTCONSTANTS.MDNCode.Z120607.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120607.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120608',
      label: 'CODELISTCONSTANTS.MDNCode.Z120608.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120608.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120609',
      label: 'CODELISTCONSTANTS.MDNCode.Z120609.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120609.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120610',
      label: 'CODELISTCONSTANTS.MDNCode.Z120610.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120610.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120611',
      label: 'CODELISTCONSTANTS.MDNCode.Z120611.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120611.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120612',
      label: 'CODELISTCONSTANTS.MDNCode.Z120612.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120612.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120613',
      label: 'CODELISTCONSTANTS.MDNCode.Z120613.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120613.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120614',
      label: 'CODELISTCONSTANTS.MDNCode.Z120614.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120614.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120615',
      label: 'CODELISTCONSTANTS.MDNCode.Z120615.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120615.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120616',
      label: 'CODELISTCONSTANTS.MDNCode.Z120616.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120616.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120617',
      label: 'CODELISTCONSTANTS.MDNCode.Z120617.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120617.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120618',
      label: 'CODELISTCONSTANTS.MDNCode.Z120618.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120618.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120619',
      label: 'CODELISTCONSTANTS.MDNCode.Z120619.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120619.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120620',
      label: 'CODELISTCONSTANTS.MDNCode.Z120620.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120620.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120621',
      label: 'CODELISTCONSTANTS.MDNCode.Z120621.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120621.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120622',
      label: 'CODELISTCONSTANTS.MDNCode.Z120622.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120622.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120623',
      label: 'CODELISTCONSTANTS.MDNCode.Z120623.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120623.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120624',
      label: 'CODELISTCONSTANTS.MDNCode.Z120624.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120624.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12062401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062402',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062403',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062404',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062404.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062499',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120625',
      label: 'CODELISTCONSTANTS.MDNCode.Z120625.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120625.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12062501',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062502',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062503',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062504',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062504.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062504.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062505',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062505.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062505.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062506',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062506.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062506.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062580',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062582',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062585',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062599',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120626',
      label: 'CODELISTCONSTANTS.MDNCode.Z120626.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120626.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12062601',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062602',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062602.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062603',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062603.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062680',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062680.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062682',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062682.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062682.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062685',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062685.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062685.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062699',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062699.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120627',
      label: 'CODELISTCONSTANTS.MDNCode.Z120627.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120627.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12062701',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062702',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062703',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062703.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062704',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062704.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062704.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062705',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062705.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062705.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062780',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062782',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062782.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062785',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062799',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062799.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062799.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120628',
      label: 'CODELISTCONSTANTS.MDNCode.Z120628.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120628.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12062801',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062802',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062802.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062802.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062880',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062880.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062880.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062882',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062882.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062882.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062885',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062885.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062885.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12062899',
      label: 'CODELISTCONSTANTS.MDNCode.Z12062899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12062899.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120690',
      label: 'CODELISTCONSTANTS.MDNCode.Z120690.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120690.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12069001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12069001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12069001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12069002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12069002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12069002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12069003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12069003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12069003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12069080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12069080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12069080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12069082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12069082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12069082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12069085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12069085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12069085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12069099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12069099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12069099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1207',
      label: 'CODELISTCONSTANTS.MDNCode.Z1207.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1207.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120701',
      label: 'CODELISTCONSTANTS.MDNCode.Z120701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120702',
      label: 'CODELISTCONSTANTS.MDNCode.Z120702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120703',
      label: 'CODELISTCONSTANTS.MDNCode.Z120703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120704',
      label: 'CODELISTCONSTANTS.MDNCode.Z120704.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120704.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120706',
      label: 'CODELISTCONSTANTS.MDNCode.Z120706.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120706.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120707',
      label: 'CODELISTCONSTANTS.MDNCode.Z120707.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120707.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120780',
      label: 'CODELISTCONSTANTS.MDNCode.Z120780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120780.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120782',
      label: 'CODELISTCONSTANTS.MDNCode.Z120782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120782.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120785',
      label: 'CODELISTCONSTANTS.MDNCode.Z120785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120785.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z120799',
      label: 'CODELISTCONSTANTS.MDNCode.Z120799.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z1208',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120801',
      label: 'CODELISTCONSTANTS.MDNCode.Z120801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120801.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12080101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080102',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080103',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080104',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080104.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1208010401',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208010401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208010401.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208010402',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208010402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208010402.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208010403',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208010403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208010403.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208010480',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208010480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208010480.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208010482',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208010482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208010482.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208010485',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208010485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208010485.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208010499',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208010499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208010499.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12080105',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120802',
      label: 'CODELISTCONSTANTS.MDNCode.Z120802.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120802.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12080201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080202',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080203',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080204',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080205',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080205.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080299',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120803',
      label: 'CODELISTCONSTANTS.MDNCode.Z120803.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120803.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12080301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080303',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080304',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080304.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080305',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080305.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080305.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080399',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120804',
      label: 'CODELISTCONSTANTS.MDNCode.Z120804.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120804.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12080401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080402',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080403',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080403.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1208040301',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208040301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208040301.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208040302',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208040302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208040302.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208040380',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208040380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208040380.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208040382',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208040382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208040382.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1208040385',
      label: 'CODELISTCONSTANTS.MDNCode.Z1208040385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1208040385.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12080404',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080404.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080405',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080405.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080405.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080406',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080406.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080406.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080407',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080407.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080407.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080408',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080408.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080408.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080409',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080409.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080409.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080410',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080410.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080410.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12080499',
      label: 'CODELISTCONSTANTS.MDNCode.Z12080499.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12080499.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120899',
      label: 'CODELISTCONSTANTS.MDNCode.Z120899.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120899.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z1209',
      label: 'CODELISTCONSTANTS.MDNCode.Z1209.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1209.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z120901',
      label: 'CODELISTCONSTANTS.MDNCode.Z120901.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120901.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12090101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120902',
      label: 'CODELISTCONSTANTS.MDNCode.Z120902.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120902.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12090201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120903',
      label: 'CODELISTCONSTANTS.MDNCode.Z120903.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120903.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12090301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12090385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12090385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12090385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z120990',
      label: 'CODELISTCONSTANTS.MDNCode.Z120990.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z120990.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12099001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12099099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12099099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12099099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1210',
      label: 'CODELISTCONSTANTS.MDNCode.Z1210.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1210.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z121001',
      label: 'CODELISTCONSTANTS.MDNCode.Z121001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121001.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121002',
      label: 'CODELISTCONSTANTS.MDNCode.Z121002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121002.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121003',
      label: 'CODELISTCONSTANTS.MDNCode.Z121003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121003.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12100301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100302',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100303',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121004',
      label: 'CODELISTCONSTANTS.MDNCode.Z121004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121004.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12100401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121005',
      label: 'CODELISTCONSTANTS.MDNCode.Z121005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121005.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12100501',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100580',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100582',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100585',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121006',
      label: 'CODELISTCONSTANTS.MDNCode.Z121006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121006.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12100601',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100601.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100680',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100680.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100682',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100682.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100682.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100685',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100685.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100685.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121007',
      label: 'CODELISTCONSTANTS.MDNCode.Z121007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121007.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12100701',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100701.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100702',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100702.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100703',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100703.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100780',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100780.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100782',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100782.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100785',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100785.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121008',
      label: 'CODELISTCONSTANTS.MDNCode.Z121008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121008.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12100801',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100801.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100801.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100802',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100802.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100802.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100803',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100803.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100803.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100880',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100880.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100880.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100882',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100882.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100882.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100885',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100885.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100885.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121009',
      label: 'CODELISTCONSTANTS.MDNCode.Z121009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121009.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12100901',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100901.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100901.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100902',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100902.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100902.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100980',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100980.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100980.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100982',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100982.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100982.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12100985',
      label: 'CODELISTCONSTANTS.MDNCode.Z12100985.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12100985.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121010',
      label: 'CODELISTCONSTANTS.MDNCode.Z121010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121010.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12101001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121011',
      label: 'CODELISTCONSTANTS.MDNCode.Z121011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121011.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12101101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101102',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101103',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101104',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101105',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101106',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12101199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12101199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12101199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121090',
      label: 'CODELISTCONSTANTS.MDNCode.Z121090.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121090.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12109001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12109099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12109099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12109099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1211',
      label: 'CODELISTCONSTANTS.MDNCode.Z1211.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1211.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z121101',
      label: 'CODELISTCONSTANTS.MDNCode.Z121101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121101.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12110101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12110101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12110101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12110102',
      label: 'CODELISTCONSTANTS.MDNCode.Z12110102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12110102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12110180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12110180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12110180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12110182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12110182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12110182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12110185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12110185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12110185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12110199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12110199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12110199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121190',
      label: 'CODELISTCONSTANTS.MDNCode.Z121190.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121190.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12119001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119008',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119009',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12119099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12119099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12119099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1212',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z121201',
      label: 'CODELISTCONSTANTS.MDNCode.Z121201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121201.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12120101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120102',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120103',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120103.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120104',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120104.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120105',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120105.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120106',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120106.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120107',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120107.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120107.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120108',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120108.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120108.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120109',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120109.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120109.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120110',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120110.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120110.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120111',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120111.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120111.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120112',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120112.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120112.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120113',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120113.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120113.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120114',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120114.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120114.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120115',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120115.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120115.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120116',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120116.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120116.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120117',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120117.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120117.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120118',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120118.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120118.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120119',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120119.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120119.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120120',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120120.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120120.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1212012001',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012001.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212012002',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012002.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212012003',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012003.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212012004',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012004.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212012005',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012005.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212012080',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012080.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212012082',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012082.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212012085',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012085.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212012099',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212012099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212012099.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12120121',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120121.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120121.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120122',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120122.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120122.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120123',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120123.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120123.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120124',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120124.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120124.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120125',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120125.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120125.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121202',
      label: 'CODELISTCONSTANTS.MDNCode.Z121202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121202.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12120201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120202',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120203',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120204',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120204.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120204.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120205',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120205.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120205.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1212020501',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020501.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020502',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020502.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020580',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020580.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020582',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020582.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020585',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020585.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020599',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020599.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12120206',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120206.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120206.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1212020601',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020601.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020602',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020602.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020680',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020680.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020680.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020682',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020682.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020682.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020685',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020685.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020685.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020699',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020699.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020699.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12120207',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120207.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120207.description',
      level: 5,
      bottomLevel: false,
    },
    {
      value: 'Z1212020701',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020701.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020702',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020702.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020703',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020703.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020780',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020780.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020782',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020782.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020782.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020785',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020785.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020785.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z1212020799',
      label: 'CODELISTCONSTANTS.MDNCode.Z1212020799.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1212020799.description',
      level: 6,
      bottomLevel: true,
    },
    {
      value: 'Z12120208',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120208.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120208.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12120299',
      label: 'CODELISTCONSTANTS.MDNCode.Z12120299.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12120299.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121290',
      label: 'CODELISTCONSTANTS.MDNCode.Z121290.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121290.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12129001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12129001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12129001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12129080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12129080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12129080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12129082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12129082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12129082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12129085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12129085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12129085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12129099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12129099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12129099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1213',
      label: 'CODELISTCONSTANTS.MDNCode.Z1213.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1213.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z121301',
      label: 'CODELISTCONSTANTS.MDNCode.Z121301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121301.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121302',
      label: 'CODELISTCONSTANTS.MDNCode.Z121302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121302.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12130201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130202',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121303',
      label: 'CODELISTCONSTANTS.MDNCode.Z121303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121303.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12130301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121304',
      label: 'CODELISTCONSTANTS.MDNCode.Z121304.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121304.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12130401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121305',
      label: 'CODELISTCONSTANTS.MDNCode.Z121305.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121305.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12130501',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130502',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130502.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130503',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130503.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130503.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130580',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130582',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130585',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12130599',
      label: 'CODELISTCONSTANTS.MDNCode.Z12130599.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12130599.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121390',
      label: 'CODELISTCONSTANTS.MDNCode.Z121390.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121390.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12139001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12139099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12139099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12139099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1214',
      label: 'CODELISTCONSTANTS.MDNCode.Z1214.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1214.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z121401',
      label: 'CODELISTCONSTANTS.MDNCode.Z121401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121401.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121402',
      label: 'CODELISTCONSTANTS.MDNCode.Z121402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121402.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121403',
      label: 'CODELISTCONSTANTS.MDNCode.Z121403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121403.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12140301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140302',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121404',
      label: 'CODELISTCONSTANTS.MDNCode.Z121404.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121404.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12140401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140402',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140403',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140403.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140403.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140404',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140404.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140404.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121405',
      label: 'CODELISTCONSTANTS.MDNCode.Z121405.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121405.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12140501',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140501.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140580',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140580.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140580.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140582',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140582.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140582.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12140585',
      label: 'CODELISTCONSTANTS.MDNCode.Z12140585.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12140585.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121490',
      label: 'CODELISTCONSTANTS.MDNCode.Z121490.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121490.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12149001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149008',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149009',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149010',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149010.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149011',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149011.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12149099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12149099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12149099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1215',
      label: 'CODELISTCONSTANTS.MDNCode.Z1215.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1215.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z121501',
      label: 'CODELISTCONSTANTS.MDNCode.Z121501.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121501.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12150101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150102',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150102.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150199',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150199.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150199.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121502',
      label: 'CODELISTCONSTANTS.MDNCode.Z121502.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121502.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12150201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150202',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150203',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150203.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150203.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12150285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12150285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12150285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121590',
      label: 'CODELISTCONSTANTS.MDNCode.Z121590.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121590.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12159001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159005',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159005.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159006',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159006.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159007',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159007.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159008',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159008.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159008.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159009',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159009.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159010',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159010.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159011',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159011.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12159099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12159099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12159099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1216',
      label: 'CODELISTCONSTANTS.MDNCode.Z1216.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1216.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z121601',
      label: 'CODELISTCONSTANTS.MDNCode.Z121601.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121601.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12160101',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160101.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160180',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160180.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160180.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160182',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160182.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160182.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160185',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160185.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160185.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121602',
      label: 'CODELISTCONSTANTS.MDNCode.Z121602.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121602.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12160201',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160201.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160201.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160202',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160202.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160202.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160280',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160280.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160280.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160282',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160282.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160282.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160285',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160285.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160285.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121603',
      label: 'CODELISTCONSTANTS.MDNCode.Z121603.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121603.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12160301',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160301.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160302',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160302.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160303',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160303.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160303.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160380',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160380.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160380.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160382',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160382.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160382.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160385',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160385.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160385.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160399',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160399.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121604',
      label: 'CODELISTCONSTANTS.MDNCode.Z121604.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121604.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12160401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160480',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160480.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160480.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160482',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160482.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160482.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12160485',
      label: 'CODELISTCONSTANTS.MDNCode.Z12160485.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12160485.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121605',
      label: 'CODELISTCONSTANTS.MDNCode.Z121605.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121605.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121690',
      label: 'CODELISTCONSTANTS.MDNCode.Z121690.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121690.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12169001',
      label: 'CODELISTCONSTANTS.MDNCode.Z12169001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12169001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12169002',
      label: 'CODELISTCONSTANTS.MDNCode.Z12169002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12169002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12169003',
      label: 'CODELISTCONSTANTS.MDNCode.Z12169003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12169003.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12169004',
      label: 'CODELISTCONSTANTS.MDNCode.Z12169004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12169004.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12169080',
      label: 'CODELISTCONSTANTS.MDNCode.Z12169080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12169080.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12169082',
      label: 'CODELISTCONSTANTS.MDNCode.Z12169082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12169082.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12169085',
      label: 'CODELISTCONSTANTS.MDNCode.Z12169085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12169085.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12169099',
      label: 'CODELISTCONSTANTS.MDNCode.Z12169099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12169099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1217',
      label: 'CODELISTCONSTANTS.MDNCode.Z1217.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1217.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z121701',
      label: 'CODELISTCONSTANTS.MDNCode.Z121701.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121701.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121702',
      label: 'CODELISTCONSTANTS.MDNCode.Z121702.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121702.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121703',
      label: 'CODELISTCONSTANTS.MDNCode.Z121703.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121703.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121704',
      label: 'CODELISTCONSTANTS.MDNCode.Z121704.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121704.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z12170401',
      label: 'CODELISTCONSTANTS.MDNCode.Z12170401.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12170401.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z12170402',
      label: 'CODELISTCONSTANTS.MDNCode.Z12170402.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z12170402.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z121780',
      label: 'CODELISTCONSTANTS.MDNCode.Z121780.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121780.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z121799',
      label: 'CODELISTCONSTANTS.MDNCode.Z121799.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z121799.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z1290',
      label: 'CODELISTCONSTANTS.MDNCode.Z1290.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1290.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z129003',
      label: 'CODELISTCONSTANTS.MDNCode.Z129003.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129003.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129004',
      label: 'CODELISTCONSTANTS.MDNCode.Z129004.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129004.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129005',
      label: 'CODELISTCONSTANTS.MDNCode.Z129005.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129005.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129006',
      label: 'CODELISTCONSTANTS.MDNCode.Z129006.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129006.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129007',
      label: 'CODELISTCONSTANTS.MDNCode.Z129007.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129007.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129009',
      label: 'CODELISTCONSTANTS.MDNCode.Z129009.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129009.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129010',
      label: 'CODELISTCONSTANTS.MDNCode.Z129010.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129010.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129011',
      label: 'CODELISTCONSTANTS.MDNCode.Z129011.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129011.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129012',
      label: 'CODELISTCONSTANTS.MDNCode.Z129012.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129012.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129013',
      label: 'CODELISTCONSTANTS.MDNCode.Z129013.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129013.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129017',
      label: 'CODELISTCONSTANTS.MDNCode.Z129017.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129017.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129080',
      label: 'CODELISTCONSTANTS.MDNCode.Z129080.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129080.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129082',
      label: 'CODELISTCONSTANTS.MDNCode.Z129082.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129082.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129085',
      label: 'CODELISTCONSTANTS.MDNCode.Z129085.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129085.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z129099',
      label: 'CODELISTCONSTANTS.MDNCode.Z129099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z129099.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z13',
      label: 'CODELISTCONSTANTS.MDNCode.Z13.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z13.description',
      level: 2,
      bottomLevel: false,
    },
    {
      value: 'Z1301',
      label: 'CODELISTCONSTANTS.MDNCode.Z1301.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1301.description',
      level: 3,
      bottomLevel: false,
    },
    {
      value: 'Z130101',
      label: 'CODELISTCONSTANTS.MDNCode.Z130101.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z130101.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z130102',
      label: 'CODELISTCONSTANTS.MDNCode.Z130102.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z130102.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z130103',
      label: 'CODELISTCONSTANTS.MDNCode.Z130103.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z130103.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z130104',
      label: 'CODELISTCONSTANTS.MDNCode.Z130104.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z130104.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z130105',
      label: 'CODELISTCONSTANTS.MDNCode.Z130105.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z130105.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z130106',
      label: 'CODELISTCONSTANTS.MDNCode.Z130106.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z130106.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z130107',
      label: 'CODELISTCONSTANTS.MDNCode.Z130107.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z130107.description',
      level: 4,
      bottomLevel: true,
    },
    {
      value: 'Z130190',
      label: 'CODELISTCONSTANTS.MDNCode.Z130190.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z130190.description',
      level: 4,
      bottomLevel: false,
    },
    {
      value: 'Z13019001',
      label: 'CODELISTCONSTANTS.MDNCode.Z13019001.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z13019001.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z13019002',
      label: 'CODELISTCONSTANTS.MDNCode.Z13019002.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z13019002.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z13019099',
      label: 'CODELISTCONSTANTS.MDNCode.Z13019099.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z13019099.description',
      level: 5,
      bottomLevel: true,
    },
    {
      value: 'Z1302',
      label: 'CODELISTCONSTANTS.MDNCode.Z1302.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1302.description',
      level: 3,
      bottomLevel: true,
    },
    {
      value: 'Z1399',
      label: 'CODELISTCONSTANTS.MDNCode.Z1399.label',
      description: 'CODELISTCONSTANTS.MDNCode.Z1399.description',
      level: 3,
      bottomLevel: true,
    },
  ],
};
